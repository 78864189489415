import moment from 'moment';
import { useState } from 'react';
import { Table } from '../../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../../context/PaginationContext';
import {
  GreenBadge,
  RedBadge,
  YellowBadge,
} from '../../../../components/Badges/Badges';
import { Button } from '../../../../components/Buttons/Button';
import NoData from '../../../../components/NoData/NoData';
import useCompaniesWithExpiringCertifications from '../../../../hooks/api/useCompaniesWithExpiringCertifications';

type ExpiringCertificationCompanyListProps = {
  certificationTypeId?: string;
  projectId?: string;
};

export const CompanyExpiringCertificationsTable = ({
  certificationTypeId,
  projectId,
}: ExpiringCertificationCompanyListProps) => {
  const { limit, offset, setOffset } = usePagination();

  const tabs = [
    'Expired',
    'Invalid',
    'Next 30 days',
    'Next 3 months',
    'Next 6 months',
    'Next 12 months',
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const headerMapping = {
    companyName: 'Company Name',
    owner: 'Company Owner',
    companyEmail: 'Email',
    expiry: 'Expires',
    expiryDate: 'Expiry Date',
  } as Record<string, any>;

  const [expiringCertifications, { count, loading }] =
    useCompaniesWithExpiringCertifications({
      certificationTypeId,
      projectId,
      ...(activeTab
        ? { dateFilter: activeTab?.replaceAll(' ', '_')?.toUpperCase() }
        : {}),
      limit,
      offset,
    });

  const totalPages = Math.ceil(count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  return (
    <div>
      <>
        <div className="flex bg-white w-full flex-row">
          {tabs.map((tab) => (
            <div
              className={`${
                activeTab === tab ? 'bg-black text-white' : ''
              } justify-center flex items-center`}
            >
              <Button
                style={{
                  borderRadius: 0,
                  backgroundColor: 'transparent',
                  color: activeTab === tab ? 'white' : 'black',
                }}
                text={
                  tab?.includes('Next')
                    ? `Expiring ${tab}`
                    : tab === 'Invalid'
                    ? 'Invalid / No Attachment'
                    : tab
                }
                onClick={() => setActiveTab(tab)}
              />
            </div>
          ))}
        </div>
        <Table
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          showFilterOptions={false}
          filterDimensions={[]}
          headerMapper={(text: string) => headerMapping[text]}
          onPageChange={handlePageChange}
          data={expiringCertifications.map((result: any) => {
            const expiryDate = result?.certifications?.[0]?.expiryDate
              ? moment.unix(
                  parseInt(result?.certifications?.[0]?.expiryDate) / 1000
                )
              : undefined;
            const now = moment();
            const tenWeeksFromNow = moment().add(10, 'weeks');

            return {
              id: result?.id,
              companyName: result?.companyName,
              owner: result?.owner?.firstName
                ? `${result?.owner?.lastName}, ${result?.owner?.firstName}`
                : '',
              companyEmail: result.companyEmail,
              expiry: expiryDate ? (
                expiryDate.isBefore(now) ? (
                  <RedBadge text="Expired" />
                ) : expiryDate.isBefore(tenWeeksFromNow) ? (
                  <YellowBadge text={expiryDate.fromNow()} />
                ) : (
                  <GreenBadge text={expiryDate.fromNow()} />
                )
              ) : (
                <YellowBadge text={'No date provided'} />
              ),
              expiryDate: expiryDate
                ? expiryDate.format('LL')
                : ((<YellowBadge text={'No date provided'} />) as any),
              status: undefined,
            };
          })}
          NoData={() => (
            <NoData loading={loading}>
              It looks like there are no companies that match that filter
            </NoData>
          )}
        />
      </>
    </div>
  );
};

export const ExpiringCertificationCompanyList = ({
  certificationTypeId,
  projectId,
}: ExpiringCertificationCompanyListProps) => (
  <PaginationProvider>
    <CompanyExpiringCertificationsTable
      certificationTypeId={certificationTypeId}
      projectId={projectId}
    />
  </PaginationProvider>
);
