import { Modal } from '../../components/Dialogue/ModalDialogue';
import { Formik } from 'formik';
import * as Yup from 'yup';
import EmailInput from '../../components/Inputs/EmailInput';
import { CREATE_LOGIN_FOR_USER } from '../../graphql/mutations/users';
import { useCallback } from 'react';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { useToast } from '../../context/ToastContext';

export default function CreateLoginForUserModal({
  showModal,
  setShowModal,
  userProfile,
}: {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  userProfile: {
    id: string;
    email?: string;
  };
}) {
  const { useMutation } = useOrganisationAwareApollo();
  const [createLoginForUser] = useMutation(CREATE_LOGIN_FOR_USER);
  const { addToast } = useToast();

  const handleCreateLoginForUser = useCallback(
    async ({
      email,
      userProfileId,
    }: {
      email: string;
      userProfileId: string;
    }) => {
      const { data } = await createLoginForUser({
        variables: { email, userProfileId },
      });

      if (data?.createLoginForUser.success) {
        addToast('Successfully created a login for this user', 'success');
      } else {
        addToast('Failed to create a login for this user', 'error');
      }
    },
    [addToast, createLoginForUser]
  );

  if (!userProfile) {
    return null;
  }

  const initialValues = {
    email: userProfile.email,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Email must be valid')
      .required('Please provide an email'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await handleCreateLoginForUser({
          userProfileId: userProfile.id,
          email: values.email!,
        });
        setShowModal(false);
      }}
    >
      {({ errors, handleChange, submitForm, values }) => {
        return (
          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            title={'Confirm Details'}
            onComplete={() => {
              submitForm();
            }}
          >
            <div className="px-4 py-4">
              <div className="flex flex-col gap-y-4 w-full">
                <EmailInput
                  id="email"
                  name="email"
                  title="Title"
                  placeholder="Please enter an email for this user"
                  value={values['email']}
                  validation={errors['email']}
                  handleChange={handleChange('email')}
                />
              </div>
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
}
