import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useContext } from 'react';

import FormCard from '../../components/FormCard/FormCard';

import moment from 'moment';
import Avatar from 'react-avatar';
import { YellowBadge } from '../../components/Badges/Badges';
import { UserProfileContext } from '../../context/UserProfileContext';
import { SAVE_USER_PROFILE } from '../../graphql/mutations/users';
import { SEARCH_PROJECTS } from '../../graphql/queries/projects';
import { GET_USERS, SEARCH_GROUPS } from '../../graphql/queries/users';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../types/Form';
import { Permission } from '../../types/Permissions';
import useDebouncedState from '../../hooks/useDebouncedState';
import { useToast } from '../../context/ToastContext';
import LinkButton from '../../components/Buttons/LinkButton';

export const SaveUser = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useMutation, useQuery } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const [
    projectsSearchTerm,
    debouncedProjectsSearchTerm,
    setProjectsSearchTerm,
  ] = useDebouncedState<string>('');

  const [groupSearchTerm, debouncedGroupSearchTerm, setGroupSearchTerm] =
    useDebouncedState<string>('');

  const { userProfile } = useContext(UserProfileContext);

  const { data } = useQuery(GET_USERS, {
    variables: { input: { id } },
    fetchPolicy: 'network-only',
  });

  const { data: searchProjectsData } = useQuery(SEARCH_PROJECTS, {
    variables: {
      searchTerm: debouncedProjectsSearchTerm,
      input: { limit: 1000, offset: 0 },
    },
    fetchPolicy: 'network-only',
  });

  const { data: searchGroupsData } = useQuery(SEARCH_GROUPS, {
    variables: {
      searchTerm: debouncedGroupSearchTerm,
      input: { limit: 1000, offset: 0 },
    },
    fetchPolicy: 'network-only',
  });

  const groupResults = searchGroupsData?.searchGroups?.results || [];
  const projectResults = searchProjectsData?.searchProjects?.results || [];

  const [user] = data?.getUsers?.results || [];

  const [saveUser] = useMutation(SAVE_USER_PROFILE);

  const initialValues = id
    ? {
        ...user,
        projectFilter: user?.projectFilter ?? [],
        groups: user?.user_groups?.map((group: any) => group.id),
      }
    : {};

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const { data } = await saveUser({
        variables: {
          id: values?.id,
          input: {
            email: values?.email,
            groups: values?.groups,
            projectFilter: values?.projectFilter,
            enabled: values?.enabled,
          },
        },
      });
      if (setSubmitting) {
        setSubmitting(false);
      }
      if (data?.saveUserProfile?.success) {
        addToast('User saved successfully', 'success');
        navigate(-1);
      } else {
        if (
          data?.saveUserProfile?.message.includes(
            'Email address is already in use'
          )
        ) {
          addToast(
            `The email address ${values?.email} is already in use`,
            'error'
          );
        } else {
          addToast('Error saving user', 'error');
        }
      }
    } catch (error) {
      addToast('Error saving user', 'error');
      console.error(error);
    }
  };

  const userConfig = {
    formSections: [
      {
        title: 'User',
        components: [
          <hr />,
          <div className="flex gap-x-2">
            <Avatar
              name={`${user?.labourResource?.firstName} ${user?.labourResource?.lastName}`}
              size="35"
              round
            />
            <h1 className="text-3xl font-extrabold text-gray-900">
              {user?.labourResource?.firstName} {user?.labourResource?.lastName}
            </h1>
          </div>,
          <div className="text-gray-700 text-sm">
            Last Logged In:{' '}
            {user?.lastActivityTime
              ? moment.unix(user?.lastActivityTime / 1000).fromNow()
              : 'Never'}
          </div>,
          <div className="text-gray-700 text-sm">
            Email: {user?.email ?? <YellowBadge text="No Email Provided" />}
          </div>,
          <hr />,
        ],
        fields: [
          ...(id &&
          userProfile?.permissions.includes(Permission.UpdateUserEmailAddress)
            ? [
                {
                  title: 'Email',
                  id: 'email',
                  type: 'email',
                  placeholder: 'No email configured for this user',
                  required: true,
                },
              ]
            : []),
          ...(user?.hasUserLogin
            ? [
                {
                  id: 'groups',
                  type: 'multiselecttable',
                  title: 'Groups',
                  options: groupResults.map((group: any) => ({
                    name: group.name,
                    value: group.id,
                  })),
                  setSearchTerm: setGroupSearchTerm,
                  searchTerm: groupSearchTerm,
                },
                ...(userProfile?.permissions.includes(
                  Permission.AssignProjectFilterToUser
                )
                  ? userProfile?.personalDetails?.email !== user?.email
                    ? [
                        {
                          id: 'projectFilter',
                          type: 'multiselecttable',
                          title: 'Project Filter',
                          description:
                            'Select projects to filter this user to. Please note that some user permissions may still allow users to see beyond this filter.',
                          options: projectResults.map((project: any) => ({
                            name: project.name,
                            value: project.id,
                          })),
                          setSearchTerm: setProjectsSearchTerm,
                          searchTerm: projectsSearchTerm,
                        },
                      ]
                    : [
                        {
                          id: 'projectFilter',
                          type: 'warningPlaceholder',
                          title: 'Project Filter',
                          description: `You cannot modify your own project filter.`,
                        },
                      ]
                  : []),
                {
                  id: 'enabled',
                  type: 'switch',
                  title: 'Enabled',
                },
              ]
            : []),
        ],
      },
    ],
  } as FormConfig;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Not a valid email address')
      .required('Email is required'),
  });

  const tableQueryParams =
    sessionStorage.getItem('users_table_query_params') || '';

  const renderConfirmationModal = ({ values }: { values: any }) => {
    return (
      <div className="flex flex-col gap-y-4 text-gray-500">
        You are about to update this person's email address, please ensure this
        is correct.
        <div>
          <div className="text-lg font-medium text-gray-900">Current Email</div>
          <div>{user?.email}</div>
        </div>
        <div>
          <div className="text-lg font-medium text-gray-900">New Email</div>
          <div>{values?.email}</div>
        </div>
      </div>
    );
  };

  const shouldRenderConfirmationModal = ({
    values,
  }: {
    values: { email: string };
  }) => {
    return user?.email !== values?.email;
  };

  return (
    <>
      <LinkButton
        style={{
          backgroundColor: 'transparent',
          color: 'gray',
          boxShadow: 'none',
          borderRadius: 0,
        }}
        to={`/users/list${tableQueryParams ? `?${tableQueryParams}` : ''}`}
      >
        {`< Back to Users`}
      </LinkButton>
      <FormCard
        key={`${userConfig?.title}`}
        config={userConfig}
        isDisabled={!user?.hasUserLogin}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        renderConfirmationModal={renderConfirmationModal}
        shouldRenderConfirmationModal={shouldRenderConfirmationModal}
      />
    </>
  );
};
