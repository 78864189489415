import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { Button } from '../Buttons/Button';

type DropdownItem = {
  className?: string;
  href?: string;
  onClick?: () => void;
  text: string | React.ReactNode;
};

interface DropdownButtonProps extends React.HTMLProps<HTMLButtonElement> {
  children?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  items: DropdownItem[];
  style?: React.CSSProperties;
  isDisabled?: boolean;
}

export default function DropdownButton({
  children,
  className,
  id,
  style,
  items,
  ...props
}: DropdownButtonProps) {
  return (
    <Menu as="div" className="self-center relative inline-block text-left">
      <MenuButton
        id={id}
        className={twMerge(
          `capitalize transition-all justify-center py-2 px-4 border disabled:bg-gray-400 border-transparent hover:translate-y-0.5 hover:translate-x-0.5 shadow-sm text-sm font-medium rounded-md text-white bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`,
          className
        )}
        style={style}
        aria-label={props['aria-label']}
      >
        {children}
      </MenuButton>
      <MenuItems
        anchor="bottom end"
        className={twMerge(
          `mt-4 min-w-max max-w-[50vw] [--anchor-max-height:60vh] origin-top-right rounded-md shadow-lg bg-gray-800 focus:outline-none overflow-y-auto`,
          'transition',
          'data-[closed]:opacity-0 data-[closed]:scale-95',
          'data-[enter]:ease-out data-[enter]:duration-100',
          'data-[leave]:ease-in data-[leave]:duration-75'
        )}
        transition
      >
        {items &&
          items.map((item: DropdownItem) => {
            return (
              <div className="w-full" key={`dropdown-item-${item.text}`}>
                <MenuItem>
                  {() => (
                    <Button
                      href={item.href}
                      className={twMerge(
                        'rounded-none py-4 px-32 sm:px-4 bg-gray-800 hover:bg-black w-full text-center sm:text-left hover:translate-y-0 hover:translate-x-0',
                        item.className
                      )}
                      onClick={item.onClick}
                    >
                      {item.text}
                    </Button>
                  )}
                </MenuItem>
              </div>
            );
          })}
      </MenuItems>
    </Menu>
  );
}
