import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useContext, useEffect } from 'react';

import FormCard from '../../../components/FormCard/FormCard';

import { SAVE_GROUP } from '../../../graphql/mutations/users';
import { GET_GROUPS } from '../../../graphql/queries/users';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FieldType, FormConfig } from '../../../types/Form';
import { ActiveOrganisationContext } from '../../../context/ActiveOrganisationContext';
import { Permission } from '../../../types/Permissions';
import { useToast } from '../../../context/ToastContext';
import { Button } from '../../../components/Buttons/Button';
interface PermissionItem {
  name: string;
  value: string;
}

export interface PermissionOptions {
  organisationPermissions: PermissionItem[];
  projectPermissions: PermissionItem[];
  assessmentPermissions: PermissionItem[];
  customDataPermissions: PermissionItem[];
  userAndGroupPermissions: PermissionItem[];
  timesheetPermissions: PermissionItem[];
  progressPermissions: PermissionItem[];
  labourPermissions: PermissionItem[];
  exportPermissions: PermissionItem[];
  rolePermissions: PermissionItem[];
  companiesPermissions: PermissionItem[];
  eventTypePermissions: PermissionItem[];
  contractPermissions: PermissionItem[];
}

export const permissionOptions: PermissionOptions = {
  organisationPermissions: [
    { name: 'Update Organisation', value: 'update_organisation' },
  ],
  projectPermissions: [
    { name: 'Update Project', value: Permission.UpdateProject },
    { name: 'Update Teams', value: Permission.UpdateTeams },
    {
      name: 'Assign Labour to Project',
      value: Permission.AssignLabourToProject,
    },
    { name: 'Download Attachments', value: Permission.DownloadAttachments },
    { name: 'View Project Details', value: Permission.ViewProjectDetails },
    {
      name: 'View Project Assignment Details',
      value: Permission.ViewProjectAssignmentDetail,
    },
    {
      name: 'View Project Assignment Rates',
      value: Permission.ViewProjectAssignmentRates,
    },
    { name: 'Delete Assignment Role', value: Permission.DeleteAssignmentRole },
    {
      name: 'Generate Project Exports',
      value: Permission.GenerateProjectExports,
    },
    { name: 'Delete Project Team', value: Permission.DeleteProjectTeam },
  ],
  assessmentPermissions: [
    { name: 'View Assessments', value: Permission.ViewAssessments },
    { name: 'Update Assessments', value: Permission.UpdateAssessmentTypes },
    { name: 'Send Assessments', value: Permission.SendAssessments },
    {
      name: 'Delete Assessment Attachments',
      value: Permission.DeleteAssessmentAttachments,
    },
    {
      name: 'Delete Assessment Submissions',
      value: Permission.DeleteAssessmentSubmissions,
    },
  ],
  customDataPermissions: [
    { name: 'Update Custom Data', value: Permission.UpdateCustomData },
    { name: 'View Custom Data', value: Permission.ViewCustomData },
    { name: 'Delete Custom Data', value: Permission.DeleteCustomData },
  ],
  userAndGroupPermissions: [
    { name: 'Assign User to Group', value: Permission.AssignUserToGroup },
    {
      name: 'Assign Project Filter to User',
      value: Permission.AssignProjectFilterToUser,
    },
    { name: 'Update Group', value: Permission.UpdateGroups },
    {
      name: 'Update User Email Address',
      value: Permission.UpdateUserEmailAddress,
    },
    { name: 'View Users', value: Permission.ViewUsers },
  ],
  timesheetPermissions: [
    {
      name: 'Update Timesheet Configuration',
      value: Permission.UpdateTimesheetConfiguration,
    },
    { name: 'Approve Timesheets', value: Permission.ApproveTimesheets },
    { name: 'Unapprove Timesheets', value: Permission.UnapproveTimesheets },
    {
      name: 'View Timesheet Submissions',
      value: Permission.ViewTimesheetSubmissions,
    },
    { name: 'View Timesheets', value: Permission.ViewTimesheets },
    { name: 'View Activity Codes', value: Permission.ViewActivityCodes },
    { name: 'Update Activity Codes', value: Permission.UpdateActivityCodes },
    { name: 'Delete Activity Codes', value: Permission.DeleteActivityCodes },
  ],
  progressPermissions: [
    { name: 'View Progress Submissions', value: Permission.ViewProgressModule },
    {
      name: 'View Progress Dashboard',
      value: Permission.ViewProgressDashboard,
    },
    {
      name: 'Save Progress Submission',
      value: Permission.SaveProgressSubmission,
    },
    {
      name: 'Configure Progress Module',
      value: Permission.ConfigureProgressModule,
    },
    {
      name: 'Lock Progress Submission',
      value: Permission.LockProgressSubmission,
    },
    {
      name: 'Approve Progress Submission',
      value: Permission.ApproveProgressSubmission,
    },
    {
      name: 'Generate Progress Reports',
      value: Permission.GenerateProgressReports,
    },
    {
      name: 'Delete Progress Activity Codes',
      value: Permission.DeleteProgressActivityCodes,
    },
  ],
  labourPermissions: [
    { name: 'Update Labour Profiles', value: Permission.UpdateLabourProfiles },
    { name: 'View Labour Profiles', value: Permission.ViewLabourProfiles },
    { name: 'View Skills', value: Permission.ViewSkills },
    {
      name: 'Update Certifications',
      value: Permission.UpdateCertificationTypes,
    },
    {
      name: 'Download Certification Attachments',
      value: Permission.DownloadCertificationAttachments,
    },
    {
      name: 'Delete Certification Attachments',
      value: Permission.DeleteCertificationAttachments,
    },
    {
      name: 'Delete Certification Types',
      value: Permission.DeleteCertificationTypes,
    },
    { name: 'Delete Certifications', value: Permission.DeleteCertifications },
    { name: 'Update Skill Types', value: Permission.UpdateSkillTypes },
    { name: 'Update Skills', value: Permission.UpdateSkills },
  ],
  exportPermissions: [
    {
      name: 'Update Export Configuration',
      value: Permission.UpdateExportConfiguration,
    },
  ],
  rolePermissions: [
    { name: 'Update Roles', value: Permission.UpdateRoles },
    { name: 'View Roles', value: Permission.ViewRoles },
    { name: 'View Role Rates', value: Permission.ViewRoleRates },
  ],
  companiesPermissions: [
    { name: 'Delete Company Profiles', value: Permission.DeleteCompanyProfile },
  ],
  eventTypePermissions: [
    { name: 'View Event Types', value: Permission.ViewEventTypes },
    { name: 'Update Event Types', value: Permission.UpdateEventTypes },
  ],
  contractPermissions: [
    {
      name: 'View Contract Templates',
      value: Permission.ViewContractTemplates,
    },
    {
      name: 'Edit Contract Templates',
      value: Permission.EditContractTemplates,
    },
    { name: 'Send Contracts', value: Permission.SendContracts },
    {
      name: 'View Contract Submissions',
      value: Permission.ViewContractSubmissions,
    },
  ],
};

export const SaveGroup = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  const docusealEnabled = activeOrganisation?.docusealEnabled;

  const isUpdate = !!id;

  const { addToast } = useToast();

  const [fetchGroups, { data }] = useLazyQuery(GET_GROUPS, {
    variables: { input: { id } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchGroups({ variables: { input: { id } } });
  }, [id, isUpdate]);

  const [group] = data?.getGroups?.results || [];

  const [saveGroup] = useMutation(SAVE_GROUP);

  const initialValues = id
    ? {
        ...group,
        organisationPermissions: group?.permissions?.filter((permission: any) =>
          permissionOptions.organisationPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        projectPermissions: group?.permissions?.filter((permission: any) =>
          permissionOptions.projectPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        assessmentPermissions: group?.permissions?.filter((permission: any) =>
          permissionOptions.assessmentPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        customDataPermissions: group?.permissions?.filter((permission: any) =>
          permissionOptions.customDataPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        userAndGroupPermissions: group?.permissions?.filter((permission: any) =>
          permissionOptions.userAndGroupPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        timesheetPermissions: group?.permissions?.filter((permission: any) =>
          permissionOptions.timesheetPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        progressPermissions: group?.permissions?.filter((permission: any) =>
          permissionOptions.progressPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        labourPermissions: group?.permissions?.filter((permission: any) =>
          permissionOptions.labourPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        exportPermissions: group?.permissions?.filter((permission: any) =>
          permissionOptions.exportPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        rolePermissions: group?.permissions?.filter((permission: any) =>
          permissionOptions.rolePermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        companiesPermissions: group?.permissions?.filter((permission: any) =>
          permissionOptions.companiesPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        eventTypePermissions: group?.permissions?.filter((permission: any) =>
          permissionOptions.eventTypePermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        contractPermissions: group?.permissions?.filter((permission: any) =>
          permissionOptions.contractPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
      }
    : {
        organisationPermissions: [],
        projectPermissions: [],
        assessmentPermissions: [],
        customDataPermissions: [],
        userAndGroupPermissions: [],
        timesheetPermissions: [],
        progressPermissions: [],
        labourPermissions: [],
        exportPermissions: [],
        rolePermissions: [],
        companiesPermissions: [],
        eventTypePermissions: [],
        contractPermissions: [],
      };

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const { data } = await saveGroup({
        variables: {
          id: values?.id,
          input: {
            id: undefined,
            name: values?.name,
            permissions: [
              ...values.organisationPermissions,
              ...values.projectPermissions,
              ...values.assessmentPermissions,
              ...values.customDataPermissions,
              ...values.userAndGroupPermissions,
              ...values.timesheetPermissions,
              ...values.progressPermissions,
              ...values.labourPermissions,
              ...values.exportPermissions,
              ...values.rolePermissions,
              ...values.companiesPermissions,
              ...values.eventTypePermissions,
              ...values.contractPermissions,
            ],
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      if (setSubmitting) {
        setSubmitting(false);
      }
      if (data?.saveGroup?.success) {
        addToast('Permissions updated', 'success');
        navigate(-1);
        return;
      } else {
        addToast('Error saving permissions', 'error');
      }
    } catch (error) {
      addToast('Error saving permissions', 'error');
      console.error(error);
    }
  };

  const groupConfig = {
    formSections: [
      {
        title: 'Group',
        fields: [
          {
            id: 'name',
            type: FieldType.input,
            title: 'Name',
          },
          {
            id: 'organisationPermissions',
            type: FieldType.checkboxgroup,
            title: 'Organisation Permissions',
            options: permissionOptions.organisationPermissions,
          },
          {
            id: 'projectPermissions',
            type: FieldType.checkboxgroup,
            title: 'Project Permissions',
            options: permissionOptions.projectPermissions,
          },
          {
            id: 'assessmentPermissions',
            type: FieldType.checkboxgroup,
            title: 'Assessment Permissions',
            options: permissionOptions.assessmentPermissions,
          },
          {
            id: 'customDataPermissions',
            type: FieldType.checkboxgroup,
            title: 'Custom Data Permissions',
            options: permissionOptions.customDataPermissions,
          },
          {
            id: 'userAndGroupPermissions',
            type: FieldType.checkboxgroup,
            title: 'User & Group Permissions',
            options: permissionOptions.userAndGroupPermissions,
          },
          {
            id: 'timesheetPermissions',
            type: FieldType.checkboxgroup,
            title: 'Timesheet Permissions',
            options: permissionOptions.timesheetPermissions,
          },
          {
            id: 'progressPermissions',
            type: FieldType.checkboxgroup,
            title: 'Progress Permissions',
            options: permissionOptions.progressPermissions,
          },
          {
            id: 'labourPermissions',
            type: FieldType.checkboxgroup,
            title: 'Labour Permissions',
            options: permissionOptions.labourPermissions,
          },
          {
            id: 'exportPermissions',
            type: FieldType.checkboxgroup,
            title: 'Export Permissions',
            options: permissionOptions.exportPermissions,
          },
          {
            id: 'rolePermissions',
            type: FieldType.checkboxgroup,
            title: 'Role Permissions',
            options: permissionOptions.rolePermissions,
          },
          {
            id: 'companiesPermissions',
            type: FieldType.checkboxgroup,
            title: 'Companies Permissions',
            options: permissionOptions.companiesPermissions,
          },
          {
            id: 'eventTypePermissions',
            type: FieldType.checkboxgroup,
            title: 'Event Type Permissions',
            options: permissionOptions.eventTypePermissions,
          },
        ],
      },
    ],
  } as FormConfig;

  // inserting Contract Permissions at third place in fields if docuseal is enabled
  if (docusealEnabled && groupConfig.formSections) {
    groupConfig.formSections[0].fields = [
      ...groupConfig.formSections[0].fields.slice(0, 3),
      {
        id: 'contractPermissions',
        type: FieldType.checkboxgroup,
        title: 'Contract Permissions',
        options: permissionOptions.contractPermissions,
      },
      ...groupConfig.formSections[0].fields.slice(3),
    ];
  }

  const GroupCreationSchema = Yup.object().shape({});

  return (
    <>
      <Button
        style={{
          backgroundColor: 'transparent',
          color: 'black',
          boxShadow: 'none',
          borderRadius: 0,
        }}
        onClick={() => navigate(-1)}
        type="submit"
        text={`< Go Back`}
      />
      <FormCard
        key={`${groupConfig?.title}`}
        config={groupConfig}
        validationSchema={GroupCreationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </>
  );
};
