import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import { Table } from '../../../components/Tables/tables/Table';
import { PaginationProvider } from '../../../context/PaginationContext';
import { SAVE_TEAM_ASSIGNMENT } from '../../../graphql/mutations/assignments';
import { GET_PROJECT_TEAMS } from '../../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { AssignmentRoleList } from './assignmentRoles/list';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import LinkButton from '../../../components/Buttons/LinkButton';

const headerMapping = {
  name: 'Name',
  email: 'Email',
  type: 'Type',
  internalId: 'Internal ID',
  status: 'Status',
  updatedAt: 'Updated At',
} as Record<string, any>;

const AssignmentsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    showPagination={false}
    columnsToFilter={['firstName', 'lastName', 'id']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const AssignToTeamPage = () => {
  let { projectId, teamId } = useParams();
  const navigate = useNavigate();

  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const [saveAssignment, { loading: isSaving }] =
    useMutation(SAVE_TEAM_ASSIGNMENT);

  const [teamAssignments, setTeamAssignments] = useState<any>([]);

  const [fetchTeam, { data: teamData }] = useLazyQuery(GET_PROJECT_TEAMS, {
    variables: { input: { id: teamId }, projectId },
    fetchPolicy: 'network-only',
  });

  const [team] = teamData?.getProjectTeams?.results || [];

  useEffect(() => {
    fetchTeam({ variables: { input: { id: teamId } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  const saveAllAssignees = async () => {
    try {
      // merge the two arrays into one
      const allAssignments = [
        ...teamAssignments.map((assignment: any) => ({
          ...assignment,
        })),
      ];

      // create a promise for each assignment
      const assignmentPromises = allAssignments.map((assignment) =>
        saveAssignment({
          variables: {
            input: {
              teamId: team.id,
              assignmentRoleId: assignment.id,
            },
          },
        })
      );

      await Promise.all(assignmentPromises);
      navigate(`/projects/${projectId}/teams/${teamId}`);
    } catch (error) {
      console.error(error);
    }
  };

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="py-2 border-b">
          {projectId ? (
            <LinkButton
              style={{
                backgroundColor: 'transparent',
                color: 'black',
                boxShadow: 'none',
                borderRadius: 0,
              }}
              to={`/projects/${projectId}/teams/${teamId}`}
              type="submit"
            >
              {`< Back to Team`}
            </LinkButton>
          ) : null}
        </div>
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Assign Resource to {team?.name}
            </h1>
            <h2 className="text-lg mt-1 font-base text-gray-500">
              {team?.internalId}
            </h2>
          </div>
        </div>
        <div>
          {teamAssignments.length > 0 ? (
            <p className="text-sm px-4 text-gray-800 pb-2 border-b">
              Note: You can click on a row to remove assignments.
            </p>
          ) : null}

          {teamAssignments.length > 0 ? (
            <div className="mb-2">
              <h4 className="my-2 px-4 text-sm text-gray-600">
                Assigning {teamAssignments.length} Assignees
                {teamAssignments.length === 1 ? '' : 's'}
              </h4>
              <AssignmentsTable
                totalPages={null}
                itemsPerPage={0}
                onClickRow={(id: any) => {
                  setTeamAssignments(
                    teamAssignments.filter(
                      (employeeAssignment: any) => employeeAssignment.id !== id
                    )
                  );
                }}
                data={
                  teamAssignments.length > 0
                    ? teamAssignments?.map((result: any) => ({
                        name: `${result?.assignment?.lrs?.lastName}, ${result?.assignment?.lrs?.firstName}`,
                        ...result,
                        type: result?.role?.name,
                      }))
                    : [{}]
                }
              />
            </div>
          ) : null}
        </div>

        {teamAssignments.length > 0 ? (
          <div className="mt-4 mx-2 w-full mb-4">
            <Button
              isLoading={isSaving}
              isDisabled={
                !(
                  userProfile?.permissions?.includes(
                    Permission.AssignLabourToProject
                  ) ||
                  userProfile?.permissions?.includes(Permission.UpdateTeams)
                )
              }
              text="Save Assignments"
              onClick={async () => {
                await saveAllAssignees();
              }}
            />
          </div>
        ) : null}

        <div className="my-4 border-t py-2 px-4">
          <p className="text-sm px-4 text-gray-600">
            Select assignments from the list below to assign as resources to
            this team.
          </p>
          <p className="text-sm px-4 text-gray-600">
            Please note:{' '}
            <a
              className="text-green-600"
              href={`/projects/${projectId}?tab=Assignees`}
            >
              assignees must have a role
            </a>
            , and be enabled to appear in this list.
          </p>
          <hr className="my-2 px-10" />

          <>
            <h4 className="my-2 px-4">Assignments</h4>
            <AssignmentRoleList
              projectId={projectId ?? ''}
              filterIds={teamAssignments.map(
                (assignment: any) => assignment.id
              )}
              showNewItemButton={false}
              onClickRow={(id, results) => {
                if (
                  !teamAssignments.find(
                    (assignment: any) => assignment.id === id
                  )
                ) {
                  setTeamAssignments((assignments: any) => [
                    ...assignments,
                    results?.find((result) => result.id === id),
                  ]);
                }
              }}
            />
          </>
        </div>
      </div>
    </div>
  );
};

export const AssignToTeam = () => (
  <PaginationProvider useUrl={false}>
    <AssignToTeamPage />
  </PaginationProvider>
);
