import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';

import moment from 'moment';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { GET_MY_CERTIFICATIONS } from '../../../graphql/queries/my-profile';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import {
  GreenBadge,
  RedBadge,
  YellowBadge,
} from '../../../components/Badges/Badges';
import { useFilter } from '../../../hooks/useFilter';

const headerMapping = {
  name: 'Name',
  expiryDate: 'Expiry',
  expiry: 'Expiry Date',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
} as Record<string, any>;

const SentCertificationsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={['firstName', 'lastName', 'id', 'signerSlug', 'updatedAt']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
      createdAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
      expiry: (text: string) =>
        text
          ? moment.unix(parseInt(text) / 1000).format('LL')
          : ((<YellowBadge text={'No date provided'} />) as any),
      // @ts-ignore
      expiryDate: (text: string) => {
        if (!text) {
          return <YellowBadge text={'No date provided'} />;
        }
        const date = moment.unix(parseInt(text) / 1000);
        const now = moment();
        const tenWeeksFromNow = moment().add(10, 'weeks');

        if (date.isBefore(now)) {
          return <RedBadge text="Expired" />;
        } else if (date.isBefore(tenWeeksFromNow)) {
          return <YellowBadge text={date.fromNow()} />;
        } else {
          return <GreenBadge text={date.fromNow()} />;
        }
      },
    }}
    showFilterOptions
    filterDimensions={[{ id: 'name', name: 'Name' }]}
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const CertificationSubmissionPage = () => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const { limit, offset, setOffset } = usePagination();

  const {
    filterText,
    setFilterText,
    filterDimension,
    setFilterDimension,
    filter,
  } = useFilter({ defaultFilterDimension: 'name' });

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const [fetch, { data, loading, error }] = useLazyQuery(
    GET_MY_CERTIFICATIONS,
    {
      variables: {},
      fetchPolicy: 'network-only',
    }
  );

  const certificationSubmissions = data?.getMyCertifications?.results || [];

  useEffect(() => {
    fetch({
      variables: { input: { limit, offset, ...(filter ? { filter } : {}) } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, filter]);

  const totalPages = Math.ceil(data?.getMyCertifications?.count / limit);

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-row w-full justify-between">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Certifications
            </h1>
            <div>
              <Button
                onClick={() => navigate(`/profile/certifications/new`)}
                type="submit"
                text="Add Certification"
              />
            </div>
          </div>
        </div>
        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}
        <div className="px-2">
          <SentCertificationsTable
            currentPage={Math.floor(offset / limit) + 1}
            totalPages={totalPages}
            itemsPerPage={limit}
            totalResults={data?.getMyCertifications?.count || 0}
            onClickRow={(id: any) => {
              navigate(`/profile/certifications/${id}`);
            }}
            filterText={filterText}
            setFilterText={setFilterText}
            selectedFilterDimension={filterDimension}
            setSelectedFilterDimension={setFilterDimension}
            data={
              data?.getMyCertifications?.results.length > 0
                ? certificationSubmissions.map((result: any) => ({
                    id: result?.id,

                    name: result?.certificationType?.name,
                    issueDate: result?.issueDate
                      ? String(result?.issueDate)
                      : undefined,
                    expiryDate: result?.expiryDate
                      ? String(result?.expiryDate)
                      : undefined,
                    expiry: result?.expiryDate
                      ? String(result?.expiryDate)
                      : undefined,
                    createdAt: result?.createdAt,
                    updatedAt: result?.updatedAt,
                  }))
                : [{}]
            }
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export const MyCertification = () => (
  <PaginationProvider>
    <CertificationSubmissionPage />
  </PaginationProvider>
);
