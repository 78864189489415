import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { GET_CV_EXPORTS_FOR_LABOUR_RESOURCE } from '../../../graphql/queries/exports';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { YellowBadge } from '../../../components/Badges/Badges';
import { Button } from '../../../components/Buttons/Button';
import { useContext, useState } from 'react';
import { LoadingSpinner } from '../../../components/Loading/LoadingSpinner';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { exponentialBackoff } from '../../../utils/utils';
import { Permission } from '../../../types/Permissions';
import { CvExportModal } from './CvExportModal';
import { CvExportTable } from './CvExportTable';

type CvExportListProps = {
  labour: any;
  labourResourceId: string;
};

export type Export = {
  id: string;
  status: string;
};

function PaginatedCvExportList({
  labour,
  labourResourceId,
}: CvExportListProps) {
  const { userProfile } = useContext(UserProfileContext);
  const { useQuery } = useOrganisationAwareApollo();
  const { limit, offset, setOffset } = usePagination();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  const { data, loading, refetch } = useQuery(
    GET_CV_EXPORTS_FOR_LABOUR_RESOURCE,
    {
      variables: { labourResourceId, input: { limit, offset } },
      fetchPolicy: 'network-only',
    }
  );

  const count = data?.getCvExportsForLabourResource?.count;
  const exports: Export[] = data?.getCvExportsForLabourResource?.results;

  const exportIsProcessing = exports?.some(
    (entity) => entity.status === 'PENDING'
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      {userProfile?.permissions.includes(Permission.UpdateLabourProfiles) ? (
        <div className="w-full flex flex-col lg:flex-row justify-between items-center gap-y-2 py-2 px-6">
          <div className="text-sm">
            {`This page allows you to create a pdf export of this ${
              labour?.type === 'EMPLOYEE' ? 'employee' : 'subcontractor'
            } for use as a CV.`}{' '}
            Please note, this capability is <YellowBadge text="Beta" /> and is
            available for feedback purposes.
          </div>
          <Button
            className="text-nowrap"
            disabled={loading || isSubmitting || exportIsProcessing}
            onClick={() => setShowExportModal(true)}
          >
            New Export
          </Button>
        </div>
      ) : null}
      {labour && (
        <CvExportModal
          labour={labour}
          showModal={showExportModal}
          setShowModal={setShowExportModal}
          setIsSubmitting={setIsSubmitting}
          onConfirm={() => {
            setOffset(0);
            setIsSubmitting(false);
            // Check for 4 minutes with a backoff
            exponentialBackoff(() => refetch(), 240000);
          }}
        />
      )}
      <CvExportTable
        count={count}
        labour={labour}
        exports={exports}
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        loading={loading}
      />
    </div>
  );
}

export default function CvExportList(props: CvExportListProps) {
  return (
    <>
      <PaginationProvider>
        <PaginatedCvExportList {...props} />
      </PaginationProvider>
    </>
  );
}
