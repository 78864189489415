import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import { DataImportGrid } from '../../../components/DataImportGrid/DataImportGrid';
import Map from '../../../components/Map/Map';
import { SAVE_PROJECT_LOCATIONS } from '../../../graphql/mutations/projects';
import { GET_PROJECT_LOCATIONS } from '../../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { useToast } from '../../../context/ToastContext';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';

export const LocationPage = () => {
  let { projectId } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const [fetch, { data, loading, error }] = useLazyQuery(
    GET_PROJECT_LOCATIONS,
    {
      variables: { projectId },
      fetchPolicy: 'network-only',
    }
  );

  const locations = data?.getProjectLocations?.results || [];

  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetch({ variables: { projectId } });
  }, [projectId]);

  const [saveProjectLocations, { loading: saving }] = useMutation(
    SAVE_PROJECT_LOCATIONS
  );

  useEffect(() => {
    setRows(locations);
  }, [locations]);

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="py-2 border-b">
          {projectId ? (
            <Button
              style={{
                backgroundColor: 'transparent',
                color: 'gray',
                boxShadow: 'none',
                borderRadius: 0,
              }}
              onClick={() => navigate(`/projects/${projectId}?tab=Locations`)}
              type="submit"
              text={`< Back to Project`}
            />
          ) : null}
        </div>
        {rows?.length > 0 ? (
          <Map
            markers={[
              ...rows.map((item: any) => ({
                ...item,
                latitude: Number(item.latitude),
                longitude: Number(item.longitude),
                detail: item.category,
              })),
            ]}
          />
        ) : null}
        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}
        <div className="flex justify-end py-2 px-4">
          {userProfile?.permissions?.includes(Permission.UpdateProject) ? (
            <Button
              isLoading={saving}
              text={'Save'}
              onClick={async () => {
                try {
                  await saveProjectLocations({
                    variables: {
                      input: {
                        projectId,
                        locations: rows
                          ?.filter((row: any) => row.name || row.toBeDeleted)
                          ?.map((item: any) => ({
                            id: item.isNew ? undefined : item.id,
                            name: item.name?.trim(),
                            latitude: Number(item.latitude),
                            longitude: Number(item.longitude),
                            toBeDeleted: item.toBeDeleted,
                          })),
                      },
                    },
                  });
                  await fetch();
                  addToast('Locations saved successfully', 'success');
                } catch (error) {
                  console.error(error);
                  addToast('Failed to save locations', 'error');
                }
              }}
            />
          ) : null}
        </div>
        <DataImportGrid
          // @ts-ignore
          rows={rows}
          setRows={setRows}
          config={{
            columns: [
              { key: 'name', name: 'Name' },
              { key: 'latitude', name: 'Latitude' },
              { key: 'longitude', name: 'Longitude' },
            ],
            exampleData: [,],
          }}
        />
      </div>
    </div>
  );
};

export const LocationBulkSave = () => <LocationPage />;
