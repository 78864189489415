import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect, useState } from 'react';

import FormCard from '../../../components/FormCard/FormCard';

import {
  DELETE_CERTIFICATION_TYPE,
  SAVE_CERTIFICATION_TYPE,
} from '../../../graphql/mutations/certifications';
import { GET_CERTIFICATION_TYPES } from '../../../graphql/queries/certifications';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { SEARCH_ROLES } from '../../../graphql/queries/roles';
import { useToast } from '../../../context/ToastContext';
import useDebouncedState from '../../../hooks/useDebouncedState';
import LinkButton from '../../../components/Buttons/LinkButton';

export const SaveCertificationType = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const isUpdate = !!id;

  const [searchTerm, debouncedSearchTerm, setSearchTerm] =
    useDebouncedState<string>('');

  const { data: searchRolesData } = useQuery(SEARCH_ROLES, {
    variables: {
      searchTerm: debouncedSearchTerm,
      input: { limit: 1000, offset: 0 },
    },
    fetchPolicy: 'network-only',
  });

  const roleResults = searchRolesData?.searchRoles?.results || [];

  const { data } = useQuery(GET_CERTIFICATION_TYPES, {
    variables: { input: { id } },
    skip: !isUpdate,
    fetchPolicy: 'network-only',
  });

  const [certificationType] = data?.getCertificationTypes?.results || [];

  const [saveCertificationType] = useMutation(SAVE_CERTIFICATION_TYPE);
  const [deleteCertificationType] = useMutation(DELETE_CERTIFICATION_TYPE);

  const rateConfig = {
    formSections: [
      {
        title: 'Certification Type',
        fields: [
          {
            title: 'Name',
            id: 'name',
            type: 'input',
          },
          {
            title: 'Notifier email',
            id: 'notifierEmail',
            description:
              'Who will be notified in relation to this certification, e.g. for expiry',
            type: 'email',
          },
          {
            id: 'requiredForRoles',
            type: 'multiselecttable',
            title: 'Required For Roles',
            description:
              'Note: This must apply to specific roles. If no roles are specified, and "Required For Subcontractors" or "Required For Employees" is not enabled, this will have no effect.',
            options: roleResults.map((role: any) => ({
              name: role.name,
              value: role.id,
            })),
            searchTerm,
            setSearchTerm,
          },
          {
            title: 'Required For Subcontractors',
            id: 'requiredForSubcontractors',
            type: 'switch',
            description:
              'Note: If this is enabled, you must select some roles to have an effect.',
          },
          {
            title: 'Required For Employees',
            id: 'requiredForEmployees',
            type: 'switch',
            description:
              'Note: If this is enabled, you must select some roles to have an effect.',
          },
          {
            title: 'Required For Companies',
            id: 'requiredForCompanies',
            type: 'switch',
          },
          {
            title: 'Allows Exemption',
            id: 'allowsExemption',
            type: 'switch',
            description:
              'Note: If this is disabled, you will not be able to create exemptions for this certification type.',
          },
          {
            title: 'Requires Expiry Date',
            id: 'requiresExpiryDate',
            type: 'switch',
          },
          {
            title: 'Enabled',
            id: 'enabled',
            type: 'switch',
          },
        ],
      },
    ],
  } as FormConfig;

  const initialValues = id ? certificationType : {};

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    notifierEmail: Yup.string().email(),
  });

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    console.log(values);
    try {
      const result = await saveCertificationType({
        variables: {
          id: values?.id,
          input: {
            ...values,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      if (!result.data.saveCertificationType.success) {
        addToast('Error saving Certification Type', 'error');
      } else {
        addToast('Certification Type saved', 'success');
        navigate(`/labour/certifications`);
      }
    } catch (error) {
      console.error(error);
      addToast('Error saving Certification Type', 'error');
    }
  };

  const handleDelete = async () => {
    try {
      const result = await deleteCertificationType({
        variables: { id },
      });
      if (!result.data.deleteCertificationType.success) {
        addToast('Error deleting certification type', 'error');
      } else {
        addToast('Certification type deleted', 'success');
        navigate(`/labour/certifications`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const tableQueryParams =
    sessionStorage.getItem('certifications_table_query_params') || '';

  return (
    <>
      <LinkButton
        style={{
          backgroundColor: 'transparent',
          color: 'gray',
          boxShadow: 'none',
          borderRadius: 0,
        }}
        to={`/labour/certifications${
          tableQueryParams ? `?${tableQueryParams}` : ''
        }`}
      >
        {`< Back to Certifications`}
      </LinkButton>
      <FormCard
        key={`${rateConfig?.title}`}
        config={rateConfig}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        entityName="Certification Type"
        onDelete={handleDelete}
      />
    </>
  );
};
