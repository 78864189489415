import ValidationMessage from '../Validation/ValidationMessage';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { joinClassNames } from '../../utils/utils';
import { twMerge } from 'tailwind-merge';

export type Option = {
  id: string;
  name: string;
  value?: string;
  detail?: string;
  colour?: string;
};

interface DropDownParams {
  classNames?: string;
  menuButtonClassNames?: string;
  description?: string;
  handleChange: (value: string) => void;
  id: string;
  isDisabled?: boolean;
  label?: string;
  options?: Option[];
  placeholder?: string;
  title?: string;
  validation?: any;
  value: any;
  hideClearButton?: boolean;
  hideOptionColourSwatches?: boolean;
  emptyOptionText?: string;
}

const DropDown = ({
  classNames,
  menuButtonClassNames,
  description,
  handleChange,
  id,
  isDisabled,
  label,
  options,
  title,
  validation,
  value,
  hideClearButton,
  hideOptionColourSwatches,
  emptyOptionText,
}: DropDownParams) => {
  return (
    <div className={classNames}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {title ? title : null}
      </label>
      {validation ? <ValidationMessage message={validation} /> : null}
      <Menu as="div" className="relative inline-block text-left my-2 w-full">
        {label ? (
          <label
            htmlFor={id}
            className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs text-gray-900"
          >
            {label}
          </label>
        ) : null}
        <div className="flex items-center gap-x-2">
          <MenuButton
            id={id}
            data-testid={'keyDropdownButton'}
            disabled={isDisabled}
            className={twMerge(
              'inline-flex disabled:bg-gray-100 disabled:opacity-90 justify-center rounded-md border border-gray-300 items-center bg-white px-2 sm:px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-100 text-nowrap',
              menuButtonClassNames
            )}
          >
            {!hideOptionColourSwatches &&
            options?.find((option) => option.name === value)?.colour ? (
              <div
                className="mr-2 border"
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor:
                    options?.find((option) => option.name === value)?.colour ||
                    'white',
                }}
              ></div>
            ) : null}
            {options?.find((option) => option.value === value)?.name ||
              value ||
              emptyOptionText ||
              'Empty'}
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </MenuButton>
          {!hideClearButton && value ? (
            <button
              className="h-7 w-7 cursor-pointer"
              onClick={() => handleChange('')}
            >
              <XCircleIcon />
            </button>
          ) : null}
        </div>

        <MenuItems
          anchor="bottom start"
          style={{ zIndex: 99999 }}
          className={twMerge(
            'mt-2 w-56 [--anchor-max-height:200px] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-scroll',
            'transition',
            'data-[closed]:opacity-0 data-[closed]:scale-95',
            'data-[enter]:ease-out data-[enter]:duration-100',
            'data-[leave]:ease-in data-[leave]:duration-75'
          )}
          transition
        >
          <div className="py-1">
            {/* @ts-ignore */}
            {options?.map((option) => (
              <MenuItem
                key={option.name}
                // @ts-ignore
                onClick={
                  isDisabled
                    ? () => null
                    : () => handleChange(option.value ?? option.name)
                }
              >
                {({ focus }) => (
                  <div
                    className={joinClassNames(
                      focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'flex px-4 py-2 text-sm'
                    )}
                  >
                    {option.colour ? (
                      <div
                        className="mr-2 border"
                        style={{
                          width: 20,
                          height: 20,
                          backgroundColor: option.colour || 'white',
                        }}
                      ></div>
                    ) : null}
                    <p className="flex items-center">
                      {option.name ?? 'Empty'}
                      {option.detail ? (
                        <p className="ml-1 text-gray-400 text-xs font-light">
                          ({option.detail})
                        </p>
                      ) : null}
                    </p>
                  </div>
                )}
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Menu>
      {description ? (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      ) : null}
    </div>
  );
};

export default DropDown;
