import { useContext, useEffect, useState } from 'react';

import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { twMerge } from 'tailwind-merge';
import { ColourSummaryCard } from '../../../components/ModuleItemSummary/ColourSummaryCard';
import { PaginationProvider } from '../../../context/PaginationContext';
import { GET_STATISTICS } from '../../../graphql/queries/statistics';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { RequiredCertificationsTable } from '../../Labour/certifications/CertificationTypeDashboard';
import ProjectRolesChart from '../charts/ProjectRolesChart';
import { ExpiredCertificationsTable } from './ExpiredCertificationsTable';
import { MissingRatesTable } from './MissingRatesTable';
import { AssessmentsTable } from './AssessmentsTable';
import { IncompleteAssessmentFilter } from '../../../hooks/api/assessments/useAssignmentsWithMissingOrIncompleteAssessments';
import { LoadingSpinner } from '../../../components/Loading/LoadingSpinner';
import { CompanyCertificationsTable } from './CompanyCertificationsTable';
import { CompanyCertificationFilter } from '../../../hooks/api/companies/useCompaniesWithMissingOrInvalidCertifications';
import { ActiveOrganisationContext } from '../../../context/ActiveOrganisationContext';
import { formatCurrency } from '../../../components/Inputs/CurrencyInput';

type ProjectSummaryProps = {
  project: any;
};

export default function ProjectSummary({ project }: ProjectSummaryProps) {
  const { useLazyQuery } = useOrganisationAwareApollo();
  const [activeCard, setActiveCard] = useState<string | null>(null);

  const [fetchStats, { loading, data: projectStats }] = useLazyQuery(
    GET_STATISTICS,
    {
      variables: {
        query: [
          {
            calculation: 'projects',
            projectId: project.id,
          },
        ],
      },
      fetchPolicy: 'network-only',
    }
  );

  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  useEffect(() => {
    if (project.id) {
      fetchStats();
    }
  }, [fetchStats, project.id]);

  const activeProjectStats =
    projectStats?.getStatistics?.projects?.activeProjects[0];

  const hasComplianceIssues =
    activeProjectStats?.assessmentsAwaitingOutcomeCount > 0 ||
    activeProjectStats?.assessmentsNotStartedCount > 0 ||
    activeProjectStats?.missingAssessmentCount > 0 ||
    activeProjectStats?.missingCertificationCount > 0 ||
    activeProjectStats?.expiredCertificationCount > 0 ||
    activeProjectStats?.employeeMissingRatesCount > 0 ||
    activeProjectStats?.subcontractorMissingRatesCount > 0 ||
    activeProjectStats?.companiesMissingCertificationsCount > 0 ||
    activeProjectStats?.companiesExpiredCertificationsCount > 0;

  const toggleActiveCard = (cardName: string) => {
    activeCard === cardName ? setActiveCard(null) : setActiveCard(cardName);
  };

  const projectSummaryCards = [
    {
      heading: activeProjectStats?.missingAssessmentCount,
      description: 'Assignees with Missing Assessments',
      classNames: activeCard === 'MISSING_ASSESSMENTS' ? 'border-red-300' : '',
      onClick: () => toggleActiveCard('MISSING_ASSESSMENTS'),
    },
    {
      heading: activeProjectStats?.assessmentsNotStartedCount,
      description: 'Assignees with Assessments Not Started',
      classNames:
        activeCard === 'ASSESSMENTS_NOT_STARTED' ? 'border-red-300' : '',
      onClick: () => toggleActiveCard('ASSESSMENTS_NOT_STARTED'),
    },
    {
      heading: activeProjectStats?.assessmentsAwaitingOutcomeCount,
      description: 'Assignees with Assessments Awaiting Outcome',
      classNames:
        activeCard === 'ASSESSMENTS_AWAITING_OUTCOME' ? 'border-red-300' : '',
      onClick: () => toggleActiveCard('ASSESSMENTS_AWAITING_OUTCOME'),
    },
    {
      heading: activeProjectStats?.missingCertificationCount,
      description: 'Assignees with Missing Certifications',
      classNames:
        activeCard === 'MISSING_CERTIFICATIONS' ? 'border-red-300' : '',
      onClick: () => toggleActiveCard('MISSING_CERTIFICATIONS'),
    },
    {
      heading: activeProjectStats?.expiredCertificationCount,
      description: 'Assignees with Expired Certifications',
      classNames:
        activeCard === 'EXPIRED_CERTIFICATIONS' ? 'border-red-300' : '',
      onClick: () => toggleActiveCard('EXPIRED_CERTIFICATIONS'),
    },
    {
      heading: activeProjectStats?.companiesMissingCertificationsCount,
      description: 'Companies with Missing Certifications',
      classNames:
        activeCard === 'COMPANIES_MISSING_CERTIFICATIONS'
          ? 'border-red-300'
          : '',
      onClick: () => toggleActiveCard('COMPANIES_MISSING_CERTIFICATIONS'),
    },
    {
      heading: activeProjectStats?.companiesExpiredCertificationsCount,
      description: 'Companies with Expired Certifications',
      classNames:
        activeCard === 'COMPANIES_EXPIRED_CERTIFICATIONS'
          ? 'border-red-300'
          : '',
      onClick: () => toggleActiveCard('COMPANIES_EXPIRED_CERTIFICATIONS'),
    },
    {
      heading: activeProjectStats?.companiesExpiringCertificationsCount,
      description: 'Company Certifications expiring before Project End Date',
      classNames:
        activeCard === 'COMPANIES_EXPIRING_CERTIFICATIONS'
          ? 'border-red-300'
          : '',
      onClick: () => toggleActiveCard('COMPANIES_EXPIRING_CERTIFICATIONS'),
    },
    {
      heading: activeOrganisation?.financeModuleEnabled
        ? activeProjectStats?.employeeMissingRatesCount
        : 0,
      description: 'Employees without Rates configured',
      classNames:
        activeCard === 'EMPLOYEES_MISSING_RATES' ? 'border-red-300' : '',
      onClick: () => toggleActiveCard('EMPLOYEES_MISSING_RATES'),
    },
    {
      heading: activeOrganisation?.financeModuleEnabled
        ? activeProjectStats?.subcontractorMissingRatesCount
        : 0,
      description: 'Subcontractors without Rates configured',
      classNames:
        activeCard === 'SUBCONTRACTORS_MISSING_RATES' ? 'border-red-300' : '',
      onClick: () => toggleActiveCard('SUBCONTRACTORS_MISSING_RATES'),
    },
  ].filter((card) => card.heading > 0);

  const sections = [
    {
      title: 'Internal ID',
      content: project?.internalId,
    },
    {
      title: 'Description',
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: project?.description,
          }}
        />
      ),
    },
    {
      title: 'Default Currency',
      content: project?.defaultCurrency,
    },
    {
      title: 'Project Value',
      content: project?.projectValue
        ? formatCurrency({
            value: project.projectValue,
            currency: project?.defaultCurrency || 'GBP',
          })
        : project?.projectValue,
    },
    {
      title: 'Start Date',
      content: project?.startDate
        ? moment(Number(project?.startDate)).format('ll')
        : 'None Provided',
    },
    {
      title: 'Expected End Date',
      content: project?.endDate
        ? moment(Number(project?.endDate)).format('ll')
        : 'None Provided',
    },
    {
      title: 'Created',
      content: moment.unix(project?.createdAt / 1000).fromNow(),
    },
    {
      title: 'Updated',
      content: moment.unix(project?.updatedAt / 1000).fromNow(),
    },
    ...(project?.managers?.length
      ? [
          {
            title: 'Project Managers',
            content: project?.managers.map((manager: any) => (
              <div>{`${manager.firstName} ${manager.lastName} (${manager.email})`}</div>
            )),
          },
        ]
      : []),
  ];

  const endingInDays = project?.endDate
    ? moment(Number(project.endDate)).diff(moment(), 'days')
    : null;

  return (
    <div className="border-t border-gray-200 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
      {endingInDays && endingInDays < 60 ? (
        <h3 className="bg-yellow-50 flex gap-x-2 text-sm px-6 py-4 text-orange-600">
          <ExclamationCircleIcon className="text-orange-600 h-5" />
          According to the project end date,{' '}
          {endingInDays > 0
            ? `this project is ending in ${endingInDays} days.`
            : 'this project has ended.'}
        </h3>
      ) : null}
      {loading ? (
        <LoadingSpinner
          spinnerStyle={{
            borderColor: 'gray',
            height: '5rem',
            width: '5rem',
          }}
        />
      ) : null}
      {hasComplianceIssues ? (
        <div className="p-4">
          <h3 className="mx-4 pb-4">Compliance Issues</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {projectSummaryCards.map((card) => (
              <ColourSummaryCard
                IconComponent={() => (
                  <ExclamationCircleIcon className="w-10 text-red-900" />
                )}
                heading={card.heading}
                description={card.description}
                classNames={twMerge(
                  'bg-red-100 border-2 border-transparent hover:bg-red-200',
                  card.classNames
                )}
                onClick={card.onClick}
              />
            ))}
          </div>
        </div>
      ) : null}
      {activeCard && (
        <PaginationProvider>
          <>
            <div className="p-4">
              {activeCard === 'MISSING_ASSESSMENTS' ? (
                <AssessmentsTable
                  projectId={project.id}
                  filter={IncompleteAssessmentFilter.MISSING}
                />
              ) : null}
              {activeCard === 'ASSESSMENTS_NOT_STARTED' ? (
                <AssessmentsTable
                  projectId={project.id}
                  filter={IncompleteAssessmentFilter.NOT_STARTED}
                />
              ) : null}
              {activeCard === 'ASSESSMENTS_AWAITING_OUTCOME' ? (
                <AssessmentsTable
                  projectId={project.id}
                  filter={IncompleteAssessmentFilter.AWAITING_OUTCOME}
                />
              ) : null}
              {activeCard === 'MISSING_CERTIFICATIONS' ? (
                <RequiredCertificationsTable projectId={project.id} />
              ) : null}
              {activeCard === 'EXPIRED_CERTIFICATIONS' ? (
                <ExpiredCertificationsTable projectId={project.id} />
              ) : null}
              {activeCard === 'EMPLOYEES_MISSING_RATES' ? (
                <MissingRatesTable
                  projectId={project.id}
                  labourResourceType="EMPLOYEE"
                />
              ) : null}
              {activeCard === 'SUBCONTRACTORS_MISSING_RATES' ? (
                <MissingRatesTable
                  projectId={project.id}
                  labourResourceType="SUBCONTRACTOR"
                />
              ) : null}
              {activeCard === 'COMPANIES_MISSING_CERTIFICATIONS' ? (
                <CompanyCertificationsTable projectId={project.id} />
              ) : null}
              {activeCard === 'COMPANIES_EXPIRED_CERTIFICATIONS' ? (
                <CompanyCertificationsTable
                  projectId={project.id}
                  filter={CompanyCertificationFilter.EXPIRED}
                />
              ) : null}
              {activeCard === 'COMPANIES_EXPIRING_CERTIFICATIONS' ? (
                <CompanyCertificationsTable
                  projectId={project.id}
                  filter={CompanyCertificationFilter.EXPIRING}
                />
              ) : null}
            </div>
            <hr />
          </>
        </PaginationProvider>
      )}
      <div className="pb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {sections.map((section, index) => (
          <div key={index} className="px-4 py-2">
            <div className="text-md font-medium text-gray-900">
              {section.title}
            </div>
            <p className="mt-1 text-sm text-gray-500 line-clamp-3">
              {section.content}
            </p>
          </div>
        ))}
      </div>
      <div className="px-4 my-4 h-[400px] mb-10">
        <h3 className="my-2">Roles and Number Assigned and Enabled</h3>
        <ProjectRolesChart data={projectStats} />
      </div>
    </div>
  );
}
