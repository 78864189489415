import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import { DataImportGrid } from '../../../components/DataImportGrid/DataImportGrid';
import Map from '../../../components/Map/Map';
import { SAVE_PROJECT_ACTIVITIES } from '../../../graphql/mutations/projects';
import { GET_PROJECT_ACTIVITY_TYPES } from '../../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { useToast } from '../../../context/ToastContext';

export const ActivityPage = () => {
  let { projectId } = useParams();
  const navigate = useNavigate();
  const { useQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const { data, loading, error, refetch } = useQuery(
    GET_PROJECT_ACTIVITY_TYPES,
    {
      variables: { projectId, input: { limit: 2000 } },
      fetchPolicy: 'network-only',
    }
  );

  const activities = data?.getProjectActivityTypes?.results || [];

  const [rows, setRows] = useState([]);

  const [saveProjectActivities, { loading: saving }] = useMutation(
    SAVE_PROJECT_ACTIVITIES
  );

  useEffect(() => {
    setRows(
      activities?.map((item: any) => ({
        id: item?.id,
        name: item?.name,
        categoryName: item?.pag?.name,
        defaultProgressCode: item?.defaultProgressCode?.code,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="py-2 border-b">
          {projectId ? (
            <Button
              style={{
                backgroundColor: 'transparent',
                color: 'gray',
                boxShadow: 'none',
                borderRadius: 0,
              }}
              onClick={() => navigate(`/projects/${projectId}?tab=Activities`)}
              type="submit"
              text={`< Back to Project`}
            />
          ) : null}
        </div>

        {rows?.length > 0 ? (
          <Map
            markers={[
              ...rows.map((item: any) => ({
                ...item,
                latitude: Number(item.latitude),
                longitude: Number(item.longitude),
                detail: item.category,
              })),
            ]}
          />
        ) : null}
        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}
        <div className="flex justify-end py-2 px-4">
          <Button
            isLoading={saving}
            text={'Save'}
            onClick={async () => {
              try {
                await saveProjectActivities({
                  variables: {
                    input: {
                      projectId,
                      activities: rows
                        ?.filter((row: any) => row.name || row.toBeDeleted)
                        ?.map((item: any) => ({
                          id: item.isNew ? undefined : item.id,
                          name: item.name?.trim(),
                          categoryName: item.categoryName?.trim(),
                          defaultProgressCode: item.defaultProgressCode?.trim(),
                          toBeDeleted: item.toBeDeleted,
                        })),
                    },
                  },
                });
                await refetch();
                addToast('Activities saved successfully', 'success');
              } catch (error) {
                console.error(error);
                addToast('Failed to save Activities', 'error');
              }
            }}
          />
        </div>
        <DataImportGrid
          // @ts-ignore
          rows={rows}
          setRows={setRows}
          config={{
            columns: [
              { key: 'name', name: 'Name' },
              { key: 'categoryName', name: 'Category Name' },
              { key: 'defaultProgressCode', name: 'Default Progress Code' },
            ],
            exampleData: [],
          }}
        />
      </div>
    </div>
  );
};

export const ActivityBulkSave = () => <ActivityPage />;
