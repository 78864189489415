import { FastField, Field } from 'formik';

import DropDown from '../../../../components/Inputs/Dropdown';
import {
  useIsLargeScreenOrSmaller,
  useIsMediumScreenOrSmaller,
  useIsSmallScreenOrSmaller,
  useIsXlScreenOrSmaller,
} from '../../../../hooks/useMediaQuery';

export const MultipleCodingsActivityCodeInput = ({
  code,
  dayIndex,
  entryIndex,
  values,
  setFieldValue,
  timesheetConfig,
  entry,
  disabled,
}: any) => {
  const handleValueChange = (event: any, max: any, step: any) => {
    let value =
      step === '.1' || step === '.5'
        ? parseFloat(event.target.value)
        : parseInt(event.target.value);
    const roundToNearestHalf = (value: number) => {
      return Math.round(value * 2) / 2;
    };

    if (step === '.5') {
      value = roundToNearestHalf(value);
    }
    if (value > max) {
      setFieldValue(
        `entries.${entryIndex}.days.${dayIndex}.codings.${code.id}`,
        max
      );
    } else if (value < 0) {
      setFieldValue(
        `entries.${entryIndex}.days.${dayIndex}.codings.${code.id}`,
        0
      );
    } else {
      setFieldValue(
        `entries.${entryIndex}.days.${dayIndex}.codings.${code.id}`,
        value
      );
    }
  };

  return (
    <div className="flex items-center gap-x-2 justify-around mb-2">
      <div
        className="w-8 h-8 px-2 rounded-lg flex items-center justify-center"
        style={{ background: code.color }}
      >
        <p className="text-white text-sm">{code.value}</p>
      </div>
      <Field
        type="number"
        id={`entries.${entryIndex}.days.${dayIndex}.codings.${code.id}`}
        name={`entries.${entryIndex}.days.${dayIndex}.codings.${code.id}`}
        value={values?.entries[entryIndex].days[dayIndex].codings[code.id] ?? 0}
        onChange={(event: any) => {
          handleValueChange(
            event,
            timesheetConfig?.granularity?.toUpperCase() === 'HOURLY' ? 24 : 1,
            timesheetConfig?.granularity?.toUpperCase() === 'HOURLY'
              ? '.5'
              : '.1'
          );
        }}
        min={0}
        disabled={entry.disabled || disabled}
        max={timesheetConfig?.granularity?.toUpperCase() === 'HOURLY' ? 24 : 1}
        step={
          timesheetConfig?.granularity?.toUpperCase() === 'HOURLY' ? '.5' : '.1'
        }
        className="w-full 2xl:w-20 3xl:w-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
      />
    </div>
  );
};

export const ActivityCodeInput = ({
  day,
  dayIndex,
  timesheetConfig,
  selectedDate,
  setFieldValue,
  entryIndex,
  values,
  multiProject,
  entry,
  disabled,
}: any) => {
  const isSmall = useIsSmallScreenOrSmaller();
  const isLarge = useIsLargeScreenOrSmaller();
  const isXl = useIsXlScreenOrSmaller();

  return (
    <div
      className={`flex flex-col border-r border-l px-2 items-center bg-gray-100 py-2 justify-center`}
    >
      <label
        htmlFor={`activity-code-entry-${entryIndex}-day-${dayIndex}`}
        className="block text-sm font-medium text-gray-700 my-2"
      >
        {`${isLarge ? '' : isXl ? day.day.slice(0, 3) : day.day}${
          isLarge ? '' : ' - '
        }`}

        {new Date(
          selectedDate?.getTime() + dayIndex * 86400000
        ).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
        })}
      </label>
      {timesheetConfig?.granularity?.toUpperCase() === 'DAILY' ? (
        <DropDown
          classNames="w-full"
          menuButtonClassNames="w-full"
          hideClearButton={true}
          hideOptionColourSwatches={isLarge && !isSmall}
          emptyOptionText={isLarge ? '-' : undefined}
          id={`activity-code-entry-${entryIndex}-day-${dayIndex}`}
          options={[
            { color: 'white', value: null },
            ...timesheetConfig?.activityCodes,
          ].map((option) => ({
            id: option.value,
            name: option.value,
            value: option.value,
            colour: option.color,
          }))}
          handleChange={(selectedValue) => {
            const activityCode = timesheetConfig?.activityCodes.find(
              (code: any) => code.value === selectedValue
            )?.id;
            setFieldValue(
              `entries.${entryIndex}.days.${dayIndex}.selectedCode`,
              activityCode
            );
          }}
          value={
            timesheetConfig?.activityCodes.find(
              (code: any) =>
                code.id ===
                values.entries?.[entryIndex]?.days?.[dayIndex]?.selectedCode
            )?.value
          }
          isDisabled={entry.disabled || disabled}
        />
      ) : (
        timesheetConfig?.activityCodes?.map((code: any, codeIndex: any) => (
          <MultipleCodingsActivityCodeInput
            key={codeIndex}
            code={code}
            dayIndex={dayIndex}
            entryIndex={entryIndex}
            values={values}
            timesheetConfig={timesheetConfig}
            setFieldValue={setFieldValue}
            multiProject={multiProject}
            entry={entry}
            disabled={disabled}
          />
        ))
      )}
    </div>
  );
};
