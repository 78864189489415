import LinkButton from '../../../../components/Buttons/LinkButton';

export const BackToTimesheetsButton = () => {
  const tableQueryParams =
    sessionStorage.getItem('timesheets_table_query_params') || '';

  return (
    <LinkButton
      style={{
        backgroundColor: 'transparent',
        color: 'white',
        boxShadow: 'none',
        borderRadius: 0,
      }}
      to={`/time/timesheets${tableQueryParams ? `?${tableQueryParams}` : ''}`}
    >
      {`< Back to Timesheets`}
    </LinkButton>
  );
};
