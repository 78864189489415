import { Button } from '../../../../components/Buttons/Button';

import NumericInput from '../../../../components/Inputs/NumericInput';

export const IncidentEntry = ({
  date,
  incidents,
  handleIncidentChange,
  setModalIncidentId,
  locked,
}: any) => {
  const IncidentsInput = ({ incident }: any) => {
    return (
      <>
        <div className={`flex flex-col mt-2`}>
          <div className="flex flex-col gap-y-2 lg:gap-y-0 lg:grid grid-cols-5 gap-x-2 py-0 text-sm items-center">
            <label
              style={
                incident.hasChanged && !incident.savedLocally
                  ? { borderColor: 'orange' }
                  : incident.savedLocally
                  ? { borderColor: 'rgb(63, 81, 181)' }
                  : {}
              }
              className="col-span-1 border-b-4 pb-1"
            >
              {incident?.name}:
            </label>
            <div className="flex flex-col items-center">
              {Number(incident.toDateValue ?? 0)}
            </div>
            <div className="flex flex-col">
              <NumericInput
                id={'incident-number'}
                name={'incidentNumber'}
                min={0}
                isDisabled={locked}
                step={'1'}
                value={incident.value ?? 0}
                handleChange={(value: any) =>
                  handleIncidentChange(incident.id, 'value', Number(value))
                }
                classNames="border p-1 rounded text-lg"
              />
            </div>
            <div className="flex flex-col items-center">
              {Number(incident.toDateValue ?? 0) + Number(incident.value ?? 0)}
            </div>

            <Button
              style={incident.comment ? { background: 'orange' } : {}}
              text={
                locked
                  ? 'View Comment'
                  : incident?.comment
                  ? 'View/Edit Comment'
                  : `Add Comments`
              }
              onClick={() => setModalIncidentId(incident.id)}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="rounded-sm">
      <div className="hidden flex-col py-2 px-4 border-b bg-white gap-y-2 lg:gap-y-0 lg:grid grid-cols-5 gap-x-2 text-sm items-center">
        <p>Type</p>
        <p className="text-center">Previous</p>
        <p className="text-center">Today</p>
        <p className="text-center">Total (to {date})</p>
        <p>Comments</p>
      </div>
      <div className="w-full border-b py-2 gap-2 px-4 bg-gray-50">
        <div className="w-full flex md:flex-col flex-wrap lg:grid lg:grid-cols-1 gap-x-10 gap-y-10 lg:gap-y-0 items-center justify-center">
          {incidents?.map((incident: any) => (
            <>
              <div className="flex flex-col">
                <IncidentsInput incident={incident} />
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
