import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { GET_CUSTOM_FIELDS_SUMMARY } from '../../../graphql/queries/custom-data';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import moment from 'moment';
import { Button } from '../../../components/Buttons/Button';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import { ActiveBadge, DisabledBadge } from '../../../components/Badges/Badges';
import { useFilter } from '../../../hooks/useFilter';

interface CustomFieldListProps {
  children?: React.ReactElement;
}

const headerMapping = {
  name: 'Custom Field Name',
  status: 'Status',
  internalId: 'Internal ID',
  updatedAt: 'Updated At',
} as Record<string, any>;

const CustomFieldsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={[{ id: 'name', name: 'Name' }]}
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    showFilterOptions
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const CustomFieldListPage = ({ children }: CustomFieldListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const { limit, offset, setOffset } = usePagination();

  const [searchParams] = useSearchParams();
  const {
    filterText,
    setFilterText,
    filterDimension,
    setFilterDimension,
    filter,
  } = useFilter({ defaultFilterDimension: 'name' });

  const [fetch, { data }] = useLazyQuery(GET_CUSTOM_FIELDS_SUMMARY, {
    variables: { input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: { input: { limit, offset, ...(filter ? { filter } : {}) } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, filter]);

  const customFieldResults = data?.getCustomFieldGroups;

  const totalPages = Math.ceil(customFieldResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const { userProfile } = useContext(UserProfileContext);
  return (
    <div>
      <>
        <div className="w-full flex justify-between items-center py-2">
          <h1 className="text-xl font-bold py-4">Custom Fields</h1>
          {userProfile?.permissions?.includes(Permission.UpdateCustomData) ? (
            <Button
              text="New Custom Field Set"
              onClick={() => navigate(`/custom/fields/new`)}
            />
          ) : null}
        </div>
        <p className="text-sm py-2 text-gray-600">
          Custom fields can be assigned to specific groups to allow you to
          collect extra information about your labour pool.
        </p>
        <CustomFieldsTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={customFieldResults?.count || 0}
          onClickRow={(id: any) => {
            sessionStorage.setItem(
              'custom_fields_table_query_params',
              searchParams.toString()
            );
            navigate(`/custom/fields/${id}`);
          }}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={filterDimension}
          setSelectedFilterDimension={setFilterDimension}
          data={
            customFieldResults?.results.length > 0
              ? customFieldResults?.results.map((result: any) => ({
                  ...result,
                  status: result.enabled ? <ActiveBadge /> : <DisabledBadge />,
                }))
              : [{}]
          }
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const CustomFieldList = () => (
  <PaginationProvider>
    <CustomFieldListPage />
  </PaginationProvider>
);
