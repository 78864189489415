import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import { Table } from '../../../components/Tables/tables/Table';
import { PaginationProvider } from '../../../context/PaginationContext';
import { GET_PROJECTS } from '../../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { EmployeeList } from '../../Labour/employees/list';
import { SubcontractorList } from '../../Labour/subcontractors/list';
import { SAVE_ASSIGNMENT } from '../../../graphql/mutations/assignments';
import { useToast } from '../../../context/ToastContext';
import { GET_ROLES_SUMMARY } from '../../../graphql/queries/roles';
import { RolesDropdown } from './RolesDropdown';
import { Tabs } from '../../../components/Tabs/Tabs';

const headerMapping = {
  name: 'Name',
  email: 'Email',
  type: 'Type',
  internalId: 'Internal ID',
  status: 'Status',
  updatedAt: 'Updated At',
  defaultRole: 'Role',
} as Record<string, any>;

const AssignmentsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    showPagination={false}
    columnsToFilter={['firstName', 'lastName', 'id']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

const tabs = ['Subcontractors', 'Employees'];

export const AssignToProjectPage = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const { addToast } = useToast();

  const { useLazyQuery, useMutation, useQuery } = useOrganisationAwareApollo();

  const [saveAssignment, { loading: isSaving }] = useMutation(SAVE_ASSIGNMENT);

  const { data: jobRolesData } = useQuery(GET_ROLES_SUMMARY, {});

  const [employeeAssignments, setEmployeeAssignments] = useState<any>([]);
  const [subcontractorAssignments, setSubcontractorAssignments] = useState<any>(
    []
  );

  const [hiddenDropdowns, setHiddenDropdowns] = useState<Array<String>>([]);

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const [fetchProject, { data: projectData }] = useLazyQuery(GET_PROJECTS, {
    variables: { input: { id } },
    fetchPolicy: 'network-only',
  });

  const [project] = projectData?.getProjects?.results || [];

  const jobRoles = jobRolesData?.getRoles?.results || [];

  useEffect(() => {
    fetchProject({ variables: { input: { id } } });
  }, [fetchProject, id]);

  const saveAllAssignees = async () => {
    try {
      // merge the two arrays into one
      const allAssignments = [
        ...employeeAssignments.map((assignment: any) => ({
          ...assignment,
          type: 'EMPLOYEE',
        })),
        ...subcontractorAssignments.map((assignment: any) => ({
          ...assignment,
          type: 'SUBCONTRACTOR',
        })),
      ];

      // create a promise for each assignment
      const assignmentPromises = allAssignments.map((assignment) =>
        saveAssignment({
          variables: {
            id: assignment?.assigneeId,
            input: {
              roleId:
                assignment?.roleId?.id || assignment?.defaultRole?.id || null,
              projectId: project.id,
              assigneeType: assignment.type,
              assigneeId: assignment.id,
            },
          },
        })
      );

      await Promise.all(assignmentPromises);
      navigate(`/projects/${id}?tab=Assignees`);
      addToast('Assignments saved successfully', 'success');
    } catch (error) {
      console.error(error);
      addToast('Failed to save assignments', 'error');
    }
  };

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Assign Resource to {project?.name}
            </h1>
            <h2 className="text-lg mt-1 font-base text-gray-500">
              {project?.internalId}
            </h2>
          </div>
        </div>
        <div>
          {subcontractorAssignments.length > 0 ||
          employeeAssignments.length > 0 ? (
            <p className="text-sm px-4 text-gray-800 pb-2 border-b leading-6">
              Note: You can click on a row to remove assignments. You can define
              the role for each assignee at this stage, but these can be changed
              later.
            </p>
          ) : null}
          {subcontractorAssignments.length > 0 ? (
            <div className="mb-2">
              <h4 className="my-2 px-4 text-sm text-gray-600">
                Assigning {subcontractorAssignments.length} Subcontractor
                {subcontractorAssignments.length === 1 ? '' : 's'}
              </h4>
              <AssignmentsTable
                totalPages={null}
                itemsPerPage={0}
                onClickRow={(id: any) => {
                  setSubcontractorAssignments(
                    subcontractorAssignments.filter(
                      (subcontractorAssignment: any) =>
                        subcontractorAssignment.id !== id
                    )
                  );
                }}
                setAssignments={setSubcontractorAssignments}
                assignments={subcontractorAssignments}
                dropdownOptions={{ jobRoleOptions: jobRoles }}
                hiddenDropdowns={hiddenDropdowns}
                setHiddenDropdowns={setHiddenDropdowns}
                data={
                  subcontractorAssignments.length > 0
                    ? subcontractorAssignments?.map((result: any) => ({
                        name: `${result.lastName}, ${result.firstName}`,
                        ...result,
                        defaultRole: (
                          <RolesDropdown
                            rowData={result}
                            defaultRole={result.defaultRole.name}
                            setStateValue={setSubcontractorAssignments}
                          />
                        ),
                        type: 'Subcontractor',
                      }))
                    : [{}]
                }
              />
            </div>
          ) : null}
          {subcontractorAssignments.length > 0 &&
          employeeAssignments.length > 0 ? (
            <hr className="mb-4" />
          ) : null}
          {employeeAssignments.length > 0 ? (
            <div className="mb-2">
              <h4 className="my-2 px-4 text-sm text-gray-600">
                Assigning {employeeAssignments.length} Employee
                {employeeAssignments.length === 1 ? '' : 's'}
              </h4>
              <AssignmentsTable
                totalPages={null}
                itemsPerPage={0}
                onClickRow={(id: any) => {
                  setEmployeeAssignments(
                    employeeAssignments.filter(
                      (employeeAssignment: any) => employeeAssignment.id !== id
                    )
                  );
                }}
                setAssignments={setEmployeeAssignments}
                assignments={employeeAssignments}
                dropdownOptions={{ jobRoleOptions: jobRoles }}
                hiddenDropdowns={hiddenDropdowns}
                setHiddenDropdowns={setHiddenDropdowns}
                data={
                  employeeAssignments.length > 0
                    ? employeeAssignments?.map((result: any) => ({
                        name: `${result.lastName}, ${result.firstName}`,
                        ...result,
                        defaultRole: (
                          <RolesDropdown
                            rowData={result}
                            defaultRole={result.defaultRole.name}
                            setStateValue={setEmployeeAssignments}
                          />
                        ),
                        type: 'Employee',
                      }))
                    : [{}]
                }
              />
            </div>
          ) : null}
        </div>

        {employeeAssignments.length > 0 ||
        subcontractorAssignments.length > 0 ? (
          <div className="my-4 mx-2 w-full">
            <Button
              isLoading={isSaving}
              text="Save Assignments"
              onClick={async () => {
                await saveAllAssignees();
              }}
            />
          </div>
        ) : null}
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
        <div className="my-4 border-t py-2 px-4">
          <p className="text-sm px-4 text-gray-600">
            Select {activeTab} from the list below to assign as resources to
            this project. Once they are assigned, you will be able to assign a
            role to these {activeTab}.
          </p>
          <hr className="my-2 px-10" />
          {activeTab === 'Subcontractors' ? (
            <>
              <h4 className="my-2 px-4">Subcontractors</h4>
              <SubcontractorList
                filterIds={subcontractorAssignments.map(
                  (assignment: any) => assignment.id
                )}
                showNewItemButton={false}
                onClickRow={(id, results) => {
                  if (
                    !subcontractorAssignments.find(
                      (assignment: any) => assignment.id === id
                    )
                  ) {
                    setSubcontractorAssignments((assignments: any) => [
                      ...assignments,
                      results?.find((result) => result.id === id),
                    ]);
                  }
                }}
              />
            </>
          ) : null}
          {activeTab === 'Employees' ? (
            <>
              <h4 className="my-2 px-4">Employees</h4>
              <EmployeeList
                filterIds={employeeAssignments.map(
                  (assignment: any) => assignment.id
                )}
                showNewItemButton={false}
                onClickRow={(id, results) => {
                  if (
                    !employeeAssignments.find(
                      (assignment: any) => assignment.id === id
                    )
                  ) {
                    setEmployeeAssignments((assignments: any) => [
                      ...assignments,
                      results?.find((result) => result.id === id),
                    ]);
                  }
                }}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const AssignToProject = () => (
  <PaginationProvider>
    <AssignToProjectPage />
  </PaginationProvider>
);
