import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import React, { useRef } from 'react';
import { twMerge } from 'tailwind-merge';

type ModalProps = {
  showModal: boolean;
  setShowModal: (value: boolean | any) => void;
  onComplete: () => Promise<void> | void;
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  completeButtonText?: string | React.ReactNode;
  cancelButtonText?: string | React.ReactNode;
  disableCompleteButton?: boolean;
  isCompleting?: boolean;
  completeButtonClassName?: string;
  classNames?: string;
  icon?: React.ReactNode;
  iconClassName?: string;
};

export const Modal = ({
  showModal,
  setShowModal = () => {},
  onComplete,
  children,
  title,
  completeButtonText = 'Save',
  cancelButtonText = 'Cancel',
  disableCompleteButton = false,
  isCompleting,
  completeButtonClassName,
  classNames,
  icon,
  iconClassName,
}: ModalProps) => {
  const cancelButtonRef = useRef(null);
  return (
    <Transition show={showModal}>
      <Dialog
        as="div"
        className="relative z-10"
        style={{ zIndex: 9999 }}
        initialFocus={cancelButtonRef}
        onClose={setShowModal}
      >
        <TransitionChild>
          <DialogBackdrop
            className={twMerge(
              'fixed inset-0 bg-gray-500 bg-opacity-75',
              'transition-opacity ease-out duration-300',
              'data-[closed]:opacity-0',
              'data-[leave]:ease-in data-[leave]:duration-200'
            )}
          />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center  justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild>
              <DialogPanel
                className={twMerge(
                  'relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl w-full sm:my-8 sm:w-full md:max-w-[600px] sm:p-6',
                  'transition-all',
                  // closed state
                  'data-[closed]:opacity-0 data-[closed]:translate-y-4 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95',
                  // entering transitions
                  'data-[enter]:ease-out data-[enter]:duration-300',
                  // exiting transitions
                  'data-[leave]:ease-in data-[leave]:duration-200',
                  classNames
                )}
              >
                <div className="sm:flex sm:items-start">
                  {icon ? (
                    <div
                      className={twMerge(
                        'mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10',
                        iconClassName
                      )}
                    >
                      {icon}
                    </div>
                  ) : null}
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      className="text-lg font-semibold leading-6 text-gray-900"
                    >
                      {title}
                    </DialogTitle>
                    <div className="w-full mt-2">{children}</div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    disabled={disableCompleteButton}
                    type="button"
                    className={twMerge(
                      `inline-flex w-full justify-center rounded-md ${
                        disableCompleteButton ? 'bg-gray-400' : 'bg-green-600'
                      } px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                        disableCompleteButton
                          ? 'hover:bg-gray-400'
                          : 'hover:bg-red-500'
                      } sm:ml-3 sm:w-auto`,
                      completeButtonClassName
                    )}
                    onClick={async () => {
                      await onComplete();
                    }}
                  >
                    {isCompleting ? (
                      <div className="border-dashed border-2 rounded-full animate-spin w-5 h-5" />
                    ) : (
                      completeButtonText
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setShowModal(false)}
                    ref={cancelButtonRef}
                  >
                    {cancelButtonText || 'Cancel'}
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
