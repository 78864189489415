import { DocumentIcon } from '@heroicons/react/24/outline';
import { ColourSummaryCard } from '../../../components/ModuleItemSummary/ColourSummaryCard';
import * as Yup from 'yup';
import { SAVE_NOTIFICATIONS_CONFIG } from '../../../graphql/mutations/notifications-config';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { useToast } from '../../../context/ToastContext';
import { useContext } from 'react';
import { FormStateContext } from '../NotificationSettings';
import { Formik } from 'formik';
import FormSection from '../../../components/FormSection/FormSection';
import FormSubmit from '../../../components/Buttons/FormSubmit';
import Switch from '../../../components/Inputs/Switch';
import ChipsInput from '../../../components/Inputs/ChipsInput';

export function CertificationsMissingOrInvalid({
  config,
  enabled,
  onClick,
  isActive,
  setActiveTile,
  refetchEventTypes,
}: {
  config: any;
  enabled: boolean;
  onClick?: () => void;
  isActive?: boolean;
  setActiveTile: (tile: string) => void;
  refetchEventTypes: () => Promise<void>;
}) {
  const { useMutation } = useOrganisationAwareApollo();

  const [saveNotificationsConfig] = useMutation(SAVE_NOTIFICATIONS_CONFIG);

  const { setIsDirty } = useContext(FormStateContext);

  const { addToast } = useToast();

  const initialValues = {
    enabled: enabled,
    emails: config?.emails,
    sendToAssignee: config?.sendToAssignee,
    sendToProjectManagers: config?.sendToProjectManagers,
    sendToCompanyOwner: config?.sendToCompanyOwner,
  };

  const validationSchema = Yup.object().shape({
    enabled: Yup.boolean(),
    sendToAssignee: Yup.boolean(),
    sendToProjectManagers: Yup.boolean(),
    sendToCompanyOwner: Yup.boolean(),
  });

  const onSubmit = async (values: Record<string, any>) => {
    try {
      const { data } = await saveNotificationsConfig({
        variables: {
          input: {
            eventType: 'certificationsMissingOrInvalid',
            enabled: values.enabled,
            config: {
              emails: values.emails,
              sendToAssignee: values.sendToAssignee,
              sendToProjectManagers: values.sendToProjectManagers,
              sendToCompanyOwner: values.sendToCompanyOwner,
            },
          },
        },
      });

      if (data.saveNotificationsConfig?.success) {
        addToast('Settings updated', 'success');
        setIsDirty(false);
        await refetchEventTypes();
      } else {
        addToast('Error updating settings', 'error');
      }
    } catch (error) {
      console.log(error);
      addToast('Error updating settings', 'error');
    }
  };

  return (
    <div className="flex flex-col w-full border rounded-lg">
      <ColourSummaryCard
        classNames="cursor-pointer bg-white border hover:bg-gray-100 z-10"
        heading="Assignee has missing or invalid certifications"
        headingClassNames="text-xl"
        IconComponent={DocumentIcon}
        isExpandable={true}
        isActive={isActive}
        onClick={onClick ? () => onClick() : undefined}
      />
      {isActive ? (
        <div className="px-4">
          <div className="text-sm py-4">
            {`If this setting is 'enabled' when an assignee has missing or invalid certifications an email will be sent to the configured recipients to inform them of this.`}
          </div>
          <div className="flex flex-col z-0 rounded-b-lg">
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                setFieldValue,
                isValid,
                handleSubmit,
                isSubmitting,
                initialValues,
              }) => {
                return (
                  <>
                    <FormSection title={'Settings'} classNames="rounded-lg">
                      <Switch
                        handleChange={(checked) => {
                          const isReverted = checked === initialValues.enabled;
                          setIsDirty(!isReverted);
                          setFieldValue('enabled', checked);
                        }}
                        text={'Enabled'}
                        enabled={values.enabled}
                      />
                      <ChipsInput
                        inputTransformer={(input: string | undefined) =>
                          input?.toLowerCase().trim()
                        }
                        id="email-recipients"
                        name="emails"
                        description="Enter an email address and press 'Enter' to add to the list of email recipients"
                        placeholder="Enter email address"
                        handleChange={(options: string[]) => {
                          const isReverted =
                            JSON.stringify(options) ===
                            JSON.stringify(initialValues.emails);
                          setIsDirty(!isReverted);
                          setFieldValue('emails', options);
                        }}
                        value={values.emails}
                        validation={errors.emails as string}
                      />
                      <div className="text-sm text-gray-500">
                        Choose recipients:
                      </div>
                      <Switch
                        handleChange={(checked) => {
                          const isReverted =
                            checked === initialValues.sendToAssignee;
                          setIsDirty(!isReverted);
                          setFieldValue('sendToAssignee', checked);
                        }}
                        text={'Send to Assignee'}
                        enabled={values.sendToAssignee}
                      />
                      <Switch
                        handleChange={(checked) => {
                          const isReverted =
                            checked === initialValues.sendToProjectManagers;
                          setIsDirty(!isReverted);
                          setFieldValue('sendToProjectManagers', checked);
                        }}
                        text={'Send to Project Managers'}
                        enabled={values.sendToProjectManagers}
                      />
                      <Switch
                        handleChange={(checked) => {
                          const isReverted =
                            checked === initialValues.sendToCompanyOwner;
                          setIsDirty(!isReverted);
                          setFieldValue('sendToCompanyOwner', checked);
                        }}
                        text={'Send to Company Owner'}
                        enabled={values.sendToCompanyOwner}
                      />
                    </FormSection>
                    <div className="py-4">
                      <FormSubmit
                        onCancel={() => {
                          setIsDirty(false);
                          setActiveTile('');
                        }}
                        disabled={!isValid}
                        isLoading={isSubmitting}
                        submitText={'Save'}
                        onSubmit={handleSubmit}
                      />
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      ) : null}
    </div>
  );
}
