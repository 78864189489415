import ValidationMessage from '../Validation/ValidationMessage';

interface TextAreaParams {
  classNames?: string;
  defaultText?: string;
  description?: string;
  handleChange: (props: any) => void;
  id: string;
  isDisabled?: boolean;
  name: string;
  placeholder?: string;
  rows?: number;
  title?: string;
  value: string;
  validation?: string;
}

const TextArea = ({
  handleChange,
  value,
  validation,
  isDisabled,
  rows = 3,
  id,
  title,
  placeholder,
  name,
  description,
  defaultText,
  classNames,
}: TextAreaParams) => {
  return (
    <div className={classNames}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      {validation ? <ValidationMessage message={validation} /> : null}
      <div className="mt-1">
        <textarea
          id={id}
          value={value || ''}
          onChange={(event) => {
            handleChange(event.target.value);
          }}
          disabled={isDisabled}
          name={name}
          rows={rows}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
          placeholder={placeholder}
          defaultValue={defaultText}
        />
      </div>
      <p className="mt-2 text-sm text-gray-500">{description}</p>
    </div>
  );
};

export default TextArea;
