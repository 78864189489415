import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import {
  GET_PROJECT_LOCATIONS,
  GET_PROJECT_LOCATION_ASSETS,
} from '../../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import Map from '../../../components/Map/Map';
import { DataImportGrid } from '../../../components/DataImportGrid/DataImportGrid';
import { SAVE_PROJECT_LOCATION_ASSETS } from '../../../graphql/mutations/projects';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import { useProject } from '../../../hooks/api/projects/useProjects';
import LinkButton from '../../../components/Buttons/LinkButton';

export const LocationPage = () => {
  let { projectId, locationId } = useParams();
  const [project] = useProject(projectId);
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error }] = useLazyQuery(
    GET_PROJECT_LOCATIONS,
    {
      variables: { input: { id: locationId }, projectId },
      fetchPolicy: 'network-only',
    }
  );

  const [fetchAssets, { data: projectLocAssets }] = useLazyQuery(
    GET_PROJECT_LOCATION_ASSETS,
    {
      variables: { projectLocationId: locationId },
      fetchPolicy: 'network-only',
    }
  );

  const [location] = data?.getProjectLocations?.results || [];

  const assets = projectLocAssets?.getProjectLocationAssets?.results || [];

  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetch({ variables: { input: { id: locationId }, projectId } });
    fetchAssets();
  }, [locationId]);

  const [saveProjectLocationAssets, { loading: saving }] = useMutation(
    SAVE_PROJECT_LOCATION_ASSETS
  );

  useEffect(() => {
    setRows(assets);
  }, [assets]);

  const { userProfile } = useContext(UserProfileContext);

  const tableQueryParams =
    sessionStorage.getItem('locations_table_query_params') || '';

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="py-2 border-b">
          {location?.name ? (
            <LinkButton
              style={{
                backgroundColor: 'transparent',
                color: 'gray',
                boxShadow: 'none',
                borderRadius: 0,
              }}
              to={`/projects/${projectId}${
                tableQueryParams ? `?${tableQueryParams}` : ''
              }`}
            >
              {`< Back to Project: ${project?.name}`}
            </LinkButton>
          ) : null}
        </div>
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <div className="flex gap-x-2">
              <h1 className="text-3xl font-extrabold text-gray-900">
                {location?.name} Assets
              </h1>
            </div>
            <p className="mt-1 text-sm text-gray-500">
              Created: {moment.unix(location?.createdAt / 1000).fromNow()}
            </p>
            <p className="mt-1 text-sm text-gray-500">
              Updated: {moment.unix(location?.updatedAt / 1000).fromNow()}
            </p>
          </div>
        </div>
        {location ? (
          <Map
            markers={[
              location,
              ...rows.map((item: any) => ({
                ...item,
                latitude: Number(item.latitude),
                longitude: Number(item.longitude),
                detail: item.category,
              })),
            ]}
          />
        ) : null}
        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}
        <div className="flex justify-end py-2 px-4">
          {userProfile?.permissions?.includes(Permission.UpdateProject) ? (
            <Button
              isLoading={saving}
              text={'Save'}
              onClick={async () => {
                await saveProjectLocationAssets({
                  variables: {
                    input: {
                      projectLocationId: locationId,
                      assets: rows
                        ?.filter((row: any) => row.name || row.toBeDeleted)
                        .map((item: any) => ({
                          id: item.id,
                          name: item.name?.trim(),
                          category: item.category?.trim(),
                          latitude: Number(item.latitude),
                          longitude: Number(item.longitude),
                          toBeDeleted: item.toBeDeleted,
                        })),
                    },
                  },
                });
                await fetchAssets();
              }}
            />
          ) : null}
        </div>
        <DataImportGrid
          // @ts-ignore
          rows={rows}
          setRows={setRows}
          config={{
            columns: [
              { key: 'name', name: 'Name' },
              { key: 'category', name: 'Category' },
              { key: 'latitude', name: 'Latitude' },
              { key: 'longitude', name: 'Longitude' },
            ],
            exampleData: [,],
          }}
        />
      </div>
    </div>
  );
};

export const Location = () => <LocationPage />;
