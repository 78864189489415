import { useEffect } from 'react';
import { useOrganisationAwareApollo } from '../../useOrganisationAwareApollo';
import { ApiHookMeta } from '../types';
import { GET_CERTIFICATIONS } from '../../../graphql/queries/certifications';

export default function useCertifications({
  id,
  companyId,
  labourResourceId,
  limit,
  offset,
  filter,
  skip,
}: {
  id?: string;
  companyId?: string;
  labourResourceId?: string;
  limit?: number;
  offset?: number;
  filter?: any;
  skip?: boolean;
}): [any, ApiHookMeta, any?] {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error, ...result }] = useLazyQuery(
    GET_CERTIFICATIONS,
    {
      variables: {
        companyId,
        labourResourceId,
        input: { id, limit, offset, ...(filter ? { filter } : {}) },
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    !skip &&
      fetch({
        variables: {
          companyId,
          labourResourceId,
          input: { id, limit, offset, ...(filter ? { filter } : {}) },
        },
      });
  }, [companyId, fetch, filter, id, labourResourceId, limit, offset, skip]);

  const results = data?.getCertifications?.results || [];
  const count = data?.getCertifications?.count || 0;

  return [
    results,
    {
      count,
      loading,
      error,
      ...result,
    },
    async (variables: any) => await fetch(variables),
  ];
}

export function useCertification(id?: string) {
  const [results, meta] = useCertifications({ id, skip: !id });

  const [certification] = results;

  return [certification, meta];
}
