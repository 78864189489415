import { useEffect, useState } from 'react';
import ComboBox from '../Inputs/ComboBox';
import useProjects from '../../hooks/api/projects/useProjects';
import { Button } from '../Buttons/Button';
import { XMarkIcon } from '@heroicons/react/24/solid';

export const ProjectSelection = ({
  setSelectedProject,
  selectedProject,
  placeholder = 'Search by project name',
}: any) => {
  const [searchTerm, setSearchTerm] = useState('');

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [projects] = useProjects({ searchTerm: debouncedSearchTerm });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  return (
    <div className="flex w-full md:w-1/3 gap-x-4">
      <ComboBox
        classNames="w-full"
        testId="project-selection"
        placeholder={placeholder}
        id="Project Search"
        options={projects?.map((project: any) => ({
          id: project?.id,
          name: project?.name,
          detail: project?.internalId,
        }))}
        onChange={(value) => setSearchTerm(value)}
        handleChange={(id) => {
          setSelectedProject(
            projects?.find((project: any) => project?.id === id)
          );
        }}
        value={selectedProject?.name || ''}
        validation={undefined}
      />
      <Button
        className="shadow-none mt-1 -ml-3 px-2 bg-transparent text-black hover:bg-transparent"
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setSelectedProject(null);
        }}
        aria-label="Clear Project"
        title="Clear Project"
      >
        <XMarkIcon className="h-5 w-5" />
      </Button>
    </div>
  );
};
