import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import {
  Bars3Icon,
  ChevronDownIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Auth } from 'aws-amplify';
import { useContext, useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { Link, useLocation } from 'react-router-dom';
import { ActiveOrganisationContext } from '../../context/ActiveOrganisationContext';
import { NetworkContext } from '../../context/NetworkContext';
import { UserProfileContext } from '../../context/UserProfileContext';
import { childRoutes, mainRoutes } from '../../pages/routes';
import { joinClassNames } from '../../utils/utils';
import Switch from '../Inputs/Switch';
import OrganisationPicker from '../OrganisationPicker/OrganisationPicker';
import { TopBar } from './TopBar';
import { useBasePath } from '../../hooks/useBasePath';
import MissingCertifications from '../MissingCertifications';
import { twMerge } from 'tailwind-merge';

interface SideBarProps {
  children?: React.ReactElement;
}

export const SideBar = ({ children }: SideBarProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();
  const baseName = useBasePath();

  const { userProfile, refreshUserProfile } = useContext(UserProfileContext);

  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  const [moduleToggles, setModuleToggles] = useState<string[]>([]);

  useEffect(() => {
    let toggles: string[] = [];
    if (activeOrganisation?.progressModuleEnabled) {
      toggles.push('progress');
    }
    if (activeOrganisation?.financeModuleEnabled) {
      toggles.push('finance');
    }
    setModuleToggles(toggles ? toggles : []);
  }, [
    activeOrganisation?.progressModuleEnabled,
    activeOrganisation?.financeModuleEnabled,
    activeOrganisation,
  ]);

  const { isOffline, setIsOffline } = useContext(NetworkContext);
  const [showFullDesktopNav] = useState(false);

  const isOfflineToggleRoute =
    mainRoutes.find((route: any) => route.path === baseName)
      ?.isAvailableOffline ||
    childRoutes.find((route: any) => route.path === baseName)
      ?.isAvailableOffline;

  useEffect(() => {
    refreshUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const sideBarRoutes = mainRoutes
    .filter((route) => !route.hidden)
    .filter(() => !isOffline)
    .filter(
      (route) =>
        !route.moduleTogglesRequired ||
        route.moduleTogglesRequired.every((toggle) =>
          moduleToggles.includes(toggle)
        )
    )
    .filter((route) => {
      // @ts-ignore
      return (
        (route.permissionsSomeRequired &&
          route.permissionsSomeRequired?.some((permission) =>
            userProfile?.permissions?.includes(permission)
          )) ||
        (!route.permissionsSomeRequired &&
          (!route.permissionsRequired ||
            route.permissionsRequired?.every((permission) =>
              userProfile?.permissions?.includes(permission)
            )))
      );
    });

  return (
    <div className="flex flex-col">
      {isOffline ? null : (
        <Transition show={sidebarOpen}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <TransitionChild>
              <DialogBackdrop
                className={twMerge(
                  'fixed inset-0 bg-gray-900/80',
                  'transition-opacity ease-linear duration-300',
                  'data-[closed]:opacity-0'
                )}
              />
            </TransitionChild>

            <div className="fixed inset-0 flex">
              <TransitionChild>
                <DialogPanel
                  className={twMerge(
                    'relative mr-16 flex w-full max-w-xs flex-1',
                    'transition ease-in-out duration-300 transform',
                    'data-[closed]:-translate-x-full '
                  )}
                >
                  <TransitionChild>
                    <div
                      className={twMerge(
                        'absolute left-full top-0 flex w-16 justify-center pt-5',
                        'transition-opacity ease-in-out duration-300',
                        'data-[closed]:opacity-0'
                      )}
                    >
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </TransitionChild>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-0 overflow-y-auto bg-white px-6 pb-4 ring-1 ring-white/10">
                    <div className="flex py-4 justify-between border-b shrink-0 items-center">
                      <img
                        className="h-12 w-auto"
                        src="/logo.png"
                        alt="Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7 border-b">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {mainRoutes
                              .filter((route) => !route.hidden)
                              .filter(
                                (route) =>
                                  (isOffline && route.isAvailableOffline) ||
                                  !isOffline
                              )
                              .filter(
                                (route) =>
                                  !route.moduleTogglesRequired ||
                                  route.moduleTogglesRequired.every((toggle) =>
                                    moduleToggles.includes(toggle)
                                  )
                              )
                              .filter(
                                (route) =>
                                  !route.permissionsRequired ||
                                  route.permissionsRequired.every(
                                    (permission) =>
                                      userProfile?.permissions?.includes(
                                        permission
                                      )
                                  )
                              )
                              .map((route) => (
                                <li key={route.name}>
                                  <Link
                                    className={joinClassNames(
                                      pathname === route.path ||
                                        pathname.includes(route.path)
                                        ? 'bg-black text-white'
                                        : '',
                                      'text-gray-400 hover:text-white hover:bg-green-600',
                                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 tracking-tight font-bold'
                                    )}
                                    onClick={() => setSidebarOpen(false)}
                                    to={route.path}
                                  >
                                    <route.icon
                                      className="ml-1 h-6 w-6 shrink-0"
                                      aria-hidden="true"
                                    />
                                    {route.name}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                          <hr className="mt-4" />
                        </li>
                      </ul>
                    </nav>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </Dialog>
        </Transition>
      )}

      {/* Static sidebar for desktop */}
      <div
        className={`hidden lg:fixed lg:inset-y-0 lg:z-50 transition-all lg:flex ${
          showFullDesktopNav ? 'lg:w-72' : 'lg:w-16 hover:w-72'
        } lg:flex-col group ${isOffline ? 'lg:hidden' : ''}`}
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-0 overflow-y-auto overflow-x-hidden bg-white transition-all group-hover:bg-white shadow-sm pb-4">
          <div className="flex w-full  group-hover:bg-white transition-all justify-evenly border-b py-3 shrink-0 items-center px-4">
            <img
              className="h-10 w-10 group-hover:hidden"
              src="/icon.svg"
              alt="EngGenius Technology"
            />
            <img
              className="h-10 w-auto hidden group-hover:block"
              src="/logo.svg"
              alt="EngGenius Technology"
            />
          </div>
          <nav className="flex flex-1 mt-2 flex-col px-4">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-0 bg-gray-800 rounded-2xl border">
                  {sideBarRoutes.map((item, index) => (
                    <li key={item.name}>
                      <Link
                        className={joinClassNames(
                          pathname === item.path || pathname.includes(item.path)
                            ? 'bg-green-600 text-white'
                            : '',
                          'text-gray-200 hover:text-white hover:bg-green-600',
                          `relative flex gap-x-3 p-2 text-xs items-center tracking-wide font-white ${
                            index === 0 ? 'rounded-t-xl' : ''
                          } ${
                            index === sideBarRoutes.length - 1
                              ? 'rounded-b-xl'
                              : ''
                          }`
                        )}
                        to={item.path}
                      >
                        <item.icon
                          className="ml-1 h-5 w-5 shrink-0"
                          aria-hidden="true"
                        />
                        <span
                          className={`absolute left-14 top-1/2 transform -translate-y-1/2 whitespace-nowrap ${
                            showFullDesktopNav
                              ? 'opacity-100'
                              : 'opacity-0 group-hover:opacity-100'
                          } transition-opacity duration-300`}
                        >
                          {item.name}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
                <div className="mt-2 border-b-2 border-green-800" />
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div
        className={`transition-all  ${
          isOffline ? 'lg:pl-0' : showFullDesktopNav ? 'lg:pl-72' : 'lg:pl-16'
        }`}
      >
        <div
          className={`sticky ${
            isOffline ? 'hidden' : ''
          } shadow-xs pb-0 top-0 z-40 flex shrink-0 items-center gap-x-4 px-4 bg-white  sm:gap-x-6 sm:px-6 lg:px-8`}
        >
          {isOffline ? null : (
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          )}
          <div className="flex w-full">
            <div className="hidden md:flex items-center justify-center w-full">
              <TopBar />
            </div>
          </div>
          <div
            className="h-6 w-px bg-gray-900/10 lg:hidden"
            aria-hidden="true"
          />

          {isOffline ? null : (
            <div className="flex gap-x-4 self-stretch lg:gap-x-6">
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />
                <OrganisationPicker />
                <Menu as="div" className="">
                  <MenuButton className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <div className="h-8 w-8 ">
                      <Avatar
                        name={`${userProfile?.personalDetails?.firstName} ${userProfile?.personalDetails?.lastName}`}
                        size="30"
                        round
                      />
                    </div>
                    <span className="hidden lg:flex lg:items-center">
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </MenuButton>
                  <MenuItems
                    anchor={'bottom'}
                    className={twMerge(
                      'z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none',
                      'transition',
                      'data-[closed]:opacity-0 data-[closed]:scale-95',
                      'data-[enter]:ease-out data-[enter]:duration-100',
                      'data-[leave]:ease-in data-[leave]:duration-75'
                    )}
                    transition
                  >
                    {[{ name: 'Sign Out', href: '#' }].map((item) => (
                      <MenuItem key={item.name}>
                        {({ focus }) => (
                          <button
                            onClick={async () => {
                              await Auth.signOut();
                            }}
                            className={joinClassNames(
                              focus ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900'
                            )}
                          >
                            {item.name}
                          </button>
                        )}
                      </MenuItem>
                    ))}
                  </MenuItems>
                </Menu>
              </div>
            </div>
          )}
        </div>
        <hr />
        {isOffline ? null : (
          <div className="bg-white flex md:hidden px-6 shadow-sm items-start justify-center w-full">
            <TopBar />
          </div>
        )}
        {activeOrganisation?.companyName ? (
          <div className="text-sm shadow-sm flex bg-white justify-between px-10">
            <div className="flex items-center gap-x-3 py-2">
              <img
                alt={`${activeOrganisation.companyName} logo`}
                className=" px-2 w-auto h-10"
                src={activeOrganisation.logoUrl}
              />
              <strong className="border-l text-xs px-2">
                {activeOrganisation.companyName}
              </strong>
            </div>
            {isOfflineToggleRoute ? (
              <Switch
                text={isOffline ? 'Offline' : 'Online'}
                enabled={!isOffline}
                handleChange={() => setIsOffline(!isOffline)}
              />
            ) : null}
          </div>
        ) : (
          <div className="text-base mb-4 shadow-sm bg-white px-10 tracking-tight"></div>
        )}
        <MissingCertifications
          className="rounded-none"
          missingOrInvalidCertifications={
            userProfile?.missingOrInvalidCertifications
          }
        >
          You have missing, incomplete, expired or soon to be expiring
          certifications required for your role, please add / update these in
          your profile:
        </MissingCertifications>
        <main className="py-4">
          <div className="px-4 sm:px-6 lg:px-8">{children}</div>
        </main>
      </div>
    </div>
  );
};
