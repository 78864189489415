import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

import moment from 'moment';
import { Button } from '../../../components/Buttons/Button';

import * as Yup from 'yup';
import {
  BlueBadge,
  GeneratingBadge,
  GreenBadge,
  RedBadge,
} from '../../../components/Badges/Badges';
import FormCard from '../../../components/FormCard/FormCard';
import { GENERATE_PROJECT_EXPORT } from '../../../graphql/mutations/exports';
import { GET_EXPORTS, GET_EXPORT_URL } from '../../../graphql/queries/exports';

import Switch from '../../../components/Inputs/Switch';
import { ProjectSelection } from '../../../components/ProjectSelection/ProjectSelection';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { Permission } from '../../../types/Permissions';
import { exponentialBackoff } from '../../../utils/utils';

interface CreateProjectExportProps {
  children?: React.ReactElement;
  showNewItemButton?: boolean;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

const headerMapping = {
  location: 'File Name',
  email: 'Email',
  status: 'Status',
  date: 'Start Date',
  updatedAt: 'Updated',
  createdAt: 'Created',
  configure: 'Options',
} as Record<string, any>;

const ExportsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  RowComponent,
}: any) => (
  <Table
    key={JSON.stringify(data)}
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    RowComponent={RowComponent}
    columnsToFilter={['firstName', 'lastName', 'id', 'createdAt']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
      createdAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
      date: (text: string) => moment.unix(parseInt(text) / 1000).format('LL'),
      location: (text: string) => text?.replace('project-export/', ''),
    }}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

// Hardcoded UUID for Project Export Configuration
const PROJECT_EXPORT_CONFIGURATION_UUID =
  'e4c628e1-691f-40e3-a2c8-4314716935c7';

export const CreateProjectExportPage = ({
  children,
  showNewItemButton,
}: CreateProjectExportProps) => {
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const [newItemTime, setNewItemTime] = useState<any>();

  const [generateProjectSubmissionExport] = useMutation(
    GENERATE_PROJECT_EXPORT
  );

  const { limit, offset, setOffset } = usePagination();

  const [fetch] = useLazyQuery(GET_EXPORTS, {
    variables: { input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  const [fetchExportURL, { data: exportURL }] = useLazyQuery(GET_EXPORT_URL, {
    fetchPolicy: 'network-only',
  });

  const [exportResults, setExportResults] = useState({ count: 0, results: [] });

  const fetchAndSetResults = useCallback(async () => {
    const { data } = await fetch({
      variables: {
        exportConfigurationId: PROJECT_EXPORT_CONFIGURATION_UUID,
        input: { limit, offset },
      },
    });
    setExportResults(data?.getExports);
  }, [limit, offset]);

  useEffect(() => {
    fetchAndSetResults();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset]);

  const ExportResults = exportResults;

  const totalPages = Math.ceil(ExportResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const downloadPath = (path: string, filename: string) => {
    // Create a new link
    const anchor = document.createElement('a');
    anchor.href = path;
    anchor.download = filename;
    anchor.style.display = 'none';

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);

    // Revoke the Blob URL to free up resources
    URL.revokeObjectURL(path);
  };

  useEffect(() => {
    if (exportURL?.getExportURL) {
      const url = exportURL?.getExportURL?.url;
      downloadPath(url, `export.csv`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(exportURL)]);

  const [showCreateNewExport, setShowCreateNewExport] = useState(false);

  const [filterByProject, setFilterByProject] = useState(false);
  const [includeDisabledAssignees, setIncludeDisabledAssignees] =
    useState(false);
  const [includeCertifications, setIncludeCertifications] = useState(false);
  const [includeCvs, setIncludeCvs] = useState(false);
  const [selectedProject, setSelectedProject] = useState<any>(undefined);

  const { userProfile } = useContext(UserProfileContext);

  const createNewExportFormConfig = {
    formSections: [
      {
        title: 'New Project Export',
        description:
          "Create a ZIP export file of all projects, or a single project's assignees along with pertinent information about them. You can also choose to include any recorded certifications for assignees on a project (if you have permissions to do so).",
        components: [
          <Switch
            enabled={filterByProject}
            handleChange={setFilterByProject}
            text={'Filter by project'}
          />,
          <Switch
            enabled={includeDisabledAssignees}
            handleChange={setIncludeDisabledAssignees}
            text={'Include assignees that are not enabled'}
          />,
          filterByProject ? (
            <ProjectSelection
              placeholder="Filter by project"
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
            />
          ) : null,
          filterByProject &&
          userProfile?.permissions.includes(
            Permission.DownloadCertificationAttachments
          ) ? (
            <Switch
              enabled={includeCertifications}
              handleChange={setIncludeCertifications}
              text={'Include Certifications'}
            />
          ) : null,
          filterByProject &&
          userProfile?.permissions.includes(Permission.ViewLabourProfiles) ? (
            <Switch
              enabled={includeCvs}
              handleChange={setIncludeCvs}
              text={'Include Latest CVs'}
            />
          ) : null,
        ],
      },
    ],
  } as unknown as FormConfig;

  const initialValues = {};
  const validationSchema = Yup.object().shape({});

  useEffect(() => {
    if (!filterByProject) {
      setSelectedProject(undefined);
      setIncludeCertifications(false);
    }
  }, [filterByProject]);

  const onSubmitProjectSubmissionExport = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setOffset(0);
    setNewItemTime(Date.now());
    try {
      await generateProjectSubmissionExport({
        variables: {
          input: {
            ...values,
            includeCertifications,
            includeCvs,
            includeDisabledAssignees,
            ...(selectedProject?.id ? { projectId: selectedProject?.id } : {}),
          },
        },
      });
      await fetchAndSetResults();
      if (setSubmitting) {
        setSubmitting(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowCreateNewExport(false);
    }
    // Check for 4 minutes with a backoff
    exponentialBackoff(() => fetchAndSetResults(), 240000);
  };

  const navigate = useNavigate();

  return (
    <div>
      <>
        <div className="flex w-full border-b mb-2">
          <Button
            style={{
              backgroundColor: 'transparent',
              color: 'black',
              boxShadow: 'none',
              borderRadius: 0,
            }}
            onClick={() => navigate(-1)}
            type="submit"
            text={`< Go Back`}
          />
        </div>
        <p className="text-sm py-2 text-gray-600">Export project data.</p>
        {showNewItemButton &&
        userProfile?.permissions.includes(Permission.GenerateProjectExports) ? (
          <div className="w-full flex justify-between items-center py-2">
            <Button
              text="New Export"
              onClick={() => setShowCreateNewExport(true)}
            />
          </div>
        ) : null}
        {showCreateNewExport ? (
          <div className="mb-6">
            <FormCard
              initialValues={initialValues}
              onSubmit={onSubmitProjectSubmissionExport}
              validationSchema={validationSchema}
              submitText="Create"
              onCancel={() => setShowCreateNewExport(false)}
              config={createNewExportFormConfig}
            />
          </div>
        ) : null}
        <ExportsTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={ExportResults?.count || 0}
          key={`${newItemTime}`}
          RowComponent={({ id, rowData }: any) => (
            <>
              <div
                key={`${id}_row`}
                className={`border-b flex flex-col w-full justify-around items-center lg:flex-row border-gray-200 hover:bg-gray-100`}
              >
                {rowData?.id ? (
                  <div
                    key={`${id}_row_entry`}
                    className="py-3 px-6 text-sm text-left w-full"
                  >
                    <div className="flex flex-col flex-wrap gap-y-2 gap-x-2 mt-2">
                      <p>{rowData?.id}</p>
                      <div className="text-xs flex flex-col">
                        Created:{' '}
                        {rowData.createdAt
                          ? moment
                              .unix(parseInt(rowData.createdAt) / 1000)
                              .fromNow()
                          : null}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div
                  key={id}
                  className="py-3 px-6 text-sm flex flex-col gap-y-2 lg:flex-row items-center justify-end  gap-x-2 text-black w-full"
                >
                  <div className="flex items-center gap-x-2">
                    Report Status:
                    {rowData.status === 'COMPLETE' ? (
                      <GreenBadge text={'Generated'} />
                    ) : rowData.status === 'FAILED' ? (
                      <RedBadge text={rowData.status} />
                    ) : rowData.status === 'SUBMITTED' ? (
                      <BlueBadge text={'Submitted'} />
                    ) : (
                      <GeneratingBadge />
                    )}
                  </div>
                  {rowData.status === 'COMPLETE' ||
                  rowData.status === 'SUBMITTED' ? (
                    <div className="flex gap-x-2">
                      <Button
                        text="Download"
                        onClick={async () => {
                          await fetchExportURL({
                            variables: {
                              exportId: rowData?.id,
                            },
                          });
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          )}
          data={
            ExportResults?.results.length > 0 ? ExportResults?.results : [{}]
          }
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const CreateProjectExport = ({
  onClickRow,
  showNewItemButton = true,
  filterIds = [],
}: CreateProjectExportProps) => (
  <PaginationProvider>
    <CreateProjectExportPage
      showNewItemButton={showNewItemButton}
      onClickRow={onClickRow}
      filterIds={filterIds}
    />
  </PaginationProvider>
);
