import { useNavigate, useParams } from 'react-router-dom';
import { Table } from '../../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../../context/PaginationContext';

import moment from 'moment';

import { GET_PROJECT_ASSIGNMENT_ROLES_SUMMARY } from '../../../../graphql/queries/assignments';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import AssignmentRow from './row';
import { useEffect } from 'react';

interface ProjectAssignmentRoleListProps {
  assignment?: any;
  archivedOnly?: boolean;
  children?: React.ReactElement;
  refetchAllAssignmentRoles: () => void;
  setRefetchAssignmentRoles: (refetch: () => void) => void;
  setRefetchArchivedAssignmentRoles: (refetch: () => void) => void;
}

const headerMapping = {
  role: 'Role',
  updatedAt: 'Updated At',
} as Record<string, any>;

export const ProjectAssignmentRoleListPage = ({
  assignment,
  archivedOnly = false,
  children,
  refetchAllAssignmentRoles,
  setRefetchAssignmentRoles,
  setRefetchArchivedAssignmentRoles,
}: ProjectAssignmentRoleListProps) => {
  let { assignmentId } = useParams();

  const navigate = useNavigate();

  const { useQuery } = useOrganisationAwareApollo();

  const { limit, offset, setOffset } = usePagination();
  const { data, refetch } = useQuery(GET_PROJECT_ASSIGNMENT_ROLES_SUMMARY, {
    variables: { assignmentId, input: { limit, offset }, archivedOnly },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (refetch) {
      if (archivedOnly) {
        setRefetchArchivedAssignmentRoles(() => refetch);
      } else {
        setRefetchAssignmentRoles(() => refetch);
      }
    }
  }, [
    archivedOnly,
    refetch,
    setRefetchArchivedAssignmentRoles,
    setRefetchAssignmentRoles,
  ]);

  const projectAssignmentRoleResults = data?.getAssignmentRoles;

  const totalPages = Math.ceil(projectAssignmentRoleResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const ProjectAssignmentRolesTable = ({
    data = [{}],
    currentPage = 1,
    totalPages = 0,
    itemsPerPage = 0,
    totalResults = 0,
    onPageChange = () => {},
  }: any) => (
    <Table
      currentPage={currentPage}
      totalPages={totalPages}
      totalResults={totalResults}
      itemsPerPage={itemsPerPage}
      RowComponent={(props) => (
        <AssignmentRow
          {...props}
          assignment={assignment}
          refetch={refetchAllAssignmentRoles}
        />
      )}
      columnValueMapper={{
        updatedAt: (text: string) =>
          moment.unix(parseInt(text) / 1000).fromNow(),
      }}
      showFilterOptions={false}
      columnsToFilter={['enabled', 'id']}
      headerMapper={(text: string) => headerMapping[text]}
      onPageChange={onPageChange}
      data={data}
    />
  );

  return (
    <div>
      <>
        <ProjectAssignmentRolesTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={projectAssignmentRoleResults?.count || 0}
          data={
            projectAssignmentRoleResults?.results.length > 0
              ? projectAssignmentRoleResults?.results.map(
                  (assignmentRole: any) => ({
                    id: assignmentRole.id,
                    projectId: assignment?.project?.id,
                    labourType: assignment?.lrs?.type,
                    role: (
                      <div
                        role="button"
                        onClick={() =>
                          navigate(`/labour/roles/${assignmentRole?.role?.id}`)
                        }
                      >
                        {assignmentRole?.role?.name ?? 'None Allocated'}
                      </div>
                    ),
                    enabled: assignmentRole.enabled,
                  })
                )
              : [{}]
          }
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const ProjectAssignmentRoleList = (
  props: ProjectAssignmentRoleListProps
) => (
  <PaginationProvider>
    <ProjectAssignmentRoleListPage {...props} />
  </PaginationProvider>
);
