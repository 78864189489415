import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  Table,
  TableRowComponentProps,
} from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

import { LoadingSpinner } from '../../../components/Loading/LoadingSpinner';
import { SEARCH_PROJECT_ASSIGNMENT_ROLES } from '../../../graphql/queries/assignments';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import SearchAssignmentRow from './search/row';

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { joinClassNames } from '../../../utils/utils';

interface AssignmentSearchProps {
  children?: React.ReactElement;
}

const headerMapping = {} as Record<string, any>;

const AssignmentsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  showPagination,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    showPagination={showPagination}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    showFilterOptions={false}
    RowComponent={SearchAssignmentRow as React.FC<TableRowComponentProps>}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const AssignmentSearchPage = ({ children }: AssignmentSearchProps) => {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [isSearching, setIsSearching] = useState(false);
  const [assignmentStatus, setAssignmentStatus] = useState('All');

  const { limit, offset, setOffset } = usePagination();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [fetch, { data }] = useLazyQuery(SEARCH_PROJECT_ASSIGNMENT_ROLES, {
    variables: { searchTerm, assignmentStatus, input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  const performSearch = async () => {
    setIsSearching(true);
    try {
      await fetch({
        variables: {
          searchTerm: debouncedSearchTerm,
          assignmentStatus,
          input: { limit, offset },
        },
      });
    } catch (err) {
      // do nothing
    } finally {
      setIsSearching(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 1) {
      performSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, assignmentStatus, limit, offset]);

  const assignmentResults = data?.searchAssignmentRoles;

  const totalPages = Math.ceil(assignmentResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  return (
    <div>
      <>
        <div className="w-full flex justify-between items-center py-2">
          <h1 className="text-xl font-bold py-4">Search Assignments</h1>
        </div>
        <div className="relative py-2 flex items-center">
          <div className="absolute left-0 ml-2">
            <MagnifyingGlassIcon
              className={joinClassNames(
                'h-8 w-8 shrink-0 text-gray-600 rounded-full p-2 shadow-sm'
              )}
              aria-hidden="true"
            />
          </div>
          <input
            name="search"
            id="search"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 px-10"
            placeholder="Search by assignee name, project name, project internal id or role title"
            required={true}
          />
          {isSearching && (
            <div className="absolute right-0 mr-5">
              <LoadingSpinner spinnerStyle={{ borderColor: 'gray' }} />
            </div>
          )}
        </div>
        <div className="flex items-center gap-x-4 py-2">
          <p className="text-sm">Status:</p>
          <select
            value={assignmentStatus}
            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:max-w-xs sm:text-sm sm:leading-6"
            onChange={(event: any) => setAssignmentStatus(event.target.value)}
          >
            {['All', 'Active', 'Disabled'].map((option: any) => (
              <option key={option}>{option}</option>
            ))}
          </select>
        </div>
        <div className="py-2">
          {!isSearching && assignmentResults?.results.length > 0 ? (
            <AssignmentsTable
              currentPage={Math.floor(offset / limit) + 1}
              totalPages={totalPages}
              itemsPerPage={limit}
              showPagination={assignmentResults?.count > 0}
              totalResults={assignmentResults?.count || 0}
              columnsToFilter={[
                'id',
                'type',
                'name',
                'projectName',
                'enabled',
                'type',
                'projectId',
              ]}
              data={
                assignmentResults?.results.length > 0
                  ? assignmentResults?.results.map(
                      ({ assignment: [assignment], role, enabled }: any) => {
                        return {
                          id: assignment.id,
                          name: `${assignment.lrs?.lastName}, ${assignment.lrs?.firstName}`,
                          type:
                            assignment?.lrs?.type === 'EMPLOYEE'
                              ? 'Employee'
                              : 'Subcontractor',
                          projectName: `${assignment?.project?.name} - ${assignment?.project?.internalId}`,
                          projectId: assignment?.project?.id,
                          enabled: enabled,
                          roleName: `${role?.name}`,
                        };
                      }
                    )
                  : [{}]
              }
              onPageChange={handlePageChange}
            />
          ) : null}
          {searchTerm &&
            !isSearching &&
            assignmentResults?.results?.length === 0 && (
              <p>No Results Found.</p>
            )}
        </div>
      </>
      {children}
    </div>
  );
};

export const AssignmentSearch = () => (
  <PaginationProvider>
    <AssignmentSearchPage />
  </PaginationProvider>
);
