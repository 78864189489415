import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import { RolesList } from './RolesList';

interface RoleListProps {
  includeArchivedRoles?: boolean;
  showNewItemButton?: boolean;
  children?: React.ReactElement;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

export const RoleListPage = ({
  children,
  onClickRow,
  showNewItemButton = true,
}: RoleListProps) => {
  const navigate = useNavigate();

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div>
      <>
        {showNewItemButton ? (
          <div className="w-full flex justify-between items-center py-2">
            <h1 className="text-xl font-bold py-4">Roles</h1>
            {userProfile?.permissions?.includes(Permission.UpdateRoles) ? (
              <Button
                text="New Role"
                onClick={() => navigate(`/labour/roles/new`)}
              />
            ) : null}
          </div>
        ) : null}
        <p className="text-sm py-2 text-gray-600">
          Roles can be assigned to subcontractors and employees. Roles contain
          rates against specific currencies.
        </p>
      </>
      <RolesList
        includeArchivedRoles={true}
        showNewItemButton={true}
        filterIds={[]}
      />
      {children}
    </div>
  );
};
