import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

import moment from 'moment';

import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { BackToTimesheetsButton } from '../timesheet/buttons/BackToTimesheets';
import {
  GET_TIMESHEET_SUBMISSION,
  GET_UNSUBMITTED_TIMESHEETS,
} from '../../../graphql/queries/timesheet-submissions';
import {
  DateTimePicker,
  DateTimePickerSelectorType,
} from 'react-datetime-pickers';
import { GET_TIMESHEET_CONFIGURATIONS } from '../../../graphql/queries/timesheet-configurations';
import { GreenBadge, YellowBadge } from '../../../components/Badges/Badges';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import { Tabs } from '../../../components/Tabs/Tabs';
import { useFilter } from '../../../hooks/useFilter';
import { useSort } from '../../../hooks/useSort';

interface TimesheetSubmissionListProps {
  children?: React.ReactElement;
  showNewItemButton?: boolean;
  filterIds?: string[];
  weekId?: string;
  onClickRow?: (id: string, results: any[]) => void;
}

const tabs = ['Submitted', 'Unsubmitted'];

const filterDimensions = [{ id: 'subject', name: 'Subject' }];

export const TimesheetSubmissionListPage = ({
  children,
  onClickRow,
  showNewItemButton,
  filterIds,
}: TimesheetSubmissionListProps) => {
  const navigate = useNavigate();
  const { configurationId } = useParams();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const { sortByColumn, sortDirection, setSort } = useSort({
    defaultSortColumn: 'subject',
  });

  const { limit, offset, setOffset } = usePagination();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    filterText,
    setFilterText,
    filterDimension,
    setFilterDimension,
    filter,
  } = useFilter({ defaultFilterDimension: 'subject' });

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const selectedDateString = searchParams.get('date');
  const selectedDate = useMemo(
    () =>
      selectedDateString
        ? moment(selectedDateString).toDate()
        : moment().startOf('isoWeek').toDate(),
    [selectedDateString]
  );

  const setSelectedDate = (date: Date) => {
    setSearchParams((searchParams) => {
      searchParams.set('date', moment(date).format('YYYY-MM-DD'));
      return searchParams;
    });
  };

  const minDate = moment().subtract(12, 'weeks').toDate();
  const maxDate = moment().add(4, 'weeks').toDate();

  const start = moment(selectedDate);

  // Getting the year and week number
  const startYear = start.isoWeekYear();
  const startWeek = start.isoWeek();

  // Formatting to the desired output, e.g., Y2022-W05
  const formattedStartWeekId = `Y${startYear}-W${String(startWeek).padStart(
    2,
    '0'
  )}`;

  const [fetchTimesheetConfigurations, { data: timesheetConfigResults }] =
    useLazyQuery(GET_TIMESHEET_CONFIGURATIONS, {
      variables: { input: { id: configurationId } },
      fetchPolicy: 'network-only',
    });

  const [fetch, { data }] = useLazyQuery(GET_TIMESHEET_SUBMISSION, {
    variables: {
      input: { limit, offset },
      weekId: formattedStartWeekId,
      onlySubmitted: true,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (configurationId) {
      fetchTimesheetConfigurations({
        variables: {
          ...(configurationId ? { id: configurationId } : {}),
        },
      });
    }
  }, [configurationId, filter]);

  useEffect(() => {
    if (activeTab === 'Submitted') {
      fetch({
        variables: {
          ...(configurationId ? { configurationId } : {}),
          weekId: formattedStartWeekId,
          input: {
            limit,
            offset,
            ...(filter ? { filter } : {}),
            ...(sortByColumn && sortDirection
              ? { order: [[sortByColumn, sortDirection]] }
              : {}),
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    configurationId,
    limit,
    offset,
    activeTab,
    formattedStartWeekId,
    filter,
    sortByColumn,
    sortDirection,
  ]);

  const TimesheetSubmissionResults = data?.getTimesheetSubmissions;

  const [timesheetConfig] =
    timesheetConfigResults?.getTimesheetConfigurations?.results || [];

  const totalPages = Math.ceil(TimesheetSubmissionResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const headerMapping = {
    id: 'id',
    subject: {
      text: 'Subject',
      sorted: ['subject'].includes(sortByColumn),
      sortDirection,
      onClick: () => {
        setSort('subject');
      },
    },
    approved: 'Approved',
    approvedBy: 'Approved By',
    updatedAt: {
      text: 'Updated At',
      sorted: ['updatedAt'].includes(sortByColumn),
      sortDirection,
      onClick: () => {
        setSort('updatedAt');
      },
    },
  } as Record<string, any>;

  const { userProfile } = useContext(UserProfileContext);

  const canViewTimesheetSubmissions = userProfile?.permissions?.includes(
    Permission.ViewTimesheetSubmissions
  );

  return (
    <div>
      <>
        {showNewItemButton ? (
          <div className="w-full flex justify-between items-center py-2">
            {timesheetConfig && configurationId ? (
              <h1 className="text-xl font-bold py-4">
                Submissions for {timesheetConfig?.name}
              </h1>
            ) : (
              <h1 className="text-xl font-bold py-4">Submissions</h1>
            )}
            <div className="flex flex-col items-center gap-y-2">
              <p className="text-sm text-gray-600">Select a Week:</p>
              <div className="border rounded-md">
                <DateTimePicker
                  minDate={minDate}
                  maxDate={maxDate}
                  selector={DateTimePickerSelectorType.WEEK}
                  onChange={(props: any) => {
                    setSelectedDate(props);
                  }}
                  selected={selectedDate}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className="bg-black flex rounded-t-md">
          <BackToTimesheetsButton />
        </div>
        {configurationId && (
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
        )}
        {activeTab === 'Submitted' ? (
          <Table
            currentPage={Math.floor(offset / limit) + 1}
            totalPages={totalPages}
            itemsPerPage={limit}
            totalResults={TimesheetSubmissionResults?.count || 0}
            headerMapper={(text: string) => headerMapping[text]}
            columnsToFilter={['firstName', 'lastName']}
            columnValueMapper={{
              weekCommencingDate: (text: string) => moment(text).format('LL'),
              updatedAt: (text: string) =>
                moment.unix(parseInt(text) / 1000).fromNow(),
            }}
            showFilterOptions={true}
            filterText={filterText}
            setFilterText={(filterText) => {
              setFilterText(filterText);
              setOffset(0);
            }}
            selectedFilterDimension={filterDimension}
            setSelectedFilterDimension={setFilterDimension}
            filterDimensions={filterDimensions}
            onClickRow={(id: any) => {
              if (canViewTimesheetSubmissions) {
                if (onClickRow) {
                  onClickRow(id, TimesheetSubmissionResults?.results);
                } else {
                  sessionStorage.setItem(
                    'previous_table_url',
                    `${
                      configurationId
                        ? `/time/timesheets/submissions/configuration/${timesheetConfig?.id}`
                        : '/time/timesheets/submissions'
                    }`
                  );
                  sessionStorage.setItem(
                    `${
                      configurationId
                        ? `${configurationId}_timesheet_submissions_table_query_params`
                        : 'timesheet_submissions_table_query_params'
                    }`,
                    searchParams.toString()
                  );
                  navigate(`/time/timesheets/submissions/${id && id}`);
                }
              }
            }}
            data={
              TimesheetSubmissionResults?.results.length > 0
                ? TimesheetSubmissionResults?.results
                    ?.filter((result: any) => !filterIds?.includes(result.id))
                    .map((result: any) => ({
                      id: result.id,
                      ...(result?.lrs?.id
                        ? {
                            subject: `${result.lrs.lastName}, ${result.lrs.firstName}`,
                          }
                        : {}),
                      ...(result?.project?.id
                        ? { subject: result.project.name }
                        : {}),
                      updatedAt: result.updatedAt,
                      approvedBy: result.approvedBy ?? 'Not Available',
                      approved: result?.approved ? (
                        <GreenBadge text="Approved" />
                      ) : (
                        <YellowBadge text={'Unapproved'} />
                      ),
                    }))
                : [{}]
            }
            onPageChange={handlePageChange}
          />
        ) : (
          <UnsubmittedTimesheetsList weekId={formattedStartWeekId} />
        )}
      </>
      {children}
    </div>
  );
};

export const UnsubmittedTimesheetsListPage = ({
  children,
  filterIds,
  weekId,
}: TimesheetSubmissionListProps) => {
  const { configurationId } = useParams();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const { limit, offset, setOffset } = usePagination();

  const [fetchUnsubmittedTimesheets, { data: unsubmittedTimesheets }] =
    useLazyQuery(GET_UNSUBMITTED_TIMESHEETS, {
      variables: { input: { limit, offset }, configurationId, weekId },
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    setOffset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekId, configurationId]);

  useEffect(() => {
    if (configurationId) {
      fetchUnsubmittedTimesheets({
        variables: { configurationId, weekId, input: { limit, offset } },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationId, limit, offset, weekId]);

  const UnsubmittedTimesheetsResults =
    unsubmittedTimesheets?.getUnsubmittedTimesheets;

  const totalPages = Math.ceil(UnsubmittedTimesheetsResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const headerMapping = {
    id: 'id',
    name: 'Name',
    internalId: 'Internal ID',
    updatedAt: 'Updated At',
  } as Record<string, any>;

  return (
    <div>
      <>
        <Table
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          columnsToFilter={['firstName', 'lastName']}
          columnValueMapper={{
            weekCommencingDate: (text: string) =>
              moment(parseInt(text)).format('LL'),
            updatedAt: (text: string) =>
              moment.unix(parseInt(text) / 1000).fromNow(),
          }}
          totalResults={UnsubmittedTimesheetsResults?.count || 0}
          data={
            UnsubmittedTimesheetsResults?.results.length > 0
              ? UnsubmittedTimesheetsResults?.results
                  ?.filter((result: any) => !filterIds?.includes(result.id))
                  .map((result: any) => ({
                    name: result.name,
                    internalId: result.internalId,
                  }))
              : [{}]
          }
          onPageChange={handlePageChange}
          headerMapper={(text: string) => headerMapping[text]}
        />
      </>
      {children}
    </div>
  );
};

export const UnsubmittedTimesheetsList = ({
  onClickRow,
  showNewItemButton = true,
  filterIds = [],
  weekId,
}: TimesheetSubmissionListProps) => (
  <PaginationProvider>
    <UnsubmittedTimesheetsListPage
      showNewItemButton={showNewItemButton}
      onClickRow={onClickRow}
      weekId={weekId}
      filterIds={filterIds}
    />
  </PaginationProvider>
);

export const TimesheetSubmissionList = ({
  onClickRow,
  showNewItemButton = true,
  filterIds = [],
}: TimesheetSubmissionListProps) => (
  <PaginationProvider>
    <TimesheetSubmissionListPage
      showNewItemButton={showNewItemButton}
      onClickRow={onClickRow}
      filterIds={filterIds}
    />
  </PaginationProvider>
);
