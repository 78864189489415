import { useCallback, useEffect, useRef, useState } from 'react';
import { GENERATE_CV_EXPORT } from '../../../graphql/mutations/exports';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { GET_ROLES_SUMMARY } from '../../../graphql/queries/roles';
import { Modal } from '../../../components/Dialogue/ModalDialogue';
import DropDown from '../../../components/Inputs/Dropdown';
import TextInput from '../../../components/Inputs/TextInput';
import Switch from '../../../components/Inputs/Switch';
import RichTextInput from '../../../components/Inputs/RichTextInput';

type CvExportModalProps = {
  labour: {
    id: string;
    jobTitle?: string;
    personalProfile?: string;
    defaultRole?: {
      id?: string;
      name: string;
      description?: string;
    };
  };
  assignmentRole?: {
    id: string;
    description?: string;
    role: { name: string; description?: string };
  };
  onConfirm: () => void;
  setIsSubmitting: (value: boolean) => void;
  setShowModal: (value: boolean) => void;
  showModal: boolean;
};

export function CvExportModal({
  labour,
  assignmentRole,
  onConfirm,
  setIsSubmitting,
  setShowModal,
  showModal,
}: CvExportModalProps) {
  const { useMutation, useQuery } = useOrganisationAwareApollo();
  const [generateCvExport] = useMutation(GENERATE_CV_EXPORT);

  const { data: rolesData } = useQuery(GET_ROLES_SUMMARY, {});

  const roles = rolesData?.getRoles?.results || [];

  const [includeNameInCv, setIncludeNameInCv] = useState<boolean>(true);
  const [includeProfileInCv, setIncludeProfileInCv] = useState<boolean>(true);
  const [shouldUseProjectDates, setshouldUseProjectDates] =
    useState<boolean>(true);

  const [profileInfo, setProfileInfo] = useState<
    Record<string, string | undefined>
  >({
    jobTitle: labour?.jobTitle || '',
    personalProfile: labour?.personalProfile || '',
    jobTitleId: `jobTitleCheckbox-${labour?.jobTitle}`,
  });

  const profileInfoRef = useRef(profileInfo);

  useEffect(() => {
    profileInfoRef.current = profileInfo;
  }, [profileInfo]);

  const createNewExport = useCallback(async () => {
    setIsSubmitting(true);

    // makes sure we don't send the jobTitleId to the backend (it's only used in the frontend for providing unique input values)
    const profileInfoCopy = { ...profileInfoRef.current };
    delete profileInfoCopy.jobTitleId;

    await generateCvExport({
      variables: {
        input: {
          labourResourceId: labour.id,
          assignmentRoleId: assignmentRole?.id,
          includeNameInCv: includeNameInCv,
          profileInfo: includeProfileInCv ? profileInfoCopy : null,
          shouldUseProjectDates,
        },
      },
    });
    onConfirm();
  }, [
    setIsSubmitting,
    generateCvExport,
    labour.id,
    assignmentRole?.id,
    includeNameInCv,
    includeProfileInCv,
    shouldUseProjectDates,
    onConfirm,
  ]);

  const handleDefaultRoleRadioChange = () => {
    setProfileInfo({
      ...profileInfo,
      jobTitle: labour?.defaultRole?.name || '',
      jobTitleId: `defaultRoleCheckbox-${labour?.defaultRole?.name}`,
    });
  };

  const handleJobTitleRadioChange = () => {
    setProfileInfo({
      ...profileInfo,
      jobTitle: labour?.jobTitle || '',
      jobTitleId: `jobTitleCheckbox-${labour?.jobTitle}`,
    });
  };

  return (
    <Modal
      classNames="md:max-w-[900px]"
      showModal={showModal}
      setShowModal={setShowModal}
      title={'CV Export Settings'}
      completeButtonText={'Create CV'}
      onComplete={() => {
        createNewExport();
        setShowModal(false);
      }}
      disableCompleteButton={
        includeProfileInCv &&
        (profileInfo?.jobTitle === '' || profileInfo?.personalProfile === '')
      }
    >
      <div className="px-4">
        <div className="text-gray-600 flex flex-col gap-y-4">
          <h1 className="pt-4 text-lg text-black">Personal Profile</h1>
          <div
            className={`transition-all duration-500 overflow-hidden`}
            style={{
              maxHeight: includeProfileInCv ? '1000px' : '0',
              transition: 'max-height 0.5s ease, opacity 0.5s ease',
            }}
          >
            <div className="text-sm pb-2">
              Choose which job title will appear on the CV and make any
              amendments below.
            </div>
            <div className="bg-gray-100 p-4 rounded-lg">
              <div className="flex flex-col gap-y-2 ">
                {labour?.jobTitle ||
                  (labour?.defaultRole?.name && <p>Select a job role</p>)}
                <div className="flex gap-x-4">
                  {labour?.jobTitle ? (
                    <div
                      className="inline-flex gap-x-2 px-2 items-center cursor-pointer"
                      onClick={handleJobTitleRadioChange}
                    >
                      <input
                        id="jobTitle"
                        type="radio"
                        name="jobTitle"
                        value={labour?.jobTitle || ''}
                        onChange={handleJobTitleRadioChange}
                        checked={
                          profileInfo?.jobTitleId ===
                          `jobTitleCheckbox-${labour?.jobTitle}`
                        }
                        className="form-radio text-primary-500 focus:ring-primary-500 cursor-pointer"
                      />
                      {labour?.jobTitle ? (
                        <span>{`${labour.jobTitle} (Default job title)`}</span>
                      ) : null}
                    </div>
                  ) : null}

                  {labour?.defaultRole && labour?.defaultRole?.name ? (
                    <div
                      className="inline-flex gap-x-2 px-2 items-center cursor-pointer"
                      onClick={handleDefaultRoleRadioChange}
                    >
                      <input
                        id="defaultRole"
                        type="radio"
                        name="defaultRole"
                        value={labour?.defaultRole?.name || ''}
                        onChange={handleDefaultRoleRadioChange}
                        checked={
                          profileInfo?.jobTitleId ===
                          `defaultRoleCheckbox-${labour?.defaultRole?.name}`
                        }
                        className="form-radio text-primary-500 focus:ring-primary-500 cursor-pointer"
                      />
                      {labour?.defaultRole?.name ? (
                        <span>{`${labour?.defaultRole?.name} (Default job role)`}</span>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
              <p className="py-2 text-sm">
                {`${
                  !labour?.jobTitle && !labour?.defaultRole?.name
                    ? 'You can'
                    : 'Or'
                } choose a role from the dropdown below:`}
              </p>
              <div>
                <DropDown
                  classNames="px-2"
                  hideClearButton
                  id="job-role"
                  options={roles}
                  handleChange={(e: any) => {
                    setProfileInfo({
                      jobTitle: e,
                      personalProfile: labour?.personalProfile || '',
                      jobTitleId: `jobRoleDropdown-${e}`,
                    });
                  }}
                  value={
                    profileInfo?.jobTitleId ===
                      `defaultRole-${labour?.defaultRole?.name}` ||
                    profileInfo?.jobTitle === labour?.defaultRole?.name ||
                    profileInfo?.jobTitle === labour?.jobTitle ||
                    !roles.some(
                      (role: any) => role?.name === profileInfo?.jobTitle
                    )
                      ? 'All roles'
                      : profileInfo?.jobTitle
                  }
                />
              </div>
            </div>
            <div>
              <h2 className="text-black text-lg mt-2">Job Title</h2>
              <TextInput
                title="Job Title"
                value={profileInfo?.jobTitle || ''}
                id="job-title"
                name="jobTitle"
                handleChange={(e: any) => {
                  setProfileInfo({
                    ...profileInfo,
                    jobTitle: e,
                    jobTitleId: 'jobTitleTextInput',
                  });
                }}
              />
            </div>
            <div className="mb-2">
              <h2 className="text-black text-lg mt-2">Personal Profile</h2>
              <RichTextInput
                id="personal-profile"
                value={
                  profileInfo?.personalProfile
                    ? profileInfo?.personalProfile
                    : ''
                }
                handleChange={(e: any) => {
                  setProfileInfo({
                    ...profileInfo,
                    personalProfile: e?.target?.value,
                  });
                }}
                description="Enter personal profile here"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 flex gap-y-2 flex-col">
        <Switch
          handleChange={() => setIncludeNameInCv(!includeNameInCv)}
          text="Include person's name in CV"
          description=""
          enabled={includeNameInCv}
        />
        <Switch
          handleChange={() => setIncludeProfileInCv(!includeProfileInCv)}
          text="Include personal profile in CV"
          description=""
          enabled={includeProfileInCv}
        />
        <Switch
          handleChange={() => setshouldUseProjectDates(!shouldUseProjectDates)}
          text="Use project dates for assignment roles when no start and end dates are provided"
          description=""
          enabled={shouldUseProjectDates}
        />
      </div>
    </Modal>
  );
}
