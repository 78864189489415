import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useContext, useEffect, useState } from 'react';

import FormCard from '../../../../components/FormCard/FormCard';
import {
  GET_SKILLS,
  SEARCH_SKILL_TYPES,
} from '../../../../graphql/queries/skills';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../../types/Form';

import { SAVE_SKILL } from '../../../../graphql/mutations/skills';
import { LabourHeading } from '../../heading/labour';
import { Permission } from '../../../../types/Permissions';
import { UserProfileContext } from '../../../../context/UserProfileContext';
import { useToast } from '../../../../context/ToastContext';

export const SaveSkill = () => {
  let { id, labourResourceId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();
  const { useQuery, useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const isUpdate = !!id;

  const { data, refetch } = useQuery(GET_SKILLS, {
    variables: { input: { id } },
    skip: !isUpdate,
  });
  const [searchSkillTypes, { data: skilltypesData }] =
    useLazyQuery(SEARCH_SKILL_TYPES);

  useEffect(() => {
    if (isUpdate) {
      refetch({ id });
    }
  }, [id, refetch, isUpdate]);

  useEffect(() => {
    if (isUpdate) {
      refetch({ id });
    }
  }, [id, refetch, isUpdate]);

  const [skill] = data?.getSkills?.results || [];

  useEffect(() => {
    if (skill) {
      searchSkillTypes({ variables: { searchTerm: skill?.skillType?.name } });
    }
  }, [searchSkillTypes, skill]);

  const [saveSkill] = useMutation(SAVE_SKILL);

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const performSkillTypeSearch = async () => {
    try {
      await searchSkillTypes({
        variables: {
          searchTerm: debouncedSearchTerm,
        },
        fetchPolicy: 'network-only',
      });
    } catch (err) {
      // do nothing
    } finally {
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    performSkillTypeSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const initialValues = id
    ? {
        id,
        skillType: skill?.skillType?.id,
        rating: skill?.rating,
        notes: skill?.notes,
      }
    : {};

  const validationSchema = Yup.object().shape({});

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const { data } = await saveSkill({
        variables: {
          id: values?.id,
          input: {
            ...values,
            labourResourceId,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      setSubmitting(false);
      if (data.saveSkill.success) {
        addToast('Skill saved successfully', 'success');
        navigate(-1);
      } else {
        if (data.saveSkill?.message === 'Duplicate skill value') {
          addToast(
            `Error saving skill. This person already has this skill.`,
            'error'
          );
        } else {
          addToast('Error saving Skill', 'error');
        }
      }
    } catch (error) {
      addToast('Error saving Skill', 'error');
      console.error(error);
    }
  };

  const { userProfile } = useContext(UserProfileContext);

  const skillConfig = {
    title: '',
    description: '',
    formSections: [
      {
        title: id ? skill?.skillType?.name : 'Skill',
        fields: [
          ...(!id
            ? [
                {
                  title: 'Skill Type',
                  id: 'skillType',
                  type: id ? 'input' : 'combobox',
                  disabled: !!id,
                  description: 'This must match an existing skill name',
                  placeholder: 'Start typing the skill name',
                  // @ts-ignore
                  onChange: (value: string) => {
                    setSearchTerm(value);
                  },
                  valueTransformer: (id: string) =>
                    skilltypesData?.searchSkillTypes?.results?.find(
                      (result: any) => result.id === id
                    )?.name,
                  options: skilltypesData?.searchSkillTypes?.results.map(
                    (skill: any) => ({
                      id: skill.id,
                      name: skill.name,
                    })
                  ),
                  required: true,
                },
              ]
            : []),
          {
            title: 'Rating',
            id: 'rating',
            disabled: !userProfile?.permissions.includes(
              Permission.UpdateSkills
            ),
            type: 'rangeslider',
            min: 0,
            max: 10,
          },
          {
            title: 'Notes',
            id: 'notes',
            disabled: !userProfile?.permissions.includes(
              Permission.UpdateSkills
            ),
            type: 'textarea',
          },
        ],
      },
    ],
  } as FormConfig;

  return (
    <>
      <LabourHeading
        id={labourResourceId}
        labourType={
          location.pathname.includes('subcontractors')
            ? 'subcontractors'
            : 'employees'
        }
      />
      <FormCard
        key={`${skillConfig?.title}`}
        config={skillConfig}
        isDisabled={!userProfile?.permissions.includes(Permission.UpdateSkills)}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </>
  );
};
