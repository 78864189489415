import { useRef, useState } from 'react';
import {
  useFloating,
  offset,
  flip,
  shift,
  autoUpdate,
  arrow,
} from '@floating-ui/react';

export enum TooltipPlacement {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
}

export default function Tooltip({
  children,
  text,
  placement = TooltipPlacement.Bottom,
  disabled,
}: {
  children: React.ReactNode;
  text: string;
  placement?: TooltipPlacement;
  disabled?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);
  const {
    refs,
    floatingStyles,
    placement: resolvedPlacement,
  } = useFloating({
    placement,
    middleware: [offset(10), flip(), shift(), arrow({ element: arrowRef })],
    whileElementsMounted: autoUpdate,
  });
  return !disabled ? (
    <div
      ref={refs.setReference}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onClick={() => setIsOpen(!isOpen)}
      className="relative inline-block"
    >
      {children}
      {isOpen && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className="absolute z-50 bg-gray-900 opacity-80 text-white text-xs p-3 rounded min-w-56"
        >
          {text}
          <div
            ref={arrowRef}
            className="absolute w-3 h-3 bg-gray-900 rotate-45"
            style={{
              left:
                resolvedPlacement === 'left'
                  ? '100%'
                  : resolvedPlacement === 'right'
                  ? '-0.5px'
                  : '50%',
              top:
                resolvedPlacement === 'left' || resolvedPlacement === 'right'
                  ? '50%'
                  : resolvedPlacement === 'top'
                  ? 'calc(100% - 2px)'
                  : '-0.5px',
              transform: 'translate(-50%, -50%) rotate(45deg)',
            }}
          />
        </div>
      )}
    </div>
  ) : (
    <>{children}</>
  );
}
