import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

import moment from 'moment';

import { GET_PROJECT_ACTIVITY_GROUPS } from '../../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { Button } from '../../../components/Buttons/Button';
import NoData from '../../../components/NoData/NoData';
import LinkButton from '../../../components/Buttons/LinkButton';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import { useFilter } from '../../../hooks/useFilter';

interface ProjectActivityGroupsListProps {
  children?: React.ReactElement;
  projectId?: string;
  assigneeId?: string;
}

const headerMapping = {
  name: 'Assignee Name',
  status: 'Status',
  type: 'Type',
  updatedAt: 'Updated At',
} as Record<string, any>;

const ProjectActivitiesTable = ({
  data,
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  filterDimensions = [],
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
  NoData,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={filterDimensions}
    showFilterOptions
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    RowComponent={({ id, rowData }) => (
      <div
        key={id}
        onClick={() => onClickRow(id)}
        role="button"
        className={`border-b flex flex-col w-full justify-around items-center lg:flex-row border-gray-200 hover:bg-gray-100`}
      >
        {rowData?.name ? (
          <div key={id} className="py-3 px-6 text-sm text-left w-full">
            <div className="flex flex-col flex-wrap gap-y-2 gap-x-2 mt-2">
              <p>{rowData?.name}</p>
            </div>
          </div>
        ) : null}
        <div className="py-3 px-6 text-sm flex items-center justify-end  gap-x-2 text-black w-full"></div>
      </div>
    )}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
    NoData={NoData}
  />
);

export const ProjectActivityGroupsListPage = ({
  children,
  projectId,
  assigneeId,
}: ProjectActivityGroupsListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const { limit, offset, setOffset } = usePagination();

  const [searchParams] = useSearchParams();

  const {
    filterText,
    setFilterText,
    filterDimension,
    setFilterDimension,
    filter,
  } = useFilter({ defaultFilterDimension: 'name' });

  const [fetch, { data, loading }] = useLazyQuery(GET_PROJECT_ACTIVITY_GROUPS, {
    variables: { projectId, input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: {
        projectId,
        assigneeId,
        input: { limit, offset, ...(filter ? { filter } : {}) },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, projectId, assigneeId, filter]);

  const projectActivityGroupsResults = data?.getProjectActivityGroups;

  const totalPages = Math.ceil(projectActivityGroupsResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div>
      <>
        <div className="w-full flex justify-end px-4 py-2">
          {userProfile?.permissions?.includes(Permission.UpdateProject) ? (
            <Button
              onClick={() =>
                navigate(`/projects/${projectId}/activities/groups/new`)
              }
              type="submit"
              text="New Activity Group"
            />
          ) : null}
        </div>
        <p className="text-sm px-4 text-gray-600">
          Activity groups can be used to categorise different groups of related
          tasks together for scopes of work.
        </p>
        <ProjectActivitiesTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={projectActivityGroupsResults?.count || 0}
          onClickRow={(id: any) => {
            sessionStorage.setItem(
              'activity_groups_table_query_params',
              searchParams.toString()
            );
            navigate(`/projects/${projectId}/activities/groups/${id}`);
          }}
          filterDimensions={[{ id: 'name', name: 'Name' }]}
          NoData={() => (
            <NoData loading={loading}>
              {filterText
                ? 'It looks like there are no activity groups that match your query!'
                : 'It looks like there are no activity groups for this project!'}
              <LinkButton
                to={`/projects/${projectId}/activities/groups/new`}
                target="_blank"
              >
                Add an Activity Group
              </LinkButton>
            </NoData>
          )}
          data={projectActivityGroupsResults?.results?.map(
            (activitie: any) => ({
              id: activitie.id,
              name: activitie.name,
            })
          )}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={filterDimension}
          setSelectedFilterDimension={setFilterDimension}
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const ProjectActivityGroupsList = ({
  projectId,
  assigneeId,
}: ProjectActivityGroupsListProps) => (
  <PaginationProvider>
    <ProjectActivityGroupsListPage
      projectId={projectId}
      assigneeId={assigneeId}
    />
  </PaginationProvider>
);
