import { ErrorMessage, useFormikContext } from 'formik';
import { useState } from 'react';
import { Button } from '../Buttons/Button';
import { DocumentIcon } from '@heroicons/react/24/solid';

interface FileInputProps {
  id: string;
  label?: string;
  filetypes?: string;
  filetypesDescription?: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  clearForm?: any;
  removalAllowed?: boolean;
}

export const FileInput = ({
  id,
  label = '',
  filetypes = 'image/png, image/jpeg, image/gif',
  filetypesDescription = 'PNG, JPG, GIF up to 10MB',
  removalAllowed = true,
  setFieldValue,
  clearForm,
}: FileInputProps) => {
  const { values, resetForm } = useFormikContext();
  // @ts-ignore
  const value = values?.[id];

  const [isDraggedOver, setIsDraggedOver] = useState(false);

  const handleDragEnd = () => setIsDraggedOver(false);

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDraggedOver(true);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDraggedOver(true);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      setFieldValue(id, files[0]);
    }
    setIsDraggedOver(false);
  };

  return (
    <>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-6 text-gray-700"
      >
        {label}
      </label>
      <ErrorMessage name={id} component="div" className="text-red-700" />
      <div className="flex justify-start w-full">
        {value?.name ? (
          <div className="relative flex flex-col place-content-center">
            <div className="flex justify-center mb-2 py-2 border px-4 w-full">
              <p className="text-sm">File: {value.name}</p>
            </div>
            {removalAllowed && (
              <Button
                onClick={() => {
                  setFieldValue(id, {});
                  resetForm();
                  clearForm();
                }}
                text={'Remove'}
              />
            )}
          </div>
        ) : (
          <div
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragLeave={handleDragEnd}
            className={`text-center flex flex-col place-content-center ${
              isDraggedOver ? 'bg-green-200' : ''
            }`}
          >
            <div className="w-full flex text-sm leading-6 text-gray-600">
              <label
                htmlFor={id}
                className="relative cursor-pointer w-full px-32 md:px-52 lg:px-96 py-8 border rounded-md font-semibold text-primary-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-600 focus-within:ring-offset-2 hover:text-primary-500"
              >
                <DocumentIcon
                  className="mx-auto mb-2 h-12 w-12 text-gray-300"
                  aria-hidden="true"
                />
                <span>Upload a file</span>
                <input
                  accept={filetypes}
                  id={id}
                  name={id}
                  type="file"
                  className="sr-only w-full"
                  onChange={(event: any) => {
                    if (event.currentTarget.files) {
                      setFieldValue(id, event.currentTarget.files[0]);
                    }
                  }}
                />
                <p className="text-xs leading-5 text-gray-600">
                  {filetypesDescription}
                </p>
              </label>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
