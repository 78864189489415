import DropDown from './Dropdown';
import TextInput from './TextInput';

export function FilterInput({
  filterDimensions,
  filterText,
  setFilterText,
  filterDimension,
  setFilterDimension,
}: {
  filterDimensions: any;
  filterText?: string;
  setFilterText: (value: string) => void;
  filterDimension?: string;
  setFilterDimension: (value: string) => void;
}) {
  return (
    <div className="flex items-center justify-between bg-gray-50 border-b">
      <div className="w-full">
        <TextInput
          key="filter-selection"
          id="search"
          placeholder={`Filter by ${
            filterDimensions?.find(
              (dimension: any) => dimension.id === filterDimension
            )?.name
          }`}
          handleChange={setFilterText ?? (() => {})}
          value={filterText}
          name={'search'}
        />
      </div>
      <div className="mt-2 w-52 flex justify-center">
        <DropDown
          hideClearButton
          id={'filter-dimensions'}
          key={'filter_dimensions'}
          options={filterDimensions?.map((opt: any) => ({
            id: opt?.id,
            name: opt?.name,
            value: opt?.id,
          }))}
          handleChange={(selectedValue) => {
            setFilterDimension && setFilterDimension(selectedValue);
          }}
          value={filterDimension}
        />
      </div>
    </div>
  );
}
