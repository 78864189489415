import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useContext, useEffect, useState } from 'react';

import moment from 'moment';
import { Button } from '../../../components/Buttons/Button';
import FormCard from '../../../components/FormCard/FormCard';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { SAVE_ATTACHMENT } from '../../../graphql/mutations/attachments';
import { SAVE_MY_CERTIFICATION } from '../../../graphql/mutations/certifications';
import { GET_MY_CERTIFICATION_ATTACHMENT_URL } from '../../../graphql/queries/attachments';
import {
  GET_MY_CERTIFICATION_ATTACHMENTS,
  SEARCH_CERTIFICATION_TYPES,
} from '../../../graphql/queries/certifications';
import { GET_MY_CERTIFICATIONS } from '../../../graphql/queries/my-profile';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { saveFileToS3 } from '../../../utils/utils';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import { YellowBadge } from '../../../components/Badges/Badges';
import { useToast } from '../../../context/ToastContext';

const headerMapping = {
  name: 'File Name',
  id: 'ID',
  download: 'Download',
  updatedAt: 'Updated At',
} as Record<string, any>;

export const SaveMyCertificationPage = () => {
  let { id } = useParams();

  const navigate = useNavigate();
  const { useQuery, useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const isUpdate = !!id;

  const { data, refetch } = useQuery(GET_MY_CERTIFICATIONS, {
    variables: { input: { id } },
    skip: !isUpdate,
  });
  const [searchCertificationTypes, { data: certificationtypesData }] =
    useLazyQuery(SEARCH_CERTIFICATION_TYPES);

  const [getMyCertificationAttachmentURL] = useLazyQuery(
    GET_MY_CERTIFICATION_ATTACHMENT_URL
  );

  const [fetchAttachmentsForThisCertification, { data: attachmentsData }] =
    useLazyQuery(GET_MY_CERTIFICATION_ATTACHMENTS);

  useEffect(() => {
    if (isUpdate) {
      refetch({ id });
    }
  }, [id, refetch, isUpdate]);

  useEffect(() => {
    if (isUpdate) {
      refetch({ id });
    }
  }, [id, refetch, isUpdate]);

  const [certification] = data?.getMyCertifications?.results || [];

  const { limit, offset, setOffset } = usePagination();

  useEffect(() => {
    if (certification) {
      fetchAttachmentsForThisCertification({
        variables: {
          certificationId: certification.id,
          input: { limit, offset },
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [certification, limit, offset]);

  const [saveMyCertification] = useMutation(SAVE_MY_CERTIFICATION);

  const { userProfile } = useContext(UserProfileContext);

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const performCertificationTypeSearch = async () => {
    try {
      await searchCertificationTypes({
        variables: {
          searchTerm: debouncedSearchTerm,
        },
        fetchPolicy: 'network-only',
      });
    } catch (err) {
      // do nothing
    } finally {
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    performCertificationTypeSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const totalPages = Math.ceil(
    attachmentsData?.getMyCertificationAttachments?.count / limit
  );

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const [saveAttachment] = useMutation(SAVE_ATTACHMENT);

  const initialValues = id
    ? {
        id,
        certificationType: certification?.certificationType?.id,
        expiryDate: certification?.expiryDate
          ? Number(certification?.expiryDate)
          : null,
        issueDate: certification?.issueDate
          ? Number(certification?.issueDate)
          : null,
      }
    : {};

  const validationSchema = Yup.object().shape(
    certification?.certificationType?.requiresExpiryDate
      ? {
          expiryDate: Yup.string().required(
            'Expiry date is required for this certification'
          ),
        }
      : {}
  );

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue?: (field: any, values: any) => void
  ) => {
    let fileSaved;
    let newAttachmentId;
    if (values?.file?.name) {
      const { data } = await saveAttachment({
        variables: {
          input: {
            name: values.importName,
            id: null,
            fileName: values.file.name,
          },
        },
      });

      const presignedUrl = data?.saveAttachment?.presignedUrl;

      fileSaved = await saveFileToS3(values.file, presignedUrl);
      newAttachmentId = data?.saveAttachment?.id;
    }

    try {
      if (!values?.file?.name || (values?.file?.name && fileSaved)) {
        const { data } = await saveMyCertification({
          variables: {
            id: values?.id,
            input: {
              ...values,
              newAttachmentId,
              expiryDate: values?.expiryDate
                ? String(values?.expiryDate)
                : undefined,
              issueDate: values?.issueDate
                ? String(values?.issueDate)
                : undefined,
              id: undefined,
              file: undefined,
              updatedAt: undefined,
              createdAt: undefined,
            },
          },
        });
        addToast('Certification saved', 'success');
        setSubmitting(false);
        if (data.saveMyCertification.success) {
          navigate(-1);
        }
      }
      if (setFieldValue) {
        setFieldValue('file', undefined);
      }
    } catch (error) {
      console.error(error);
      addToast('Failed to save certification', 'error');
    }
  };

  const certificationConfig = {
    title: '',

    formSections: [
      {
        title: id ? certification?.certificationType?.name : 'Certification',
        description: 'Upload and configure your certification.',
        fields: [
          ...(!id
            ? [
                {
                  title: 'Certification Type',
                  id: 'certificationType',
                  type: id ? 'input' : 'combobox',
                  disabled: !!id,
                  placeholder: 'Start typing the certification name',
                  // @ts-ignore
                  onChange: (value: string) => {
                    setSearchTerm(value);
                  },
                  valueTransformer: (id: string) =>
                    certificationtypesData?.searchCertificationTypes?.results?.find(
                      (result: any) => result.id === id
                    )?.name,
                  options:
                    certificationtypesData?.searchCertificationTypes?.results.map(
                      (certification: any) => ({
                        id: certification.id,
                        name: certification.name,
                      })
                    ),
                  required: true,
                },
              ]
            : []),
          {
            id: 'issueDate',
            type: 'datepicker',
            description: 'Set this to be the issue date of your certification.',
            title: 'Issue Date',
            inputTransformer: (value: Date) => new Date(value).getTime(),
          },
          {
            id: 'expiryDate',
            type: 'datepicker',
            description:
              'Set this to be the most recent expiry date of your certification.',
            title: `Expiry Date ${
              certification?.certificationType?.requiresExpiryDate
                ? '(required)'
                : ''
            }`,
            inputTransformer: (value: Date) => new Date(value).getTime(),
          },
          {
            id: 'file',
            type: 'file',
            title:
              'Upload Certification - ⚠️ Please ensure you upload up to date evidence of your certification below.',
            limits: 'application/msword, application/pdf',
            description: 'PDF or DOCX supported',
          },
        ],
      },
    ],
  } as FormConfig;

  const downloadAttachment = async (id: string, fileName: string) => {
    const { data } = await getMyCertificationAttachmentURL({
      variables: {
        input: { id },
        ownerId: certification.id,
        ownerType: 'certification',
      },
    });
    if (data.getMyCertificationAttachmentURL.presignedUrl) {
      // Create a new link
      const anchor = document.createElement('a');
      anchor.href = data.getMyCertificationAttachmentURL.presignedUrl;
      anchor.download = fileName;
      anchor.style.display = 'none';

      // Append to the DOM
      document.body.appendChild(anchor);

      // Trigger `click` event
      anchor.click();

      // Remove element from DOM
      document.body.removeChild(anchor);

      // Revoke the Blob URL to free up resources
      URL.revokeObjectURL(data.getMyCertificationAttachmentURL.presignedUrl);
    }
  };

  return (
    <>
      <div className="flex w-full border-b mb-2">
        <Button
          style={{
            backgroundColor: 'transparent',
            color: 'black',
            boxShadow: 'none',
            borderRadius: 0,
          }}
          onClick={() => navigate(-1)}
          type="submit"
          text={`< Go Back`}
        />
      </div>
      <FormCard
        key={`${certificationConfig?.title}`}
        config={certificationConfig}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
      {id ? (
        <div>
          <h1 className="my-2">Attachments</h1>
          <Table
            currentPage={Math.floor(offset / limit) + 1}
            totalPages={totalPages}
            totalResults={
              attachmentsData?.getMyCertificationAttachments?.count || 0
            }
            itemsPerPage={limit}
            columnsToFilter={[]}
            columnValueMapper={{
              updatedAt: (text: string) =>
                moment.unix(parseInt(text) / 1000).fromNow(),
            }}
            headerMapper={(text: string) => headerMapping[text]}
            onPageChange={handlePageChange}
            data={
              attachmentsData?.getMyCertificationAttachments?.results.length > 0
                ? attachmentsData?.getMyCertificationAttachments?.results?.map(
                    (result: any) => ({
                      id: result?.id,
                      name: result?.fileName,
                      updatedAt: result?.updatedAt,
                      download: userProfile?.permissions.includes(
                        Permission.DownloadCertificationAttachments
                      ) ? (
                        <Button
                          text={'Download'}
                          onClick={async () =>
                            await downloadAttachment(
                              result.id,
                              result?.fileName
                            )
                          }
                        />
                      ) : (
                        <YellowBadge text="Not Available" />
                      ),
                    })
                  )
                : []
            }
          />
        </div>
      ) : null}
    </>
  );
};

export const SaveMyCertification = () => (
  <PaginationProvider>
    <SaveMyCertificationPage />
  </PaginationProvider>
);
