import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

import { Cog6ToothIcon, PlayIcon } from '@heroicons/react/20/solid';
import moment from 'moment';
import { ActiveBadge, DisabledBadge } from '../../../components/Badges/Badges';
import { Button } from '../../../components/Buttons/Button';
import { GET_EXPORT_CONFIGURATIONS_SUMMARY } from '../../../graphql/queries/export-configurations';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import { useFilter } from '../../../hooks/useFilter';

interface ExportListProps {
  children?: React.ReactElement;
  showNewItemButton?: boolean;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

const headerMapping = {
  name: 'Name',
  email: 'Email',
  status: 'Status',
  configure: 'Options',
  updatedAt: 'Updated At',
} as Record<string, any>;

const ExportsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={['firstName', 'lastName', 'id', 'groups']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={[{ id: 'name', name: 'Name' }]}
    showFilterOptions
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const ExportListPage = ({
  children,
  onClickRow,
  showNewItemButton,
  filterIds,
}: ExportListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const { limit, offset, setOffset } = usePagination();

  const {
    filterText,
    setFilterText,
    filterDimension,
    setFilterDimension,
    filter,
  } = useFilter({ defaultFilterDimension: 'name' });

  const [fetch, { data }] = useLazyQuery(GET_EXPORT_CONFIGURATIONS_SUMMARY, {
    variables: { input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: { input: { limit, offset, ...(filter ? { filter } : {}) } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, filter]);

  const ExportResults = data?.getExportConfigurations;

  const totalPages = Math.ceil(ExportResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div>
      <>
        {showNewItemButton ? (
          <div className="w-full flex justify-between items-center py-2">
            <h1 className="text-xl font-bold py-4">Exports</h1>
            {userProfile?.permissions?.includes(
              Permission.UpdateExportConfiguration
            ) ? (
              <Button
                text="New Export Configuration"
                onClick={() =>
                  navigate(`/time/timesheets/exports/configuration/new`)
                }
              />
            ) : null}
          </div>
        ) : null}
        <ExportsTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={ExportResults?.count || 0}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={filterDimension}
          setSelectedFilterDimension={setFilterDimension}
          data={
            ExportResults?.results.length > 0
              ? ExportResults?.results
                  ?.filter((result: any) => !filterIds?.includes(result.id))
                  .map((result: any) => ({
                    name: `${result.lastName}, ${result.firstName}`,
                    status: result.enabled ? (
                      <ActiveBadge />
                    ) : (
                      <DisabledBadge />
                    ),
                    ...result,
                    configure: (
                      <div className="flex gap-x-2">
                        {userProfile?.permissions?.includes(
                          Permission.UpdateExportConfiguration
                        ) ? (
                          <Button
                            text={
                              <Cog6ToothIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            }
                            style={{ background: 'black' }}
                            onClick={() => {
                              if (onClickRow) {
                                onClickRow(result?.id, ExportResults?.results);
                              } else {
                                navigate(
                                  `/time/timesheets/exports/configuration/${result?.id}`
                                );
                              }
                            }}
                          />
                        ) : null}
                        {userProfile?.groups?.some((group) =>
                          result?.groups?.includes(group)
                        ) ? (
                          <Button
                            text={
                              <PlayIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            }
                            onClick={() => {
                              if (onClickRow) {
                                onClickRow(result?.id, ExportResults?.results);
                              } else {
                                navigate(
                                  `/time/timesheets/exports/configuration/${result?.id}/run`
                                );
                              }
                            }}
                          />
                        ) : null}
                      </div>
                    ),
                  }))
              : [{}]
          }
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const ExportList = ({
  onClickRow,
  showNewItemButton = true,
  filterIds = [],
}: ExportListProps) => (
  <PaginationProvider>
    <ExportListPage
      showNewItemButton={showNewItemButton}
      onClickRow={onClickRow}
      filterIds={filterIds}
    />
  </PaginationProvider>
);
