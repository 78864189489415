import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ValidationMessage from '../Validation/ValidationMessage';
import { twMerge } from 'tailwind-merge';

import './inputs.css';
import React from 'react';

interface RichTextInputProps {
  classNames?: string;
  description?: string;
  handleChange: (props: any) => void;
  id: string;
  isDisabled?: boolean;
  title?: string;
  validation?: string;
  value: string | undefined;
}

const RichTextInput = ({
  classNames,
  description,
  handleChange,
  id,
  isDisabled,
  title,
  validation,
  value,
}: RichTextInputProps) => {
  return (
    <div className={twMerge('list-disc list-inside', classNames)}>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-gray-700"
      >
        {title}
      </label>
      {validation ? <ValidationMessage message={validation} /> : null}
      <CKEditor
        id={id}
        editor={ClassicEditor}
        config={{
          toolbar: [
            'heading',
            'bold',
            'italic',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'link',
          ],
        }}
        disabled={isDisabled}
        data={value ?? ''}
        onChange={(_event, editor) => {
          const data = editor.getData();
          handleChange(data);
        }}
      />
      {description ? (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      ) : null}
    </div>
  );
};

export const RichTextViewer = ({
  className,
  text,
}: {
  className?: string;
  text: string;
}) => {
  return (
    <div
      className={twMerge('ck-content', className)}
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  );
};

export default RichTextInput;
