import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

import moment from 'moment';
import { ActiveBadge, DisabledBadge } from '../../../components/Badges/Badges';
import { Button } from '../../../components/Buttons/Button';
import { GET_PROJECT_ASSIGNMENTS } from '../../../graphql/queries/assignments';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import Avatar from 'react-avatar';
import { GET_PROJECT_TEAMS } from '../../../graphql/queries/projects';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import { useFilter } from '../../../hooks/useFilter';

interface ProjectTeamListProps {
  children?: React.ReactElement;
  projectId?: string;
  assigneeId?: string;
}

const headerMapping = {
  name: 'Assignee Name',
  status: 'Status',
  type: 'Type',
  updatedAt: 'Updated At',
} as Record<string, any>;

const ProjectTeamsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  filterDimensions = [],
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={filterDimensions}
    showFilterOptions
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    RowComponent={({ id, rowData }) => (
      <div
        key={id}
        className={`border-b flex flex-col w-full justify-around items-center lg:flex-row border-gray-200 hover:bg-gray-100`}
      >
        {rowData?.name ? (
          <div key={id} className="py-3 px-6 text-sm text-left w-full">
            <div className="flex flex-col flex-wrap gap-y-2 gap-x-2 mt-2">
              <p>{rowData?.name}</p>
            </div>
          </div>
        ) : null}
        <div
          key={id}
          className="py-3 px-6 text-sm flex items-center justify-end  gap-x-2 text-black w-full"
        >
          <Button text="View Team" onClick={() => onClickRow(rowData?.id)} />
        </div>
      </div>
    )}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const ProjectTeamListPage = ({
  children,
  projectId,
  assigneeId,
}: ProjectTeamListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const { limit, offset, setOffset } = usePagination();

  const [searchParams] = useSearchParams();

  const {
    filterText,
    setFilterText,
    filterDimension,
    setFilterDimension,
    filter,
  } = useFilter({ defaultFilterDimension: 'name' });

  const [fetch, { data }] = useLazyQuery(GET_PROJECT_TEAMS, {
    variables: { projectId, input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: {
        projectId,
        assigneeId,
        input: { limit, offset, ...(filter ? { filter } : {}) },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, projectId, assigneeId, filter]);

  const projectTeamResults = data?.getProjectTeams;

  const totalPages = Math.ceil(projectTeamResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div>
      <>
        {userProfile?.permissions?.includes(Permission.UpdateProject) ||
        userProfile?.permissions?.includes(Permission.UpdateTeams) ? (
          <div className="w-full flex justify-end px-4 py-2">
            <Button
              onClick={() => navigate(`/projects/${projectId}/teams/new`)}
              type="submit"
              text="New Team"
            />
          </div>
        ) : null}
        <p className="text-sm px-4 text-gray-600">
          You can assign active subcontractors and employees (with roles) to a
          team. This can be used in daily progress reporting.
        </p>
        <ProjectTeamsTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={projectTeamResults?.count || 0}
          onClickRow={(id: any) => {
            sessionStorage.setItem(
              'teams_table_query_params',
              searchParams.toString()
            );
            navigate(`/projects/${projectId}/teams/${id}`);
          }}
          filterDimensions={[{ id: 'name', name: 'Name' }]}
          data={
            projectTeamResults?.results?.length > 0
              ? projectTeamResults?.results?.map((team: any) => ({
                  id: team.id,
                  name: team.name,
                }))
              : [{}]
          }
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={filterDimension}
          setSelectedFilterDimension={setFilterDimension}
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const ProjectTeamList = ({
  projectId,
  assigneeId,
}: ProjectTeamListProps) => (
  <PaginationProvider>
    <ProjectTeamListPage projectId={projectId} assigneeId={assigneeId} />
  </PaginationProvider>
);
