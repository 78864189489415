import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

import moment from 'moment';
import { ActiveBadge, DisabledBadge } from '../../../components/Badges/Badges';
import { Button } from '../../../components/Buttons/Button';
import { GET_PROJECT_ASSIGNMENTS } from '../../../graphql/queries/assignments';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import Avatar from 'react-avatar';
import { GET_PROJECT_LOCATIONS } from '../../../graphql/queries/projects';
import Map from '../../../components/Map/Map';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import { useFilter } from '../../../hooks/useFilter';

interface ProjectLocationListProps {
  children?: React.ReactElement;
  projectId?: string;
  assigneeId?: string;
}

const headerMapping = {
  name: 'Assignee Name',
  status: 'Status',
  type: 'Type',
  updatedAt: 'Updated At',
} as Record<string, any>;

const ProjectLocationsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  filterDimensions = [],
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={filterDimensions}
    showFilterOptions
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    RowComponent={({ id, rowData }) => (
      <div
        key={id}
        className={`border-b flex flex-col w-full justify-around items-center lg:flex-row border-gray-200 hover:bg-gray-100`}
      >
        {rowData?.name ? (
          <div key={id} className="py-3 px-6 text-sm text-left w-full">
            <div className="flex flex-col flex-wrap gap-y-2 gap-x-2 mt-2">
              <p>{rowData?.name}</p>
            </div>
          </div>
        ) : null}

        <div
          key={id}
          className="py-3 px-6 text-sm flex items-center justify-end  gap-x-2 text-black w-full"
        >
          <Button
            text="View Location"
            onClick={() => onClickRow(rowData?.id)}
          />
        </div>
      </div>
    )}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const ProjectLocationListPage = ({
  children,
  projectId,
  assigneeId,
}: ProjectLocationListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const { limit, offset, setOffset } = usePagination();

  const [searchParams] = useSearchParams();

  const {
    filterText,
    setFilterText,
    filterDimension,
    setFilterDimension,
    filter,
  } = useFilter({ defaultFilterDimension: 'name' });

  const { userProfile } = useContext(UserProfileContext);

  const [fetch, { data }] = useLazyQuery(GET_PROJECT_LOCATIONS, {
    variables: { projectId, assigneeId, input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: {
        projectId,
        assigneeId,
        input: { limit, offset, ...(filter ? { filter } : {}) },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, projectId, assigneeId, filter]);

  const projectLocationResults = data?.getProjectLocations;

  const totalPages = Math.ceil(projectLocationResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  return (
    <div>
      <>
        <Map markers={projectLocationResults?.results ?? []} />
        <div className="flex py-2 px-4 justify-end">
          {userProfile?.permissions?.includes(Permission.UpdateProject) ? (
            <Button
              onClick={() => navigate(`/projects/${projectId}/locations/bulk`)}
              text={'Bulk Edit'}
            />
          ) : null}
        </div>
        <ProjectLocationsTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={projectLocationResults?.count || 0}
          onClickRow={(id: any) => {
            sessionStorage.setItem(
              'locations_table_query_params',
              searchParams.toString()
            );
            navigate(`/projects/${projectId}/locations/${id}`);
          }}
          filterDimensions={[{ id: 'name', name: 'Name' }]}
          data={
            projectLocationResults?.results.length > 0
              ? projectLocationResults?.results.map((location: any) => ({
                  id: location.id,
                  name: location.name,
                }))
              : [{}]
          }
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={filterDimension}
          setSelectedFilterDimension={setFilterDimension}
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const ProjectLocationList = ({
  projectId,
  assigneeId,
}: ProjectLocationListProps) => (
  <PaginationProvider>
    <ProjectLocationListPage projectId={projectId} assigneeId={assigneeId} />
  </PaginationProvider>
);
