import React from 'react';
import { Button } from '../Buttons/Button';

interface InputListValue {
  id: string;
  label: string;
  value: string;
  placeholder?: string;
  disabled?: boolean;
}

interface InputListProps {
  description?: string;
  handleChange: (value: InputListValue[]) => void;
  id: string;
  isDisabled?: boolean;
  title?: string;
  validation?: string;
  value: InputListValue[];
}

const InputList: React.FC<InputListProps> = ({
  description,
  handleChange,
  id,
  isDisabled,
  title,
  validation,
  value = [],
}) => {
  const handleInputChange = (id: string, updatedValue: string) => {
    const updatedValues = value?.map((item) =>
      item.id === id ? { ...item, value: updatedValue } : item
    );
    handleChange(updatedValues);
  };

  const handleAddOption = () => {
    const newOption: InputListValue = {
      id: `option-${value.length + 1}`,
      label: ``,
      value: '',
    };
    handleChange([...value, newOption]);
  };

  const handleRemoveOption = (id: string) => {
    const updatedValues = value.filter((item) => item.id !== id);
    handleChange(updatedValues);
  };

  return (
    <div className="space-y-5">
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      {validation && <p className="text-red-500">{validation}</p>}
      {value?.map((option) => (
        <div key={option.id} className="flex flex-row gap-x-2 space-y-1">
          {option.label ? (
            <label
              htmlFor={option.id}
              className="text-sm font-medium text-gray-700"
            >
              {option.label}
            </label>
          ) : null}
          <input
            type="text"
            id={option.id}
            value={option.value}
            disabled={isDisabled || option.disabled}
            onChange={(e) => handleInputChange(option.id, e.target.value)}
            placeholder={option.placeholder}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
          />
          <Button
            onClick={() => handleRemoveOption(option.id)}
            text={'Remove'}
          />
        </div>
      ))}
      <Button onClick={handleAddOption} text={'Add Option'} />
      {description ? (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      ) : null}
    </div>
  );
};

export default InputList;
