import { useMemo } from 'react';
import { debounce } from '../utils/utils';

export function useDebounce(callback: Function, delay: number = 600) {
  const debouncedCallback = useMemo(
    () => debounce(callback, delay),
    [callback, delay]
  );

  return debouncedCallback;
}
