import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect, useState } from 'react';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { GET_COMPANY_PROFILES } from '../../graphql/queries/companies';
import { useToast } from '../../context/ToastContext';
import { SAVE_COMPANY_DETAILS } from '../../graphql/mutations/company-details';
import { LabourSelection } from '../../components/LabourSelection/LabourSelection';
import { FormConfig } from '../../types/Form';
import FormCard from '../../components/FormCard/FormCard';
import { LoadingSpinner } from '../../components/Loading/LoadingSpinner';

export const SaveCompany = () => {
  let { companyId } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const isUpdate = !!companyId;

  const [fetch, { data, loading }] = useLazyQuery(GET_COMPANY_PROFILES, {
    variables: {
      input: { id: companyId },
    },
    fetchPolicy: 'network-only',
  });

  const { addToast } = useToast();

  useEffect(() => {
    fetch({
      variables: { input: { id: companyId } },
    });
  }, [companyId, fetch, isUpdate]);

  const [company] = data?.getCompanyProfiles?.results || [];

  const [saveCompany] = useMutation(SAVE_COMPANY_DETAILS);

  const [selectedLabour, setSelectedLabour] = useState<any>();

  const initialValues = companyId
    ? {
        ...company,
      }
    : {};

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const submitResult = await saveCompany({
        variables: {
          companyId,
          labourResourceId: selectedLabour?.id,

          input: {
            ...values,
            missingOrInvalidCertifications: undefined,
            hasAssignees: undefined,
            id: undefined,
            profileId: undefined,
            owner: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });

      if (setSubmitting) {
        setSubmitting(false);
      }
      if (!submitResult.data.saveCompanyDetails.success) {
        if (
          submitResult?.data?.saveCompanyDetails?.message.includes(
            'already a company owner'
          )
        ) {
          addToast(
            `Couldn't save company, ${selectedLabour?.firstName} ${selectedLabour?.lastName} is already the owner of a company.`,
            'error'
          );
        } else {
          addToast('Error saving company', 'error');
        }
      } else {
        addToast('Company saved', 'success');
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
      addToast('Error saving company', 'error');
    }
  };

  useEffect(() => {
    setSelectedLabour({ ...company?.owner });
  }, [company]);

  const companyConfig = {
    formSections: [
      {
        title: 'Company Information',
        description: 'Basic details about the company',
        components: [
          <div>
            <h3>Company Record Owner</h3>
            <LabourSelection
              selectedLabour={selectedLabour}
              setSelectedLabour={setSelectedLabour}
              labourType={'SUBCONTRACTOR'}
            />
          </div>,
        ],
        fields: [
          {
            id: 'companyName',
            type: 'input',
            title: 'Company Name',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'companyEmail',
            type: 'input',
            title: 'Company Email',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'contactName',
            type: 'input',
            title: 'Contact Name',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'companyRegNumber',
            type: 'input',
            title: 'Company Registration Number',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'companyPhoneNumber',
            type: 'input',
            title: 'Company Phone Number',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'companyType',
            type: 'dropdown',
            title: 'Company Type',
            options: [
              {
                name: 'Limited',
                value: 'LIMITED',
              },
              { name: 'Umbrella', value: 'UMBRELLA' },
            ],
          },
          {
            id: 'companyAddress',
            type: 'textarea',
            title: 'Company Address',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'vatRegistrationNumber',
            type: 'input',
            title: 'VAT Registration Number',
            inputTransformer: (text: string) => text,
          },
        ],
      },
    ],
  } as FormConfig;

  const CompanyCreationSchema = Yup.object().shape({
    companyName: Yup.string()
      .min(3, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required('Company Name is required'),
    companyEmail: Yup.string()
      .email('Invalid email format')
      .typeError('Required field')
      .required('Company Email is required'),
    contactName: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required('Contact Name is required'),
    companyRegNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required('Company Reg Number is required'),
    companyPhoneNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required('Company Phone Number is required'),
    companyType: Yup.string().nullable(),
    companyAddress: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required('Company Address is required'),
    vatRegistrationNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required('VAT Registration is required'),
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <FormCard
        key={`${companyConfig?.title}`}
        config={companyConfig}
        validationSchema={CompanyCreationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </>
  );
};
