import { useState } from 'react';
import { DeletionDialogue } from './DeletionDialogue';
import { Button } from '../Buttons/Button';
import Tooltip, { TooltipPlacement } from '../../utils/Tooltip';

type DeleteButtonProps = {
  children: React.ReactNode;
  isDeleting?: boolean;
  isDisabled?: boolean;
  disabledMessage?: string;
  disabledMessagePlacement?: TooltipPlacement;
  isLoading?: boolean;
  modalText: string;
  modalTitle: string;
  onClick?: () => void;
  onDelete: () => void;
};

export function DeleteButton({
  children,
  isDeleting = false,
  isDisabled = false,
  disabledMessage,
  disabledMessagePlacement,
  isLoading = false,
  modalText,
  modalTitle,
  onClick = () => {},
  onDelete,
}: DeleteButtonProps) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <DeletionDialogue
        showModal={showModal}
        setShowModal={setShowModal}
        onDelete={() => {
          onDelete();
          setShowModal(false);
        }}
        title={modalTitle}
        isDeleting={isDeleting}
      >
        {modalText}
      </DeletionDialogue>
      <Tooltip
        text={disabledMessage ?? ''}
        placement={disabledMessagePlacement}
        disabled={!isDisabled || !disabledMessage}
      >
        <Button
          isLoading={isLoading}
          isDisabled={isDisabled || isLoading}
          className={`bg-red-500 hover:bg-red-600 ${
            isDisabled ? 'cursor-not-allowed' : ''
          }`}
          onClick={() => {
            onClick();
            setShowModal(true);
          }}
          text={'Delete'}
        >
          {children}
        </Button>
      </Tooltip>
    </>
  );
}
