import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useContext, useEffect } from 'react';

import FormCard from '../../../components/FormCard/FormCard';

import { GET_PROJECT_TEAMS } from '../../../graphql/queries/projects';
import { SAVE_PROJECT_TEAM } from '../../../graphql/mutations/projects';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { useToast } from '../../../context/ToastContext';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';

export const SaveProjectTeam = () => {
  let { id, projectId } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const isUpdate = !!id;

  const { addToast } = useToast();

  const [fetchProjectTeams, { data }] = useLazyQuery(GET_PROJECT_TEAMS, {
    variables: { input: { id } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (isUpdate) {
      fetchProjectTeams({ variables: { input: { id }, projectId } });
    }
  }, [id, isUpdate]);

  const [projectteam] = data?.getProjectTeams?.results || [];

  console.log(projectteam);

  const [saveProjectTeam] = useMutation(SAVE_PROJECT_TEAM);

  const initialValues = id
    ? {
        id,
        name: projectteam?.name,
      }
    : { name: undefined, description: undefined };

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await saveProjectTeam({
        variables: {
          id: values?.id,
          input: {
            ...values,
            projectId,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      if (setSubmitting) {
        setSubmitting(false);
      }
      if (id) {
        navigate(`/projects/${projectId}/teams/${id}`);
      } else {
        navigate(`/projects/${projectId}?tab=Teams`);
      }
      addToast('Project Team saved successfully', 'success');
    } catch (error) {
      console.error(error);
      addToast('Failed to save Project Team', 'error');
    }
  };

  const projectteamConfig = {
    formSections: [
      {
        title: 'Project Team',
        description: 'Basic details about the Project Team',
        fields: [
          {
            id: 'name',
            type: 'input',
            title: 'Project Team Name',
            placeholder: 'Some team name',
            inputTransformer: (text: string) => text,
          },
        ],
      },
    ],
  } as FormConfig;

  const ProjectTeamCreationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
  });

  const { userProfile } = useContext(UserProfileContext);

  return (
    <FormCard
      key={`${projectteamConfig?.title}`}
      config={projectteamConfig}
      isDisabled={
        !(
          userProfile?.permissions?.includes(Permission.UpdateProject) ||
          userProfile?.permissions?.includes(Permission.UpdateTeams)
        )
      }
      validationSchema={ProjectTeamCreationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
