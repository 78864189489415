import {
  Description,
  Field,
  Label,
  Switch as UISwitch,
} from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

interface BoxConfig {
  classNames?: string;
  description?: string;
  enabled: boolean;
  handleChange: (checked: boolean) => void;
  isDisabled?: boolean;
  text: string;
}

const Switch = ({
  classNames,
  description,
  enabled,
  handleChange,
  isDisabled,
  text,
}: BoxConfig) => {
  return (
    <Field>
      <div className={twMerge('flex items-center', classNames)}>
        <UISwitch
          checked={enabled}
          disabled={isDisabled}
          onChange={handleChange}
          className={twMerge(
            enabled ? 'bg-green-500' : 'bg-gray-200',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black',
            'data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50'
          )}
        >
          <span
            aria-hidden="true"
            className={twMerge(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
          />
        </UISwitch>
        <Label className="ml-3">
          <span className="text-sm font-medium text-gray-900">{text}</span>
        </Label>
      </div>
      {description && (
        <Description className="text-left mt-1 text-xs font-medium text-gray-500">
          {description}
        </Description>
      )}
    </Field>
  );
};

export default Switch;
