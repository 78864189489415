import { useNavigate, useParams } from 'react-router-dom';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import { useToast } from '../../../../context/ToastContext';
import { SAVE_ASSIGNMENT } from '../../../../graphql/mutations/assignments';
import { useState } from 'react';
import { Button } from '../../../../components/Buttons/Button';
import { useProject } from '../../../../hooks/api/projects/useProjects';
import { Table } from '../../../../components/Tables/tables/Table';
import Avatar from 'react-avatar';
import { BlueBadge, YellowBadge } from '../../../../components/Badges/Badges';
import moment from 'moment';
import { AssignableLabourList } from './AssignableLabourList';
import { useCompany } from '../../../../hooks/api/companies/useCompany';

const headerMapping = {
  name: 'Name',
  internalId: 'Internal ID',
  email: 'Email',
  updatedAt: 'Updated At',
} as Record<string, string>;

export function AssignLabourToProjectForCompany() {
  let { id: projectId, companyId } = useParams();
  const navigate = useNavigate();
  const { useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const [saveAssignment, { loading: isSaving }] = useMutation(SAVE_ASSIGNMENT);

  const [company, { loading, error }] = useCompany(companyId!);

  const [project] = useProject(projectId!);

  const [assignments, setAssignments] = useState<any[]>([]);

  console.log(company);

  const assignLabourToProject = async () => {
    try {
      const assignmentPromises = assignments.map((assignment) =>
        saveAssignment({
          variables: {
            input: {
              projectId: project.id,
              assigneeType: assignment.type,
              assigneeId: assignment.id,
            },
          },
        })
      );

      await Promise.all(assignmentPromises);
      navigate(`/projects/${project.id}/companies/${company.id}`);
      addToast('Assignments saved successfully', 'success');
    } catch (err) {
      console.error(error);
      addToast('Failed to save assignments', 'error');
    }
  };

  return (
    <div>
      <div className="flex w-full border-b mb-2">
        <Button
          style={{
            backgroundColor: 'transparent',
            color: 'black',
            boxShadow: 'none',
            borderRadius: 0,
          }}
          onClick={() =>
            navigate(`/projects/${project?.id}/companies/${company?.id}?page=1`)
          }
          type="submit"
          text={`< Go Back`}
        />
      </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Assign Subcontractors to {project?.name} via{' '}
              {company?.companyName}
            </h1>
          </div>
        </div>

        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}
        {assignments.length > 0 ? (
          <div className="mb-2">
            <h4 className="my-2 px-4 text-sm text-gray-600">
              Assigning {assignments.length} Subcontractor
              {assignments.length === 1 ? '' : 's'}
            </h4>
            <Table
              headerMapper={(text) => headerMapping[text]}
              onClickRow={(id: any) => {
                setAssignments(
                  assignments.filter((assignment: any) => assignment.id !== id)
                );
              }}
              data={
                assignments.length > 0
                  ? assignments?.map((result: any) => ({
                      id: result?.id,
                      name: (
                        <div className="flex gap-x-2 items-center">
                          <Avatar
                            name={`${result?.firstName} ${result?.lastName}`}
                            size="30"
                            round
                          />
                          {`${result.lastName}, ${result.firstName}`}
                        </div>
                      ),
                      email: result?.email || 'Not Configured',
                      internalId: result?.internalId ? (
                        <BlueBadge text={result?.internalId} />
                      ) : (
                        <YellowBadge text={'Not Configured'} />
                      ),
                      updatedAt: moment
                        .unix(parseInt(result.updatedAt) / 1000)
                        .fromNow(),
                      status: undefined,
                    }))
                  : [{}]
              }
            />
            <div className="mt-4 mx-2 w-full mb-4">
              <Button
                isLoading={isSaving}
                text="Save Assignments"
                onClick={async () => {
                  await assignLabourToProject();
                }}
              />
            </div>
          </div>
        ) : null}

        <div className="border-t border-gray-200 py-2 px-4">
          <p className="text-sm px-4 text-gray-600">
            Select subcontractors from the list below to assign to this project.
          </p>
          {company && project ? (
            <AssignableLabourList
              companyId={company.id}
              projectId={project.id}
              filterIds={assignments.map((assignment: any) => assignment.id)}
              onClickRow={(id, results) => {
                if (
                  !assignments.find((assignment: any) => assignment.id === id)
                ) {
                  setAssignments((assignments: any) => [
                    ...assignments,
                    results?.find((result) => result.id === id),
                  ]);
                }
              }}
            />
          ) : (
            <p className="px-4 py-4">No company data available</p>
          )}
        </div>
      </div>
    </div>
  );
}
