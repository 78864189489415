import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Table } from '../../../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../../../context/PaginationContext';

import moment from 'moment';
import { Button } from '../../../../../components/Buttons/Button';

import * as Yup from 'yup';
import {
  BlueBadge,
  GeneratingBadge,
  GreenBadge,
  RedBadge,
  YellowBadge,
} from '../../../../../components/Badges/Badges';
import FormCard from '../../../../../components/FormCard/FormCard';
import { UserProfileContext } from '../../../../../context/UserProfileContext';
import { GENERATE_PROGRESS_EXPORT } from '../../../../../graphql/mutations/exports';
import {
  GET_EXPORTS,
  GET_EXPORT_URL,
} from '../../../../../graphql/queries/exports';
import {
  GET_PROGRESS_ACTIVITY_SUBMISSIONS_SUMMARY,
  GET_PROGRESS_SIGNING_SUBMISSIONS,
} from '../../../../../graphql/queries/progress-activity-submissions';
import { useOrganisationAwareApollo } from '../../../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../../../types/Form';
import { Permission } from '../../../../../types/Permissions';
import { exponentialBackoff } from '../../../../../utils/utils';
import { useProject } from '../../../../../hooks/api/projects/useProjects';

interface CreateProgressExportProps {
  children?: React.ReactElement;
  showNewItemButton?: boolean;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

const headerMapping = {
  location: 'File Name',
  email: 'Email',
  status: 'Status',
  date: 'Start Date',
  updatedAt: 'Updated',
  createdAt: 'Created',
  configure: 'Options',
} as Record<string, any>;

const ExportsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  RowComponent,
}: any) => (
  <Table
    key={JSON.stringify(data)}
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    RowComponent={RowComponent}
    columnsToFilter={['firstName', 'lastName', 'id', 'createdAt']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
      createdAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
      date: (text: string) => moment.unix(parseInt(text) / 1000).format('LL'),
      location: (text: string) => text?.replace('progress-export/', ''),
    }}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const CreateProgressExportPage = ({
  children,
  showNewItemButton,
}: CreateProgressExportProps) => {
  const { id, outcomeId, date } = useParams();

  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { userProfile } = useContext(UserProfileContext);

  const [newItemTime, setNewItemTime] = useState<any>();

  const [generateProgressSubmissionExport] = useMutation(
    GENERATE_PROGRESS_EXPORT
  );

  const { limit, offset, setOffset } = usePagination();

  const [fetch] = useLazyQuery(GET_EXPORTS, {
    variables: { input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  const [fetchExportURL, { data: exportURL }] = useLazyQuery(GET_EXPORT_URL, {
    fetchPolicy: 'network-only',
  });

  const [fetchProgressSubmissions, { data: projectActivitySubmissions }] =
    useLazyQuery(GET_PROGRESS_ACTIVITY_SUBMISSIONS_SUMMARY, {
      fetchPolicy: 'network-only',
    });

  const [
    fetchProgressSigningSubmissions,
    { data: progressSigningSubmissions },
  ] = useLazyQuery(GET_PROGRESS_SIGNING_SUBMISSIONS, {
    fetchPolicy: 'network-only',
  });

  const [exportResults, setExportResults] = useState({ count: 0, results: [] });

  const [progressSigningSubmission] =
    progressSigningSubmissions?.getProgressSigningSubmissions?.results || [];
  const [projectActivitySubmission] =
    projectActivitySubmissions?.getProgressSubmissions?.results || [];

  const [expandedExport, setExpandedExport] = useState(undefined);

  const [project] = useProject(id);

  useEffect(() => {
    if (outcomeId && id && date) {
      fetchProgressSubmissions({
        variables: { projectId: id, date, outcomeId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outcomeId, id, date]);

  const fetchAndSetResults = useCallback(async () => {
    const { data } = await fetch({
      variables: {
        exportConfigurationId: projectActivitySubmission?.id,
        input: { limit, offset },
      },
    });
    setExportResults(data?.getExports);
  }, [projectActivitySubmission?.id, limit, offset]);

  useEffect(() => {
    if (projectActivitySubmission?.id) {
      fetchAndSetResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, projectActivitySubmission?.id]);

  const ExportResults = exportResults;

  const totalPages = Math.ceil(ExportResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const downloadPath = (path: string, filename: string) => {
    // Create a new link
    const anchor = document.createElement('a');
    anchor.href = path;
    anchor.download = filename;
    anchor.style.display = 'none';

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);

    // Revoke the Blob URL to free up resources
    URL.revokeObjectURL(path);
  };

  useEffect(() => {
    if (exportURL?.getExportURL) {
      const url = exportURL?.getExportURL?.url;
      downloadPath(url, `export.csv`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(exportURL)]);

  const [showCreateNewExport, setShowCreateNewExport] = useState(false);

  const createNewExportFormConfig = {
    formSections: [
      {
        title: 'New Progress Export',
        fields: [
          {
            id: 'sendReportToSigners',
            type: 'switch',
            title: 'Send Report to Signers?',
          },
          {
            id: 'includeStatusReport',
            type: 'switch',
            title: 'Include status report?',
          },
          {
            id: 'bccRecipients',
            type: 'chipsinput',
            title: 'BCC Email Recipients',
            description:
              'Type an email address and hit enter to add to the recipients list',
          },
          {
            id: 'signers',
            type: 'objectlist',
            title: 'Signers',
            options: [
              { id: 'name', name: 'Signer Name' },
              { id: 'title', name: 'Signer Title' },
              { id: 'email', name: 'Signer Email' },
            ],
          },
        ],
      },
    ],
  } as FormConfig;

  const initialValues = { signers: [] };
  const validationSchema = Yup.object().shape({
    signers: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Signer Name is required'),
        title: Yup.string().required('Signer Title is required'),
        email: Yup.string()
          .email('Must be a valid email address')
          .required('Signer Email is required'),
      })
    ),
  });

  const onSubmitProgressSubmissionExport = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setOffset(0);
    setNewItemTime(Date.now());
    try {
      await generateProgressSubmissionExport({
        variables: {
          input: {
            ...values,
            projectId: id,
            outcomeId,
            date,
          },
        },
      });
      await fetchAndSetResults();
      if (setSubmitting) {
        setSubmitting(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowCreateNewExport(false);
    }
    // Check for 4 minutes with a backoff
    exponentialBackoff(() => fetchAndSetResults(), 240000);
  };

  const navigate = useNavigate();

  return (
    <div>
      <>
        <div className="flex w-full border-b mb-2">
          <Button
            style={{
              backgroundColor: 'transparent',
              color: 'black',
              boxShadow: 'none',
              borderRadius: 0,
            }}
            onClick={() => navigate(`/progress/submissions/${id}/${outcomeId}`)}
            type="submit"
            text={`< Go Back`}
          />
        </div>
        <p className="text-sm py-2 text-gray-600">
          Create a progress submission export for Project: {project?.name} for
          date: {date}.
        </p>
        {showNewItemButton &&
        userProfile?.permissions.includes(
          Permission.GenerateProgressReports
        ) ? (
          <div className="w-full flex justify-between items-center py-2">
            <Button
              text="New Export"
              onClick={() => setShowCreateNewExport(true)}
            />
          </div>
        ) : null}
        {showCreateNewExport ? (
          <div className="mb-6">
            <FormCard
              initialValues={initialValues}
              onSubmit={onSubmitProgressSubmissionExport}
              validationSchema={validationSchema}
              submitText="Create"
              onCancel={() => setShowCreateNewExport(false)}
              config={createNewExportFormConfig}
            />
          </div>
        ) : null}
        <ExportsTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={ExportResults?.count || 0}
          key={`${newItemTime}`}
          RowComponent={({ id, rowData }: any) => (
            <>
              <div
                key={`${id}_row`}
                className={`border-b flex flex-col w-full justify-around items-center lg:flex-row border-gray-200 hover:bg-gray-100`}
              >
                {rowData?.id ? (
                  <div
                    key={`${id}_row_entry`}
                    className="py-3 px-6 text-sm text-left w-full"
                  >
                    <div className="flex flex-col flex-wrap gap-y-2 gap-x-2 mt-2">
                      <p>{rowData?.id}</p>
                      <div className="text-xs flex flex-col">
                        Created:{' '}
                        {rowData.createdAt
                          ? moment
                              .unix(parseInt(rowData.createdAt) / 1000)
                              .fromNow()
                          : null}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div
                  key={id}
                  className="py-3 px-6 text-sm flex flex-col gap-y-2 lg:flex-row items-center justify-end  gap-x-2 text-black w-full"
                >
                  <div className="flex items-center gap-x-2">
                    Report Status:
                    {rowData.status === 'COMPLETE' ? (
                      <GreenBadge text={'Generated'} />
                    ) : rowData.status === 'FAILED' ? (
                      <RedBadge text={rowData.status} />
                    ) : rowData.status === 'SUBMITTED' ? (
                      <BlueBadge text={'Submitted'} />
                    ) : (
                      <GeneratingBadge />
                    )}
                  </div>
                  {rowData.status === 'COMPLETE' ||
                  rowData.status === 'SUBMITTED' ? (
                    <div className="flex gap-x-2">
                      <Button
                        text="Download"
                        onClick={async () => {
                          await fetchExportURL({
                            variables: {
                              exportId: rowData?.id,
                            },
                          });
                        }}
                      />
                      {rowData.status === 'SUBMITTED' ? (
                        <Button
                          text="View Signing Details"
                          onClick={async () => {
                            setExpandedExport(
                              expandedExport === rowData?.id
                                ? undefined
                                : rowData?.id
                            );

                            await fetchProgressSigningSubmissions({
                              variables: { exportId: rowData?.id },
                            });
                          }}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
              {expandedExport === rowData?.id && progressSigningSubmission ? (
                <div className="px-4 py-2">
                  <div>
                    {progressSigningSubmission.status === 'COMPLETE' ? (
                      <GreenBadge text="COMPLETE" />
                    ) : progressSigningSubmission.status === 'FAILED' ? (
                      <RedBadge text="FAILED" />
                    ) : (
                      <YellowBadge text={'Awaiting Signatures'} />
                    )}
                  </div>

                  <div>
                    {progressSigningSubmission.signers &&
                      progressSigningSubmission.signers.map(
                        (signer: any, index: any) => (
                          <div
                            className="mt-2 flex flex-col text-sm gap-y-2"
                            key={index}
                          >
                            <p>
                              <strong>Name:</strong> {signer.name}
                            </p>
                            <p>
                              <strong>Title:</strong> {signer.title}
                            </p>
                            <p>
                              <strong>Email:</strong> {signer.email}
                            </p>
                          </div>
                        )
                      )}
                  </div>
                </div>
              ) : null}
            </>
          )}
          data={
            ExportResults?.results.length > 0 ? ExportResults?.results : [{}]
          }
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const CreateProgressExport = ({
  onClickRow,
  showNewItemButton = true,
  filterIds = [],
}: CreateProgressExportProps) => (
  <PaginationProvider>
    <CreateProgressExportPage
      showNewItemButton={showNewItemButton}
      onClickRow={onClickRow}
      filterIds={filterIds}
    />
  </PaginationProvider>
);
