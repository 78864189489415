import React from 'react';
import ValidationMessage from '../Validation/ValidationMessage';

interface RangeSliderProps {
  id: string;
  title: string;
  description?: string;
  min: number;
  max: number;
  minLabel?: string;
  maxLabel?: string;
  classNames?: string;
  value: number;
  handleChange: (value: any) => void;
  validation?: string;
  isDisabled?: boolean;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  id,
  title,
  description,
  min,
  max,
  minLabel,
  maxLabel,
  classNames,
  value,
  handleChange,
  validation,
  isDisabled,
}) => {
  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(parseInt(event.target.value, 10));
  };

  return (
    <div className={classNames}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      {validation && <ValidationMessage message={validation} />}
      <div className="mt-2">
        <span className="text-2xl">{value}</span>
        <input
          type="range"
          id={id}
          min={min}
          max={max}
          value={value || 0}
          onChange={handleSliderChange}
          disabled={isDisabled}
          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
        />
      </div>
      <div className="flex justify-between text-base text-gray-500">
        <span>{minLabel || min}</span>
        <span>{maxLabel || max}</span>
      </div>
      {description && (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      )}
    </div>
  );
};

export default RangeSlider;
