import ValidationMessage from '../Validation/ValidationMessage';

export interface EmailInputProps {
  classNames?: string;
  description?: string;
  handleChange: (props: any) => void;
  id: string;
  inputTransformer?: (value: string | undefined) => any;
  isDisabled?: boolean;
  name: string;
  placeholder?: string;
  title?: string;
  validation?: string;
  value: string | undefined;
}

const EmailInput = ({
  classNames,
  description,
  handleChange,
  id,
  inputTransformer = (text) => text,
  isDisabled,
  name,
  placeholder,
  title,
  validation,
  value,
}: EmailInputProps) => {
  return (
    <div className={classNames}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      {validation ? <ValidationMessage message={validation} /> : null}
      <input
        type="email"
        id={id}
        name={name || id}
        disabled={isDisabled}
        value={value || ''}
        onChange={(event) => handleChange(inputTransformer(event.target.value))}
        autoComplete={'email'}
        className="w-full px-3 mt-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
        placeholder={placeholder}
      />
      {description ? (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      ) : null}
    </div>
  );
};

export default EmailInput;
