import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import DropDown from '../../../components/Inputs/Dropdown';
import { useState } from 'react';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { GET_ROLES_SUMMARY } from '../../../graphql/queries/roles';

interface RolesDropdownProps {
  rowData: any;
  defaultRole: string;
  setStateValue: React.Dispatch<React.SetStateAction<any>>;
}

export const RolesDropdown: React.FC<RolesDropdownProps> = ({
  rowData,
  defaultRole,
  setStateValue,
}) => {
  const { useQuery } = useOrganisationAwareApollo();
  const { data: jobRolesData } = useQuery(GET_ROLES_SUMMARY, {});

  const jobRoles = jobRolesData?.getRoles?.results || [];

  const handleRoleChange = (selectedValue: string) => {
    setStateValue((prevState: any) => {
      return prevState.map((row: any) =>
        row.id === rowData.id
          ? {
              ...row,
              roleId: {
                id: jobRoles.find((role: any) => role.name === selectedValue)
                  ?.id,
                name: selectedValue,
              },
            }
          : row
      );
    });
  };

  const [hiddenDropdowns, setHiddenDropdowns] = useState<Array<string>>([]);

  const handleDropdownClose = () => {
    setHiddenDropdowns([...hiddenDropdowns, `${rowData.id}`]);
    setStateValue((prevState: any) => {
      return prevState.map((row: any) =>
        row.id === rowData.id
          ? {
              ...row,
              roleId: {
                id: null,
                name: 'Select Job Role',
              },
              defaultRole: { id: null, name: null },
            }
          : row
      );
    });
  };

  const handleDropdownOpen = () => {
    setHiddenDropdowns(
      hiddenDropdowns.filter((item: any) => item !== `${rowData.id}`)
    );
    setStateValue((prevState: any) => {
      return prevState.map((row: any) =>
        row.id === rowData.id
          ? {
              ...row,
              roleId: {
                id: null,
                name: 'Select Job Role',
              },
            }
          : row
      );
    });
  };

  return (
    <div className="flex w-full">
      <div
        className="flex gap-x-2 items-center"
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
      >
        {!hiddenDropdowns?.includes(`${rowData.id}`) &&
        (defaultRole || rowData.roleId) ? (
          <DropDown
            hideClearButton
            id="jobRole"
            options={jobRoles}
            handleChange={handleRoleChange}
            value={rowData?.roleId?.name || defaultRole || 'Select Job Role'}
          />
        ) : null}

        {!hiddenDropdowns?.includes(`${rowData.id}`) &&
        (defaultRole || rowData.roleId) ? (
          <button
            className="h-7 w-7 cursor-pointer"
            onClick={handleDropdownClose}
          >
            <XCircleIcon />
          </button>
        ) : null}

        {hiddenDropdowns.includes(`${rowData.id}`) ||
        (!defaultRole && !rowData.roleId) ? (
          <button
            className="h-7 w-7 cursor-pointer"
            onClick={handleDropdownOpen}
          >
            <PlusCircleIcon />
          </button>
        ) : null}
      </div>
    </div>
  );
};
