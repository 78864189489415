import { useState } from 'react';
import { useDebounce } from './useDebounce';

export default function useDebouncedState<T>(
  defaultValue: T,
  delay: number = 600
): [T, T, Function] {
  const [value, setValue] = useState<T>(defaultValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  const debouncedSetDebouncedValue = useDebounce(setDebouncedValue, delay);

  return [
    value,
    debouncedValue,
    (value: T) => {
      setValue(value);
      debouncedSetDebouncedValue(value);
    },
  ];
}
