import {
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Combobox as UIComboBox,
} from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { Option } from '../../types/QuestionTypes';
import ValidationMessage from '../Validation/ValidationMessage';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';

interface ComboBoxParams {
  classNames?: string;
  description?: string;
  handleChange: (value: string[]) => void;
  id: string;
  inputTransformer?: (text: string) => string;
  isDisabled?: boolean;
  label?: string;
  onChange?: (text: string) => void;
  options?: Option[];
  placeholder?: string;
  testId?: string;
  title?: string;
  validation?: any;
  value: any[];
}

const MultiSelectComboBox = ({
  classNames,
  description,
  handleChange,
  id,
  inputTransformer = (text) => text,
  isDisabled,
  label = 'Searching by:',
  onChange: onQueryChange = () => {},
  options,
  placeholder = 'Search',
  testId = '',
  title,
  validation,
  value = [],
}: ComboBoxParams) => {
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? options
      : // @ts-ignore
        options?.filter(
          (option) =>
            // @ts-ignore
            option?.name?.toLowerCase().includes(query?.toLowerCase()) ||
            // @ts-ignore
            option?.detail?.toLowerCase().includes(query?.toLowerCase())
        );

  useEffect(() => {
    onQueryChange(inputTransformer(query));
  }, [query, onQueryChange, inputTransformer]);

  const handleSelect = (selected: any) => {
    if (Array.isArray(selected)) {
      // Deduplicating the array
      const uniqueById = new Map(selected.map((item) => [item.id, item]));
      const deduplicatedArray = Array.from(uniqueById.values());

      handleChange(deduplicatedArray);
    } else {
      // Handle single item selection/deselection
      if (value?.some((v) => v.id === selected.id)) {
        handleChange(value?.filter((v) => v.id !== selected.id));
      } else {
        handleChange([...value, selected]);
      }
    }
  };

  const valueIds = value?.map((item) => item.id) ?? [];

  return (
    <div className={classNames}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {title ? title : null}
      </label>
      {validation ? <ValidationMessage message={validation} /> : null}
      <UIComboBox
        data-testid={testId}
        disabled={isDisabled}
        as="div"
        value={value}
        onChange={handleSelect}
        multiple
      >
        {value?.length > 0 ? (
          <div className="flex flex-col">
            <p>{label}</p>
            <div className="flex flex-wrap gap-y-2 gap-x-2 my-4">
              {value?.map((item) => (
                <div
                  role="button"
                  aria-disabled={isDisabled}
                  onClick={() =>
                    !isDisabled
                      ? handleChange(value?.filter((v) => v.id !== item.id))
                      : {}
                  }
                  className="inline-flex items-center rounded-lg bg-blue-100 px-3 py-0.5 text-sm font-medium text-blue-800"
                >
                  <div className="flex flex-col">
                    <p>{item.name}</p>
                    {item.detail ? (
                      <p className="text-xs">{item.detail}</p>
                    ) : null}
                  </div>
                  {!isDisabled ? (
                    <XCircleIcon className="ml-2 w-5 h-5 text-blue-800" />
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        ) : null}
        <div className="relative">
          <ComboboxButton className="relative w-full">
            <ComboboxInput
              key={`input_${isDisabled}`}
              id={id}
              disabled={isDisabled}
              placeholder={placeholder}
              autoComplete="off"
              className={`w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 ${
                isDisabled ? 'bg-gray-200 opacity-75' : ''
              } shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm`}
              onChange={(event) =>
                isDisabled
                  ? null
                  : setQuery(inputTransformer(event.target.value))
              }
              displayValue={() => {
                return query;
              }}
            />
            <div className=" absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </ComboboxButton>

          {filteredOptions &&
            filteredOptions?.filter((item) =>
              valueIds.length === 0 ? true : !valueIds.includes(item.id)
            ).length > 0 && (
              <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredOptions
                  ?.filter((item) =>
                    valueIds.length === 0 ? true : !valueIds.includes(item.id)
                  )
                  .map((option) => (
                    <ComboboxOption
                      key={option.id}
                      value={option}
                      className={twMerge(
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                        'text-gray-900'
                      )}
                    >
                      {({ focus, selected }) => (
                        <>
                          <span
                            className={twMerge(
                              'block truncate',
                              // @ts-ignore
                              selected && 'font-semibold'
                            )}
                          >
                            {option.name}
                          </span>
                          {/* @ts-ignore */}
                          <span
                            className={twMerge(
                              'block truncate',
                              // @ts-ignore
                              selected && 'font-semibold'
                            )}
                          >
                            {option.detail}
                          </span>

                          {selected && (
                            <span
                              className={twMerge(
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                focus ? 'text-black' : 'text-gray-900'
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </ComboboxOption>
                  ))}
              </ComboboxOptions>
            )}
        </div>
      </UIComboBox>
      {description ? (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      ) : null}
    </div>
  );
};

export default MultiSelectComboBox;
