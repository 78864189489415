import { useContext } from 'react';
import { ActiveOrganisationContext } from '../../context/ActiveOrganisationContext';
import { ErrorMessage, Formik } from 'formik';
import FormSection from '../../components/FormSection/FormSection';
import { FileInput } from '../../components/Inputs/FileInput';
import FormSubmit from '../../components/Buttons/FormSubmit';
import { SAVE_ORGANISATION } from '../../graphql/mutations/organisation';
import TextInput from '../../components/Inputs/TextInput';
import * as Yup from 'yup';
import { saveImageToS3 } from '../../utils/utils';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { useToast } from '../../context/ToastContext';

function OrganisationSettings() {
  const { activeOrganisation, refetchOrganisations } = useContext(
    ActiveOrganisationContext
  );

  const { useMutation } = useOrganisationAwareApollo();

  const [saveOrganisation] = useMutation(SAVE_ORGANISATION);

  const { addToast } = useToast();

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      setSubmitting(true);
      const { data } = await saveOrganisation({
        variables: {
          activeOrganisation: activeOrganisation?.id,
          input: {
            companyName: values.companyName,
            logoUrl: values.file?.name || activeOrganisation?.logoUrl,
          },
        },
      });

      if (!data?.saveOrganisation?.success) {
        addToast('Error updating organisation', 'error');
        console.error('Error updating organisation');
        setSubmitting(false);
        return;
      }

      const presignedUrl = data?.saveOrganisation?.preSignedUrl;

      if (values.file && presignedUrl) {
        const isUploaded = await saveImageToS3(values.file, presignedUrl);
        if (!isUploaded) {
          addToast('Error uploading new logo', 'error');
          return;
        }
      }
      addToast('Organisation successfully updated', 'success');

      if (refetchOrganisations) {
        await refetchOrganisations();
      }
      values.file = undefined;
      setSubmitting(false);
    } catch (error) {
      addToast('Error updating organisation', 'error');
      console.error(error);
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required('Organisation name is required'),
  });

  const initialValues = {
    companyName: activeOrganisation?.companyName || '',
  };

  return (
    <>
      <div className="flex flex-col gap-y-2 w-full px-4 py-5 sm:px-6 flex-wrap">
        <h1 className="text-3xl font-extrabold text-gray-900">
          Organisation Settings
        </h1>
      </div>
      <div className="pl-6">
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            await onSubmit(values, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            isValid,
            handleSubmit,
            isSubmitting,
          }) => {
            return (
              <>
                <FormSection title="Details" classNames="mb-4">
                  <>
                    <TextInput
                      id={'companyName'}
                      name="companyName"
                      title="Organisation Name"
                      handleChange={(options) =>
                        setFieldValue('companyName', options)
                      }
                      placeholder={'Enter company name'}
                      value={values.companyName}
                      validation={errors['companyName']}
                    />
                    <ErrorMessage
                      name="companyName"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                    <h3 className="block text-sm font-medium leading-6 text-gray-700">
                      Current Logo
                    </h3>
                    <img
                      src={activeOrganisation?.logoUrl}
                      alt="company-logo"
                      className="w-24 h-24"
                    />
                  </>
                  <FileInput
                    id={'file'}
                    label="Upload New Logo"
                    filetypes="image/*"
                    filetypesDescription={'SVG, PNG, JPEG'}
                    setFieldValue={setFieldValue}
                    clearForm={() => setFieldValue('file', undefined)}
                  />
                </FormSection>
                <FormSubmit
                  disabled={!isValid}
                  isLoading={isSubmitting}
                  submitText={'Submit'}
                  entityName="Organisation"
                  onSubmit={handleSubmit}
                />
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

export default OrganisationSettings;
