import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect, useState } from 'react';

import FormCard from '../../../components/FormCard/FormCard';
import { SAVE_EMPLOYEE } from '../../../graphql/mutations/employees';
import { GET_EMPLOYEES } from '../../../graphql/queries/employees';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FieldType, FormConfig } from '../../../types/Form';
import RateConfigurationByKey, {
  TableValue,
} from '../../../components/RateConfiguration/RateConfigurationByKey';
import { GET_ACTIVITY_CODES } from '../../../graphql/queries/activity-codes';
import { Currency } from '../../../types/Currency';
import { GET_GROUPS, SEARCH_USERS } from '../../../graphql/queries/users';
import { BlueBadge } from '../../../components/Badges/Badges';
import { GET_CUSTOM_FIELDS } from '../../../graphql/queries/custom-data';
import { useToast } from '../../../context/ToastContext';
import { GET_ROLES_SUMMARY } from '../../../graphql/queries/roles';
import { LoadingSpinner } from '../../../components/Loading/LoadingSpinner';
import { RichTextViewer } from '../../../components/Inputs/RichTextInput';

export const SaveEmployee = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useQuery, useMutation, useLazyQuery } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const [searchUsers, { data: usersData }] = useLazyQuery(SEARCH_USERS);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [defaultRate, setDefaultRate] = useState<TableValue[]>([]);

  const isUpdate = !!id;

  const { data: jobRolesData } = useQuery(GET_ROLES_SUMMARY, {});

  const { data, refetch, loading } = useQuery(GET_EMPLOYEES, {
    variables: { input: { id } },
    skip: !isUpdate,
  });

  const { data: activityCodes, refetch: fetchActivityCodes } = useQuery(
    GET_ACTIVITY_CODES,
    {
      variables: {},
      fetchPolicy: 'network-only',
    }
  );

  const [fetchCustomFields, { data: customFieldsData }] = useLazyQuery(
    GET_CUSTOM_FIELDS,
    {
      variables: {
        labourResourceId: id,
        isFetchingForCreatingUser: !id,
        onlyEnabled: true,
      },
      fetchPolicy: 'network-only',
    }
  );

  const performUserSearch = async () => {
    try {
      await searchUsers({
        variables: {
          searchTerm: debouncedSearchTerm,
        },
        fetchPolicy: 'network-only',
      });
    } catch (err) {
      // do nothing
    } finally {
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 1) {
      performUserSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const activityCodesResults = activityCodes?.getActivityCodes?.results || [];
  const filteredAndMappedActivityCodes =
    activityCodesResults.filter(
      (code: any) => code.enabled && code.useResourceDefaultRate
    ) || [];
  useEffect(() => {
    if (isUpdate) {
      refetch({ id });
      fetchActivityCodes();
    }
  }, [id, refetch, isUpdate, fetchActivityCodes]);

  useEffect(() => {
    fetchCustomFields();
    if (isUpdate) {
      refetch({ id });
    }
  }, [id, refetch, isUpdate, fetchCustomFields]);

  const customFields = customFieldsData?.getCustomFieldGroups?.results || [];

  const [employee] = data?.getEmployees?.results || [];

  const [saveEmployee] = useMutation(SAVE_EMPLOYEE);

  const currencies = Object.values(Currency).filter((e: any) => isNaN(e));

  const initialDefaultRates = currencies.reduce((acc: any, currency: any) => {
    const currencyActivities = filteredAndMappedActivityCodes.map(
      (activityCode: any) => ({
        columnId: currency,
        name: activityCode?.name,
        rowId: activityCode.key,
        value: 0,
      })
    );
    return [...acc, ...currencyActivities];
  }, []);

  const projectCustomData = employee?.customData || [];

  const initialValues = id
    ? {
        ...employee,
        defaultRoleId: employee?.defaultRole.id || '',
        ...projectCustomData.reduce(
          (acc: Record<string, any>, customItem: any) => {
            acc[`custom:${customItem.key}`] = customItem.value;
            return acc;
          },
          {}
        ),
      }
    : { type: 'EMPLOYEE', hasUserLogin: true };

  useEffect(() => {
    if (employee?.defaultRate && employee?.defaultRate.length > 0) {
      setDefaultRate(
        employee?.defaultRate?.map((itemRate: any) => ({
          columnId: itemRate.currency,
          name:
            itemRate.name ??
            filteredAndMappedActivityCodes.find(
              (code: any) => code.id === itemRate.key
            )?.name,
          rowId: itemRate.key,
          value: parseFloat(itemRate.rate),
        }))
      );
    } else {
      setDefaultRate(initialDefaultRates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string()
      .nullable()
      .when('hasUserLogin', {
        is: (hasUserLogin: boolean) => hasUserLogin,
        then: (emailValidation) =>
          emailValidation
            .email('Not a valid email address')
            .required('Email is required'),
        otherwise: (emailValidation) =>
          emailValidation.email('Not a valid email address'),
      }),
    hasUserLogin: Yup.boolean(),
  });

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const customData: { key: string; value: any }[] = [];
    const filteredValues = Object.keys(values).reduce(
      (acc: Record<string, string>, key) => {
        if (key.startsWith('custom:')) {
          customData.push({
            key: key?.replace('custom:', ''),
            value: values[key],
          });
        } else if (key === 'defaultRole') {
          acc['defaultRoleId'] = values[key];
        } else {
          acc[key] = values[key];
        }
        return acc;
      },
      {}
    );

    try {
      const { data } = await saveEmployee({
        variables: {
          id: values?.id,
          input: {
            ...filteredValues,
            defaultRate: defaultRate
              ?.map((rateOption: any) => ({
                id: `${rateOption?.columnId}_${rateOption?.rowId}`,
                name: rateOption?.name,
                currency: rateOption?.columnId,
                key: rateOption?.rowId,
                rate: parseFloat(rateOption?.value),
              }))
              .filter((rate: any) =>
                filteredAndMappedActivityCodes
                  .map((code: any) => code.id)
                  .includes(rate.key)
              ),
            customData,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
            missingOrInvalidCertifications: undefined,
          },
        },
      });
      addToast('Employee saved', 'success');
      setSubmitting(false);
      if (data.saveEmployee.success) {
        if (id) {
          navigate(
            `/labour/${
              values?.type === 'SUBCONTRACTOR' ? 'subcontractors' : 'employees'
            }/${id}`
          );
        } else {
          navigate(`/labour/employees/list`);
        }
      }
    } catch (error) {
      console.error(error);
      addToast('Error saving employee', 'error');
    }
  };

  const [fetch, { data: groupsData }] = useLazyQuery(GET_GROUPS, {
    variables: { input: { limit: 1000, offset: 0 } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({ variables: { input: { limit: 1000, offset: 0 } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const jobRoles = jobRolesData?.getRoles?.results || [];

  const groupResults = groupsData?.getGroups?.results || [];

  const employeeConfig = {
    title: '',
    description: '',
    formSections: [
      {
        title: 'Employee',
        description: 'Basic details about the Employee',
        fields: [
          {
            title: 'First Name',
            id: 'firstName',
            type: FieldType.input,
            required: true,
          },
          {
            title: 'Last Name',
            id: 'lastName',
            type: FieldType.input,
            required: true,
          },
          {
            title: 'Nationality',
            id: 'nationality',
            type: FieldType.input,
          },
          {
            title: 'Country of Residence',
            id: 'countryOfResidence',
            type: FieldType.input,
          },
          {
            title: 'Email',
            id: 'email',
            description:
              usersData?.searchUsers?.count > 0 ? 'Email in use' : '',
            type: id ? FieldType.input : FieldType.combobox,
            disabled: !!id || usersData?.searchUsers?.count,
            placeholder: !id
              ? 'Start typing the new users email address'
              : 'No email configured for this user',
            onChange: (value: string) => {
              setSearchTerm(value);
            },
            options:
              searchTerm.length > 5 &&
              (!usersData?.searchUsers?.count ||
                usersData?.searchUsers?.count === 0)
                ? [
                    {
                      id: searchTerm.trim(),
                      name: searchTerm.trim(),
                      detail: !Yup.string().email().isValidSync(searchTerm)
                        ? 'Not valid email address'
                        : 'Available',
                    },
                  ]
                : [],
            required: true,
          },
          {
            title: 'Job Title',
            id: 'jobTitle',
            type: FieldType.input,
            required: false,
          },
          {
            title: 'Default Role',
            id: 'defaultRoleId',
            type: FieldType.dropdown,
            options: jobRoles.map((role: { id: string; name: string }) => ({
              name: role.name,
              value: role.id,
            })),
            required: false,
          },
          {
            title: 'Personal Profile',
            id: 'personalProfile',
            type: FieldType.richtextinput,
            required: false,
          },
          {
            title: 'Past Experience',
            description: 'This will be used in CV exports.',
            id: 'projectExperience',
            type: FieldType.richtextinput,
            required: false,
          },
          ...(!id
            ? [
                {
                  id: 'groupMemberships',
                  type: FieldType.multiselect,
                  title: 'Groups',
                  options: groupResults.map((group: any) => ({
                    name: group.name,
                    value: group.id,
                  })),
                },
              ]
            : []),
          {
            title: 'Internal Id',
            id: 'internalId',
            type: FieldType.input,
          },
          ...(id
            ? [
                {
                  title: 'Type',
                  id: 'type',
                  type: FieldType.dropdown,
                  description:
                    'Note: Changing this will effect where this user is listed and may affect other parts of the system. Be sure you know what you are doing!',
                  options: [
                    { name: 'Subcontractor', value: 'SUBCONTRACTOR' },
                    { name: 'Employee', value: 'EMPLOYEE' },
                  ],
                },
              ]
            : []),
          ...(!id
            ? [
                {
                  title: 'Create User Login',
                  id: 'hasUserLogin',
                  type: FieldType.switch,
                  description:
                    'Should this employee be able to log into the application?',
                },
              ]
            : []),
        ],
      },
      ...(customFields?.length > 0
        ? customFields.map((field: any) => ({
            title: field.name,
            fields: field.custom_fields.map((customField: any) => ({
              title: customField.name,
              id: `custom:${customField.id}`,
              type:
                customField.type === 'longText'
                  ? FieldType.textarea
                  : FieldType.input,
              description: customField.description,
            })),
          }))
        : []),
      {
        title: 'Rates',
        description: 'Default Rates',
        components: [
          <RateConfigurationByKey
            value={defaultRate}
            handleChange={(item) => setDefaultRate(item)}
            question={{
              tableOptions: filteredAndMappedActivityCodes?.map((key: any) => ({
                heading: {
                  id: key.id,
                  name: key.name,
                  colour: key?.colour,
                },
                options: currencies.map((entry) => ({
                  heading: {
                    id: Object.entries(Currency).find(
                      (item: any) => item[1] === entry
                    )?.[0],
                    name: Object.values(Currency).find(
                      (currency) => currency === entry
                    ),
                  },
                })) as {
                  heading?: {
                    id: string;
                    name: string;
                  };
                }[],
              })),
            }}
          />,
        ],
      },
    ],
  } as FormConfig;

  const renderConfirmationModal = ({ values }: { values: any }) => {
    return (
      <div className="flex flex-col gap-y-4">
        <div className="text-gray-500">
          You are about to save an employee with the following details, please
          ensure they are correct:
        </div>
        {employeeConfig?.formSections?.[0].fields.map((field) => {
          return (
            <div>
              <div className="text-lg font-medium text-gray-900">
                {field.title}
              </div>
              {field.id === 'groupMemberships' ? (
                <div className="flex gap-x-2">
                  {values[field.id]?.map((groupId: string) => (
                    <BlueBadge
                      text={
                        groupResults.find((group: any) => group.id === groupId)
                          ?.name
                      }
                    />
                  ))}
                </div>
              ) : field.id === 'defaultRoleId' ? (
                <p className="text-gray-500">
                  {
                    <RichTextViewer
                      text={
                        jobRoles.find(
                          (role: any) => role.id === values[field.id]
                        )?.name
                      }
                    />
                  }
                </p>
              ) : (
                <p className="text-gray-500">
                  <RichTextViewer text={values[field.id]} />
                </p>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <FormCard
      key={`${employeeConfig?.title}`}
      config={employeeConfig}
      renderConfirmationModal={renderConfirmationModal}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
