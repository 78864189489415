import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { useCallback, useEffect, useMemo } from 'react';
import { Table } from '../../../components/Tables/tables/Table';
import { ActiveBadge } from '../../../components/Badges/Badges';
import useProjectCertifications from '../../../hooks/api/projects/useProjectCertifications';
import useRoles from '../../../hooks/api/useRoles';
import { useNavigate } from 'react-router-dom';

type ProjectCertificationsListProps = {
  projectId?: string;
};

const ProjectCertificationsTable = ({ projectId }: { projectId?: string }) => {
  const { limit, offset, setOffset } = usePagination();
  const navigate = useNavigate();

  const [projectCertifications, { count }] = useProjectCertifications({
    projectId,
    limit,
    offset,
  });

  const [roles] = useRoles({ limit: 1000, offset: 0 });

  // Make a map of roleId to roles present in the project certifications, to avoid doing a .find for every project certification in the table each render
  const roleIdMap = useMemo(() => {
    return projectCertifications.reduce(
      (acc: any, projectCertification: any) => {
        projectCertification.requiredForRoles?.forEach((roleId: string) => {
          const role = roles.find((role: any) => role.id === roleId);

          if (!acc[roleId]) {
            acc[roleId] = role;
          }
        });

        return acc;
      },
      {}
    );
  }, [projectCertifications, roles]);

  const headerMapping = useMemo(
    () =>
      ({
        certification: 'Certification',
        requiredForCompanies: 'Required for Companies',
        requiredForEmployees: 'Required for Employees',
        requiredForSubcontractors: 'Required for Subcontractors',
        requiredForRoles: 'Required for Roles',
      } as Record<string, any>),
    []
  );

  const onPageChange = useCallback(
    (pageNumber: number) => {
      setOffset((pageNumber - 1) * limit);
    },
    [limit, setOffset]
  );

  const totalResults = count;
  const totalPages = Math.ceil(totalResults / limit);

  return (
    <Table
      currentPage={Math.floor(offset / limit) + 1}
      totalPages={totalPages}
      totalResults={totalResults}
      itemsPerPage={limit}
      onClickRow={(id) => {
        const projectCertification = projectCertifications.find(
          (projectCertification: any) => projectCertification.id === id
        );
        navigate(`/projects/${projectId}/certifications/new`, {
          state: {
            certificationTypeId: projectCertification?.certificationType?.id,
          },
        });
      }}
      headerMapper={(text: string) => headerMapping[text]}
      onPageChange={onPageChange}
      data={projectCertifications.map((projectCertification: any) => ({
        id: projectCertification.id,
        certification: projectCertification.certificationType?.name,
        requiredForCompanies: projectCertification?.requiredForCompanies ? (
          <ActiveBadge text="Required" />
        ) : null,
        requiredForEmployees: projectCertification?.requiredForEmployees ? (
          <ActiveBadge text="Required" />
        ) : null,
        requiredForSubcontractors:
          projectCertification.requiredForSubcontractors ? (
            <ActiveBadge text="Required" />
          ) : null,
        requiredForRoles: (
          <div className="flex flex-wrap gap-x-2 gap-y-2">
            {projectCertification.requiredForRoles?.map((roleId: string) => {
              const role = roleIdMap[roleId];

              return role ? <ActiveBadge text={role?.name} /> : undefined;
            })}
          </div>
        ),
      }))}
    />
  );
};

export const ProjectCertificationsList = ({
  projectId,
}: ProjectCertificationsListProps) => {
  return (
    <PaginationProvider>
      <div className="px-4 py-2">
        <p className="py-2 text-sm">
          You can configure certifications required for this project here. These
          will combine with the rules defined in{' '}
          <a href="/labour/certifications" className="text-green-600">
            Certifications
          </a>
          .
        </p>
        <ProjectCertificationsTable projectId={projectId} />
      </div>
    </PaginationProvider>
  );
};
