import { ClockIcon } from '@heroicons/react/24/outline';
import {
  BlueBadge,
  GreenBadge,
  OrangeBadge,
  RedBadge,
  YellowBadge,
} from '../../../../components/Badges/Badges';
import { areActivityStartAndEndTimesValid } from './utils';

export const ActivityInfo = ({ activity, progressCodes }: any) => {
  const activityIdPart = activity?.id?.split('-')?.slice(-1)[0];
  const totalAllocatedRoles = activity?.assignmentRolesList.reduce(
    (accumulator: any, currentObject: any) => {
      return accumulator + (currentObject.enabled ? 1 : 0);
    },
    0
  );

  const progressCode = progressCodes?.find(
    (code: { id: string }) => code.id === activity.progressCodeId
  );

  return (
    <div className="not-italic flex flex-col gap-y-2 lg:flex-row items-center justify-between w-full text-gray-500 text-base">
      <div className="flex flex-col gap-y-2 lg:flex-row items-center gap-x-2">
        <ClockIcon className="w-4 h-4" />
        {activity?.isNew && (
          <>
            <span> </span>
            <YellowBadge text="Unsaved" />
          </>
        )}
        {activity?.hasChanged && (
          <>
            <span> </span>
            <OrangeBadge text="Modified" />
          </>
        )}
        {activity?.toBeDeleted && (
          <>
            <span> </span>
            <RedBadge text="Marked for Deletion" />
          </>
        )}
        {activity?.savedLocally && (
          <>
            <BlueBadge text="Ready to Sync" />
          </>
        )}
        {!activity?.toBeDeleted &&
          !activity?.hasChanged &&
          !activity?.isNew && (
            <>
              <span> </span>
              <GreenBadge text="Saved" />
            </>
          )}
        <i className="not-italic text-gray-500 text-sm">
          <span> </span>#{activityIdPart}
        </i>

        <i className="not-italic flex flex-col md:flex-row gap-x-1 items-center text-black text-sm lg:text-base">
          {activity.pat?.id ? (
            ` ${activity?.pat?.name} ${
              progressCode ? `(${progressCode.code})` : ''
            }`
          ) : (
            <>
              <span> </span>
              <YellowBadge text="No Activity" />
            </>
          )}
          {activity.pl?.id ? (
            ` @ ${activity?.pl?.name}`
          ) : (
            <>
              <span> </span>
              <YellowBadge text="No Location" />
            </>
          )}
        </i>
        <i className="not-italic">
          {totalAllocatedRoles === 0 ? (
            <YellowBadge text="No Workers Allocated" />
          ) : (
            <BlueBadge text={`${totalAllocatedRoles} Allocated`} />
          )}
        </i>
      </div>
      <i className="flex not-italic text-gray-900 text-lg gap-x-4">
        {!areActivityStartAndEndTimesValid(activity) ? (
          <RedBadge text="Invalid Start/End Times" />
        ) : null}
        {activity.start && (activity.finish || activity.endsAtMidnight) ? (
          `${activity.start} - ${
            activity.endsAtMidnight
              ? '24:00'
              : activity.finish
              ? activity.finish
              : 'Not defined'
          }`
        ) : (
          <YellowBadge text="No Duration" />
        )}
      </i>
    </div>
  );
};
