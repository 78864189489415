import { twMerge } from 'tailwind-merge';

interface ValidationMessageParams {
  className?: string;
  message: string;
}

const ValidationMessage = ({ className, message }: ValidationMessageParams) => {
  return (
    <div
      className={twMerge(
        'rounded-md bg-red-50 p-3 text-sm text-red-700',
        className
      )}
    >
      <p className="ml-2">{message}</p>
    </div>
  );
};

export default ValidationMessage;
