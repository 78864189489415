import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

import { Option } from '../../types/QuestionTypes';
import { joinClassNames } from '../../utils/utils';
import ValidationMessage from '../Validation/ValidationMessage';
import { twMerge } from 'tailwind-merge';

interface DropDownParams {
  classNames?: string;
  description?: string;
  handleChange: (value: string[]) => void;
  id: string;
  isDisabled?: boolean;
  label?: string;
  options?: Option[];
  styles?: Record<string, any>;
  title?: string;
  validation?: any;
  value: string[];
}

const MultiSelectDropDown = ({
  classNames,
  description,
  id,
  isDisabled,
  handleChange,
  label,
  options,
  styles,
  title,
  validation,
  value,
}: DropDownParams) => {
  if (!Array.isArray(value)) {
    console.warn('Value prop must be an array');
    value = [];
  }

  // Toggles selection of a value
  const toggleValue = (selectedValue: string) => {
    if (value.includes(selectedValue)) {
      handleChange(value.filter((item) => item !== selectedValue));
    } else {
      handleChange([...value, selectedValue]);
    }
  };

  return (
    <div className={classNames}>
      <label
        htmlFor={id || 'multi-select-dropdown'}
        className="block text-sm font-medium text-gray-700"
      >
        {title ? title : null}
      </label>
      {validation ? <ValidationMessage message={validation} /> : null}
      <Menu as="div" className="relative inline-block text-left my-2 z-99">
        {label ? (
          <label
            htmlFor={id || 'multi-select-dropdown'}
            className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs text-gray-900"
          >
            {label}
          </label>
        ) : null}
        <div>
          <MenuButton
            id={id}
            data-testid={'keyDropdownButton'}
            disabled={isDisabled}
            className="inline-flex disabled:bg-gray-100 disabled:opacity-90 justify-center rounded-md border border-gray-300 items-center bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-100"
          >
            {value.length > 0
              ? // Map the value to the option's name using the options array
                value
                  .map(
                    (val) =>
                      options?.find((option) => option.value === val)?.name ||
                      val
                  )
                  .join(', ')
              : 'Select options'}
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </MenuButton>
        </div>

        <MenuItems
          anchor="bottom start"
          style={{ ...styles?.menuItems }}
          className={twMerge(
            'mt-2 w-56 [--anchor-max-height:200px] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-scroll',
            'transition',
            'data-[closed]:opacity-0 data-[closed]:scale-95',
            'data-[enter]:ease-out data-[enter]:duration-100',
            'data-[leave]:ease-in data-[leave]:duration-75'
          )}
          transition
        >
          <div className="py-1">
            {/* @ts-ignore */}
            {options?.map((option) => (
              <MenuItem
                key={option.name}
                // @ts-ignore
                onClick={
                  isDisabled
                    ? () => null
                    : () => toggleValue(option.value ?? option.name)
                }
              >
                {({ focus }) => (
                  <div
                    className={joinClassNames(
                      focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'flex px-4 py-2 text-sm'
                    )}
                  >
                    <p className="flex items-center">
                      {/* @ts-ignore */}
                      {value.includes(option.value) ? '✓ ' : ''}{' '}
                      {/* Mark selected items */}
                      {option.name}
                      {option.detail ? (
                        <p className="ml-1 text-gray-400 text-xs font-light">
                          ({option.detail})
                        </p>
                      ) : null}
                    </p>
                  </div>
                )}
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Menu>
      {description ? (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      ) : null}
    </div>
  );
};

export default MultiSelectDropDown;
