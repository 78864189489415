import { useEffect } from 'react';
import { SEARCH_LABOUR_RESOURCES } from '../../graphql/queries/labour';
import { useOrganisationAwareApollo } from '../useOrganisationAwareApollo';
import { ApiHookMeta } from './types';
import { GET_SUBCONTRACTORS } from '../../graphql/queries/subcontractors';
import { GET_EMPLOYEES } from '../../graphql/queries/employees';

export default function useLabourResources({
  id,
  labourType,
  isASearch = false,
  searchTerm,
  limit,
  offset,
}: {
  id?: string;
  isASearch?: boolean;
  labourType?: string;
  searchTerm?: string;
  limit?: number;
  offset?: number;
}): [any, ApiHookMeta] {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error, ...meta }] = useLazyQuery(
    !labourType || isASearch
      ? SEARCH_LABOUR_RESOURCES
      : labourType === 'subcontractors'
      ? GET_SUBCONTRACTORS
      : GET_EMPLOYEES,

    {
      variables: {
        ...(isASearch ? { searchTerm } : {}),
        ...(labourType ? { labourType } : {}),
        input: { id, limit, offset },
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetch({
      variables: {
        searchTerm,

        ...(labourType ? { labourType } : {}),
        input: { id, limit, offset },
      },
    });
  }, [fetch, id, limit, offset, searchTerm]);

  const result =
    (labourType === 'subcontractors'
      ? data?.getSubcontractors
      : labourType === 'employees'
      ? data?.getEmployees
      : data?.searchLabourResources) || {};

  const results = result?.results || [];
  const count = result?.count || 0;

  return [
    results,
    {
      count,
      loading,
      error,
      ...meta,
    },
  ];
}

export function useLabourResource({
  id,
  labourType,
  searchTerm,
}: {
  id?: string;
  labourType?: 'employees' | 'subcontractors';
  searchTerm?: string;
}) {
  const [results, meta] = useLabourResources({ id, labourType, searchTerm });

  const [result] = results;

  return [result, meta];
}
