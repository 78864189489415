import { useEffect, useState } from 'react';
import { PolarArea } from 'react-chartjs-2';
import MultiSelectComboBox from '../../../../components/Inputs/MultiSelectComboBox';
import { Location } from '../../../../types/Outcomes';

export const LocationProgressChart = ({ data }: any) => {
  const [locations, setLocations] = useState<any>([]);

  const generateColors = (numColors: number) => {
    const colors = [];
    for (let index = 0; index < numColors; index++) {
      const hue = (index * 360) / numColors;
      const color = `hsla(${hue}, 70%, 50%, 0.5)`;
      colors.push(color);
    }
    return colors;
  };

  const allLocations = data
    ?.map((location: any) => ({
      id: location?.locationId,
      name: location?.locationName,
    }))
    ?.reduce(
      (acc: any, location: any) => {
        const { id, name } = location;
        if (!acc.tempIds[id]) {
          acc.result.push({ id, name });
          acc.tempIds[id] = true;
        }
        return acc;
      },
      { result: [], tempIds: {} }
    )?.result;

  const firstLocations = allLocations?.slice(0, 20);

  useEffect(() => {
    if (data?.length > 0) {
      setLocations(firstLocations);
    }
  }, [data]);

  useEffect(() => {
    if (locations?.length === 0) {
      setLocations(allLocations);
    }
  }, [locations]);

  const getChartData = (
    locationData: [],
    hiddenStatus: { [key: number]: boolean }
  ) => {
    const activityGroups = locationData
      ?.filter(
        (locationDatum: any) =>
          locationDatum.phaseName !== 'Unscoped Activities'
      )
      ?.reduce((acc: any, curr: any) => {
        acc[curr.activityGroupName] = acc[curr.activityGroupName] || [];
        acc[curr.activityGroupName].push(curr.averageProgress);
        return acc;
      }, {});

    const labels = activityGroups ? Object?.keys(activityGroups) : [];

    const data = labels?.map((label: string, index: number) => {
      if (hiddenStatus[index]) {
        return null;
      }
      const progresses = activityGroups[label];
      return (
        progresses.reduce((a: number, b: number) => a + b, 0) /
        progresses.length
      );
    });

    const colors = generateColors(labels?.length);

    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: colors,
        },
      ],
    };
  };

  const PolarChart = ({ location }: { location: Location }) => {
    const [hiddenStatus, setHiddenStatus] = useState<{
      [key: number]: boolean;
    }>({});

    const toggleDataset = (index: number) => {
      setHiddenStatus((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
    };

    const locationData = data?.filter(
      (d: any) =>
        d.locationId === location.id &&
        d.activityGroupName !== 'Unscoped Activities'
    );

    const chartData = getChartData(locationData, hiddenStatus);

    const activityGroupWithBgColour = locationData
      ?.filter(
        (locationDatum: any) =>
          locationDatum.activityGroupName !== 'Unscoped Activities'
      )
      ?.map((d: any, index: number) => {
        return {
          activityGroupName: d.activityGroupName,
          backgroundColor: generateColors(locationData?.length)[index],
        };
      });

    return (
      <div key={location.id} className="flex flex-col items-center">
        <h3 className="py-4">{location.name}</h3>
        <div className="grid grid-cols-2 md:grid-cols-2 xl:grid-cols-2 gap-x-4 mb-8 border p-4">
          {activityGroupWithBgColour?.map((d: any, index: number) => {
            return (
              <div key={d.activityGroupName}>
                <div
                  className="flex gap-x-2 cursor-pointer"
                  onClick={() => toggleDataset(index)}
                >
                  <span
                    className="mt-1 h-2 w-4"
                    style={{ backgroundColor: d.backgroundColor }}
                  />
                  <p
                    className={`text-xs ${
                      hiddenStatus[index] ? 'line-through' : 'none'
                    }`}
                  >
                    {d.activityGroupName}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div>
          <PolarArea
            data={chartData}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="overflow-x-auto max-h-[1200px] flex flex-col">
      <div className="my-2">
        <MultiSelectComboBox
          placeholder="Filter by location"
          id=""
          label="Filtering By"
          options={
            data
              ?.map((location: any) => ({
                id: location?.locationId,
                name: location?.locationName,
              }))
              ?.reduce(
                (acc: any, location: any) => {
                  const { id, name } = location;
                  if (!acc.tempIds[id]) {
                    acc.result.push({ id, name });
                    acc.tempIds[id] = true;
                  }
                  return acc;
                },
                { result: [], tempIds: {} }
              ).result
          }
          value={locations}
          handleChange={setLocations}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center gap-x-24">
        {locations?.map((location: any) => (
          <PolarChart key={location.id} location={location} />
        ))}
      </div>
    </div>
  );
};
