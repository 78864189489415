import { useEffect, useState } from 'react';
import ValidationMessage from '../Validation/ValidationMessage';
import { Option } from '../../types/QuestionTypes';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { twMerge } from 'tailwind-merge';
import {
  Combobox as UIComboBox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from '@headlessui/react';
import { AnchorProps } from '@headlessui/react/dist/internal/floating';

interface ComboBoxParams {
  anchor?: AnchorProps;
  classNames?: string;
  comboOptionClassNames?: string;
  description?: string;
  handleChange: (value: string) => void;
  id: string;
  inputTransformer?: (text: string) => string;
  isDisabled?: boolean;
  onChange?: (text: string) => void;
  options?: Option[];
  parentClassNames?: string;
  placeholder?: string;
  testId?: string;
  title?: string;
  validation?: any;
  value: any;
  valueTransformer?: (id: string) => string;
}

const ComboBox = ({
  anchor,
  classNames,
  comboOptionClassNames = '',
  description,
  handleChange,
  id,
  inputTransformer = (text) => text,
  isDisabled,
  onChange: onQueryChange = () => {},
  options,
  parentClassNames,
  placeholder = 'Search',
  testId = '',
  title,
  validation,
  value,
  valueTransformer,
}: ComboBoxParams) => {
  const [query, setQuery] = useState('');
  const filteredOptions =
    query === ''
      ? options
      : options?.filter((option) => {
          return (
            option.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
            option.detail?.toLowerCase()?.includes(query?.toLowerCase())
          );
        });

  useEffect(() => {
    onQueryChange(inputTransformer(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <div className={classNames}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {title ? title : null}
      </label>
      {validation ? <ValidationMessage message={validation} /> : null}
      <UIComboBox
        data-testid={testId}
        disabled={isDisabled}
        value={valueTransformer ? valueTransformer(value) : value}
        onChange={(option) => (isDisabled ? () => {} : handleChange(option.id))}
      >
        <div className={parentClassNames ?? 'relative mt-1'}>
          <ComboboxButton className="inset-y-0 right-0 flex items-center rounded-r-md w-full focus:outline-none">
            <ComboboxInput
              key={`input_${isDisabled}`}
              id={id}
              disabled={isDisabled}
              data-value={valueTransformer ? valueTransformer(value) : value}
              placeholder={placeholder}
              autoComplete="off"
              className={`w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 ${
                isDisabled ? 'bg-gray-200 opacity-75' : null
              } shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm`}
              onChange={(event: any) =>
                setQuery(inputTransformer(event.target.value))
              }
              displayValue={(selected: string) => inputTransformer(selected)}
            />

            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </ComboboxButton>

          {(filteredOptions || []).length > 0 && (
            <ComboboxOptions
              className={twMerge(
                'z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                comboOptionClassNames
              )}
              anchor={anchor}
            >
              {(filteredOptions || []).map((option) => (
                <ComboboxOption
                  key={option.id}
                  value={option}
                  className={({ active }: any) =>
                    twMerge(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-primary-600 text-gray-900' : 'text-gray-900'
                    )
                  }
                >
                  {({ active, selected }: any) => (
                    <>
                      <span
                        className={twMerge(
                          'block truncate',
                          selected && 'font-semibold'
                        )}
                      >
                        {option.name}
                      </span>
                      <span
                        className={twMerge(
                          'block truncate text-xs text-gray-500',
                          selected && 'font-semibold'
                        )}
                      >
                        {option.detail}
                      </span>

                      {selected && (
                        <span
                          className={twMerge(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-gray-900' : 'text-gray-900'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          )}
        </div>
      </UIComboBox>
      {description ? (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      ) : null}
    </div>
  );
};

export default ComboBox;
