import { gql } from '@apollo/client';

export const SAVE_ORGANISATION = gql`
  mutation saveOrganisation(
    $input: SaveOrganisationInput!
    $activeOrganisation: String!
  ) {
    saveOrganisation(input: $input, activeOrganisation: $activeOrganisation) {
      success
      message
      preSignedUrl
    }
  }
`;
