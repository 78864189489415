import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

import { Button } from '../../../components/Buttons/Button';
import { GET_GROUPS_SUMMARY } from '../../../graphql/queries/users';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { useFilter } from '../../../hooks/useFilter';

interface GroupListProps {
  showNewItemButton?: boolean;
  children?: React.ReactElement;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

const headerMapping = {
  name: 'Name',
  email: 'Email',
  status: 'Status',
  updatedAt: 'Updated At',
} as Record<string, any>;

const GroupsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={['firstName', 'lastName', 'id']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={[{ id: 'name', name: 'Name' }]}
    showFilterOptions
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const GroupListPage = ({
  children,
  showNewItemButton,
  onClickRow,
  filterIds = [],
}: GroupListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const { limit, offset, setOffset } = usePagination();

  const {
    filterText,
    setFilterText,
    filterDimension,
    setFilterDimension,
    filter,
  } = useFilter({ defaultFilterDimension: 'name' });

  const [fetch, { data }] = useLazyQuery(GET_GROUPS_SUMMARY, {
    variables: { input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: { input: { limit, offset, ...(filter ? { filter } : {}) } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, filter]);

  const groupResults = data?.getGroups;

  const totalPages = Math.ceil(groupResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  return (
    <div>
      <>
        {showNewItemButton ? (
          <div className="w-full flex justify-between items-center py-2">
            <h1 className="text-xl font-bold py-4">Groups</h1>
            <Button
              text="New Group"
              onClick={() => navigate(`/users/groups/new`)}
            />
          </div>
        ) : null}
        <p className="text-sm py-2 text-gray-600">
          Create and manage user groups and permissions.
        </p>
        <GroupsTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={groupResults?.count || 0}
          onClickRow={(id: any) => {
            if (onClickRow) {
              onClickRow(id, groupResults?.results);
            } else {
              navigate(`/users/groups/${id}`);
            }
          }}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={filterDimension}
          setSelectedFilterDimension={setFilterDimension}
          data={
            groupResults?.results.length > 0
              ? groupResults?.results
                  .filter((result: any) => !filterIds?.includes(result.id))
                  .map((result: any) => ({
                    id: result.id,
                    name: result.name,
                    updatedAt: result.updatedAt,
                  }))
              : [{}]
          }
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const GroupList = ({
  onClickRow,
  showNewItemButton = true,
  filterIds = [],
}: GroupListProps) => (
  <PaginationProvider>
    <GroupListPage
      onClickRow={onClickRow}
      showNewItemButton={showNewItemButton}
      filterIds={filterIds}
    />
  </PaginationProvider>
);
