import { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { GET_ROLES_SUMMARY } from '../../../graphql/queries/roles';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import moment from 'moment';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import NoData from '../../../components/NoData/NoData';
import LinkButton from '../../../components/Buttons/LinkButton';
import { OrangeBadge } from '../../../components/Badges/Badges';
import { useFilter } from '../../../hooks/useFilter';

interface RolesListProps {
  includeArchivedRoles?: boolean;
  showNewItemButton?: boolean;
  children?: React.ReactElement;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

const headerMapping = {
  name: 'Name',
  updatedAt: 'Updated At',
} as Record<string, any>;

export const RoleTable = ({
  includeArchivedRoles = false,
  filterIds = [],
  onClickRow,
}: RolesListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const { limit, offset, setOffset } = usePagination();

  const [searchParams] = useSearchParams();

  const {
    filterText,
    setFilterText,
    filterDimension,
    setFilterDimension,
    filter,
  } = useFilter({ defaultFilterDimension: 'name' });

  const [fetch, { data, loading }] = useLazyQuery(GET_ROLES_SUMMARY, {
    variables: { input: { limit, offset }, includeArchivedRoles },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: { input: { limit, offset, ...(filter ? { filter } : {}) } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, filter]);

  const roleResults = data?.getRoles;

  const totalPages = Math.ceil(roleResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const { userProfile } = useContext(UserProfileContext);

  return (
    <Table
      currentPage={Math.floor(offset / limit) + 1}
      totalPages={totalPages}
      itemsPerPage={limit}
      NoData={() => (
        <NoData loading={loading}>
          It looks like there are no roles configured for this organisation!
          {userProfile?.permissions.includes(Permission.UpdateRoles) ? (
            <LinkButton to="/labour/roles/new" target="_blank">
              Add a Role
            </LinkButton>
          ) : null}
        </NoData>
      )}
      totalResults={roleResults?.count || 0}
      onClickRow={(id: any) => {
        if (onClickRow) {
          onClickRow(id, roleResults?.results);
        } else {
          sessionStorage.setItem(
            'roles_table_query_params',
            searchParams.toString()
          );
          navigate(`/labour/roles/${id}`);
        }
      }}
      showFilterOptions={true}
      columnsToFilter={['firstName', 'lastName', 'id']}
      columnValueMapper={{
        updatedAt: (text: string) =>
          moment.unix(parseInt(text) / 1000).fromNow(),
      }}
      filterDimensions={[{ id: 'name', name: 'Name' }]}
      filterText={filterText}
      setFilterText={setFilterText}
      selectedFilterDimension={filterDimension}
      setSelectedFilterDimension={setFilterDimension}
      data={
        roleResults?.results.length > 0
          ? roleResults?.results
              .filter((result: any) => !filterIds?.includes(result.id))
              .map((result: any) => ({
                id: result.id,
                name: (
                  <>
                    {result.name}{' '}
                    {result.archivedAt ? <OrangeBadge text="Archived" /> : null}
                  </>
                ),
                updatedAt: result.updatedAt,
              }))
          : []
      }
      fetchPageOfDataForExport={async (
        pageLimit: number,
        pageOffset: number
      ) => {
        const { data } = await fetch({
          variables: {
            input: {
              limit: pageLimit,
              offset: pageOffset,
              ...(filter ? { filter } : {}),
            },
          },
        });
        setOffset(pageOffset);
        return data?.getRoles?.results ?? [];
      }}
      headerMapper={(text: string) => headerMapping[text]}
      onPageChange={handlePageChange}
    />
  );
};

export const RolesList = ({
  includeArchivedRoles = false,
  filterIds = [],
  onClickRow,
}: RolesListProps) => (
  <PaginationProvider>
    <RoleTable
      includeArchivedRoles={includeArchivedRoles}
      filterIds={filterIds}
      onClickRow={onClickRow}
    />
  </PaginationProvider>
);
