import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { v4 } from 'uuid';

import FormSubmit from '../../../components/Buttons/FormSubmit';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';

import { Button } from '../../../components/Buttons/Button';
import {
  DELETE_CUSTOM_OPTION_SET,
  SAVE_CUSTOM_OPTION_SET,
} from '../../../graphql/mutations/custom-data';
import { GET_CUSTOM_OPTIONS } from '../../../graphql/queries/custom-data';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import Switch from '../../../components/Inputs/Switch';
import { useToast } from '../../../context/ToastContext';
import { DeleteButton } from '../../../components/Deletion/DeleteButton';
import LinkButton from '../../../components/Buttons/LinkButton';

export const SaveCustomOptionsSet = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useMutation, useQuery } = useOrganisationAwareApollo();
  const [saveCustomOptionSet, { loading }] = useMutation(
    SAVE_CUSTOM_OPTION_SET
  );

  const [deleteCustomOptionSet] = useMutation(DELETE_CUSTOM_OPTION_SET);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { addToast } = useToast();

  const { userProfile } = useContext(UserProfileContext);

  const canSaveCustomOptions = userProfile?.permissions?.includes(
    Permission.UpdateCustomData
  );

  const isUpdate = !!id;

  const { data, refetch } = useQuery(GET_CUSTOM_OPTIONS, {
    variables: { input: { id } },
    skip: !isUpdate,
  });

  useEffect(() => {
    if (isUpdate) {
      refetch({ id });
    }
  }, [id, refetch, isUpdate]);

  const [customOptionSet] = data?.getCustomOptionSets?.results || [];

  const initialValues = customOptionSet?.name
    ? customOptionSet
    : {
        name: '', // unique identifier for the set
        assignedToAssignmentRole: false,
        assignedToProject: false,
        custom_options: [],
      };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Option Set Name is required'),
    custom_options: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Option Name is required'),
      })
    ),
  });

  const handleDelete = useCallback(async () => {
    try {
      const result = await deleteCustomOptionSet({
        variables: { id },
      });
      navigate('/custom/options');
      if (!result.data.deleteCustomOptionSet.success) {
        addToast('Error deleting custom option set', 'error');
      } else {
        addToast('Custom option set successfully deleted', 'success');
      }
    } catch (error) {
      console.error(error);
    }
  }, [addToast, deleteCustomOptionSet, id, navigate]);

  const onSubmit = useCallback(
    async (values: any, { setSubmitting }: any) => {
      try {
        await saveCustomOptionSet({
          variables: {
            id: values?.id,
            input: {
              name: values.name,
              assignedToProject: values.assignedToProject,
              assignedToAssignment: values.assignedToAssignment,
              assignedToAssignmentRole: values.assignedToAssignmentRole,
              custom_options: values.custom_options?.map((option: any) => ({
                ...option,
                name: option.name,
                value: option.name,
                isNew: undefined,
              })),
            },
          },
        });
        addToast('Option Set saved', 'success');
        setSubmitting(false);
        navigate('/custom/options'); // Adjust based on your route
      } catch (error) {
        console.error(error);
        addToast('Error saving Option Set', 'error');
      }
    },
    [addToast, navigate, saveCustomOptionSet]
  );

  const tableQueryParams =
    sessionStorage.getItem('custom_options_table_query_params') || '';

  return (
    <>
      <LinkButton
        style={{
          backgroundColor: 'transparent',
          color: 'black',
          boxShadow: 'none',
          borderRadius: 0,
        }}
        to={`/custom/options${tableQueryParams ? `?${tableQueryParams}` : ''}`}
      >
        {`< Back to Custom Options`}
      </LinkButton>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ isValid, values, setFieldValue }) => (
          <Form className="space-y-6 mx-8 my-8">
            {isUpdate ? (
              <>
                <h1 className="text-3xl font-extrabold text-gray-900">
                  {values?.name}
                </h1>
                <p className="mt-1 text-sm text-gray-500">ID: {id}</p>
              </>
            ) : (
              <h1 className="text-3xl font-extrabold text-gray-900">
                Create Option Set
              </h1>
            )}
            <div>
              <label
                htmlFor="name"
                className="block text-gray-700 font-bold mb-2"
              >
                Option Set Name
              </label>
              <Field
                id="name"
                name="name"
                type="text"
                disabled={!canSaveCustomOptions}
                autoComplete="off"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
              />
              <ErrorMessage
                name="name"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
            <h1 className="text-lg font-extrabold text-gray-900">Options</h1>
            <FieldArray name="custom_options">
              {({ remove, push }) => (
                <>
                  <div className="flex flex-col gap-y-2">
                    {values.custom_options.length > 0 &&
                      values.custom_options.map(
                        (option: any, index: number) => (
                          <>
                            <div
                              className="ml-4 row flex w-full justify-start items-center gap-x-2"
                              key={index}
                            >
                              <div className="flex gap-x-2 w-full">
                                <Field
                                  name={`custom_options.${index}.name`}
                                  placeholder="Option Name"
                                  disabled={!canSaveCustomOptions}
                                  type="text"
                                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                />
                              </div>
                              <div className="col">
                                {canSaveCustomOptions && option.isNew ? (
                                  <Button
                                    type="button"
                                    text="Remove"
                                    style={{ backgroundColor: 'black' }}
                                    onClick={() => remove(index)}
                                  />
                                ) : null}
                              </div>
                            </div>
                            <ErrorMessage
                              name={`custom_options.${index}.name`}
                              component="div"
                              className="text-red-500 text-sm mt-1"
                            />
                          </>
                        )
                      )}
                  </div>
                  <hr className="my-2" />
                  <Button
                    type="button"
                    text="Add Option"
                    isDisabled={!canSaveCustomOptions}
                    onClick={() => push({ id: v4(), name: '', isNew: true })}
                  />
                </>
              )}
            </FieldArray>
            <div className="flex items-end w-full">
              <Switch
                text="Project"
                enabled={values.assignedToProject}
                isDisabled={!canSaveCustomOptions}
                handleChange={() => {
                  const isEnabled = !values.assignedToProject;
                  setFieldValue('assignedToProject', isEnabled);
                }}
              />
            </div>
            <div className="flex items-end">
              <Switch
                text="Assignment Role"
                enabled={values.assignedToAssignmentRole}
                isDisabled={!canSaveCustomOptions}
                handleChange={() => {
                  const isEnabled = !values.assignedToAssignmentRole;
                  setFieldValue('assignedToAssignmentRole', isEnabled);
                }}
              />
              <ErrorMessage
                name="assignedToAssignmentRole"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
            <div className={`flex ${id ? 'justify-between' : 'justify-end'}`}>
              {id && (
                <DeleteButton
                  isDisabled={
                    !userProfile?.permissions?.includes(
                      Permission.DeleteCustomData
                    )
                  }
                  modalText="Are you sure you want to delete this custom option set? You will not be able to restore it once deleted."
                  modalTitle={'Delete Custom Option Set'}
                  onDelete={async () => {
                    await handleDelete();
                  }}
                >
                  Delete
                </DeleteButton>
              )}
              <FormSubmit
                disabled={!isValid || !canSaveCustomOptions}
                isLoading={loading}
                submitText="Save"
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
