import ValidationMessage from '../Validation/ValidationMessage';
import Switch from './Switch';
import { isArray } from '@apollo/client/utilities';

interface CheckboxOption {
  name: string;
  value: string;
}

type CheckBoxGroupProps = {
  handleChange: (props: any) => void;
  id: string;
  name: string;
  options: { id: string; name: string; value: any }[];
  title?: string;
  validation?: string;
  value: string[];
};

const CheckBoxGroup = ({
  handleChange,
  id,
  options,
  title,
  validation,
  value,
}: CheckBoxGroupProps) => {
  const handleCheckboxChange = (optionId: string, isChecked: boolean) => {
    if (isChecked) {
      if (isArray(value)) {
        handleChange([...value, optionId]);
      } else {
        handleChange([optionId]);
      }
    } else {
      handleChange(value?.filter((id: string) => id !== optionId));
    }
  };

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      <div className="mt-2 space-y-2">
        {options.length > 1 ? (
          <Switch
            classNames="pb-2"
            text="Select All"
            enabled={options?.every((option: CheckboxOption) =>
              value?.includes(option.value)
            )}
            handleChange={(isChecked) => {
              if (isChecked) {
                handleChange(
                  options?.map((option: CheckboxOption) => option.value)
                );
              } else {
                handleChange([]);
              }
            }}
          />
        ) : null}
        {options.map((option: CheckboxOption) => (
          <Switch
            key={option.value}
            text={option.name}
            enabled={value?.includes(option.value)}
            handleChange={(isChecked) =>
              handleCheckboxChange(option.value, isChecked)
            }
          />
        ))}
      </div>

      {validation ? <ValidationMessage message={validation} /> : null}
    </div>
  );
};

export default CheckBoxGroup;
