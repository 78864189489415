import { AnchorProps } from '@headlessui/react/dist/internal/floating';
import ComboBox from '../Inputs/ComboBox';

interface SearchBoxProps {
  placeholder?: string;
  results?: any[];
  setSearchTerm?: (value: string) => void;
  setSelected?: (value: any) => void;
  selected?: any;
  parentClassNames?: string;
  comboOptionClassNames?: string;
  anchor?: AnchorProps;
}

export const SearchBox = ({
  placeholder = 'Search',
  results = [],
  setSearchTerm = () => {},
  setSelected = () => {},
  selected,
  parentClassNames,
  comboOptionClassNames,
  anchor,
}: SearchBoxProps) => {
  return (
    <div className="w-full">
      <ComboBox
        testId="search"
        placeholder={placeholder}
        id="Search"
        options={results?.map((result: any) => ({
          id: result.id,
          name: result.name,
          detail: result.detail,
        }))}
        onChange={(value) => setSearchTerm(value)}
        parentClassNames={parentClassNames}
        comboOptionClassNames={comboOptionClassNames}
        anchor={anchor}
        handleChange={setSelected}
        value={selected?.name ?? ''}
        validation={undefined}
      />
    </div>
  );
};
