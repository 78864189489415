// @ts-ignore
import { useEffect, useState } from 'react';
import {
  BlueBadge,
  GrayBadge,
  GreenBadge,
  RedBadge,
  YellowBadge,
} from '../../../../components/Badges/Badges';
import MultiSelectComboBox from '../../../../components/Inputs/MultiSelectComboBox';
import HoursChart from './../components/HoursChart';
import {
  calculateAdjustedForecast,
  calculateEstimatedCompletionTime,
} from './../utils/utils';
import Switch from '../../../../components/Inputs/Switch';
import { Button } from '../../../../components/Buttons/Button';

const TimeSectionRow = ({
  label,
  progress,
  originalEstimate,
  totalSoFar,
  personHoursSoFar,
  expectedTotal,
  remainingHours,
  efficiency,
  isUnscoped,
}: any) => (
  <div
    className={
      isUnscoped
        ? 'grid grid-cols-3 gap-2 border-b border-gray-300 py-2 items-center justify-center text-sm'
        : 'grid grid-cols-8 gap-2 border-b border-gray-300 py-2 items-center justify-center text-sm'
    }
  >
    <span className={isUnscoped ? 'text-sm' : 'text-sm col-span-2'}>
      {label}
    </span>

    {!isUnscoped && progress !== undefined && (
      <div className="flex items-center justify-center">
        <div className="relative w-full bg-gray-200 rounded-full h-4">
          <div
            className="bg-green-500 h-4 rounded-full"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <span className="ml-2 text-xs">{progress}%</span>
      </div>
    )}

    <span className="text-center">{totalSoFar}</span>
    {personHoursSoFar ? (
      <span className="text-center">{personHoursSoFar}</span>
    ) : null}

    {!isUnscoped && <span className="text-center">{originalEstimate}</span>}

    {!isUnscoped && <span className="text-center">{expectedTotal}</span>}

    {!isUnscoped && <span className="text-center">{remainingHours}</span>}

    {!isUnscoped && <span className="text-center">{efficiency}</span>}
  </div>
);

const EfficiencyBadge = ({
  forecast,
  averageProgress,
  actualHours,
  predictedHours,
}: any) => {
  if (!averageProgress || averageProgress === 0) {
    if (actualHours > 0) {
      return <RedBadge text={`▼ ${Math.abs(predictedHours).toFixed(2)}`} />;
    }
    return <YellowBadge text={`No Progress`} />;
  }

  if (actualHours > 0) {
    if (forecast > 0) {
      return averageProgress > 0 ? (
        <GreenBadge text={`▲ ${forecast.toFixed(2)}`} />
      ) : (
        <YellowBadge text={`↔ ${forecast.toFixed(2)}`} />
      );
    } else if (forecast < 0) {
      return <RedBadge text={`▼ ${Math.abs(forecast).toFixed(2)}`} />;
    } else {
      return <YellowBadge text={`↔ ${forecast.toFixed(2)}`} />;
    }
  }
  return averageProgress > 0 ? (
    <GreenBadge text={`▲ ${forecast.toFixed(2)}`} />
  ) : (
    <YellowBadge text={`No Progress`} />
  );
};

export const OutcomeLocationsTable = ({ data, headings }: any) => {
  const [locations, setLocations] = useState<any>([]);
  const [includeUnscopedActivities, setIncludeUnscopedActivities] =
    useState(true);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>(null);
  const [sortedData, setSortedData] = useState<any>(data); // Initialize with original data

  useEffect(() => {
    if (!sortConfig) {
      setSortedData(data);
      return;
    }

    const sorted = [...data].sort((a: any, b: any) => {
      if (a[sortConfig.key] < b[sortConfig.key])
        return sortConfig.direction === 'ascending' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key])
        return sortConfig.direction === 'ascending' ? 1 : -1;
      return 0;
    });

    setSortedData(sorted); // Set sorted data when sortConfig is applied
  }, [sortConfig, data]);

  useEffect(() => {
    if (data?.length > 0) {
      const firstLocations = data
        .slice(0, 5)
        .map((location: any) => ({
          id: location?.locationId,
          name: location?.locationName,
        }))
        ?.reduce(
          (acc: any, location: any) => {
            const { id, name } = location;
            if (!acc.tempIds[id]) {
              acc.result.push({ id, name });
              acc.tempIds[id] = true;
            }
            return acc;
          },
          { result: [], tempIds: {} }
        ).result;
      setLocations(firstLocations);
    }
  }, [data]);

  const formatHours = (hours: any) => hours?.toFixed(2);

  const handleSort = (key: string) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }

    setSortConfig({ key, direction });
  };

  const [showHoursActivities, setShowHoursActivities] = useState(false);
  const [showManHoursActivities, setShowManHoursActivities] = useState(false);
  const [showUnscopedActivities, setShowUnscopedActivities] = useState(false);

  return (
    <div className="overflow-x-auto max-h-[1200px] relative">
      <div className="h-[500px] relative w-full">
        <HoursChart
          data={sortedData
            ?.filter((locationDatum: any) =>
              locations.length > 0
                ? locations
                    .map((location: any) => location.id)
                    ?.includes(locationDatum.locationId)
                : true
            )
            .filter((locationDatum: any) =>
              includeUnscopedActivities
                ? true
                : locationDatum.phaseName !== 'Unscoped'
            )
            .map((datum: any) => ({
              ...datum,
              actualManHours: datum.actualPersonHours,
              completionHours: calculateEstimatedCompletionTime(
                datum.estimatedHours,
                datum.actualHours,
                datum.averageProgress ?? 0
              ),
              completionManHours: calculateEstimatedCompletionTime(
                datum.estimatedPersonHours,
                datum.actualPersonHours,
                datum.averageProgress ?? 0
              ),
            }))}
        />
      </div>

      <div className="my-2">
        <MultiSelectComboBox
          placeholder="Filter by location"
          id=""
          label="Filtering By"
          options={
            data
              ?.map((location: any) => ({
                id: location?.locationId,
                name: location?.locationName,
              }))
              ?.reduce(
                (acc: any, location: any) => {
                  const { id, name } = location;
                  if (!acc.tempIds[id]) {
                    acc.result.push({ id, name });
                    acc.tempIds[id] = true;
                  }
                  return acc;
                },
                { result: [], tempIds: {} }
              ).result
          }
          value={locations}
          handleChange={setLocations}
        />
      </div>

      <div className="my-4">
        <div className="w-full flex gap-x-2">
          <Button
            text={
              !showHoursActivities
                ? 'Show Hours Analysis by Activity Group & Location'
                : 'Hide Hours Analysis by Activity Group & Location'
            }
            onClick={() => setShowHoursActivities(!showHoursActivities)}
          />
          <Button
            text={
              !showManHoursActivities
                ? 'Show Man Hours Analysis by Activity Group & Location'
                : 'Hide Man Hours Analysis by Activity Group & Location'
            }
            onClick={() => setShowManHoursActivities(!showManHoursActivities)}
          />
          <Button
            text={
              !includeUnscopedActivities
                ? 'Show Unscoped Analysis by Location'
                : 'Hide Unscoped Analysis by Location'
            }
            onClick={() =>
              setIncludeUnscopedActivities(!includeUnscopedActivities)
            }
          />
        </div>
        {showHoursActivities ? (
          <>
            <h3 className="text-md my-2 font-semibold">Scoped Activities</h3>

            <div className="grid grid-cols-8 gap-2 font-bold bg-gray-50 text-sm text-center p-2 rounded-lg">
              <span
                className="text-left col-span-2 select-none"
                role="button"
                onClick={() => handleSort('locationName')}
              >
                Location - Activity & Phases (Hours){' '}
                {sortConfig?.key === 'locationName'
                  ? `${sortConfig.direction === 'ascending' ? '▲' : '▼'}`
                  : undefined}
              </span>
              <span
                className="select-none"
                role="button"
                onClick={() => handleSort('averageProgress')}
              >
                Progress{' '}
                {sortConfig?.key === 'averageProgress'
                  ? `${sortConfig.direction === 'ascending' ? '▲' : '▼'}`
                  : undefined}
              </span>
              <span
                className="select-none"
                role="button"
                onClick={() => handleSort('actualHours')}
              >
                Total Hours{' '}
                {sortConfig?.key === 'actualHours'
                  ? `${sortConfig.direction === 'ascending' ? '▲' : '▼'}`
                  : undefined}
              </span>
              <span
                className="select-none"
                role="button"
                onClick={() => handleSort('estimatedHours')}
              >
                Original Estimate{' '}
                {sortConfig?.key === 'estimatedHours'
                  ? `${sortConfig.direction === 'ascending' ? '▲' : '▼'}`
                  : undefined}
              </span>
              <span
                className="select-none"
                role="button"
                onClick={() => handleSort('expectedTotal')}
              >
                Expected Total{' '}
                {sortConfig?.key === 'expectedTotal'
                  ? `${sortConfig.direction === 'ascending' ? '▲' : '▼'}`
                  : undefined}
              </span>
              <span
                className="select-none"
                role="button"
                onClick={() => handleSort('remainingHours')}
              >
                Remaining Hours{' '}
                {sortConfig?.key === 'remainingHours'
                  ? `${sortConfig.direction === 'ascending' ? '▲' : '▼'}`
                  : undefined}
              </span>
              <span
                className="select-none"
                role="button"
                onClick={() => handleSort('efficiency')}
              >
                Efficiency{' '}
                {sortConfig?.key === 'efficiency'
                  ? `${sortConfig.direction === 'ascending' ? '▲' : '▼'}`
                  : undefined}
              </span>
            </div>
            {sortedData
              ?.filter((location: any) => location.phaseName !== 'Unscoped')
              ?.filter((locationDatum: any) =>
                locations.length > 0
                  ? locations
                      .map((location: any) => location.id)
                      ?.includes(locationDatum.locationId)
                  : true
              )
              .map((location: any, index: number) => {
                const predictedCompletionHours =
                  calculateEstimatedCompletionTime(
                    location.estimatedHours,
                    location.actualHours,
                    location.averageProgress ?? 0
                  );
                const weightedForecast = calculateAdjustedForecast(
                  location.estimatedHours,
                  location.actualHours,
                  location.averageProgress ?? 0
                );

                return (
                  <TimeSectionRow
                    key={index}
                    label={
                      <>
                        <i className="not-italic bg-blue-50 text-blue-800 px-2 rounded-md">
                          {location?.locationName}
                        </i>
                        <p className="text-sm mt-1">
                          {location.activityGroupName} ({location.phaseName})
                        </p>
                      </>
                    }
                    progress={location.averageProgress}
                    totalSoFar={formatHours(location.actualHours)}
                    originalEstimate={formatHours(location.estimatedHours)}
                    expectedTotal={formatHours(predictedCompletionHours)}
                    remainingHours={formatHours(
                      predictedCompletionHours - location.actualHours
                    )}
                    efficiency={
                      <EfficiencyBadge
                        forecast={weightedForecast}
                        predictedHours={predictedCompletionHours}
                        averageProgress={location.averageProgress ?? 0}
                        actualHours={location.actualHours ?? 0}
                      />
                    }
                    isUnscoped={false}
                  />
                );
              })}
          </>
        ) : null}

        {showManHoursActivities ? (
          <>
            <div className="grid grid-cols-8 gap-2 mt-4 font-bold bg-gray-50 text-sm text-center p-2 rounded-lg">
              <span
                className="text-left col-span-2 select-none"
                role="button"
                onClick={() => handleSort('locationName')}
              >
                Location - Activity & Phases (Man Hours){' '}
                {sortConfig?.key === 'locationName'
                  ? `${sortConfig.direction === 'ascending' ? '▲' : '▼'}`
                  : undefined}
              </span>
              <span
                className="select-none"
                role="button"
                onClick={() => handleSort('averageProgress')}
              >
                Progress{' '}
                {sortConfig?.key === 'averageProgress'
                  ? `${sortConfig.direction === 'ascending' ? '▲' : '▼'}`
                  : undefined}
              </span>
              <span
                className="select-none"
                role="button"
                onClick={() => handleSort('actualHours')}
              >
                Total Hours{' '}
                {sortConfig?.key === 'actualHours'
                  ? `${sortConfig.direction === 'ascending' ? '▲' : '▼'}`
                  : undefined}
              </span>
              <span
                className="select-none"
                role="button"
                onClick={() => handleSort('estimatedHours')}
              >
                Original Estimate{' '}
                {sortConfig?.key === 'estimatedHours'
                  ? `${sortConfig.direction === 'ascending' ? '▲' : '▼'}`
                  : undefined}
              </span>
              <span
                className="select-none"
                role="button"
                onClick={() => handleSort('expectedTotal')}
              >
                Expected Total{' '}
                {sortConfig?.key === 'expectedTotal'
                  ? `${sortConfig.direction === 'ascending' ? '▲' : '▼'}`
                  : undefined}
              </span>
              <span
                className="select-none"
                role="button"
                onClick={() => handleSort('remainingHours')}
              >
                Remaining Hours{' '}
                {sortConfig?.key === 'remainingHours'
                  ? `${sortConfig.direction === 'ascending' ? '▲' : '▼'}`
                  : undefined}
              </span>
              <span
                className="select-none"
                role="button"
                onClick={() => handleSort('efficiency')}
              >
                Efficiency{' '}
                {sortConfig?.key === 'efficiency'
                  ? `${sortConfig.direction === 'ascending' ? '▲' : '▼'}`
                  : undefined}
              </span>
            </div>
            {sortedData
              ?.filter((location: any) => location.phaseName !== 'Unscoped')
              ?.filter((locationDatum: any) =>
                locations.length > 0
                  ? locations
                      .map((location: any) => location.id)
                      ?.includes(locationDatum.locationId)
                  : true
              )
              .map((location: any, index: number) => {
                const predictedManHours = calculateEstimatedCompletionTime(
                  location.estimatedPersonHours,
                  location.actualPersonHours,
                  location.averageProgress ?? 0
                );
                const weightedManHoursForecast = calculateAdjustedForecast(
                  location.estimatedPersonHours,
                  location.actualPersonHours,
                  location.averageProgress ?? 0
                );

                return (
                  <TimeSectionRow
                    key={index}
                    label={
                      <>
                        <i className="not-italic bg-blue-50 text-blue-800 px-2 rounded-md">
                          {location?.locationName}
                        </i>
                        <p className="text-sm mt-1">
                          {location.activityGroupName} ({location.phaseName})
                        </p>
                      </>
                    }
                    progress={location.averageProgress}
                    totalSoFar={formatHours(location.actualPersonHours)}
                    originalEstimate={formatHours(
                      location.estimatedPersonHours
                    )}
                    expectedTotal={formatHours(predictedManHours)}
                    remainingHours={formatHours(
                      predictedManHours - location.actualPersonHours
                    )}
                    efficiency={
                      <EfficiencyBadge
                        predictedHours={predictedManHours}
                        forecast={weightedManHoursForecast}
                        averageProgress={location.averageProgress ?? 0}
                        actualHours={location.actualPersonHours ?? 0}
                      />
                    }
                    isUnscoped={false}
                  />
                );
              })}
          </>
        ) : null}
      </div>

      {/* Table for unscoped activities */}
      {includeUnscopedActivities && (
        <div className="my-4">
          <h3 className="text-md my-2 font-semibold">Unscoped Activities</h3>
          <div className="grid grid-cols-3 gap-2 font-bold bg-gray-50 p-2 text-xs text-center rounded-lg">
            <span
              className="text-left select-none"
              role="button"
              onClick={() => handleSort('locationName')}
            >
              Location - Activity{' '}
              {sortConfig?.key === 'locationName'
                ? `${sortConfig.direction === 'ascending' ? '▲' : '▼'}`
                : undefined}
            </span>
            <span
              className="select-none"
              role="button"
              onClick={() => handleSort('actualHours')}
            >
              Total Hours{' '}
              {sortConfig?.key === 'actualHours'
                ? `${sortConfig.direction === 'ascending' ? '▲' : '▼'}`
                : undefined}
            </span>
            <span
              className="select-none"
              role="button"
              onClick={() => handleSort('actualPersonHours')}
            >
              Total Man Hours{' '}
              {sortConfig?.key === 'actualPersonHours'
                ? `${sortConfig.direction === 'ascending' ? '▲' : '▼'}`
                : undefined}
            </span>
          </div>
          {sortedData
            ?.filter((location: any) => location.phaseName === 'Unscoped')
            ?.filter((locationDatum: any) =>
              locations.length > 0
                ? locations
                    .map((location: any) => location.id)
                    ?.includes(locationDatum.locationId)
                : true
            )
            .map((location: any, index: number) => (
              <TimeSectionRow
                key={index}
                label={
                  <>
                    <i className="not-italic bg-blue-50 text-blue-800 px-2 rounded-md">
                      {location?.locationName}
                    </i>
                    <p className="text-sm mt-1">
                      {location.activityGroupName} ({location.phaseName})
                    </p>
                  </>
                }
                totalSoFar={formatHours(location.actualHours)}
                personHoursSoFar={formatHours(location.actualPersonHours)}
                isUnscoped={true}
              />
            ))}
        </div>
      )}
    </div>
  );
};
