import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Modal } from '../Dialogue/ModalDialogue';

export const DeletionDialogue = ({
  children,
  title,
  isDeleting,
  onDelete = () => Promise.resolve(true),
  setShowModal = () => {},
  showModal,
}: any) => {
  return (
    <Modal
      completeButtonText={
        isDeleting ? (
          <div className="border-dashed border-2 rounded-full animate-spin w-5 h-5" />
        ) : (
          'Confirm Deletion'
        )
      }
      completeButtonClassName="bg-red-600 hover:bg-red-500"
      showModal={showModal}
      setShowModal={setShowModal}
      onComplete={onDelete}
      title={title}
      iconClassName="bg-red-100"
      icon={
        <ExclamationTriangleIcon
          className="h-6 w-6 text-red-600"
          aria-hidden="true"
        />
      }
    >
      <p className="text-sm text-gray-500">{children}</p>
    </Modal>
  );
};
