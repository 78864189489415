import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePagination } from '../context/PaginationContext';
import { useDebounce } from './useDebounce';

interface FilterProps {
  defaultFilterDimension: string;
  pageUrl?: string;
  useUrl?: boolean;
}

export function useFilter({
  defaultFilterDimension,
  useUrl = true,
}: FilterProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setOffset } = usePagination();

  const urlFilterText = useMemo(
    () => (useUrl ? searchParams.get('filterText') || '' : ''),
    [searchParams, useUrl]
  );

  const urlFilterDimension = useMemo(
    () =>
      useUrl
        ? searchParams.get('filterDimension') || defaultFilterDimension
        : defaultFilterDimension,
    [defaultFilterDimension, searchParams, useUrl]
  );

  const [filterText, setFilterText] = useState(urlFilterText);
  const [filterDimension, setFilterDimension] = useState(urlFilterDimension);
  const [debouncedFilterText, setDebouncedFilterText] = useState(filterText);

  const updateUrlOrDebouncedFilterText = useCallback(
    (filterText: string, urlFilterText: string, useUrl: boolean) => {
      if (useUrl) {
        if (urlFilterText !== filterText) {
          setOffset(0);
          setDebouncedFilterText(filterText);
        }

        setSearchParams((searchParams) => {
          searchParams.set('filterText', filterText);
          if (urlFilterText !== filterText) {
            searchParams.set('page', '1');
          }
          return searchParams;
        });
      } else {
        setOffset(0);
        setDebouncedFilterText(filterText);
      }
    },
    [setOffset, setSearchParams]
  );

  const debouncedUpdateUrlOrState = useDebounce(
    updateUrlOrDebouncedFilterText,
    600
  );

  const updateFilterText = useCallback(
    (text: string) => {
      setFilterText(text);
      debouncedUpdateUrlOrState(text, urlFilterText, useUrl);
    },
    [debouncedUpdateUrlOrState, urlFilterText, useUrl]
  );

  const updateFilterDimension = useCallback(
    (dimension: string) => {
      if (dimension !== filterDimension) {
        if (useUrl) {
          setSearchParams((searchParams) => {
            searchParams.set('filterDimension', dimension);
            searchParams.set('page', '1');
            return searchParams;
          });
        } else {
          setOffset(0);
        }

        setFilterDimension(dimension);
      }
    },
    [filterDimension, setOffset, setSearchParams, useUrl]
  );

  const filter = useMemo(
    () =>
      urlFilterText || debouncedFilterText
        ? {
            filters: [
              {
                field: filterDimension,
                stringFilters: [
                  { ilike: useUrl ? urlFilterText : debouncedFilterText },
                ],
              },
            ],
          }
        : null,
    [urlFilterText, debouncedFilterText, filterDimension, useUrl]
  );

  return {
    filterText,
    setFilterText: updateFilterText,
    filterDimension,
    setFilterDimension: updateFilterDimension,
    filter,
  };
}
