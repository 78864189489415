// @ts-nocheck
import { ArrowsPointingInIcon } from '@heroicons/react/24/outline';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/solid';
import { useContext, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { Button } from '../../../components/Buttons/Button';
import MultiSelectComboBox from '../../../components/Inputs/MultiSelectComboBox';
import NumericInput from '../../../components/Inputs/NumericInput';
import Switch from '../../../components/Inputs/Switch';
import TextInput from '../../../components/Inputs/TextInput';
import { OutcomeBuilder } from '../../../components/OutcomeConfiguration/OutcomeBuilder';
import { SAVE_PROJECT_OUTCOME } from '../../../graphql/mutations/projects';
import {
  GET_PROJECT_LOCATIONS,
  GET_PROJECT_OUTCOMES,
  GET_PROJECT_TEAMS,
} from '../../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { ActivityGroup, Outcome, Phase } from '../../../types/Outcomes';
import { useToast } from '../../../context/ToastContext';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import ComboBox from '../../../components/Inputs/ComboBox';
import MultiSelectTable from '../../../components/Inputs/MultiSelectTable';
import LinkButton from '../../../components/Buttons/LinkButton';
import { useProject } from '../../../hooks/api/projects/useProjects';

type OutcomeLocation = {
  id: string;
  name: string;
};

type LocationGroup = {
  id: string;
  name: string;
  locations: OutcomeLocation[];
};

type LocationTimingGroup = {
  id: string;
  locationGroupId: string;
  name: string;
  hoursEstimate: number;
  personHoursEstimate: number;
};

interface PhaseConfigProps {
  index: number;
  phase: Phase;
  projectId: string;
  outcomeId?: string;
  outcomeLocations: OutcomeLocation[];
  locationGroups: LocationGroup[];
  timings: Record<string, any>;
  setTimings: (timings: Record<string, any>) => void;
}

interface ActivityGroupConfigProps {
  index: number;
  phase: Phase;
  group: ActivityGroup;
  projectId: string;
  outcomeId?: string;
  outcomeLocations: OutcomeLocation[];
  locationGroups: LocationGroup[];
  timings: Record<string, any>;
  setTimings: (timings: Record<string, any>) => void;
}

const LocationGroupConfig = ({
  locationGroups,
  setLocationGroups,
  projectLocations,
}: {
  locationGroups: LocationGroup[];
  setLocationGroups: (groups: LocationGroup[]) => void;
  projectLocations: OutcomeLocation[];
}) => {
  const [groupName, setGroupName] = useState('');
  const [groupLocations, setGroupLocations] = useState<OutcomeLocation[]>([]);

  const addNewLocationGroup = () => {
    const newGroup: LocationGroup = {
      id: v4(),
      name: groupName,
      locations: groupLocations,
    };

    setLocationGroups([...locationGroups, newGroup]);
    setGroupName('');
    setGroupLocations([]);
  };

  const handleGroupUpdate = (
    groupId: string,
    updatedFields: Partial<LocationGroup>
  ) => {
    setLocationGroups(
      locationGroups.map((lg) =>
        lg.id === groupId ? { ...lg, ...updatedFields } : lg
      )
    );
  };

  const removeLocationGroup = (groupId: string) => {
    setLocationGroups(locationGroups.filter((group) => group.id !== groupId));
  };

  return (
    <div className="flex flex-col gap-y-4 px-4 py-4">
      <h3 className="mt-4">Existing Location Groups:</h3>
      {!locationGroups || locationGroups?.length === 0 ? (
        <div className="text-sm my-2">No Location Groups</div>
      ) : null}
      {locationGroups.map((group) => (
        <div key={group.id} className="border p-2 mb-2">
          <TextInput
            id={`group-name-${group.id}`}
            name="groupName"
            title="Group Name"
            placeholder="Name the group"
            value={group.name}
            handleChange={(name) => handleGroupUpdate(group.id, { name })}
          />
          <MultiSelectComboBox
            testId="location-selection"
            placeholder="Select Locations"
            id="Search"
            title="Locations"
            options={projectLocations.map((loc) => ({
              id: loc.id,
              name: loc.name,
            }))}
            handleChange={(locations) =>
              handleGroupUpdate(group.id, { locations })
            }
            value={group.locations ?? []}
          />
          <div className="my-2" />
          <Button
            text="Remove Group"
            onClick={() => removeLocationGroup(group.id)}
          />
        </div>
      ))}
      <h3>Add New Location Group</h3>
      <TextInput
        id="new-group-name"
        name="newGroupName"
        title="Group Name"
        placeholder="Name the group"
        value={groupName}
        handleChange={setGroupName}
      />
      <MultiSelectComboBox
        testId="location-selection"
        placeholder="Select Locations for Group"
        id="Search"
        title="Locations"
        options={projectLocations.map((loc) => ({
          id: loc.id,
          name: loc.name,
        }))}
        handleChange={setGroupLocations}
        value={groupLocations}
      />
      <Button text="Add Location Group" onClick={addNewLocationGroup} />
    </div>
  );
};

const ActivityGroupConfig = ({
  index,
  group,
  projectId,
  outcomeId,
  outcomeLocations,
  locationGroups,
  timings,
  phase,
  setTimings,
}: ActivityGroupConfigProps) => {
  const { useLazyQuery } = useOrganisationAwareApollo();
  const [showLocationConfig, setLocationConfig] = useState(false);
  const thisActivityTiming = timings?.[phase.id]?.[group.id];
  const [useLocationGroups, setUseLocationGroups] = useState(
    thisActivityTiming?.useLocationGroups ?? false
  );

  const [fetchProjectLocations, { data: projectLocations }] = useLazyQuery(
    GET_PROJECT_LOCATIONS,
    {
      variables: { projectId, input: { limit: 2000 } },
      fetchPolicy: 'network-only',
    }
  );

  const { userProfile } = useContext(UserProfileContext);

  const [appliesToSpecificLocations, setAppliesToSpecificLocations] = useState(
    thisActivityTiming?.appliesToSpecificLocations
  );
  const [locations, setLocations] = useState<any[]>(
    thisActivityTiming?.locations ?? []
  );
  const [hoursEstimate, setHoursEstimate] = useState(
    thisActivityTiming?.hoursEstimate ?? 0
  );
  const [personHoursEstimate, setPersonHoursEstimate] = useState(
    thisActivityTiming?.personHoursEstimate ?? 0
  );
  const [locationTimingGroups, setLocationTimingGroups] = useState<
    LocationTimingGroup[]
  >(thisActivityTiming?.locationTimingGroups ?? []);

  useEffect(() => {
    fetchProjectLocations();
  }, [fetchProjectLocations, outcomeId, projectId]);

  const handleUpdate = (updatedFields: any) => {
    unstable_batchedUpdates(() => {
      setHoursEstimate(updatedFields.hoursEstimate || hoursEstimate);
      setPersonHoursEstimate(
        updatedFields.personHoursEstimate || personHoursEstimate
      );
      setAppliesToSpecificLocations(
        updatedFields.appliesToSpecificLocations ?? appliesToSpecificLocations
      );
      setLocations(updatedFields.locations || locations);
      setLocationTimingGroups(
        updatedFields.locationTimingGroups || locationTimingGroups
      );
      const newTimings = {
        ...timings,
        [phase.id]: {
          ...timings[phase.id],
          [group.id]: {
            ...timings[phase.id]?.[group.id],
            hoursEstimate: updatedFields.hoursEstimate || hoursEstimate,
            personHoursEstimate:
              updatedFields.personHoursEstimate || personHoursEstimate,
            appliesToSpecificLocations:
              updatedFields.appliesToSpecificLocations ??
              appliesToSpecificLocations,
            locations: updatedFields.locations || locations,
            useLocationGroups:
              updatedFields.useLocationGroups ?? useLocationGroups,
            locationTimingGroups:
              updatedFields.locationTimingGroups ?? locationTimingGroups,
          },
        },
      };

      setTimings(newTimings);
    });
  };

  const totalHours = useLocationGroups
    ? locationTimingGroups.reduce((acc, ltg) => {
        const locationGroup = locationGroups.find(
          (lg) => lg.id === ltg.locationGroupId
        );
        if (locationGroup) {
          return (
            acc +
            (ltg.hoursEstimate || 0) * (locationGroup.locations?.length || 0)
          );
        }
        return acc;
      }, 0)
    : (thisActivityTiming?.hoursEstimate ?? 0) *
      (!thisActivityTiming?.appliesToSpecificLocations
        ? outcomeLocations.length ?? 0
        : (thisActivityTiming?.locations?.length ?? 0) || 0);

  const renderLocationTimingGroups = () => {
    return locationTimingGroups.map((group) => (
      <div key={group.id} className="border p-2 my-2">
        <Button
          text={'Remove Timing Group'}
          onClick={() =>
            handleUpdate({
              locationTimingGroups: locationTimingGroups?.filter(
                (timingGroup) => timingGroup.id !== group.id
              ),
            })
          }
        />
        <TextInput
          value={group.name}
          handleChange={(value: any) =>
            handleUpdate({
              locationTimingGroups: locationTimingGroups.map((lg) =>
                lg.id === group.id ? { ...lg, name: value } : lg
              ),
            })
          }
        />
        <div className="flex flex-col gap-y-2 my-2">
          <label>Estimated Hours for each location in {group.name}</label>
          <NumericInput
            id={`hours-estimate-${group.id}`}
            name="hoursEstimate"
            min={0}
            step=".5"
            value={group.hoursEstimate || 0}
            handleChange={(value: any) =>
              handleUpdate({
                locationTimingGroups: locationTimingGroups.map((lg) =>
                  lg.id === group.id ? { ...lg, hoursEstimate: value } : lg
                ),
              })
            }
            isDisabled={
              !userProfile?.permissions?.includes(Permission.UpdateProject)
            }
          />
        </div>
        <div className="flex flex-col gap-y-2 my-2">
          <label>Estimated Man Hours for each location in {group.name}</label>
          <NumericInput
            id={`person-hours-estimate-${group.id}`}
            name="personHoursEstimate"
            min={0}
            step=".5"
            value={group.personHoursEstimate || 0}
            handleChange={(value: any) =>
              handleUpdate({
                locationTimingGroups: locationTimingGroups.map((lg) =>
                  lg.id === group.id
                    ? { ...lg, personHoursEstimate: value }
                    : lg
                ),
              })
            }
            isDisabled={
              !userProfile?.permissions?.includes(Permission.UpdateProject)
            }
          />
        </div>
        <ComboBox
          testId="location-group-selection"
          placeholder="Select Location Group"
          id="Search"
          label=""
          options={locationGroups
            .filter((locationGroup) => {
              const isInUse = locationTimingGroups.some(
                (ltg) => ltg.locationGroupId === locationGroup.id
              );
              const hasCommonLocations = locationTimingGroups.some((ltg) => {
                const ltgLocations =
                  locationGroups.find((lg) => lg.id === ltg.locationGroupId)
                    ?.locations || [];
                const currentGroupLocations = locationGroup.locations || [];
                return ltgLocations.some((ltgLoc) =>
                  currentGroupLocations.some(
                    (currLoc) => currLoc.id === ltgLoc.id
                  )
                );
              });
              return !isInUse && !hasCommonLocations;
            })
            .map((lg) => ({
              id: lg.id,
              name: lg.name,
            }))}
          handleChange={(selectedGroupId: string) => {
            handleUpdate({
              locationTimingGroups: locationTimingGroups.map((lg) => {
                return lg.id === group.id
                  ? { ...lg, locationGroupId: selectedGroupId }
                  : lg;
              }),
            });
          }}
          value={
            locationGroups?.find(
              (locationGroup) => locationGroup.id === group.locationGroupId
            )?.name ?? ''
          }
        />
        <div className="my-2" />
        <Button
          text={'Clear Location Group'}
          onClick={() =>
            handleUpdate({
              locationTimingGroups: locationTimingGroups.map((lg) => {
                return lg.id === group.id
                  ? { ...lg, locationGroupId: undefined }
                  : lg;
              }),
            })
          }
        />
      </div>
    ));
  };

  return (
    <div className="border px-2 py-2">
      <div
        role="button"
        className="w-full flex justify-between flex-row"
        onClick={() => setLocationConfig(!showLocationConfig)}
      >
        <div className="px-2 select-none flex flex-row gap-x-2">
          <p>
            #{index + 1} {group.name}
          </p>
        </div>
        <div className="px-2 select-none flex flex-row gap-x-2">
          <p>Estimated Total Hours: {totalHours}</p>
          {showLocationConfig ? (
            <ArrowsPointingOutIcon className="w-5 h-5 text-black mr-6" />
          ) : (
            <ArrowsPointingInIcon className="w-5 h-5 text-black mr-6" />
          )}
        </div>
      </div>

      <div
        className={`${
          showLocationConfig ? '' : 'hidden'
        } px-8 py-4 select-none flex flex-col gap-y-4`}
      >
        <Switch
          enabled={useLocationGroups}
          text="Use Timing Groups"
          handleChange={() => {
            setUseLocationGroups(!useLocationGroups);
            handleUpdate({ useLocationGroups: !useLocationGroups });
          }}
        />

        {!useLocationGroups && (
          <>
            <div className="flex flex-col gap-y-2">
              <label>Estimated Hours Per Location</label>
              <NumericInput
                id={`hours-estimate`}
                name="hoursEstimate"
                min={0}
                step={'.5'}
                value={hoursEstimate}
                handleChange={(value: any) =>
                  handleUpdate({ hoursEstimate: value })
                }
                isDisabled={
                  !userProfile?.permissions?.includes(Permission.UpdateProject)
                }
              />
            </div>
            <div className="flex flex-col gap-y-2">
              <label>Estimated Man Hours Per Location</label>
              <NumericInput
                id={`person-hours-estimate`}
                name="personHoursEstimate"
                min={0}
                step={'.5'}
                value={personHoursEstimate}
                handleChange={(value: any) =>
                  handleUpdate({ personHoursEstimate: value })
                }
                isDisabled={
                  !userProfile?.permissions?.includes(Permission.UpdateProject)
                }
              />
            </div>
            <Switch
              enabled={!appliesToSpecificLocations}
              isDisabled={
                !userProfile?.permissions?.includes(Permission.UpdateProject)
              }
              text={
                !appliesToSpecificLocations
                  ? 'Applies to all scope locations'
                  : 'Applies only to specific locations'
              }
              handleChange={() =>
                handleUpdate({
                  appliesToSpecificLocations: !appliesToSpecificLocations,
                })
              }
            />
            {appliesToSpecificLocations && (
              <MultiSelectComboBox
                testId="location-selection"
                placeholder="Select Locations"
                id="Search"
                label=""
                options={projectLocations?.getProjectLocations?.results
                  ?.filter(
                    (location: any) =>
                      !locations?.find(
                        (selectedLocation) =>
                          selectedLocation.id === location?.id
                      )
                  )
                  ?.map((assessment: any) => ({
                    id: assessment.id,
                    name: assessment.name,
                  }))}
                handleChange={(selectedLocations: any) =>
                  handleUpdate({ locations: selectedLocations })
                }
                value={locations}
                validation={undefined}
              />
            )}
          </>
        )}

        {useLocationGroups && (
          <>
            {renderLocationTimingGroups()}
            <Button
              text="Add Timing Group"
              onClick={() =>
                handleUpdate({
                  locationTimingGroups: [
                    ...locationTimingGroups,
                    {
                      id: v4(),
                      locationGroupId: '',
                      name: `Timing Group ${locationTimingGroups.length + 1}`,
                      hoursEstimate: 0,
                      personHoursEstimate: 0,
                    },
                  ],
                })
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

const PhaseConfig = ({
  index,
  phase,
  projectId,
  outcomeId,
  timings,
  setTimings,
  outcomeLocations,
  locationGroups,
}: PhaseConfigProps) => {
  const [showActivityConfig, setShowActivityConfig] = useState(true);
  const totalHours = Object.values(timings[phase.id] ?? {}).reduce(
    (acc: any, groupTimings: any) => {
      if (groupTimings.useLocationGroups && groupTimings.locationTimingGroups) {
        return (
          acc +
          groupTimings.locationTimingGroups.reduce(
            (groupAcc: any, ltg: any) => {
              const locationGroup = locationGroups.find(
                (lg) => lg.id === ltg.locationGroupId
              );
              return (
                groupAcc +
                (ltg.hoursEstimate || 0) *
                  (locationGroup?.locations.length || 0)
              );
            },
            0
          )
        );
      } else if (!groupTimings.appliesToSpecificLocations) {
        return acc + groupTimings.hoursEstimate * outcomeLocations.length;
      } else if (groupTimings.locations) {
        return acc + groupTimings.hoursEstimate * groupTimings.locations.length;
      }
      return acc;
    },
    0
  ) as number;

  const totalPersonHours = Object.values(timings[phase.id] ?? {}).reduce(
    (acc: any, groupTimings: any) => {
      if (groupTimings.useLocationGroups && groupTimings.locationTimingGroups) {
        return (
          acc +
          groupTimings.locationTimingGroups.reduce(
            (groupAcc: any, ltg: any) => {
              const locationGroup = locationGroups.find(
                (lg) => lg.id === ltg.locationGroupId
              );
              return (
                groupAcc +
                (ltg.personHoursEstimate || 0) *
                  (locationGroup?.locations.length || 0)
              );
            },
            0
          )
        );
      } else if (!groupTimings.appliesToSpecificLocations) {
        return acc + groupTimings.personHoursEstimate * outcomeLocations.length;
      } else if (groupTimings.locations) {
        return (
          acc + groupTimings.personHoursEstimate * groupTimings.locations.length
        );
      }
      return acc;
    },
    0
  ) as number;

  return (
    <div className="flex flex-col w-full">
      <div
        onClick={() => setShowActivityConfig(!showActivityConfig)}
        className="py-2 w-full bg-gray-50 border px-4 flex justify-between items-center flex-row"
      >
        <p>
          #{index + 1} {phase.name}
        </p>
        <div className="px-2 select-none flex flex-row items-center gap-x-2">
          <p>Estimated Phase Total Hours: {totalHours}</p>
          <p>Estimated Phase Total Man Hours: {totalPersonHours}</p>
          {(phase?.activityGroups?.length ?? 0) > 0 ? (
            showActivityConfig ? (
              <ArrowsPointingOutIcon className="w-5 h-5 text-black mr-6" />
            ) : (
              <ArrowsPointingInIcon className="w-5 h-5 text-black mr-6" />
            )
          ) : null}
        </div>
      </div>
      {showActivityConfig ? (
        <div className="py-2 w-full px-2 pl-4 text-sm flex flex-col gap-y-2">
          {phase.activityGroups?.map((group, groupIndex) => (
            <ActivityGroupConfig
              outcomeLocations={outcomeLocations}
              timings={timings}
              phase={phase}
              setTimings={setTimings}
              group={group}
              projectId={projectId}
              outcomeId={outcomeId}
              index={groupIndex}
              locationGroups={locationGroups}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export const OutcomePage = () => {
  let { projectId, outcomeId } = useParams();
  const [project] = useProject(projectId);
  const navigate = useNavigate();
  const { useLazyQuery, useMutation, useQuery } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const [fetch, { data, loading, error }] = useLazyQuery(GET_PROJECT_OUTCOMES, {
    variables: { input: { id: outcomeId }, projectId },
    fetchPolicy: 'network-only',
  });

  const { data: projectTeamsData } = useQuery(GET_PROJECT_TEAMS, {
    variables: { projectId },
    fetchPolicy: 'network-only',
  });

  const projectTeams = projectTeamsData?.getProjectTeams?.results ?? [];

  const [fetchProjectLocations, { data: projectLocations }] = useLazyQuery(
    GET_PROJECT_LOCATIONS,
    {
      variables: { projectId, input: { limit: 2000 } },
      fetchPolicy: 'network-only',
    }
  );
  const [item] = data?.getProjectOutcomes?.results || [];

  const outcomeFromServer = item;

  const reconstructTimings = (outcome: Outcome) => {
    const timings = {};
    outcome?.configuration?.phases?.forEach((phase) => {
      timings[phase.id] = {};
      phase.activityGroups.forEach((group) => {
        timings[phase.id][group.id] = {
          hoursEstimate: group?.timings?.hoursEstimate || 0,
          personHoursEstimate: group?.timings?.personHoursEstimate || 0,
          appliesToSpecificLocations:
            group?.timings?.appliesToSpecificLocations || false,
          locations: group?.timings?.locations || [],
          useLocationGroups: group?.timings?.useLocationGroups,
          locationTimingGroups: group?.timings?.locationTimingGroups || [],
        };
      });
    });
    return timings;
  };

  const [timings, setTimings] = useState({});
  const [newOutcomeName, setNewOutcomeName] = useState('');
  const [outcome, setOutcome] = useState<
    (Outcome & { createdAt?: number; updatedAt?: number }) | undefined
  >({
    id: v4(),
    name: '',
    configuration: { phases: [], locations: [], locationGroups: [] },
    teams: [],
    createdAt: undefined,
    updatedAt: undefined,
  });

  const setStateFromServer = async () => {
    unstable_batchedUpdates(() => {
      setOutcome(outcomeFromServer);
      setLocations(outcomeFromServer?.configuration?.locations ?? []);
      setLocationGroups(outcomeFromServer?.configuration?.locationGroups ?? []);
      setTimings(reconstructTimings(outcomeFromServer));
      setNewOutcomeName(outcomeFromServer?.name);
    });
  };

  useEffect(() => {
    setStateFromServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outcomeFromServer]);

  useEffect(() => {
    if (outcomeId) {
      fetch({ variables: { input: { id: outcomeId }, projectId } });
    }
    fetchProjectLocations();
  }, [fetch, fetchProjectLocations, outcomeId, projectId]);

  const [locations, setLocations] = useState<any[]>([]);
  const [locationGroups, setLocationGroups] = useState<LocationGroup[]>([]); // Assuming location groups are retrieved from server

  const [saveOutcome, { loading: saving }] = useMutation(SAVE_PROJECT_OUTCOME);

  const totalHours = Object.values(timings).reduce(
    (accPhase: any, phaseTimings: any) => {
      return (
        accPhase +
        Object.values(phaseTimings).reduce(
          (accGroup: any, groupTimings: any) => {
            if (
              groupTimings.useLocationGroups &&
              groupTimings.locationTimingGroups
            ) {
              return groupTimings.locationTimingGroups.reduce(
                (groupAcc: any, ltg: any) => {
                  const locationGroup = locationGroups.find(
                    (lg) => lg.id === ltg.locationGroupId
                  );
                  return (
                    groupAcc +
                    (ltg.hoursEstimate || 0) *
                      (locationGroup?.locations.length || 0)
                  );
                },
                accGroup
              );
            } else if (!groupTimings.appliesToSpecificLocations) {
              return accGroup + groupTimings.hoursEstimate * locations.length;
            } else if (groupTimings.locations) {
              return (
                accGroup +
                groupTimings.hoursEstimate * groupTimings.locations.length
              );
            }
            return accGroup;
          },
          0
        )
      );
    },
    0
  ) as number;

  const totalPersonHours = Object.values(timings).reduce(
    (accPhase: any, phaseTimings: any) => {
      return (
        accPhase +
        Object.values(phaseTimings).reduce(
          (accGroup: any, groupTimings: any) => {
            if (
              groupTimings.useLocationGroups &&
              groupTimings.locationTimingGroups
            ) {
              return groupTimings.locationTimingGroups.reduce(
                (groupAcc: any, ltg: any) => {
                  const locationGroup = locationGroups.find(
                    (lg) => lg.id === ltg.locationGroupId
                  );
                  return (
                    groupAcc +
                    (ltg.personHoursEstimate || 0) *
                      (locationGroup?.locations.length || 0)
                  );
                },
                accGroup
              );
            } else if (!groupTimings.appliesToSpecificLocations) {
              return (
                accGroup + groupTimings.personHoursEstimate * locations.length
              );
            } else if (groupTimings.locations) {
              return (
                accGroup +
                groupTimings.personHoursEstimate * groupTimings.locations.length
              );
            }
            return accGroup;
          },
          0
        )
      );
    },
    0
  ) as number;

  const removeDates = {
    createdAt: undefined,
    updatedAt: undefined,
  };

  const outcomeData = {
    ...outcome,
    name: newOutcomeName,
    teams: outcome?.teams,
    configuration: {
      locations: locations.map(({ createdAt, updatedAt, ...rest }) => rest),
      locationGroups: locationGroups.map(
        ({ createdAt, updatedAt, ...rest }) => rest
      ),
      ...removeDates,
      phases: outcome?.configuration?.phases?.map((phase) => ({
        ...phase,
        ...removeDates,
        activityGroups: phase?.activityGroups?.map((group) => ({
          ...group,
          ...removeDates,
          timings: {
            ...timings?.[phase.id]?.[group.id],
            personHoursEstimate: parseFloat(
              timings?.[phase.id]?.[group.id]?.personHoursEstimate || '0'
            ),
            hoursEstimate: parseFloat(
              timings?.[phase.id]?.[group.id]?.hoursEstimate || '0'
            ),
            locations:
              timings?.[phase.id]?.[group.id]?.locations?.map(
                ({ createdAt, updatedAt, ...rest }) => rest
              ) || [],
            locationTimingGroups:
              timings?.[phase.id]?.[group.id]?.locationTimingGroups?.map(
                (locationTimingGroup) => ({
                  ...locationTimingGroup,
                  personHoursEstimate: parseFloat(
                    locationTimingGroup?.personHoursEstimate || '0'
                  ),
                  hoursEstimate: parseFloat(
                    locationTimingGroup?.hoursEstimate || '0'
                  ),
                })
              ) || [],
          },
        })),
      })),
    },
    ...removeDates,
  };

  const isLessThanMinLengthForOutcomeName = newOutcomeName?.length < 3;

  const { userProfile } = useContext(UserProfileContext);

  const [showLocationsForOutcome, setShowLocationsForOutcome] = useState(false);
  const [showLocationGroupConfig, setShowLocationGroupConfig] = useState(false);
  const [showOutcomeBuilder, setShowOutcomeBuilder] = useState(false);

  const tableQueryParams =
    sessionStorage.getItem('scopes_table_query_params') || '';

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="py-2 border-b flex w-full justify-between">
          {projectId ? (
            <LinkButton
              style={{
                backgroundColor: 'transparent',
                color: 'gray',
                boxShadow: 'none',
                borderRadius: 0,
              }}
              to={`/projects/${projectId}${
                tableQueryParams ? `?${tableQueryParams}` : ''
              }`}
            >
              {`< Back to Project: ${project?.name}`}
            </LinkButton>
          ) : null}
        </div>

        {userProfile?.permissions?.includes(Permission.UpdateProject) ? (
          <div
            style={{ zIndex: 99999 }}
            className="flex fixed bottom-0 w-full right-0 py-4 justify-end bg-white px-10"
          >
            <Button
              text={'Save'}
              isDisabled={!outcome?.name && newOutcomeName?.length < 3}
              isLoading={saving}
              onClick={async () => {
                try {
                  await saveOutcome({
                    variables: {
                      id: outcomeData.id,
                      input: { ...outcomeData, projectId },
                    },
                  });
                  addToast('Scope saved', 'success');
                } catch (error) {
                  console.error(error);
                  addToast('Error saving scope', 'error');
                }
                if (!outcomeId) {
                  navigate(`/projects/${projectId}?tab=Scopes`);
                } else {
                  fetch({
                    variables: { input: { id: outcomeId }, projectId },
                  });
                }
              }}
            />
          </div>
        ) : null}

        <div className="w-full flex flex-col md:items-center md:flex-row md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col w-full">
            <div className="flex gap-x-2 w-full">
              <TextInput
                id="scopeName"
                title="Scope Name:"
                classNames="w-full"
                isDisabled={
                  !userProfile?.permissions?.includes(Permission.UpdateProject)
                }
                handleChange={setNewOutcomeName}
                validation={
                  !outcome?.name &&
                  newOutcomeName?.length > 0 &&
                  isLessThanMinLengthForOutcomeName
                    ? 'Name needs to be at least 3 characters'
                    : ''
                }
                value={newOutcomeName}
              />
            </div>
          </div>
        </div>

        <div className="px-8 py-2 flex flex-col gap-y-2">
          <Button
            onClick={() => setShowLocationsForOutcome(!showLocationsForOutcome)}
            text={
              showLocationsForOutcome
                ? 'Hide Locations for Scope'
                : 'Show Locations for Scope'
            }
          />
          {showLocationsForOutcome ? (
            <div>
              <h3 className="pt-2 pb-1">Locations Involved in Scope:</h3>

              <MultiSelectComboBox
                testId="location-selection"
                placeholder={`Select Locations`}
                id="Search"
                label=""
                options={projectLocations?.getProjectLocations?.results
                  ?.filter(
                    (location: any) =>
                      !locations.find(
                        (selectedLocation) =>
                          selectedLocation.id === location?.id
                      )
                  )
                  ?.map((assessment: any) => ({
                    id: assessment.id,
                    name: assessment.name,
                  }))}
                handleChange={(id: any) => {
                  setLocations(id);
                }}
                isDisabled={
                  !userProfile?.permissions?.includes(Permission.UpdateProject)
                }
                value={locations}
                validation={undefined}
              />
              <div className="mt-2">
                <Button
                  text={'Add All Locations'}
                  isDisabled={
                    !userProfile?.permissions?.includes(
                      Permission.UpdateProject
                    )
                  }
                  onClick={() =>
                    setLocations(projectLocations?.getProjectLocations?.results)
                  }
                />
              </div>
            </div>
          ) : null}
          <Button
            onClick={() => setShowLocationGroupConfig(!showLocationGroupConfig)}
            text={
              showLocationGroupConfig
                ? 'Hide Location Groups'
                : 'Show Location Groups'
            }
          />
          {showLocationGroupConfig ? (
            <LocationGroupConfig
              locationGroups={locationGroups}
              setLocationGroups={setLocationGroups}
              projectLocations={locations ?? []}
            />
          ) : null}
          <Button
            onClick={() => setShowOutcomeBuilder(!showOutcomeBuilder)}
            text={
              showOutcomeBuilder ? 'Hide Scope Builder' : 'Show Scope Builder'
            }
          />
          {projectId ? (
            showOutcomeBuilder ? (
              <OutcomeBuilder
                projectId={projectId}
                key={outcome?.id}
                setOutcome={setOutcome as (value: Outcome) => void}
                outcome={outcome as Outcome}
              />
            ) : null
          ) : null}
        </div>
        <div className="flex flex-col gap-y-2 py-4 px-8">
          <div>
            <p className="my-2 text-sm text-gray-500">
              Estimated Total Hours: {totalHours}
            </p>
          </div>
          <div>
            <p className="my-2 text-sm text-gray-500">
              Estimated Total Man Hours: {totalPersonHours}
            </p>
          </div>
          {outcome?.configuration?.phases?.map((phase, index) =>
            projectId ? (
              <PhaseConfig
                outcomeLocations={locations}
                timings={timings}
                setTimings={setTimings}
                index={index}
                phase={phase}
                projectId={projectId}
                outcomeId={outcome?.id}
                locationGroups={locationGroups}
              />
            ) : null
          )}
        </div>
        <MultiSelectTable
          classNames="py-4 px-8"
          question={{
            id: 'teams',
            title: 'Assign Teams to Scope',
            options: projectTeams.map((project: any) => ({
              name: project.name,
              value: project.id,
            })),
          }}
          value={outcome?.teams ?? []}
          handleChange={(teams) => setOutcome({ ...outcome, teams })}
        />
        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}
      </div>
    </div>
  );
};

export const OutcomeView = () => <OutcomePage />;
