import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '../../../../components/Buttons/Button';
import { Modal } from '../../../../components/Dialogue/ModalDialogue';
import TextArea from '../../../../components/Inputs/TextArea';
import { ConditionEntry } from '../conditions/entry';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

export const ConditionSubmission = ({
  selectedProject,
  conditions,
  setConditions,
  locked,
  date,
}: any) => {
  const handleAddCondition = () => {
    const newCondition = {
      plc: null,
      isNew: true,
      time:
        conditions && conditions.length > 0
          ? conditions[conditions.length - 1].time ?? null
          : null,
      id: uuidv4(),
      conditionReport: [],
      createdAt: moment().valueOf(),
    };

    setConditions(conditions ? [...conditions, newCondition] : [newCondition]);
  };

  const handleConditionReportChange = (
    conditionId?: string,
    conditionTypeId?: string,
    value?: any
  ) => {
    console.log({ conditionId, conditionTypeId, value });
    const updatedConditions = conditions.map((condition: any) => {
      if (condition.id === conditionId) {
        const conditionReportForThisRole = condition?.conditionReport?.find(
          (conditionType: any) => conditionType?.id === conditionTypeId
        );
        console.log({
          conditionId,
          conditionTypeId,
          value,
          conditionReportForThisRole,
          conditionReport: condition?.conditionReport,
        });
        if (
          condition?.conditionReport?.length === 0 ||
          !conditionReportForThisRole
        ) {
          return {
            ...condition,
            savedLocally: false,
            ...(!condition.isNew && !condition.toBeDeleted
              ? {
                  hasChanged: true,

                  original: condition,
                }
              : {}),
            conditionReport: [
              ...condition?.conditionReport,
              {
                id: conditionTypeId,
                value: value,
              },
            ],
          };
        }

        return {
          ...condition,
          savedLocally: false,
          ...(!condition.isNew && !condition.toBeDeleted
            ? {
                hasChanged: true,
                original: condition,
              }
            : {}),
          conditionReport: [
            ...condition?.conditionReport?.filter(
              (aRole: any) => aRole?.id !== conditionTypeId
            ),
            {
              ...conditionReportForThisRole,
              value: value,
            },
          ],
        };
      }
      return condition;
    });
    setConditions(updatedConditions);
  };

  const bottomRef = useRef(null);

  useEffect(() => {
    if (
      bottomRef.current &&
      conditions?.find((condition: any) => !condition.pl?.id)
    ) {
      // @ts-ignore
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conditions?.length]);

  const handleRemoveCondition = (conditionId: string) => {
    setConditions(
      conditions.reduce((acc: any, condition: any) => {
        if (condition.id === conditionId && condition.isNew) {
          return acc;
        }
        if (condition.id === conditionId && !condition.isNew) {
          acc.push({ ...condition, toBeDeleted: !condition.toBeDeleted });
        } else {
          acc.push(condition);
        }
        return acc;
      }, [])
    );
  };

  const handleRevertConditionChange = (conditionId: any) => {
    const updatedConditions = conditions.map((condition: any) => {
      if (condition.id === conditionId) {
        return {
          ...condition?.original,
        };
      }
      return condition;
    });
    setConditions(updatedConditions);
  };

  const handleConditionChange = (
    conditionId?: string,
    field?: string,
    value?: any
  ) => {
    const updatedConditions = conditions.map((condition: any) => {
      if (condition.id === conditionId && field) {
        return {
          ...condition,
          [field]: value,
          savedLocally: false,
          ...(!condition.isNew && !condition.toBeDeleted
            ? {
                hasChanged: true,
                original: condition,
              }
            : {}),
        };
      }
      return condition;
    });
    setConditions(updatedConditions);
  };

  const [modalConditionId, setModalConditionId] = useState<string | undefined>(
    undefined
  );

  const [commentBoxState, setCommentBoxState] = useState('');

  useEffect(() => {
    if (modalConditionId) {
      setCommentBoxState(
        conditions?.find((condition: any) => condition?.id === modalConditionId)
          ?.comment
      );
    }
  }, [modalConditionId]);

  return (
    <>
      <div>
        <Modal
          showModal={!!modalConditionId}
          setShowModal={setModalConditionId}
          completeButtonText={'Complete'}
          title={`Comment #${modalConditionId}`}
          onComplete={() => {
            if (!locked) {
              handleConditionChange(
                modalConditionId,
                'comment',
                commentBoxState
              );
            }
            setModalConditionId(undefined);
          }}
        >
          <TextArea
            id="comment"
            name="comment"
            rows={8}
            value={commentBoxState ?? ''}
            handleChange={(value) => setCommentBoxState(value)}
            validation={''}
            isDisabled={locked}
          />
        </Modal>
        <hr />
        <div className="bg-white px-4 pt-2 flex flex-col gap-y-2">
          {conditions?.length > 0 ? (
            <>
              <h4 className="text-sm py-2">
                {moment(date).format('LL')}'s Conditions
              </h4>
              {[...conditions]
                .sort((a: any, b: any) => a?.time - b?.time)
                .map((condition: any) => (
                  <ConditionEntry
                    projectId={selectedProject?.id}
                    locked={locked}
                    handleConditionChange={handleConditionChange}
                    handleRemoveCondition={handleRemoveCondition}
                    handleRevertConditionChange={handleRevertConditionChange}
                    handleConditionReportChange={handleConditionReportChange}
                    condition={condition}
                    setModalConditionId={setModalConditionId}
                  />
                ))}
            </>
          ) : (
            <p className="py-6">
              <ExclamationTriangleIcon className="w-10 h-10 text-orange-300" />
              No Conditions Recorded
            </p>
          )}
        </div>

        <div ref={bottomRef} />
      </div>

      {!locked ? (
        <div className="border-b w-full bg-white flex justify-end sticky bottom-0 py-4 px-4">
          <Button
            text={'Record Condition'}
            onClick={handleAddCondition}
          ></Button>
        </div>
      ) : null}
    </>
  );
};
