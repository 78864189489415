import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useContext } from 'react';
import { ActiveOrganisationContext } from '../../context/ActiveOrganisationContext';
import { twMerge } from 'tailwind-merge';

export default function OrganisationPicker() {
  const { organisations, activeOrganisation, setActiveOrganisation } =
    useContext(ActiveOrganisationContext);

  return (
    <Menu
      as="div"
      className="self-center relative w-12 xl:w-24 inline-block text-left"
    >
      <div>
        {activeOrganisation ? (
          <MenuButton
            id="organisation-picker"
            aria-label="Organisation Picker"
            className="inline-flex w-full justify-center gap-x-1.5 rounded-md py-2 text-sm font-semibold text-gray-900"
          >
            <div className="flex items-center h-10 w-10 sm:h-12 sm:w-12 overflow-hidden flex-shrink-0 hover:scale-105">
              <img
                className="rounded-sm"
                src={activeOrganisation.logoUrl}
                alt={`Company logo for ${activeOrganisation.companyName}`}
              />
            </div>
            {organisations && organisations.length > 1 && (
              <ChevronDownIcon
                data-testid={'chevron-down'}
                className="h-5 w-5 mr-2 text-gray-400 self-center"
                aria-hidden="true"
              />
            )}
          </MenuButton>
        ) : null}
      </div>
      <MenuItems
        anchor="bottom end"
        className={twMerge(
          `[--anchor-max-height:60vh] z-10 mt-2 min-w-max max-w-32 max-h-[60vh] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto`,
          'transition',
          'data-[closed]:opacity-0 data-[closed]:scale-95',
          'data-[enter]:ease-out data-[enter]:duration-100',
          'data-[leave]:ease-in data-[leave]:duration-75'
        )}
        transition
      >
        <div className="py-1">
          {organisations &&
            organisations.map((org) => {
              return (
                <div key={org.companyName}>
                  <MenuItem>
                    {({ focus }) => (
                      <button
                        className={twMerge(
                          focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm'
                        )}
                        onClick={() =>
                          setActiveOrganisation && setActiveOrganisation(org)
                        }
                      >
                        <div className="flex items-center h-14 w-14 overflow-hidden flex-shrink-0 hover:scale-105">
                          <img
                            className="rounded-sm"
                            src={org.logoUrl}
                            alt={`Company logo`}
                          />
                        </div>
                        <p className="pl-4 w-32 text-xs text-left">
                          {org.companyName}
                        </p>
                      </button>
                    )}
                  </MenuItem>
                </div>
              );
            })}
        </div>
      </MenuItems>
    </Menu>
  );
}
