import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { useEffect, useRef, useState } from 'react';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import {
  GET_CERTIFICATION_TYPES,
  GET_PROJECT_CERTIFICATIONS,
} from '../../../graphql/queries/certifications';
import { Table } from '../../../components/Tables/tables/Table';
import moment from 'moment';
import { FormConfig } from '../../../types/Form';
import FormCard from '../../../components/FormCard/FormCard';
import * as Yup from 'yup';
import { SAVE_PROJECT_CERTIFICATION } from '../../../graphql/mutations/certifications';
import { useProject } from '../../../hooks/api/projects/useProjects';
import { useToast } from '../../../context/ToastContext';
import { SEARCH_ROLES } from '../../../graphql/queries/roles';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { Button } from '../../../components/Buttons/Button';
import useRoles from '../../../hooks/api/useRoles';

const CertificationTypeForm = ({
  certificationType,
  project,
  roles,
  onCancel,
}: {
  certificationType: any;
  project: any;
  roles: any[];
  onCancel: () => void;
}) => {
  const navigate = useNavigate();
  const { useMutation, useQuery } = useOrganisationAwareApollo();
  const [saveProjectCertification] = useMutation(SAVE_PROJECT_CERTIFICATION);

  const [searchTerm, debouncedSearchTerm, setSearchTerm] =
    useDebouncedState<string>('');

  const { data: searchRolesData } = useQuery(SEARCH_ROLES, {
    variables: {
      searchTerm: debouncedSearchTerm,
      input: { limit: 1000, offset: 0 },
    },
    fetchPolicy: 'network-only',
  });

  const roleResults = searchRolesData?.searchRoles?.results || [];

  const { addToast } = useToast();
  const certificationName =
    certificationType.name ||
    certificationType?.certificationType?.name ||
    'role';
  const formConfig = {
    formSections: [
      {
        title: `Configure ${certificationName} for ${project.name} (${project.internalId})?`,
        fields: [
          {
            title: 'Required For Companies',
            id: 'requiredForCompanies',
            type: 'switch',
          },
          {
            title: 'Required For Employees',
            id: 'requiredForEmployees',
            type: 'switch',
          },
          {
            title: 'Required For Subcontractors',
            id: 'requiredForSubcontractors',
            type: 'switch',
          },
          {
            id: 'requiredForRoles',
            type: 'multiselecttable',
            title: 'Required For Roles',
            options: roleResults.map((role: any) => ({
              name: role.name,
              value: role.id,
            })),
            searchTerm,
            setSearchTerm,
            styles: {
              menuItems: {
                top: '430px',
              },
            },
          },
        ],
      },
    ],
  } as FormConfig;

  const initialValues = {
    certificationTypeId: certificationType.id,
    projectId: project.id,
    requiredForCompanies: certificationType.requiredForCompanies,
    requiredForEmployees: certificationType.requiredForEmployees,
    requiredForSubcontractors: certificationType.requiredForSubcontractors,
    requiredForRoles: certificationType.requiredForRoles,
  };

  const validationSchema = Yup.object().shape({
    certificationTypeId: Yup.string().required(
      'Certification type is required'
    ),
    projectId: Yup.string().required('Project is required'),
    requiredForCompanies: Yup.boolean().nullable(),
    requiredForEmployees: Yup.boolean().nullable(),
    requiredForSubcontractors: Yup.boolean().nullable(),
    requiredForRoles: Yup.array().of(Yup.string()).nullable(),
  });

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const {
        data: {
          saveProjectCertification: { success, message },
        },
      } = await saveProjectCertification({
        variables: {
          input: {
            ...values,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });

      setSubmitting(false);
      if (success) {
        navigate(`/projects/${project.id}?tab=Certifications`);
        addToast('Certification saved successfully', 'success');
      } else {
        console.error(message);
        addToast('Failed to save certification', 'error');
      }
    } catch (error) {
      console.error(error);
      addToast('Failed to save certification', 'error');
    }
  };

  return (
    <FormCard
      key={`${formConfig?.title}`}
      config={formConfig}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};

const headerMapping = {
  name: 'Name',
  email: 'Email',
  required: 'Required For',
  updatedAt: 'Updated At',
} as Record<string, any>;

const CertificationTypeTable = ({
  certificationType,
  setCertificationType,
  projectId,
}: {
  certificationType: any;
  setCertificationType: any;
  projectId: string;
}) => {
  const { useLazyQuery, useQuery } = useOrganisationAwareApollo();
  const { limit, offset, setOffset } = usePagination();

  const [activeRow, setActiveRow] = useState(null);

  const rowRefs = useRef<(HTMLDivElement | null)[]>([]);

  const [project] = useProject(projectId);

  const location = useLocation();
  const { certificationTypeId } = location.state || {};

  const [fetchCertificationTypes, { data: certificationTypesData }] =
    useLazyQuery(GET_CERTIFICATION_TYPES, {
      variables: { input: { limit, offset } },
      fetchPolicy: 'network-only',
    });

  const { data: projectCertificationTypesData } = useQuery(
    GET_PROJECT_CERTIFICATIONS,
    {
      variables: { input: { limit, offset }, projectId: projectId },
      fetchPolicy: 'network-only',
    }
  );

  const [roles] = useRoles({ limit: 1000, offset: 0 });

  useEffect(() => {
    fetchCertificationTypes({
      variables: { input: { limit, offset } },
    });
  }, [fetchCertificationTypes, limit, offset]);

  const handleSetRow = (id: any) => {
    const projectCert = projectCertificationTypes?.find(
      (projectCertificationType: any) =>
        projectCertificationType.certificationType.id === id
    );
    const certType = certificationTypes?.find(
      (certificationType: any) => certificationType.id === id
    );
    setCertificationType(
      projectCert
        ? {
            ...projectCert,
            id: certType?.id,
          }
        : certType
    );
    setActiveRow(id);
    const index = certificationTypes.findIndex(
      (certType: any) => certType.id === id
    );

    setTimeout(() => {
      if (index !== -1 && rowRefs?.current[index]) {
        rowRefs?.current[index]?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 100);
  };

  const certificationTypeResults =
    certificationTypesData?.getCertificationTypes;

  const certificationTypes = certificationTypeResults?.results || [];

  const projectCertificationTypes =
    projectCertificationTypesData?.getProjectCertifications?.results || [];

  useEffect(() => {
    if (
      certificationTypeId &&
      certificationTypes.length > 0 &&
      projectCertificationTypes.length > 0
    ) {
      handleSetRow(certificationTypeId);
    }
  }, [certificationTypeId, certificationTypes, projectCertificationTypes]);

  const totalPages = Math.ceil(certificationTypeResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  return (
    <Table
      currentPage={Math.floor(offset / limit) + 1}
      totalPages={totalPages}
      itemsPerPage={limit}
      totalResults={certificationTypeResults?.count || 0}
      onClickRow={handleSetRow}
      headerMapper={(text: string) => headerMapping[text]}
      data={certificationTypes?.flatMap((certType: any, index: any) => {
        const mappedData = {
          id: certType?.id || '',
          name: certType?.name || '',
          updatedAt: certType?.updatedAt
            ? moment.unix(parseInt(certType.updatedAt) / 1000).fromNow()
            : 'N/A',
        };

        if (activeRow === certType.id) {
          return [
            {
              name: (
                <div
                  ref={(rowForm) => (rowRefs.current[index] = rowForm)}
                  onClick={(e) => e.stopPropagation()}
                >
                  <CertificationTypeForm
                    certificationType={certificationType || {}}
                    project={project || {}}
                    roles={roles || []}
                    onCancel={() => setActiveRow(null)}
                  />
                </div>
              ),
              updatedAt: (
                <div className="">
                  {certType?.updatedAt
                    ? moment.unix(parseInt(certType.updatedAt) / 1000).fromNow()
                    : 'N/A'}
                </div>
              ),
            },
          ];
        }
        return [mappedData];
      })}
      onPageChange={handlePageChange}
    />
  );
};

export const SaveProjectCertification = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [project] = useProject(id);

  const [certificationType, setCertificationType] = useState(null);

  return (
    <PaginationProvider>
      <div className="bg-gray-50">
        <div className="flex flex-col bg-white shadow overflow-hidden sm:rounded-lg px-4 py-5 gap-y-5 sm:px-6">
          <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between flex-wrap">
            <div className="flex flex-col px-4">
              <h1 className="text-3xl font-extrabold text-gray-900">
                Configure Required Certification for {project?.name}
              </h1>
              <h2 className="text-lg mt-1 font-base text-gray-500">
                {project?.internalId}
              </h2>
              <div>
                <Button
                  style={{
                    backgroundColor: 'transparent',
                    color: 'gray',
                    boxShadow: 'none',
                    borderRadius: 0,
                  }}
                  onClick={() =>
                    navigate(`/projects/${project?.id}?tab=Certifications`)
                  }
                  type="submit"
                  text="< Back to Certifications"
                />
              </div>
            </div>
          </div>
          <CertificationTypeTable
            projectId={project?.id}
            certificationType={certificationType}
            setCertificationType={setCertificationType}
          />
        </div>
      </div>
    </PaginationProvider>
  );
};
