import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../../../components/Buttons/Button';
import { Table } from '../../../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../../../context/PaginationContext';

import EmailInput from '../../../../../components/Inputs/EmailInput';
import TextInput from '../../../../../components/Inputs/TextInput';
import { CREATE_CONTRACT_SUBMISSION } from '../../../../../graphql/mutations/contracts';
import { GET_PROJECT_ASSIGNMENT_ROLES } from '../../../../../graphql/queries/assignments';
import {
  GET_CONTRACTS_COUNT,
  GET_CONTRACT_FIELD_PREVIEW,
  GET_CONTRACT_SUBMISSIONS,
  SEARCH_CONTRACTS,
} from '../../../../../graphql/queries/contracts';
import { useOrganisationAwareApollo } from '../../../../../hooks/useOrganisationAwareApollo';
import { ContractsBuilder } from '../../../../Contracts/builder';
import { ContractSelection } from './search';
import { UserProfileContext } from '../../../../../context/UserProfileContext';
import { Permission } from '../../../../../types/Permissions';
import {
  GreenBadge,
  YellowBadge,
} from '../../../../../components/Badges/Badges';
import { URLDropdown } from '../../../../../components/Dropdown/URLDropdown';

import * as Yup from 'yup';
import ValidationMessage from '../../../../../components/Validation/ValidationMessage';
import { GET_PROJECTS } from '../../../../../graphql/queries/projects';
import NoContracts from '../../../../../components/NoData/NoContracts';

const headerMapping = {
  contractName: 'Contract Name',
  status: 'Status',
  signerEmail: 'Signer Email',
  organisationSignerName: 'Org Signer Name',
  organisationSignerEmail: 'Org Signer Email',
  options: 'Options',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
} as Record<string, any>;

const SentContractsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow = null,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={[
      'firstName',
      'lastName',
      'id',
      'signerSlug',
      'updatedAt',
      'organisationSignerName',
      'organisationSignerEmail',
    ]}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
      createdAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const SentContractsListPage = () => {
  const navigate = useNavigate();

  const { projectId, assignmentRoleId } = useParams();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { limit, offset, setOffset } = usePagination();

  const [showPreview, setShowPreview] = useState(false);
  const [organisationSignerEmail, setOrganisationSignerEmail] = useState('');
  const [organisationSignerName, setOrganisationSignerName] = useState('');
  const [commencementDate, setCommencementDate] = useState('');

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedContract, setSelectedContract] = useState<any>(undefined);

  const [fetchProjects, { data: projectResults }] = useLazyQuery(GET_PROJECTS, {
    variables: { input: { id: projectId } },
    fetchPolicy: 'network-only',
  });

  const [
    fetchContractsCount,
    { data: contractsCountData, loading: loadingContractsCount },
  ] = useLazyQuery(GET_CONTRACTS_COUNT, {
    variables: {},
    fetchPolicy: 'network-only',
  });

  const [fetch, { data }] = useLazyQuery(GET_PROJECT_ASSIGNMENT_ROLES, {
    variables: { input: { id: assignmentRoleId } },
    fetchPolicy: 'network-only',
  });

  const [fetchContractSubmissions, { data: contractSubmissionsData }] =
    useLazyQuery(GET_CONTRACT_SUBMISSIONS, {
      variables: { assignmentRoleId },
      fetchPolicy: 'network-only',
    });

  const [fetchContractFieldPreview, { data: contractFieldPreview }] =
    useLazyQuery(GET_CONTRACT_FIELD_PREVIEW, {
      variables: { assignmentRoleId, input: { id: selectedContract?.id } },
      fetchPolicy: 'network-only',
    });

  const [project] = projectResults?.getProjects?.results || [];

  useEffect(() => {
    fetchProjects();
    fetchContractsCount();
  }, [fetchContractsCount, fetchProjects, projectId]);

  useEffect(() => {
    setCommencementDate(
      project?.startDate
        ? moment.unix(project?.startDate / 1000).format('LL')
        : moment().format('LL')
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectResults]);

  useEffect(() => {
    fetch({ variables: { input: { id: assignmentRoleId } } });
    fetchContractSubmissions({
      variables: { assignmentRoleId, input: { limit, offset } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentRoleId, limit, offset]);

  const [fetchContracts, { data: contractsData }] = useLazyQuery(
    SEARCH_CONTRACTS,
    {
      variables: { searchTerm: debouncedSearchTerm },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (selectedContract) {
      fetchContractFieldPreview({
        variables: { assignmentRoleId, input: { id: selectedContract?.id } },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentRoleId, selectedContract?.id]);

  const { userProfile } = useContext(UserProfileContext);

  const [sendContract, { loading: sending }] = useMutation(
    CREATE_CONTRACT_SUBMISSION
  );

  const performSearch = async () => {
    try {
      await fetchContracts({
        variables: {
          searchTerm: debouncedSearchTerm,
          input: { limit, offset },
        },
      });
    } catch (err) {
      // do nothing
    } finally {
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const contractSubmissionsResults =
    contractSubmissionsData?.getContractSubmissions?.results || [];
  const [assignmentData] = data?.getAssignmentRoles?.results || [];
  const contractCount = contractsCountData?.getContracts?.count || 0;
  const contractSearchResults = contractsData?.searchContracts?.results || [];

  const contractFields = useMemo(
    () =>
      contractFieldPreview?.getContractFieldPreview?.results.map(
        (contractField: { field: string; value?: string }) => {
          if (contractField.field === 'Commencement Date') {
            return {
              ...contractField,
              value: commencementDate,
            };
          }

          return contractField;
        }
      ) || [],
    [commencementDate, contractFieldPreview?.getContractFieldPreview?.results]
  );

  const totalPages = Math.ceil(
    contractsData?.getContractSubmissions?.count / limit
  );

  useEffect(() => {
    performSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, limit, offset]);

  const invalidCosignerEmail = !Yup.string()
    .email()
    .isValidSync(organisationSignerEmail);

  const fieldPreview = {
    field: 'Field',
    value: 'Value',
  } as any;

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="py-2 border-b">
          <Button
            style={{
              backgroundColor: 'transparent',
              color: 'black',
              boxShadow: 'none',
              borderRadius: 0,
            }}
            onClick={() => navigate(-1)}
            type="submit"
            text={`< Go Back`}
          />
        </div>

        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-4 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Subcontractor: {assignmentData?.assignment?.lrs?.lastName},{' '}
              {assignmentData?.assignment?.lrs?.firstName}
            </h1>
            <p className="mt-1 text-lg text-gray-900">
              Role: {assignmentData?.role?.name}
            </p>
            <p className="mt-1 text-base text-gray-500">
              Project: {assignmentData?.assignment?.project?.name} (
              {assignmentData?.assignment?.project?.internalId})
            </p>
          </div>
        </div>

        <div className="border-t border-gray-200 px-4">
          {contractCount > 0 ? (
            <>
              {userProfile?.permissions?.includes(Permission.SendContracts) ? (
                <h1 className="text-lg font-bold py-4">Send Contract</h1>
              ) : null}
              <div className="flex flex-row items-center w-full gap-x-2">
                <ContractSelection
                  contractSearchResults={contractSearchResults}
                  setSearchTerm={setSearchTerm}
                  setSelectedContract={setSelectedContract}
                  selectedContract={selectedContract}
                />
                <Button
                  style={{ marginTop: '3px', backgroundColor: 'black' }}
                  text={showPreview ? 'Hide Preview' : `Show Preview`}
                  isDisabled={!selectedContract}
                  onClick={() => {
                    setShowPreview(!showPreview);
                  }}
                />
                {userProfile?.permissions?.includes(
                  Permission.SendContracts
                ) ? (
                  <Button
                    style={{ marginTop: '3px' }}
                    text="Send Contract"
                    isLoading={sending}
                    isDisabled={
                      !selectedContract ||
                      !organisationSignerEmail ||
                      !organisationSignerName ||
                      invalidCosignerEmail
                    }
                    onClick={async () => {
                      if (
                        selectedContract?.id &&
                        assignmentRoleId &&
                        organisationSignerEmail &&
                        organisationSignerName
                      ) {
                        await sendContract({
                          variables: {
                            input: {
                              contractId: selectedContract?.id,
                              assignmentRoleId,
                              organisationSignerEmail,
                              organisationSignerName,
                              commencementDate,
                            },
                          },
                        });
                        setShowPreview(false);
                        await fetchContractSubmissions();
                      }
                    }}
                  />
                ) : null}
              </div>
            </>
          ) : (
            <NoContracts loading={loadingContractsCount} />
          )}
          {selectedContract &&
          userProfile?.permissions?.includes(Permission.SendContracts) ? (
            <div className="w-full flex flex-col gap-y-2 py-4">
              <h3>Who will sign on behalf of the organisation?</h3>

              <TextInput
                id={'organisation-signer-name'}
                name={'organisationSignerName'}
                title="Name"
                handleChange={setOrganisationSignerName}
                value={organisationSignerName}
              />
              {invalidCosignerEmail ? (
                <ValidationMessage message="Not a valid email" />
              ) : null}
              <EmailInput
                id="email"
                name="email"
                title="Email"
                handleChange={setOrganisationSignerEmail}
                value={organisationSignerEmail}
              />
              <p className="text-xs">
                Please note that it is your organisation's responsibility to
                review the contents of contracts and ensure that the details
                presented are as expected.
                <br />
                The above co-signer reserves the right to refrain from signing
                if any details are not presented as expected and is advised to
                do so should there be any concerns regarding the contents. While
                every effort is made to ensure the accuracy of details sent to
                our signing partner, we do not accept liability for any
                discrepancies in the resultant contents of these documents.
              </p>
              <TextInput
                id="commencement-date"
                name="commencementDate"
                title="Commencement Date"
                handleChange={setCommencementDate}
                value={commencementDate}
              />
            </div>
          ) : null}
          {contractFields.length > 0 && showPreview ? (
            <Table
              headerMapper={(text: string) => fieldPreview[text]}
              totalPages={1}
              totalResults={contractFields.length}
              showPagination={false}
              data={contractFields}
              itemsPerPage={contractFields.length}
              onPageChange={() => {}}
              onClickRow={() => {}}
              currentPage={1}
            />
          ) : null}
          {selectedContract && showPreview ? (
            <div className="my-2">
              <ContractsBuilder
                showBackButton={false}
                preview
                contractId={selectedContract?.id}
              />
            </div>
          ) : null}

          <hr className="my-4" />
          <div className="w-full flex justify-between items-center py-2">
            <h1 className="text-lg font-bold py-4">Sent Contracts</h1>
            <div className="flex flex-row gap-x-2"></div>
          </div>
          <SentContractsTable
            currentPage={Math.floor(offset / limit) + 1}
            totalPages={totalPages}
            itemsPerPage={limit}
            totalResults={
              contractSubmissionsData?.getContractSubmissions?.count || 0
            }
            data={
              contractSubmissionsResults.length > 0
                ? contractSubmissionsResults.map((result: any) => ({
                    contractName: result?.contract?.name,
                    ...result,
                    completedDocumentURLs: undefined,
                    status:
                      result.status === 'COMPLETED' ? (
                        <GreenBadge text="Completed" />
                      ) : (
                        <YellowBadge text={'Submitted'} />
                      ),
                    contract: undefined,
                    assignmentRole: undefined,
                    options:
                      result.status === 'COMPLETED' ? (
                        <URLDropdown
                          urls={result?.completedDocumentURLs?.map(
                            (url: any) => ({
                              url: url.url,
                              name: `Completed Document (${url.name})`,
                            })
                          )}
                        />
                      ) : null,
                  }))
                : [{}]
            }
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export const SentContractsList = () => (
  <PaginationProvider>
    <SentContractsListPage />
  </PaginationProvider>
);
