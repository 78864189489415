import { Cog8ToothIcon, PencilSquareIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActiveBadge, DisabledBadge } from '../../components/Badges/Badges';
import { Button } from '../../components/Buttons/Button';
import { Table } from '../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../context/PaginationContext';
import { UserProfileContext } from '../../context/UserProfileContext';
import { GET_CONTRACTS_SUMMARY } from '../../graphql/queries/contracts';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { Permission } from '../../types/Permissions';
import NoContracts from '../../components/NoData/NoContracts';
import { useFilter } from '../../hooks/useFilter';

interface ContractListProps {
  showNewItemButton?: boolean;
  children?: React.ReactElement;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

const headerMapping = {
  name: 'Name',
  status: 'Status',
  configure: 'Options',
  updatedAt: 'Updated At',
} as Record<string, any>;

const ContractsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
  NoData,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    showFilterOptions
    columnsToFilter={['firstName', 'lastName', 'id']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={[{ id: 'name', name: 'Name' }]}
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    NoData={NoData}
    data={data}
  />
);

export const ContractListPage = ({
  children,
  filterIds = [],
  showNewItemButton = true,
}: ContractListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const { limit, offset, setOffset } = usePagination();
  const {
    filterText,
    setFilterText,
    filterDimension,
    setFilterDimension,
    filter,
  } = useFilter({ defaultFilterDimension: 'name' });

  const [fetch, { data, loading }] = useLazyQuery(GET_CONTRACTS_SUMMARY, {
    variables: { input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: { input: { limit, offset, ...(filter ? { filter } : {}) } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, filter]);

  const contractResults = data?.getContracts;

  const totalPages = Math.ceil(contractResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div>
      <>
        {showNewItemButton ? (
          <div className="w-full flex justify-between items-center py-2">
            <h1 className="text-xl font-bold py-4">Contracts</h1>
            {userProfile?.permissions.includes(
              Permission.EditContractTemplates
            ) ||
            userProfile?.permissions.includes(
              Permission.CreateContractTemplates
            ) ? (
              <Button
                text="New Contract"
                onClick={() => navigate(`/labour/contracts/new`)}
              />
            ) : null}
          </div>
        ) : null}
        <p className="text-sm py-2 text-gray-600">
          Here you can create contracts with a contract builder that can be sent
          to subcontractors.
        </p>
        <ContractsTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={contractResults?.count || 0}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={filterDimension}
          setSelectedFilterDimension={setFilterDimension}
          NoData={() => <NoContracts loading={loading} />}
          data={contractResults?.results
            .filter((result: any) => !filterIds?.includes(result.id))
            .map((result: any) => ({
              ...result,
              status: result.enabled ? <ActiveBadge /> : <DisabledBadge />,
              configure: (
                <div className="flex gap-x-2">
                  {userProfile?.permissions.includes(
                    Permission.EditContractTemplates
                  ) ? (
                    <Button
                      style={{ background: 'black' }}
                      text={
                        <div className="flex flex-row items-center justify-center gap-x-2 ">
                          <Cog8ToothIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                          Configure
                        </div>
                      }
                      onClick={() => {
                        navigate(`/labour/contracts/${result.id}`);
                      }}
                    />
                  ) : null}
                  {userProfile?.permissions.includes(
                    Permission.EditContractTemplates
                  ) ? (
                    <Button
                      text={
                        <div className="flex flex-row items-center justify-center gap-x-2 ">
                          <PencilSquareIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                          Open in Builder
                        </div>
                      }
                      onClick={() => {
                        navigate(`/labour/contracts/${result.id}/build`);
                      }}
                    />
                  ) : null}
                </div>
              ),
            }))}
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const ContractList = ({
  showNewItemButton,
  filterIds = [],
  onClickRow,
}: ContractListProps) => (
  <PaginationProvider>
    <ContractListPage
      showNewItemButton={showNewItemButton}
      filterIds={filterIds}
      onClickRow={onClickRow}
    />
  </PaginationProvider>
);
