import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table } from '../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../context/PaginationContext';
import { GET_PROJECTS_SUMMARY } from '../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import moment from 'moment';
import { Button } from '../../components/Buttons/Button';
import {
  ActiveBadge,
  BlueBadge,
  DisabledBadge,
  YellowBadge,
} from '../../components/Badges/Badges';
import { UserProfileContext } from '../../context/UserProfileContext';
import { Permission } from '../../types/Permissions';
import NoProjects from '../../components/NoData/NoProjects';
import { ProjectStatus } from '../../types/Project';
import { statusToBadge } from './view';
import { useFilter } from '../../hooks/useFilter';

interface ProjectListProps {
  children?: React.ReactElement;
}

const headerMapping = {
  name: 'Project Name',
  internalId: 'Internal ID',
  status: 'Status',
  updatedAt: 'Updated At',
} as Record<string, any>;

const tabs: {
  name: string;
  value: ProjectStatus | 'STATUS_NOT_SET' | undefined;
}[] = [
  {
    name: 'All',
    value: undefined,
  },
  {
    name: 'No Status',
    value: 'STATUS_NOT_SET',
  },
  {
    name: 'Not Started',
    value: ProjectStatus.NOT_STARTED,
  },
  {
    name: 'In Progress',
    value: ProjectStatus.IN_PROGRESS,
  },
  {
    name: 'Complete',
    value: ProjectStatus.COMPLETE,
  },
];

const filterDimensions = [
  { id: 'name', name: 'Name' },
  { id: 'internalId', name: 'Internal ID' },
];

export const ProjectListPage = (props: ProjectListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [searchParams] = useSearchParams();

  const { limit, offset, setOffset } = usePagination();

  const [projectStatus, setProjectStatus] = useState<
    ProjectStatus | 'STATUS_NOT_SET' | undefined
  >(undefined);

  const {
    filterText,
    setFilterText,
    filterDimension,
    setFilterDimension,
    filter,
  } = useFilter({ defaultFilterDimension: 'name' });

  const [fetch, { data, loading }] = useLazyQuery(GET_PROJECTS_SUMMARY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: {
        projectStatus,
        input: { limit, offset, ...(filter ? { filter } : {}) },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, filter, projectStatus]);

  const projectResults = data?.getProjects;

  const totalPages = Math.ceil(projectResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };
  const { userProfile } = useContext(UserProfileContext);
  return (
    <div>
      <div className="w-full flex justify-between items-center py-2">
        <h1 className="text-xl font-bold py-4">Projects</h1>
        {userProfile?.permissions?.includes(Permission.UpdateProject) ? (
          <Button
            text="New Project"
            onClick={() => navigate(`/projects/new`)}
          />
        ) : null}
      </div>
      <Table
        currentPage={Math.floor(offset / limit) + 1}
        totalPages={totalPages}
        itemsPerPage={limit}
        totalResults={projectResults?.count || 0}
        onClickRow={(id: any) => {
          sessionStorage.setItem(
            'project_table_query_params',
            searchParams.toString()
          );
          navigate(`/projects/${id}`);
        }}
        showFilterOptions={true}
        filterText={filterText}
        setFilterText={setFilterText}
        selectedFilterDimension={filterDimension}
        setSelectedFilterDimension={setFilterDimension}
        filterDimensions={filterDimensions}
        tabs={tabs}
        activeTab={projectStatus}
        setActiveTab={(activeTab) => {
          setProjectStatus(activeTab);
          setOffset(0);
        }}
        columnValueMapper={{
          updatedAt: (text: string) =>
            moment.unix(parseInt(text) / 1000).fromNow(),
        }}
        headerMapper={(text: string) => headerMapping[text]}
        data={projectResults?.results.map((result: any) => ({
          ...result,
          name: (
            <div className="flex items-center">
              {result.colour ? (
                <div
                  className="w-6 h-6 mr-4 border rounded-md"
                  style={{ backgroundColor: result.colour }}
                />
              ) : (
                <div className="md:w-6 md:h-6 md:mr-4 border rounded-md bg-transparent" />
              )}
              <div>{result.name}</div>
            </div>
          ),
          internalId: result?.internalId ? (
            <BlueBadge text={result?.internalId} />
          ) : (
            <YellowBadge text={'Not Configured'} />
          ),
          status: (
            <div className="flex gap-x-2">
              {result.enabled ? <ActiveBadge /> : <DisabledBadge />}
              {result.status && statusToBadge[result.status as ProjectStatus]}
            </div>
          ),
        }))}
        NoData={() => <NoProjects loading={loading} filterText={filterText} />}
        fetchPageOfDataForExport={async (limit: number, offset: number) => {
          const { data } = await fetch({
            variables: {
              projectStatus,
              input: { limit, offset, ...(filter ? { filter } : {}) },
            },
          });
          return data?.getProjects?.results ?? [];
        }}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export const ProjectList = () => (
  <PaginationProvider>
    <ProjectListPage />
  </PaginationProvider>
);
