import { BanknotesIcon } from '@heroicons/react/24/outline';
import { ColourSummaryCard } from '../../../components/ModuleItemSummary/ColourSummaryCard';
import * as Yup from 'yup';
import { SAVE_NOTIFICATIONS_CONFIG } from '../../../graphql/mutations/notifications-config';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { useToast } from '../../../context/ToastContext';
import { useContext } from 'react';
import { FormStateContext } from '../NotificationSettings';
import { Formik } from 'formik';
import FormSection from '../../../components/FormSection/FormSection';
import FormSubmit from '../../../components/Buttons/FormSubmit';
import ChipsInput from '../../../components/Inputs/ChipsInput';
import Switch from '../../../components/Inputs/Switch';

export function UpdatedBankDetails({
  config,
  enabled,
  onClick,
  isActive,
  setActiveTile,
  refetchEventTypes,
}: {
  config: any;
  enabled: boolean;
  onClick?: () => void;
  isActive?: boolean;
  setActiveTile: (tile: string) => void;
  refetchEventTypes: () => Promise<void>;
}) {
  const { useMutation } = useOrganisationAwareApollo();

  const [saveNotificationsConfig] = useMutation(SAVE_NOTIFICATIONS_CONFIG);

  const { setIsDirty } = useContext(FormStateContext);

  const { addToast } = useToast();

  const initialValues = {
    enabled: enabled,
    emailRecipients: config?.emails || [],
  };

  const validationSchema = Yup.object().shape({
    enabled: Yup.boolean(),
    emailRecipients: Yup.array().of(
      Yup.string().email('Please ensure all email addresses are valid')
    ),
  });

  const onSubmit = async (values: Record<string, any>) => {
    try {
      const { data } = await saveNotificationsConfig({
        variables: {
          input: {
            eventType: 'updatedBankDetails',
            enabled: values.enabled,
            config: {
              emails: values.emailRecipients,
            },
          },
        },
      });

      if (data.saveNotificationsConfig?.success) {
        addToast('Settings updated', 'success');
        setIsDirty(false);
        await refetchEventTypes();
      } else {
        addToast('Error updating settings', 'error');
      }
    } catch (error) {
      console.log(error);
      addToast('Error updating settings', 'error');
    }
  };

  return (
    <div className="flex flex-col w-full border rounded-lg">
      <ColourSummaryCard
        classNames="cursor-pointer bg-white border hover:bg-gray-100 z-10"
        heading="Bank Details Updated by Subcontractor"
        headingClassNames="text-xl"
        IconComponent={BanknotesIcon}
        isExpandable={true}
        isActive={isActive}
        onClick={onClick ? () => onClick() : undefined}
      />
      {isActive ? (
        <div className="px-4">
          <div className="text-sm py-4">
            {`If this setting is 'enabled', when a subcontractor updates their own bank details an email will be sent to the configured recipients informing them that there has been a change.`}
            <br />
            {`An email will not be sent when an administrator updates bank details on behalf of a subcontractor`}
          </div>
          <div className="flex flex-col z-0 rounded-b-lg">
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                setFieldValue,
                isValid,
                handleSubmit,
                isSubmitting,
                initialValues,
              }) => {
                return (
                  <>
                    <FormSection title={'Settings'} classNames="rounded-lg">
                      <Switch
                        handleChange={(checked) => {
                          const isReverted = checked === initialValues.enabled;
                          setIsDirty(!isReverted);
                          setFieldValue('enabled', checked);
                        }}
                        text={'Enabled'}
                        enabled={values.enabled}
                      />
                      <ChipsInput
                        inputTransformer={(input: string | undefined) =>
                          input?.toLowerCase().trim()
                        }
                        id="emailRecipients"
                        name="emailRecipients"
                        description="Enter an email address and press 'Enter' to add to the list of email recipients"
                        placeholder="Enter email address"
                        handleChange={(options: string[]) => {
                          const isReverted =
                            JSON.stringify(options) ===
                            JSON.stringify(initialValues.emailRecipients);
                          setIsDirty(!isReverted);
                          setFieldValue('emailRecipients', options);
                        }}
                        value={values.emailRecipients}
                        validation={errors.emailRecipients as string}
                      />
                    </FormSection>
                    <div className="py-4">
                      <FormSubmit
                        onCancel={() => {
                          setIsDirty(false);
                          setActiveTile('');
                        }}
                        disabled={!isValid}
                        isLoading={isSubmitting}
                        submitText={'Save'}
                        onSubmit={handleSubmit}
                      />
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      ) : null}
    </div>
  );
}
