import { createContext, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface PaginationContextProps {
  limit: number;
  offset: number;
  setLimit: (num: number) => void;
  setOffset: (num: number) => void;
}

const PaginationContext = createContext<PaginationContextProps>({
  limit: 20,
  offset: 0,
  setLimit: () => null,
  setOffset: () => null,
});

export function usePagination() {
  return useContext(PaginationContext);
}

interface PaginationProviderProps {
  children: React.ReactElement;
  useUrl?: boolean;
}

export const PaginationProvider = ({
  children,
  useUrl = true,
}: PaginationProviderProps) => {
  const [limit, setLimit] = useState<number>(20);

  const [searchParams, setSearchParams] = useSearchParams();

  const [offset, setOffset] = useState<number>(
    searchParams.get('page')
      ? (Number(searchParams.get('page')) - 1) * limit
      : 0
  );

  const setOffsetAndPage = (offset: number) => {
    setOffset(offset);
    setSearchParams((searchParams) => {
      searchParams.set('page', String(offset / limit + 1));
      return searchParams;
    });
  };

  const value = {
    limit,
    offset,
    setLimit,
    setOffset: useUrl ? setOffsetAndPage : setOffset,
  };

  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  );
};
