import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useContext, useEffect, useState } from 'react';

import FormCard from '../../../../components/FormCard/FormCard';

import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../../types/Form';

import {
  DELETE_PROGRESS_SUBMISSION_ATTACHMENT,
  SAVE_ATTACHMENT,
} from '../../../../graphql/mutations/attachments';

import { saveFileToS3 } from '../../../../utils/utils';
import {
  PaginationProvider,
  usePagination,
} from '../../../../context/PaginationContext';
import { Table } from '../../../../components/Tables/tables/Table';
import moment from 'moment';
import { Button } from '../../../../components/Buttons/Button';
import { GET_PROGRESS_SUBMISSION_ATTACHMENT_URL } from '../../../../graphql/queries/attachments';
import { GET_PROGRESS_SUBMISSION_ATTACHMENTS } from '../../../../graphql/queries/progress-activity-submissions';
import { SAVE_PROGRESS_ACTIVITY_SUBMISSION } from '../../../../graphql/mutations/progress-activity-submissions';
import { UserProfileContext } from '../../../../context/UserProfileContext';
import { Permission } from '../../../../types/Permissions';
import { DeleteButton } from '../../../../components/Deletion/DeleteButton';
import { useToast } from '../../../../context/ToastContext';

const headerMapping = {
  name: 'File Name',
  id: 'ID',
  download: 'Download',
  updatedAt: 'Updated At',
} as Record<string, any>;

const SaveProgressAttachmentPage = ({
  refetch,
  progressSubmissionId,
  outcomeId,
  projectId,
  locked,
  date,
}: any) => {
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const { limit, offset, setOffset } = usePagination();

  const { userProfile } = useContext(UserProfileContext);

  const [getProgressSubmissionAttachmentURL] = useLazyQuery(
    GET_PROGRESS_SUBMISSION_ATTACHMENT_URL
  );

  const [fetchAttachmentsForThisProgressSubmission, { data: attachmentsData }] =
    useLazyQuery(GET_PROGRESS_SUBMISSION_ATTACHMENTS);

  const [deletionFocus, setDeletionFocus] = useState('');

  useEffect(() => {
    if (progressSubmissionId) {
      fetchAttachmentsForThisProgressSubmission({
        variables: {
          progressSubmissionId,
          input: { limit, offset },
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [progressSubmissionId, limit, offset]);

  const totalPages = Math.ceil(
    attachmentsData?.getProgressSubmissionAttachments?.count / limit
  );

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const [saveAttachment] = useMutation(SAVE_ATTACHMENT);

  const [saveProgressSubmission] = useMutation(
    SAVE_PROGRESS_ACTIVITY_SUBMISSION
  );

  const [deleteProgressSubmissionAttachment] = useMutation(
    DELETE_PROGRESS_SUBMISSION_ATTACHMENT
  );
  const initialValues = {};

  const validationSchema = Yup.object().shape({});

  const [fileUploadError, setFileUploadError] = useState(false);

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue?: (id: any, value: any) => void
  ) => {
    try {
      let fileSaved;
      let newAttachmentId;
      if (values?.file?.name) {
        const { data } = await saveAttachment({
          variables: {
            input: {
              name: values.importName,
              id: null,
              fileName: values.file.name,
            },
          },
        });

        const presignedUrl = data?.saveAttachment?.presignedUrl;

        fileSaved = await saveFileToS3(values.file, presignedUrl);
        newAttachmentId = data?.saveAttachment?.id;
      }

      const { data } = await saveProgressSubmission({
        variables: {
          input: {
            outcomeId,
            projectId,
            date,
            newAttachmentIds: [newAttachmentId],
          },
        },
      });

      const { data: psd } = await refetch({
        variables: { outcomeId, date, projectId },
      });

      if (
        (data?.saveProgressSubmission.success && !values?.file?.name) ||
        (values?.file?.name && fileSaved)
      ) {
        await fetchAttachmentsForThisProgressSubmission({
          variables: {
            progressSubmissionId:
              progressSubmissionId ??
              psd?.getProgressSubmissions?.results?.[0]?.id,
            input: { limit, offset },
          },
          fetchPolicy: 'network-only',
        });
        if (setFieldValue) {
          setFieldValue('file', undefined);
        }
      } else {
        setFileUploadError(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const progresssubmissionConfig = {
    description: '',
    formSections: [
      {
        fields: [
          {
            id: 'file',
            type: 'file',
            title: 'Upload Attachment',
            limits: 'application/pdf',
            description: 'PDF',
          },
        ],
      },
    ],
  } as unknown as FormConfig;

  const downloadAttachment = async (id: string, fileName: string) => {
    const { data } = await getProgressSubmissionAttachmentURL({
      variables: { input: { id } },
    });
    if (data.getProgressSubmissionAttachmentURL.presignedUrl) {
      // Create a new link
      const anchor = document.createElement('a');
      anchor.href = data.getProgressSubmissionAttachmentURL.presignedUrl;
      anchor.download = fileName;
      anchor.style.display = 'none';

      // Append to the DOM
      document.body.appendChild(anchor);

      // Trigger `click` event
      anchor.click();

      // Remove element from DOM
      document.body.removeChild(anchor);

      // Revoke the Blob URL to free up resources
      URL.revokeObjectURL(data.getProgressSubmissionAttachmentURL.presignedUrl);
    }
  };

  return (
    <div className="bg-white px-4 py-4">
      {progressSubmissionId ? (
        <div>
          <h1 className="my-2">Attachments</h1>
          <Table
            currentPage={Math.floor(offset / limit) + 1}
            totalPages={totalPages}
            totalResults={
              attachmentsData?.getProgressSubmissionAttachments?.count || 0
            }
            itemsPerPage={limit}
            columnsToFilter={[]}
            columnValueMapper={{
              updatedAt: (text: string) =>
                moment.unix(parseInt(text) / 1000).fromNow(),
            }}
            headerMapper={(text: string) => headerMapping[text]}
            onPageChange={handlePageChange}
            data={
              attachmentsData?.getProgressSubmissionAttachments?.results
                .length > 0
                ? attachmentsData?.getProgressSubmissionAttachments?.results?.map(
                    (result: any) => ({
                      id: result?.id,
                      name: result?.fileName,
                      updatedAt: result?.updatedAt,
                      download: (
                        <div className="flex flex-row gap-x-2">
                          <Button
                            text={'Download'}
                            onClick={async () =>
                              await downloadAttachment(
                                result.id,
                                result?.fileName
                              )
                            }
                          />
                          {userProfile?.permissions?.includes(
                            Permission.ApproveProgressSubmission
                          ) ? (
                            <DeleteButton
                              modalText="Are you sure you want to delete this attachment? You will not be able to restore it once deleted."
                              modalTitle={'Delete Attachment'}
                              onClick={() => setDeletionFocus(result.id)}
                              onDelete={async () => {
                                try {
                                  const { data } =
                                    await deleteProgressSubmissionAttachment({
                                      variables: { id: deletionFocus },
                                    });

                                  if (
                                    data?.deleteProgressSubmissionAttachment
                                      ?.success
                                  ) {
                                    addToast(
                                      'Attachment deleted successfully',
                                      'success'
                                    );
                                  } else {
                                    addToast(
                                      'Error deleting attachment',
                                      'error'
                                    );
                                  }
                                } catch (error) {
                                  addToast(
                                    'Error deleting attachment',
                                    'error'
                                  );
                                  console.error(error);
                                }

                                await fetchAttachmentsForThisProgressSubmission(
                                  {
                                    variables: {
                                      progressSubmissionId:
                                        progressSubmissionId,
                                      input: { limit, offset },
                                    },
                                    fetchPolicy: 'network-only',
                                  }
                                );
                              }}
                            >
                              Delete
                            </DeleteButton>
                          ) : null}
                        </div>
                      ),
                    })
                  )
                : []
            }
          />
        </div>
      ) : null}
      {!locked ? (
        <FormCard
          key={`${progresssubmissionConfig?.title}`}
          config={progresssubmissionConfig}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}
        />
      ) : null}
    </div>
  );
};

export const SaveProgressAttachment = ({
  refetch = () => Promise.resolve(true),
  progressSubmissionId,
  outcomeId,
  projectId,
  locked,
  date,
}: any) => (
  <PaginationProvider>
    <SaveProgressAttachmentPage
      refetch={refetch}
      progressSubmissionId={progressSubmissionId}
      outcomeId={outcomeId}
      locked={locked}
      projectId={projectId}
      date={date}
    />
  </PaginationProvider>
);
