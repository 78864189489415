import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Table,
  TableRowComponentProps,
} from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import useAssignments from '../../../hooks/api/useAssignments';
import { AssignmentRow } from '../assignments/list';
import NotificationIcon from '../../../components/Notification/NotificationIcon';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { useFilter } from '../../../hooks/useFilter';

interface ProjectCompanyAssignmentsListProps {
  projectId: string;
  company: { id: string; companyName: string };
}

const PaginatedProjectCompanyAssignmentsList = ({
  projectId,
  company,
}: ProjectCompanyAssignmentsListProps) => {
  const navigate = useNavigate();

  const defaultFilterDimension = useMemo(
    () => (projectId ? 'lastName' : 'projectName'),
    [projectId]
  );

  const { limit, offset, setOffset } = usePagination();

  const [searchParams] = useSearchParams();

  const {
    filterText,
    setFilterText,
    filterDimension,
    setFilterDimension,
    filter,
  } = useFilter({ defaultFilterDimension });

  const [assignments, { count }] = useAssignments({
    companyId: company.id,
    projectId,
    limit,
    offset,
    filter,
  });

  const totalPages = Math.ceil(count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const onClickRow = useCallback(
    (id: any) => {
      const matchingAssignment = assignments.find(
        (assignment: any) => assignment.id === id
      );
      sessionStorage.setItem(
        'previous_page',
        '/projects/companies/' + company.id
      );
      sessionStorage.setItem(
        'assignments_table_query_params',
        searchParams.toString()
      );
      navigate(
        `/projects/${matchingAssignment?.project?.id}/assignments/${id}`
      );
    },
    [assignments, navigate]
  );

  const RowComponent: FunctionComponent<TableRowComponentProps> = useCallback(
    ({ id, rowData }: TableRowComponentProps) => (
      <AssignmentRow id={id!} rowData={rowData} onClickRow={onClickRow} />
    ),
    [onClickRow]
  );

  return (
    <div>
      <>
        <hr />
        <p className="px-4 sm:px-6 py-4 text-lg">
          Total Assignees: {count ?? 0}
        </p>
        <hr />
        <Table
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={count || 0}
          filterDimensions={
            projectId
              ? [
                  { id: 'lastName', name: 'Last Name' },
                  { id: 'companyName', name: 'Company Name' },
                  { id: 'firstName', name: 'First Name' },
                  { id: 'role', name: 'Role Name' },
                ]
              : [
                  { id: 'projectName', name: 'Project Name' },
                  { id: 'projectId', name: 'Project ID' },
                  { id: 'role', name: 'Role Name' },
                ]
          }
          RowComponent={RowComponent}
          data={assignments.map((assignment: any) => ({
            id: assignment.id,
            name: `${assignment.lrs?.lastName}, ${assignment.lrs?.firstName}`,
            company: company.companyName,
            type:
              assignment.lrs?.type === 'EMPLOYEE'
                ? 'Employee'
                : 'Subcontractor',
            missingOrInvalidCertifications:
              assignment?.missingOrInvalidCertifications,
            complianceIssues:
              assignment?.missingOrInvalidCertifications?.length > 0 ? (
                <NotificationIcon />
              ) : (
                <CheckBadgeIcon className="w-5 text-green-500" />
              ),
            activeRoles: assignment?.assignmentRoleSummary.activeRoles.map(
              (role: any) => role.name
            ),
            inactiveRoles: assignment?.assignmentRoleSummary.inactiveRoles.map(
              (role: any) => role.name
            ),
          }))}
          showFilterOptions
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={filterDimension}
          setSelectedFilterDimension={setFilterDimension}
          onPageChange={handlePageChange}
        />
      </>
    </div>
  );
};

export const ProjectCompanyAssignmentsList = (
  props: ProjectCompanyAssignmentsListProps
) => (
  <PaginationProvider>
    <PaginatedProjectCompanyAssignmentsList {...props} />
  </PaginationProvider>
);
