import React, { useEffect, useState } from 'react';
import { Outcome } from '../../types/Outcomes';
import Switch from '../Inputs/Switch';
import './index.css';
import { Button } from '../Buttons/Button';
import TextInput from '../Inputs/TextInput';

interface Asset {
  id?: string;
  name?: string;
  projectLocationId?: string;
  category?: string;
}

interface ProgressColumn {
  id: string;
  name: string;
  isLocked?: boolean;
  progress: number;
  isNA?: boolean;
}

interface ManualProgress {
  columnId: string;
  progress: number;
  isNA?: boolean;
  isLocked?: boolean;
}

interface Row {
  rowId: string;
  rowLabel: string;
  rowCategory: string;
  progressByColumn: ProgressColumn[];
}

interface Group {
  groupId: string;
  groupLabel: string;
  rows: Row[];
  manualProgress: ManualProgress[];
}

interface MultiColumnProgressProps {
  outcome: Outcome;
  data: Group[];
  assets: Asset[];
  isDisabled?: boolean;
  setData: (data: Group[]) => void;
  isExpanded?: boolean;
  setHasChanged: (changed: boolean) => void;
}

const MultiColumnProgress: React.FC<MultiColumnProgressProps> = ({
  outcome,
  data = [],
  setData,
  assets,
  isDisabled,
  setHasChanged = () => {},
  isExpanded = false,
}) => {
  const [locationFilter, setLocationFilter] = useState('');

  const transformOutcomeToData = (
    outcome: Outcome
  ): { phases: any; rowGroups: Group[] } => {
    const phases = outcome?.configuration?.phases?.map((phase) => ({
      phaseKey: phase.id,
      phaseLabel: phase.name,
      subColumns:
        phase.activityGroups?.map((group) => ({
          columnKey: group.id,
          originId: group.originId,
          columnHeader: group.name,
          // @ts-ignore
          locations: (group?.timings?.useLocationGroups
            ? // @ts-ignore
              outcome?.configuration?.locationGroups
                // @ts-ignore
                ?.filter((locationGrouping) =>
                  // @ts-ignore
                  group?.timings?.locationTimingGroups
                    ?.map(
                      // @ts-ignore
                      (locationTimingGroup) =>
                        locationTimingGroup.locationGroupId
                    )
                    ?.includes(locationGrouping.id)
                )
                // @ts-ignore
                ?.flatMap((locationGroup: any) => locationGroup.locations)
            : // @ts-ignore
              group?.timings?.locations
          )?.map((location: any) => location.id),
        })) || [],
    }));

    const mappedLocations = outcome?.configuration?.locations?.map(
      (location) => ({
        ...location,
        assets: assets.filter(
          (asset) => asset.projectLocationId === location.id
        ),
      })
    );

    // @ts-ignore
    const rowGroups: Group[] = mappedLocations?.map((location) => {
      const existingGroup = data.find((group) => group.groupId === location.id);
      // @ts-ignore
      const rows: Row[] = location.assets.map((asset) => {
        const existingRow = existingGroup?.rows.find(
          (row) => row.rowId === asset.id
        );

        return existingRow
          ? {
              ...existingRow,
              progressByColumn: existingRow.progressByColumn.map(
                (existingColumn) => {
                  const matchingPhaseColumn = phases
                    ?.flatMap((phase) => phase.subColumns)
                    ?.find((column) => column.columnKey === existingColumn.id);

                  if (matchingPhaseColumn) {
                    return {
                      ...existingColumn,
                      isLocked:
                        matchingPhaseColumn?.locations &&
                        matchingPhaseColumn.locations?.length > 0
                          ? !matchingPhaseColumn?.locations?.includes(
                              location.id
                            )
                          : false,
                      isNA:
                        matchingPhaseColumn?.locations &&
                        matchingPhaseColumn.locations?.length > 0
                          ? !matchingPhaseColumn?.locations?.includes(
                              location.id
                            )
                          : false,
                    };
                  }
                  return existingColumn;
                }
              ),
            }
          : {
              rowId: asset.id,
              rowLabel: asset.name || '',
              rowCategory: asset.category || '',
              progressByColumn: phases
                ?.flatMap((phase) => phase.subColumns)
                ?.map((column) => ({
                  id: column.columnKey,
                  name: column.columnHeader,
                  originId: column.originId,
                  progress: 0,
                  isLocked:
                    column?.locations && column.locations?.length > 0
                      ? !column?.locations?.includes(location.id)
                      : false,
                  isNA:
                    column?.locations && column.locations?.length > 0
                      ? !column?.locations?.includes(location.id)
                      : false,
                })),
            };
      });

      const manualProgress: ManualProgress[] = phases
        ?.flatMap((phase) => phase.subColumns)
        ?.map((column) => {
          const existingProgress = existingGroup?.manualProgress.find(
            (mp) => mp.columnId === column.columnKey
          );

          return {
            ...(existingProgress || {
              columnId: column.columnKey,
              originId: column.originId,
              progress: 0,
            }),
            isLocked:
              column?.locations && column.locations?.length > 0
                ? !column?.locations?.includes(location.id)
                : false,
            isNA:
              column?.locations && column.locations?.length > 0
                ? !column?.locations?.includes(location.id)
                : existingProgress?.isNA,
          };
        });

      return {
        groupId: location.id,
        groupLabel: location.name,
        rows,
        manualProgress,
      };
    });

    return { phases, rowGroups };
  };

  const setToUseLatestOutcomeConfiguration = () => {
    setData(transformOutcomeToData(outcome).rowGroups);
    setHasChanged(true);
  };

  useEffect(() => {
    if (data.length > 0) {
      setData(data);
    } else if (outcome) {
      setData(transformOutcomeToData(outcome).rowGroups);
    }
  }, [JSON.stringify(outcome), JSON.stringify(data)]);

  const calculateColumnProgress = (group: Group) => {
    const columnProgress: { [key: string]: number[] } = {};
    group.rows.forEach((row) => {
      row.progressByColumn.forEach((column) => {
        if (!column.isNA) {
          if (!columnProgress[column.id]) {
            columnProgress[column.id] = [];
          }
          columnProgress[column.id].push(column.progress);
        }
      });
    });

    return Object.keys(columnProgress).map((columnId) => {
      const progressValues = columnProgress[columnId];
      const averageProgress =
        progressValues.reduce((sum, value) => sum + value, 0) /
        progressValues.length;
      return { columnId, progress: averageProgress };
    });
  };

  const handleProgressChange = (
    groupIndex: number,
    rowIndex: number | null,
    columnId: string,
    newValue: number,
    isNA: boolean = false
  ) => {
    setHasChanged(true);
    const newData = JSON.parse(JSON.stringify(data)) as Group[];
    if (rowIndex !== null) {
      const column = newData[groupIndex].rows[rowIndex].progressByColumn.find(
        (c) => c.id === columnId
      );
      if (column) {
        column.progress = isNA ? 0 : newValue;
        column.isNA = isNA;
      }

      const averageProgress = calculateColumnProgress(newData[groupIndex]);
      averageProgress.forEach((avg) => {
        const manualProgressItem = newData[groupIndex].manualProgress.find(
          (item) => item.columnId === avg.columnId
        );
        if (manualProgressItem) {
          manualProgressItem.progress = avg.progress;
        }
      });
    } else {
      const progressItem = newData[groupIndex].manualProgress.find(
        (item) => item.columnId === columnId
      );
      if (progressItem) {
        progressItem.progress = isNA ? 0 : newValue;
        progressItem.isNA = isNA;
      }
    }
    setData(newData);
  };

  const renderProgressForGroup = (group: Group, groupIndex: number) => {
    if (group.rows.length === 0) {
      return (
        <tr>
          <td className="px-6 py-2 border text-sm text-right bg-gray-50 sticky left-0">
            {group.groupLabel}
          </td>
          {group?.manualProgress?.map((item, index) => (
            <td key={index} className="px-4 py-2 border">
              {!item.isLocked ? (
                <>
                  <div
                    className="bg-green-500 text-xs leading-none py-2 text-center text-black"
                    style={{ width: `${item.progress}%` }}
                  >
                    {item.progress}%
                  </div>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={item.progress}
                    disabled={item.isNA || isDisabled}
                    onChange={(e) =>
                      handleProgressChange(
                        groupIndex,
                        null,
                        item.columnId,
                        parseInt(e.target.value, 10)
                      )
                    }
                    className="w-full"
                  />
                </>
              ) : null}

              {item.isLocked ? (
                <p className="text-sm">Not Required</p>
              ) : (
                <Switch
                  text={item.isNA ? `N/A` : 'Applicable'}
                  enabled={!item.isNA}
                  isDisabled={isDisabled || item.isLocked}
                  handleChange={() =>
                    handleNAChange(groupIndex, null, item.columnId, !item.isNA)
                  }
                />
              )}
            </td>
          ))}
        </tr>
      );
    } else {
      return (
        <>
          {group?.manualProgress?.map((item, index) => (
            <td key={index} className="px-4 py-2 border">
              <div
                className="bg-green-500 text-xs leading-none py-2 text-center text-black"
                style={{ width: `${item.progress}%` }}
              >
                {item.progress}%
              </div>
            </td>
          ))}
          {group.rows?.map((row: Row, rowIndex: number) => (
            <tr key={rowIndex}>
              <td className="px-4 py-2 border text-sm text-right sticky left-0 bg-gray-50">
                {row.rowLabel}
                {row.rowCategory ? ` - ${row.rowCategory}` : ''}
              </td>

              {row.progressByColumn?.map(
                (column: ProgressColumn, columnIndex: number) => (
                  <td key={columnIndex} className="px-4 py-2 border">
                    {!column.isLocked ? (
                      <>
                        <div
                          className="bg-green-500 text-xs leading-none py-2 text-center text-black"
                          style={{ width: `${column.progress}%` }}
                        >
                          {column.progress}%
                        </div>
                        <input
                          type="range"
                          min="0"
                          max="100"
                          value={column.progress}
                          disabled={column.isNA || isDisabled}
                          onChange={(e) => {
                            handleProgressChange(
                              groupIndex,
                              null,
                              column.id,
                              parseInt(e.target.value, 10)
                            );
                            handleProgressChange(
                              groupIndex,
                              rowIndex,
                              column.id,
                              parseInt(e.target.value, 10)
                            );
                          }}
                        />
                      </>
                    ) : null}
                    {column.isLocked ? (
                      <p className="text-sm">Not Required</p>
                    ) : (
                      <div className="relative">
                        <Switch
                          text={column.isNA ? `N/A` : 'Applicable'}
                          enabled={!column.isNA}
                          isDisabled={isDisabled || column.isLocked}
                          handleChange={() =>
                            handleNAChange(
                              groupIndex,
                              rowIndex,
                              column.id,
                              !column.isNA
                            )
                          }
                        />
                      </div>
                    )}
                  </td>
                )
              )}
            </tr>
          ))}
        </>
      );
    }
  };

  const handleNAChange = (
    groupIndex: number,
    rowIndex: number | null,
    columnId: string,
    isNA: boolean
  ) => {
    // @ts-ignore
    handleProgressChange(
      groupIndex,
      rowIndex,
      columnId,
      isNA
        ? 0
        : // @ts-ignore // @ts-ignore
          data[groupIndex].rows[rowIndex]?.progressByColumn.find(
            (c: any) => c.id === columnId
          )?.progress || 0,
      isNA
    );
  };

  const calculateOverallProgress = (data: Group[]) => {
    let totalProgress = 0;
    let totalCount = 0;

    data.forEach((group) => {
      if (group.rows.length > 0) {
        group.rows.forEach((row) => {
          row.progressByColumn.forEach((column) => {
            if (!column.isNA) {
              totalProgress += column.progress ?? 0;
              totalCount++;
            }
          });
        });
      } else {
        group.manualProgress.forEach((column) => {
          if (!column.isNA) {
            totalProgress += column.progress ?? 0;
            totalCount++;
          }
        });
      }
    });
    return totalCount > 0 ? (totalProgress / totalCount).toFixed(2) : 'N/A';
  };

  const overallProgress = calculateOverallProgress(data);

  return (
    <div
      className={`overflow-x-scroll ${
        isExpanded ? 'h-screen' : 'h-[calc(100vh-450px)]'
      } whitespace-pre-wrap`}
    >
      <div className="flex py-2 pb-4 flex-col bg-white px-4 gap-y-2">
        <h3 className="text-sm">Overall Progress:</h3>
        <div
          className="bg-green-500 py-2 leading-none py-1 text-center text-black text-lg"
          style={{ width: `${overallProgress}%` }}
        >
          {overallProgress}%
        </div>
      </div>
      <div className="flex flex-col bg-gray-50 px-4 border-t gap-x-4 items-center md:flex-row w-full">
        <div className="w-full ">
          <TextInput
            value={locationFilter}
            placeholder="Filter locations by name"
            handleChange={setLocationFilter}
            id={'location-filter'}
            name={'locationFilter'}
          />
        </div>
        {!isDisabled ? (
          <div className="bg-gray-50 flex w-full flex-col  py-2 pt-4 px-2 items-end">
            <Button
              text={'Fetch latest scope'}
              onClick={() => setToUseLatestOutcomeConfiguration()}
            />
            <p className="text-xs my-2">
              If you have updated the scope recently, you can use this button to
              update the status tracker's list of locations or phases.
            </p>
          </div>
        ) : null}
      </div>
      <table className="min-w-full bg-white table-auto">
        <thead className="sticky-header">
          <tr className="row-1">
            <th className="px-4 py-4 border text-right text-sm text-right bg-white sticky left-0">
              Phases
            </th>
            {transformOutcomeToData(outcome)?.phases?.map((phase: any) => (
              <th
                key={phase.phaseKey}
                className="px-4 py-2 border text-sm"
                colSpan={phase.subColumns.length}
              >
                {phase.phaseLabel}
              </th>
            ))}
          </tr>
          <tr className="row-2">
            <th className="px-4 py-4 border text-right bg-white sticky left-0 border-r text-sm">
              Activity Groups
            </th>
            {transformOutcomeToData(outcome)?.phases?.flatMap((phase: any) =>
              phase.subColumns?.map((subColumn: any) => (
                <th
                  key={subColumn.columnKey}
                  className="px-4 py-2 border text-sm bg-gray-50"
                >
                  {subColumn.columnHeader}
                </th>
              ))
            )}
          </tr>
        </thead>
        <tbody>
          {data.map((group: Group, groupIndex: number) => {
            const progress = calculateOverallProgress([group]);
            return group.groupLabel
              ?.toLowerCase()
              ?.includes(locationFilter?.toLowerCase()) ? (
              <React.Fragment key={group.groupLabel}>
                <div className="py-2 px-8 flex flex-col gap-y-2 justify-center items-center gap-x-10">
                  <h5 className="text-xl">{group.groupLabel}</h5>
                  <div
                    className="bg-green-500 py-2 leading-none text-center text-black text-sm flex justify-center items-center"
                    style={{ width: `${progress}%` }}
                  >
                    {progress}%
                  </div>
                </div>
                {renderProgressForGroup(group, groupIndex)}
              </React.Fragment>
            ) : null;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MultiColumnProgress;
