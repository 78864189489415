import { useContext, useEffect, useState } from 'react';
import { BlueBadge } from '../../../../components/Badges/Badges';
import { Button } from '../../../../components/Buttons/Button';
import { SEARCH_PROGRESS_CONDITION_TYPES } from '../../../../graphql/queries/progress-activity-submissions';
import { SEARCH_PROJECT_LOCATIONS } from '../../../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import { LocationSelection } from '../location/search';
import { ConditionInfo } from './info';

import { NetworkContext } from '../../../../context/NetworkContext';
import { useIndexedDB } from '../../../../hooks/useIndexedDB';
import NumericInput from '../../../../components/Inputs/NumericInput';
import ComboBox from '../../../../components/Inputs/ComboBox';

export const ConditionEntry = ({
  condition,
  projectId,
  locked,
  handleConditionChange,
  handleRemoveCondition,
  handleConditionReportChange,
  handleRevertConditionChange,
  setModalConditionId,
}: any) => {
  const { useLazyQuery } = useOrganisationAwareApollo();
  const { isOffline } = useContext(NetworkContext);

  const [conditionSearchTerm, setConditionSearchTerm] = useState('');
  const [locationSearchTerm, setLocationSearchTerm] = useState('');

  const [isCollapsed, setIsCollapsed] = useState(!condition.isNew);

  const [debouncedConditionSearchTerm, setDebouncedConditionSearchTerm] =
    useState(conditionSearchTerm);

  const [searchProgressConditionTypes, { data: progressConditionTypes }] =
    useLazyQuery(SEARCH_PROGRESS_CONDITION_TYPES);

  const [searchProjectLocations, { data: projectLocations }] = useLazyQuery(
    SEARCH_PROJECT_LOCATIONS
  );

  const [cachedConditionTypesData, setCachedConditionTypesData] = useState([]);
  const [cachedProjectLocationsData, setCachedProjectLocationsData] = useState(
    []
  );

  const conditionsData = isOffline
    ? cachedConditionTypesData
    : progressConditionTypes?.searchProgressConditionTypes?.results || [];
  const projectLocationsData = isOffline
    ? cachedProjectLocationsData
    : projectLocations?.searchProjectLocations?.results || [];

  const {
    db,
    putItem: updateLocalData,
    readItem: readLocalData,
  } = useIndexedDB({ objectStoreName: `cached_project_data` });

  const conditionTypesLocalCacheId = `conditionTypes_${projectId}`;
  const projectLocationsLocalCacheId = `projectLocations_${projectId}`;

  const ConditionsInput = ({ condition }: any) => {
    return (
      <>
        <div className={`flex flex-col mt-2`}>
          {conditionsData?.map((conditionReport: any) => (
            <div
              key={conditionReport?.id}
              className="grid grid-cols-2 gap-x-2 py-2 text-sm items-center"
            >
              <label htmlFor={conditionReport?.id}>
                {conditionReport?.name} ({conditionReport.unit}):
              </label>
              <div className="flex flex-col">
                {conditionReport.dataType === 'DECIMAL' ? (
                  <NumericInput
                    id={conditionReport?.id}
                    name={conditionReport?.id}
                    min={0}
                    step={'0.5'}
                    isDisabled={locked}
                    value={
                      Number(
                        condition?.conditionReport?.find(
                          (aCondition: any) =>
                            aCondition.id === conditionReport?.id
                        )?.value
                      ) ?? 0
                    }
                    handleChange={(value: any) =>
                      handleConditionReportChange(
                        condition.id,
                        conditionReport.id,
                        String(value)
                      )
                    }
                    classNames="border p-1 rounded text-lg"
                  />
                ) : (
                  <ComboBox
                    testId="activity-selection"
                    placeholder="Search conditions"
                    isDisabled={locked}
                    id={conditionReport?.id}
                    options={conditionReport?.options?.map((option: any) => ({
                      id: option,
                      name: option,
                    }))}
                    handleChange={(id) => {
                      handleConditionReportChange(
                        condition.id,
                        conditionReport.id,
                        id
                      );
                    }}
                    value={
                      condition?.conditionReport?.find(
                        (aCondition: any) =>
                          aCondition.id === conditionReport?.id
                      )?.value ?? ''
                    }
                    validation={undefined}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const syncConditionAndLocationsDataWithLocal = async () => {
    const locationsData = await readLocalData(projectLocationsLocalCacheId);
    const conditionData = await readLocalData(conditionTypesLocalCacheId);

    setCachedProjectLocationsData(locationsData?.locationResults);
    setCachedConditionTypesData(conditionData?.conditionTypeResults);
  };

  useEffect(() => {
    if (isOffline && db) {
      syncConditionAndLocationsDataWithLocal();
    } else {
      if (db) {
        searchAndSyncConditionTypes();
        searchAndSyncLocations();
      }
    }
  }, [isOffline, db]);

  const searchAndSyncConditionTypes = async () => {
    if (!isOffline) {
      const result = await searchProgressConditionTypes({
        variables: {
          searchTerm: '',
          projectId,
          input: { limit: 600 },
        },
      });
      const conditionTypeResults =
        result?.data?.searchProgressConditionTypes?.results || [];
      if (conditionSearchTerm?.length === 0) {
        updateLocalData({
          id: conditionTypesLocalCacheId,
          conditionTypeResults,
        });
      }
    }
  };

  const searchAndSyncLocations = async () => {
    if (!isOffline) {
      const result = await searchProjectLocations({
        variables: {
          searchTerm: '',
          projectId,
          input: { limit: 600 },
        },
      });
      const locationResults =
        result?.data?.searchProjectLocations?.results || [];
      if (locationSearchTerm?.length === 0) {
        updateLocalData({ id: projectLocationsLocalCacheId, locationResults });
      }
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedConditionSearchTerm(conditionSearchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [conditionSearchTerm]);

  useEffect(() => {
    if (db) {
      searchAndSyncConditionTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedConditionSearchTerm, db]);

  const [debouncedLocationSearchTerm, setDebouncedLocationSearchTerm] =
    useState(conditionSearchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedLocationSearchTerm(locationSearchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [locationSearchTerm]);

  useEffect(() => {
    if (db) {
      searchAndSyncLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedLocationSearchTerm, db]);

  useEffect(() => {
    if (
      condition.savedLocally ||
      condition.toBeDeleted ||
      (!condition.isNew && !condition.hasChanged)
    ) {
      setIsCollapsed(true);
    }
  }, [condition]);

  return (
    <div
      style={condition.toBeDeleted ? { opacity: 0.4 } : {}}
      className="rounded-sm"
    >
      <div
        role="button"
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="select-none text-lg px-1 rounded-t-sm py-2 px-2 border-t border-b"
      >
        <p className="flex flex-col md:flex-row items-center gap-x-1">
          <ConditionInfo condition={condition} />
        </p>
      </div>
      {!isCollapsed ? (
        <div
          key={condition?.id}
          className="w-full border-b py-2 gap-2 px-4 bg-gray-50"
        >
          <div className="w-full flex md:flex-col flex-wrap lg:grid lg:grid-cols-2 gap-x-10 gap-y-4 items-center justify-center">
            <div className="w-full flex flex-col gap-y-2">
              <div>
                <label
                  htmlFor={`${condition?.id}-location`}
                  className="text-xs"
                >
                  Location:
                </label>
                <LocationSelection
                  id={`${condition?.id}-location`}
                  setSearchTerm={setLocationSearchTerm}
                  selectedLocation={condition.plc ?? undefined}
                  isDisabled={locked}
                  setSelectedLocation={(value: any) => {
                    handleConditionChange(condition.id, 'plc', value);
                  }}
                  locationSearchResults={projectLocationsData}
                />
              </div>
              <div className="flex flex-row md:flex-col mt-2 gap-x-6 gap-y-4">
                <div className="flex flex-col">
                  <label htmlFor={`${condition?.id}-time`} className="text-xs">
                    Time:
                  </label>
                  <input
                    id={`${condition?.id}-time`}
                    type="time"
                    disabled={locked}
                    className="p-2 h-10 border mt-1 border-gray-300"
                    value={condition.time}
                    onChange={(e) =>
                      handleConditionChange(
                        condition.id,
                        'time',
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <ConditionsInput condition={condition} />
            </div>

            <div className="flex flex-col">
              <label className="text-sm mb-2">Comments:</label>
              {condition?.comment ? (
                <div className="px-4 py-2 flex flex-col bg-gray-100 border rounded-sm border-gray-300 shadow-sm">
                  <div className="text-sm max-w-sm md:max-w-[600px] max-h-60 truncate">
                    {condition.comment}
                  </div>
                </div>
              ) : (
                <div>
                  <BlueBadge text="No Comment" />
                </div>
              )}
            </div>

            <div className="flex items-center gap-x-4 w-full py-2 border-t">
              <Button text="Done" onClick={() => setIsCollapsed(true)} />
              {condition.hasChanged && !locked ? (
                <div>
                  <Button
                    style={{ backgroundColor: 'orange' }}
                    text="Revert"
                    onClick={() => handleRevertConditionChange(condition.id)}
                  />
                </div>
              ) : null}
              {!locked ? (
                <Button
                  style={{ backgroundColor: '#b3063c' }}
                  text={condition.toBeDeleted ? 'Unremove' : `Remove`}
                  onClick={() => handleRemoveCondition(condition.id)}
                />
              ) : null}
            </div>
            <div className="flex items-center w-full py-2 border-t">
              <Button
                text={locked ? 'View Comments' : 'View / Add Comments'}
                onClick={() => setModalConditionId(condition.id)}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
