import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useContext, useEffect } from 'react';

import FormCard from '../../../components/FormCard/FormCard';

import { SAVE_PROJECT_ACTIVITY_GROUP } from '../../../graphql/mutations/projects';
import {
  GET_PROJECT_ACTIVITY_GROUPS,
  GET_PROJECT_ACTIVITY_TYPES,
} from '../../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { useToast } from '../../../context/ToastContext';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import LinkButton from '../../../components/Buttons/LinkButton';

export const SaveProjectActivityGroup = () => {
  let { id, projectId } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const isUpdate = !!id;

  const { addToast } = useToast();

  const [fetchProjectActivityGroups, { data }] = useLazyQuery(
    GET_PROJECT_ACTIVITY_GROUPS,
    {
      variables: { input: { id } },
      fetchPolicy: 'network-only',
    }
  );

  const tableQueryParams =
    sessionStorage.getItem('activity_groups_table_query_params') || '';

  useEffect(() => {
    if (isUpdate) {
      fetchProjectActivityGroups({ variables: { input: { id }, projectId } });
    }
  }, [id, isUpdate]);

  const [ProjectActivityGroup] = data?.getProjectActivityGroups?.results || [];

  const [fetchProjectActivityTypes, { data: patData }] = useLazyQuery(
    GET_PROJECT_ACTIVITY_TYPES,
    {
      variables: {
        projectId,
        onlyUnused: true,
        input: { limit: 1000, offset: 0 },
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetchProjectActivityTypes({ variables: { projectId } });
  }, [projectId]);

  const [saveProjectActivityGroup] = useMutation(SAVE_PROJECT_ACTIVITY_GROUP);

  const patResults = patData?.getProjectActivityTypes?.results ?? [];

  const activityServerResults = ProjectActivityGroup?.pagtm?.map(
    (result: any) => ({
      id: result?.pagtmat?.id,
      name: result?.pagtmat?.name,
      detail: result?.pagtmat?.pag?.name,
    })
  );

  const initialValues = id
    ? {
        id,
        name: ProjectActivityGroup?.name,
        activities: activityServerResults,
      }
    : { name: undefined, description: undefined, activities: [] };

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const previousActivities =
      activityServerResults?.map((activity: any) => activity.id) ?? [];
    const activitiesFromForm =
      values?.activities?.map((activity: any) => activity.id) ?? [];

    let activitiesToSave = previousActivities?.map((activityId: any) => {
      if (!activitiesFromForm.includes(activityId)) {
        return { id: activityId, toBeDeleted: true };
      }
      return { id: activityId };
    });

    activitiesFromForm?.forEach((formActivityId: any) => {
      if (!previousActivities?.includes(formActivityId)) {
        activitiesToSave?.push({ id: formActivityId });
      }
    });
    try {
      await saveProjectActivityGroup({
        variables: {
          id: values?.id,
          input: {
            ...values,
            projectId,
            activities: activitiesToSave,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      if (setSubmitting) {
        setSubmitting(false);
      }
      navigate(
        `/projects/${projectId}?tab=Activity+Groups${
          id && tableQueryParams ? `&?${tableQueryParams}` : ''
        }`
      );
      addToast('Activity Group saved successfully', 'success');
    } catch (error) {
      console.error(error);
      addToast('Failed to save Activity Group', 'error');
    }
  };

  const ProjectActivityGroupConfig = {
    formSections: [
      {
        title: 'Activity Group',
        description: 'Basic details about the activity group name',
        fields: [
          {
            id: 'name',
            type: 'input',
            title: 'Activity Group Name',
            placeholder: 'Some activity group name',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'activities',
            type: 'multiselectcombobox',
            title: 'Selected Activities',
            placeholder: 'Some activity group name',
            label: '',
            options: patResults?.map((projectActivity: any) => ({
              id: projectActivity.id,
              name: projectActivity.name,
              detail: projectActivity?.pag?.name,
            })),
          },
        ],
      },
    ],
  } as FormConfig;

  const ProjectActivityGroupCreationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
  });

  const { userProfile } = useContext(UserProfileContext);

  return (
    <>
      <LinkButton
        style={{
          backgroundColor: 'transparent',
          color: 'gray',
          boxShadow: 'none',
          borderRadius: 0,
        }}
        to={`/projects/${projectId}${
          tableQueryParams ? `?${tableQueryParams}` : ''
        }`}
      >
        {`< Back to Activity Groups`}
      </LinkButton>
      <FormCard
        key={`${ProjectActivityGroupConfig?.title}`}
        config={ProjectActivityGroupConfig}
        isDisabled={
          !userProfile?.permissions?.includes(Permission.UpdateProject)
        }
        validationSchema={ProjectActivityGroupCreationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </>
  );
};
