import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { ProjectSelection } from '../../../components/ProjectSelection/ProjectSelection';
import { Button } from '../../../components/Buttons/Button';
import { useCertificationType } from '../../../hooks/api/certifications/useCertificationTypes';
import useRequiredCertifications from '../../../hooks/api/useRequiredCertifications';
import { Table } from '../../../components/Tables/tables/Table';
import moment from 'moment';
import { ExpiringCertificationsList } from './expiring/list';
import { useTabs } from '../../../hooks/useTabs';
import { Tabs } from '../../../components/Tabs/Tabs';
import NoData from '../../../components/NoData/NoData';
import { ExpiringCertificationCompanyList } from './companies/ExpiringCertificationCompanyList';
import LinkButton from '../../../components/Buttons/LinkButton';

function startsWithVowel(word: string) {
  var vowels = 'aeiouAEIOU';
  return vowels.indexOf(word[0]) !== -1;
}

export const RequiredCertificationsTable = ({
  certificationTypeId,
  projectId,
}: {
  certificationTypeId?: string;
  projectId?: string;
}) => {
  const { limit, offset, setOffset } = usePagination();
  const [requiredCertifications, { count }, fetch] = useRequiredCertifications({
    certificationTypeId,
    projectId,
    limit,
    offset,
  });

  const headerMapping = useMemo(
    () =>
      ({
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        type: 'Type',
        updatedAt: 'Updated',
      } as Record<string, any>),
    []
  );

  const onPageChange = useCallback(
    (pageNumber: number) => {
      setOffset((pageNumber - 1) * limit);
    },
    [limit, setOffset]
  );

  useEffect(() => {
    onPageChange(1);
  }, [onPageChange]);

  const totalResults = count;
  const totalPages = Math.ceil(totalResults / limit);

  const navigate = useNavigate();

  return (
    <Table
      currentPage={Math.floor(offset / limit) + 1}
      totalPages={totalPages}
      totalResults={totalResults}
      itemsPerPage={limit}
      onClickRow={(id) => {
        navigate(
          `/labour/${requiredCertifications
            ?.find((cert: any) => cert.id === id)
            ?.type?.toLowerCase()}s/${id}?tab=Certifications`
        );
      }}
      headerMapper={(text: string) => headerMapping[text]}
      onPageChange={onPageChange}
      data={requiredCertifications.map(
        ({ id, firstName, lastName, email, type, updatedAt }: any) => ({
          id,
          firstName,
          lastName,
          email,
          type: type.charAt(0) + type.slice(1).toLowerCase(),
          updatedAt: moment.unix(parseInt(updatedAt) / 1000).fromNow(),
        })
      )}
      NoData={() => (
        <NoData>
          {`It looks like there are no assignees with missing certifications${
            projectId ? ' for this project' : ''
          }!`}
        </NoData>
      )}
      fetchPageOfDataForExport={async (limit: number, pageOffset: number) => {
        const { data } = await fetch({
          variables: {
            input: { limit, offset: pageOffset },
            ...(projectId ? { projectId } : {}),
            ...(certificationTypeId ? { certificationTypeId } : {}),
          },
          fetchPolicy: 'network-only',
        });
        setOffset(pageOffset);
        return data?.getRequiredCertifications?.results ?? [];
      }}
    />
  );
};

const LabourCertificationTypeDashboard = ({
  certificationType,
  selectedProject,
}: {
  certificationType: any;
  selectedProject: any;
}) => {
  const tabs = ['Missing & Required', 'Expired / Expiring / Invalid'];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  if (!certificationType) {
    return (
      <div className="h-64 flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <>
      <div className="flex bg-white w-full flex-row py-4">
        {tabs.map((tab) => (
          <div
            className={`${
              activeTab === tab ? 'bg-black text-white' : ''
            } justify-center flex items-center`}
          >
            <Button
              style={{
                borderRadius: 0,
                backgroundColor: 'transparent',
                color: activeTab === tab ? 'white' : 'black',
              }}
              text={tab}
              onClick={() => setActiveTab(tab)}
            />
          </div>
        ))}
      </div>
      {activeTab === 'Missing & Required' ? (
        <>
          <div className="flex flex-col bg-white px-2 py-2 gap-y-2">
            <div className="text-sm">
              Assignees who require{' '}
              {startsWithVowel(certificationType.name) ? 'an ' : 'a '}
              <strong>{certificationType.name}</strong> certification but have
              not provided one.
            </div>
          </div>
          <RequiredCertificationsTable
            certificationTypeId={certificationType.id}
            projectId={selectedProject?.id}
          />
        </>
      ) : null}
      {activeTab === 'Expired / Expiring / Invalid' ? (
        <ExpiringCertificationsList
          certificationTypeId={certificationType?.id}
          projectId={selectedProject?.id}
          showHeader={false}
        />
      ) : null}
    </>
  );
};

const CompanyCertificationTypeDashboard = ({
  certificationType,
  selectedProject,
}: {
  certificationType: any;
  selectedProject: any;
}) => {
  // const tabs = ['Missing & Required', 'Expired / Expiring / Invalid'];
  // TODO: Add 'Missing & Required' support
  const tabs = ['Expired / Expiring / Invalid'];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  if (!certificationType) {
    return (
      <div className="h-64 flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <>
      <div className="flex bg-white w-full flex-row py-4">
        {tabs.map((tab) => (
          <div
            className={`${
              activeTab === tab ? 'bg-black text-white' : ''
            } justify-center flex items-center`}
          >
            <Button
              style={{
                borderRadius: 0,
                backgroundColor: 'transparent',
                color: activeTab === tab ? 'white' : 'black',
              }}
              text={tab}
              onClick={() => setActiveTab(tab)}
            />
          </div>
        ))}
      </div>
      {activeTab === 'Missing & Required' ? (
        <>
          <div className="flex flex-col bg-white px-2 py-2 gap-y-2">
            <div className="text-sm">
              Companies who require{' '}
              {startsWithVowel(certificationType.name) ? 'an ' : 'a '}
              <strong>{certificationType.name}</strong> certification but have
              not provided one.
            </div>
          </div>
        </>
      ) : null}
      {activeTab === 'Expired / Expiring / Invalid' ? (
        <ExpiringCertificationCompanyList
          certificationTypeId={certificationType?.id}
          projectId={selectedProject?.id}
        />
      ) : null}
    </>
  );
};

export const CertificationTypeDashboard = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [certificationType] = useCertificationType(id);

  const tabs = ['Labour', 'Companies'];

  const [activeTab, setActiveTab] = useTabs(tabs);

  if (!certificationType) {
    return (
      <div className="h-64 flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  const tableQueryParams =
    sessionStorage.getItem('certifications_table_query_params') || '';

  return (
    <PaginationProvider>
      <div>
        <div className="flex w-full border-b mb-2">
          <LinkButton
            style={{
              backgroundColor: 'transparent',
              color: 'black',
              boxShadow: 'none',
              borderRadius: 0,
            }}
            to={`/labour/certifications${
              tableQueryParams ? `?${tableQueryParams}` : ''
            }`}
          >
            {`< Back to Certifications`}
          </LinkButton>
        </div>
        <header className="border-b border-gray-200 py-4">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {certificationType.name}
          </h1>
        </header>
        <Tabs activeTab={activeTab} tabs={tabs} setActiveTab={setActiveTab} />
        <div className="flex w-full h-full items-center bg-white p-2">
          <ProjectSelection
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
          />
        </div>
        {activeTab === 'Labour' ? (
          <LabourCertificationTypeDashboard
            certificationType={certificationType}
            selectedProject={selectedProject}
          />
        ) : null}
        {activeTab === 'Companies' ? (
          <CompanyCertificationTypeDashboard
            certificationType={certificationType}
            selectedProject={selectedProject}
          />
        ) : null}
      </div>
    </PaginationProvider>
  );
};
