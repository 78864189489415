import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BlueBadge, YellowBadge } from '../../../../components/Badges/Badges';
import { Button } from '../../../../components/Buttons/Button';
import Switch from '../../../../components/Inputs/Switch';
import { NetworkContext } from '../../../../context/NetworkContext';
import { SEARCH_PROGRESS_ACTIVITY_TYPES } from '../../../../graphql/queries/progress-activity-submissions';
import { SEARCH_PROJECT_LOCATIONS } from '../../../../graphql/queries/projects';
import { useIndexedDB } from '../../../../hooks/useIndexedDB';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import { LocationSelection } from '../location/search';
import { ActivityInfo } from './info';
import { ActivitySelection } from './search';
import {
  areActivityStartAndEndTimesValid,
  calculateDurationInDecimalHours,
  calculateTotalPersonHours,
} from './utils';
import moment from 'moment';
import { unstable_batchedUpdates } from 'react-dom';
import DropDown from '../../../../components/Inputs/Dropdown';
import ValidationMessage from '../../../../components/Validation/ValidationMessage';

export const ActivityEntry = React.memo(
  ({
    activity,
    activities,
    projectId,
    handleActivityChange,
    handleRoleCountChange,
    locked,
    handleRemoveActivity,
    handleRevertActivityChange,
    selectedTeam,
    setModalActivityId,
    progressCodes,
  }: any) => {
    const { useLazyQuery } = useOrganisationAwareApollo();
    const { isOffline: offlineStatus } = useContext(NetworkContext);

    const duration = useMemo(
      () => calculateDurationInDecimalHours(activity),
      [activity]
    );

    const personHours = useMemo(
      () => calculateTotalPersonHours([activity]),
      [activity]
    );

    const roundedPersonHours = personHours.toFixed(2);
    const roundedDuration = duration.toFixed(2);

    const isOffline = useMemo(() => offlineStatus, [offlineStatus]);

    const [activitySearchTerm, setActivitySearchTerm] = useState('');
    const [locationSearchTerm, setLocationSearchTerm] = useState('');

    const [isCollapsed, setIsCollapsed] = useState(!activity.isNew);

    const [debouncedActivitySearchTerm, setDebouncedActivitySearchTerm] =
      useState(activitySearchTerm);

    const [searchProgressActivityTypes, { data: progressActivityTypes }] =
      useLazyQuery(SEARCH_PROGRESS_ACTIVITY_TYPES);

    const [searchProjectLocations, { data: projectLocations }] = useLazyQuery(
      SEARCH_PROJECT_LOCATIONS
    );

    const [cachedActivityTypesData, setCachedActivityTypesData] = useState([]);
    const [cachedProjectLocationsData, setCachedProjectLocationsData] =
      useState([]);

    const projectActivityTypesData = isOffline
      ? cachedActivityTypesData
      : progressActivityTypes?.searchProgressActivityTypes?.results || [];
    const projectLocationsData = isOffline
      ? cachedProjectLocationsData
      : projectLocations?.searchProjectLocations?.results || [];

    const {
      db,
      putItem: updateLocalData,
      readItem: readLocalData,
    } = useIndexedDB({ objectStoreName: `cached_project_data` });

    const [showRolesInput, setShowRolesInput] = useState(false);

    const OverlappingActivities = React.memo(
      ({ isEnabled, hasOverlaps, overlappingActivities, name }: any) => {
        return (
          isEnabled &&
          hasOverlaps && (
            <div className="text-red-500 text-xs my-2 mt-4">
              <div className="bg-yellow-50 py-2 border-t border-t-yellow-400 px-2 rounded-sm text-yellow-800">
                Warning: Overlaps in {name}'s activities are present which may
                effect reporting
              </div>
              <ul className="bg-red-50 border-b border-b-red-200 px-2 py-1">
                {overlappingActivities.map((overlap: any) => (
                  <li className="my-2" key={overlap.id}>
                    {name} was marked as involved in activity "
                    {overlap?.pat?.name}
                    ", which runs from {overlap.start} to{' '}
                    {overlap.endsAtMidnight ? '24:00' : overlap.finish}.
                  </li>
                ))}
              </ul>
            </div>
          )
        );
      }
    );

    const getOverlappingActivities = useCallback(
      (roleId: any) => {
        return activities.filter((otherActivity: any) => {
          if (otherActivity.id === activity.id) {
            return false;
          }

          const roleAssignedInOtherActivity =
            otherActivity.assignmentRolesList?.some(
              (assignedRole: any) =>
                assignedRole?.id === roleId && assignedRole.enabled
            );

          if (!roleAssignedInOtherActivity) {
            return false;
          }

          const currentActivityStart = moment(activity.start, 'HH:mm');
          const currentActivityEnd = activity.endsAtMidnight
            ? moment('24:00', 'HH:mm')
            : moment(activity.finish, 'HH:mm');

          const otherActivityStart = moment(otherActivity.start, 'HH:mm');
          const otherActivityEnd = otherActivity.endsAtMidnight
            ? moment('24:00', 'HH:mm')
            : moment(otherActivity.finish, 'HH:mm');

          const isOverlapping =
            currentActivityStart.isBefore(otherActivityEnd) &&
            currentActivityEnd.isAfter(otherActivityStart);

          return isOverlapping;
        });
      },
      [
        activities,
        activity.id,
        activity.start,
        activity.endsAtMidnight,
        activity.finish,
      ]
    );

    const RolesInput = React.memo(({ activity }: any) => {
      const totalAllocatedRoles = activity?.assignmentRolesList.reduce(
        (accumulator: any, currentObject: any) => {
          return accumulator + (currentObject.enabled ? 1 : 0);
        },
        0
      );
      const activeTeamMembers = selectedTeam?.team?.filter(
        (role: any) => role.enabledOnTeam
      );
      const inactiveTeamMembers = selectedTeam?.team?.filter(
        (role: any) => !role.enabledOnTeam
      );

      return (
        <>
          <div className="my-2 mb-4 w-full">
            <Button
              text={`Workers Allocated (${totalAllocatedRoles})`}
              style={totalAllocatedRoles === 0 ? { background: 'orange' } : {}}
              onClick={() =>
                setShowRolesInput(
                  activity?.id === showRolesInput ? undefined : activity?.id
                )
              }
            />
          </div>
          <div
            className={`${
              showRolesInput === activity.id ? '' : 'hidden'
            } w-full bg-gray-50 border rounded-lg py-4 mb-3`}
          >
            <p className="text-xs px-10 pb-3">
              Please select who worked on this activity.
            </p>
            <hr className="pb-2" />
            <h3 className="px-4 text-xs text-gray-600 font-medium mb-2 mt-2">
              Active Team Members ({activeTeamMembers?.length ?? 0})
            </h3>
            <div
              className={`grid grid-cols-1 lg:grid-cols-2 gap-x-6  gap-y-2 `}
            >
              {activeTeamMembers?.map((role: any) => {
                const overlappingActivities = getOverlappingActivities(role.id);
                const hasOverlaps = overlappingActivities.length > 0;
                const isEnabled = activity?.assignmentRolesList?.find(
                  (aRole: any) => aRole.id === role?.id
                )?.enabled;
                return (
                  <div
                    key={role?.id}
                    className="grid grid-cols-1 gap-x-0 py-2 px-4 xl:px-16 text-sm items-center"
                  >
                    <div className="flex flex-col">
                      <Switch
                        text={`${role?.name} (${role.detail})`}
                        isDisabled={locked}
                        enabled={
                          activity?.assignmentRolesList?.find(
                            (aRole: any) => aRole.id === role?.id
                          )?.enabled
                        }
                        handleChange={(value: any) =>
                          handleRoleCountChange(activity.id, role.id, value)
                        }
                      />
                    </div>
                    <OverlappingActivities
                      name={role.name}
                      isEnabled={isEnabled}
                      overlappingActivities={overlappingActivities}
                      hasOverlaps={hasOverlaps}
                    />
                  </div>
                );
              })}
            </div>

            <hr className="my-2 mt-2" />
            <h3 className="px-4 text-xs text-gray-600 font-medium mt-4 mb-2">
              Inactive Team Members ({inactiveTeamMembers?.length ?? 0})
            </h3>
            <div
              className={`grid grid-cols-1 lg:grid-cols-2 gap-x-6  gap-y-2 `}
            >
              {inactiveTeamMembers?.map((role: any) => {
                const overlappingActivities = getOverlappingActivities(role.id);
                const hasOverlaps = overlappingActivities.length > 0;
                const isEnabled = activity?.assignmentRolesList?.find(
                  (aRole: any) => aRole.id === role?.id
                )?.enabled;

                return (
                  <div
                    key={`${role?.id}_inactive`}
                    className="grid grid-cols-1 gap-x-0 py-2 px-4 xl:px-16 text-sm items-center"
                  >
                    <div className="flex flex-col">
                      <Switch
                        text={`${role?.name} (${role.detail})`}
                        isDisabled={locked}
                        enabled={isEnabled}
                        handleChange={(value: any) =>
                          handleRoleCountChange(activity.id, role.id, value)
                        }
                      />
                    </div>
                    <OverlappingActivities
                      name={role.name}
                      isEnabled={isEnabled}
                      overlappingActivities={overlappingActivities}
                      hasOverlaps={hasOverlaps}
                    />
                  </div>
                );
              })}
            </div>
            <h3 className="px-6 text-xs text-gray-600 font-medium py-2 pt-4">
              You can still assign these team members but you may need to sync
              with the server, or should notify your organisation to ensure team
              records are kept up to date.
            </h3>
          </div>
        </>
      );
    });

    const activityTypesLocalCacheId = `activityTypes_${projectId}`;
    const projectLocationsLocalCacheId = `projectLocations_${projectId}`;

    const syncActivityAndLocationsDataWithLocal = async () => {
      const locationsData = await readLocalData(projectLocationsLocalCacheId);
      const activityData = await readLocalData(activityTypesLocalCacheId);

      unstable_batchedUpdates(() => {
        setCachedProjectLocationsData(locationsData?.locationResults);
        setCachedActivityTypesData(activityData?.activityTypeResults);
      });
    };

    useEffect(() => {
      if (isOffline && db) {
        syncActivityAndLocationsDataWithLocal();
      } else {
        if (db) {
          searchAndSyncActivityTypes();
          searchAndSyncLocations();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOffline, db]);

    const searchAndSyncActivityTypes = async () => {
      if (!isOffline) {
        const result = await searchProgressActivityTypes({
          variables: {
            searchTerm: '',
            projectId,
            input: { limit: 600 },
          },
        });
        const activityTypeResults =
          result?.data?.searchProgressActivityTypes?.results || [];
        if (activitySearchTerm?.length === 0) {
          updateLocalData({
            id: activityTypesLocalCacheId,
            activityTypeResults,
          });
        }
      }
    };

    const searchAndSyncLocations = async () => {
      if (!isOffline) {
        const result = await searchProjectLocations({
          variables: {
            searchTerm: '',
            projectId,
            input: { limit: 600 },
          },
        });
        const locationResults =
          result?.data?.searchProjectLocations?.results || [];
        if (activitySearchTerm?.length === 0) {
          updateLocalData({
            id: projectLocationsLocalCacheId,
            locationResults,
          });
        }
      }
    };

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedActivitySearchTerm(activitySearchTerm);
      }, 600); // 300ms debounce time
      return () => {
        clearTimeout(handler);
      };
    }, [activitySearchTerm]);

    useEffect(() => {
      if (db) {
        searchAndSyncActivityTypes();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedActivitySearchTerm, db]);

    const [debouncedLocationSearchTerm, setDebouncedLocationSearchTerm] =
      useState(activitySearchTerm);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedLocationSearchTerm(locationSearchTerm);
      }, 600); // 300ms debounce time
      return () => {
        clearTimeout(handler);
      };
    }, [locationSearchTerm]);

    useEffect(() => {
      if (db) {
        searchAndSyncLocations();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedLocationSearchTerm, db]);

    useEffect(() => {
      if (
        activity.savedLocally ||
        activity.toBeDeleted ||
        (!activity.isNew && !activity.hasChanged)
      ) {
        setIsCollapsed(true);
      }
    }, [activity]);

    return (
      <div
        style={activity.toBeDeleted ? { opacity: 0.4 } : {}}
        className="rounded-sm"
      >
        <div
          role="button"
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="select-none text-lg rounded-t-sm py-2 px-2 border-t border-b"
        >
          <p className="flex flex-col md:flex-row items-center gap-x-1">
            <ActivityInfo activity={activity} progressCodes={progressCodes} />
          </p>
        </div>
        {!isCollapsed ? (
          <div
            key={activity?.id}
            className="w-full border-b py-2 gap-2 px-4 bg-gray-50"
          >
            <div className="w-full flex md:flex-col flex-wrap lg:grid lg:grid-cols-1 gap-x-10 gap-y-4 items-center justify-center">
              <div className="w-full grid md:grid-cols-2 lg:grid-cols-4 gap-y-2 gap-x-4">
                <div>
                  <label
                    htmlFor={`${activity.id}-activity`}
                    className="text-xs"
                  >
                    Activity:
                  </label>
                  <ActivitySelection
                    id={`${activity.id}-activity`}
                    setSearchTerm={setActivitySearchTerm}
                    isDisabled={locked}
                    selectedActivity={activity?.pat ?? undefined}
                    setSelectedActivity={(value: any) => {
                      handleActivityChange(activity.id, 'pat', value);
                      handleActivityChange(
                        activity.id,
                        'progressCodeId',
                        value.defaultProgressCode?.id
                      );
                    }}
                    activitySearchResults={projectActivityTypesData?.map(
                      (pat: any) => ({ ...pat, detail: pat?.pag?.name })
                    )}
                  />
                </div>
                <div>
                  <label
                    htmlFor={`${activity.id}-location`}
                    className="text-xs"
                  >
                    Location:
                  </label>
                  <LocationSelection
                    id={`${activity.id}-location`}
                    setSearchTerm={setLocationSearchTerm}
                    isDisabled={locked}
                    selectedLocation={activity.pl ?? undefined}
                    setSelectedLocation={(value: any) => {
                      handleActivityChange(activity.id, 'pl', value);
                    }}
                    locationSearchResults={projectLocationsData}
                  />
                </div>

                <div className="flex flex-col mt-2">
                  <label
                    htmlFor={`${activity.id}-start-time`}
                    className="text-xs"
                  >
                    Start:
                  </label>
                  <input
                    id={`${activity.id}-start-time`}
                    type="time"
                    className="p-2 h-10 border mt-1 border-gray-300"
                    value={activity.start}
                    disabled={locked}
                    onChange={(e) => {
                      const newStartTime = e.target.value;
                      handleActivityChange(activity.id, 'start', newStartTime);
                    }}
                  />
                  {!areActivityStartAndEndTimesValid(activity) ? (
                    <ValidationMessage
                      className="mt-3"
                      message="Finish time cannot be earlier than or equal to the start time."
                    />
                  ) : null}
                </div>
                <div className="flex flex-col mt-2">
                  <label
                    htmlFor={`${activity.id}-end-time`}
                    className="text-xs"
                  >
                    End:
                  </label>
                  {!activity.endsAtMidnight ? (
                    <input
                      id={`${activity.id}-end-time`}
                      type="time"
                      className="p-2 h-10 mt-1 border border-gray-300"
                      value={activity.finish}
                      disabled={locked}
                      onChange={(e) => {
                        const newEndTime = e.target.value;
                        handleActivityChange(activity.id, 'finish', newEndTime);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === '00:00') {
                          handleActivityChange(
                            activity.id,
                            'endsAtMidnight',
                            true
                          );
                        } else {
                          handleActivityChange(
                            activity.id,
                            'endsAtMidnight',
                            false
                          );
                        }
                      }}
                    />
                  ) : null}
                  <div className="mt-3">
                    <Switch
                      text="Midnight"
                      description="Select this if the activity ended at or continued past midnight."
                      handleChange={(checked) => {
                        handleActivityChange(
                          activity.id,
                          'endsAtMidnight',
                          checked
                        );
                      }}
                      enabled={activity.endsAtMidnight}
                    />
                  </div>
                </div>
                {progressCodes?.length ? (
                  <div>
                    <label
                      htmlFor={`${activity.id}-progress-code`}
                      className="text-xs"
                    >
                      Progress Code:
                    </label>
                    <DropDown
                      id={`${activity.id}-progress-code`}
                      options={progressCodes.map(
                        (code: { code: string; id: string }) => ({
                          id: code.id,
                          name: code.code,
                          value: code.id,
                        })
                      )}
                      placeholder={'Select progress code'}
                      handleChange={(value: string) => {
                        handleActivityChange(
                          activity.id,
                          'progressCodeId',
                          value
                        );
                      }}
                      value={activity.progressCodeId}
                    />
                  </div>
                ) : null}
              </div>
              <div className="w-full flex items-center flex-col md:flex-row justify-between xl:justify-evenly">
                <div className="w-full flex flex-col items-center">
                  <RolesInput activity={activity} />
                </div>
              </div>
              <div className="w-full gap-y-4 grid gap-x-10 lg:grid-cols-2">
                <div className="flex flex-col">
                  <label className="text-sm mb-2">Activity Info:</label>
                  {(activity.start &&
                    (activity.finish || activity.endsAtMidnight) &&
                    duration) ||
                  (activity.assignmentRolesList && personHours) ? (
                    <div className="px-4 py-2 flex flex-col bg-gray-100 border rounded-sm border-gray-300 shadow-sm">
                      {activity.start &&
                      (activity.finish || activity.endsAtMidnight) &&
                      duration ? (
                        <div className="text-sm">
                          <label className="text-xs">Duration:</label>{' '}
                          {roundedDuration} hour
                          {roundedDuration !== '1' ? 's' : 's'}
                        </div>
                      ) : null}
                      {activity.assignmentRolesList && roundedPersonHours ? (
                        <div className="text-sm">
                          <label className="text-xs">Man Hours:</label>{' '}
                          {roundedPersonHours} hour
                          {roundedPersonHours !== '1' ? 's' : 's'}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div>
                      <YellowBadge text="No Duration Information" />
                    </div>
                  )}
                </div>

                <div className="flex flex-col">
                  <label className="text-sm mb-2">Comments:</label>
                  {activity?.comment ? (
                    <div className="px-4 py-2 flex flex-col bg-gray-100 border rounded-sm border-gray-300 shadow-sm">
                      <div className="text-sm max-w-sm md:max-w-[600px] max-h-60 truncate">
                        {activity.comment}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <BlueBadge text="No Comment" />
                    </div>
                  )}
                  <div className="flex items-center w-full py-2 mt-2 border-t">
                    <Button
                      text={locked ? 'View Comments' : 'View / Add Comments'}
                      onClick={() => setModalActivityId(activity.id)}
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-x-4 w-full py-2 border-t">
                <Button text="Done" onClick={() => setIsCollapsed(true)} />
                {activity.hasChanged ? (
                  <div>
                    <Button
                      style={{ backgroundColor: 'orange' }}
                      text="Revert"
                      onClick={() => handleRevertActivityChange(activity.id)}
                    />
                  </div>
                ) : null}
                {locked ? null : (
                  <Button
                    style={{ backgroundColor: '#b3063c' }}
                    text={activity.toBeDeleted ? 'Unremove' : `Remove`}
                    onClick={() => handleRemoveActivity(activity.id)}
                  />
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
);
