import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useSort({
  defaultSortColumn = '',
}: {
  defaultSortColumn?: string;
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [sortByColumn, setSortByColumn] = useState(
    () => searchParams.get('sort') || defaultSortColumn
  );
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>(() =>
    searchParams.get('sortDir')
      ? (searchParams.get('sortDir') as 'ASC' | 'DESC')
      : 'ASC'
  );

  useEffect(() => {
    setSearchParams((params) => {
      params.set('sort', sortByColumn);
      params.set('sortDir', sortDirection);
      return params;
    });
  }, [sortByColumn, sortDirection, setSearchParams]);

  const setSort = (column: string) => {
    if (sortByColumn === column) {
      setSortDirection((prevDirection) =>
        prevDirection === 'DESC' ? 'ASC' : 'DESC'
      );
    } else {
      setSortByColumn(column);
      setSortDirection('ASC');
    }
  };

  return {
    sortByColumn,
    sortDirection,
    setSort,
  };
}
